import axios from "axios";
import api from "../../Utils/axiosInterceptor";
import i18n from "../../Utils/i18n/i18n";
import { lookupsMapper } from "../../Utils/helpers";


// export const getLookups = async () => {
//     const res = await api.get(`${process.env.REACT_APP_API_URL}api/lookups`);
//     if (res.data) {
//         return res.data
//     } else {
//         return { error: true }
//     }
// }
export const getLookupsEn = async () => {
    let customConfig: any = {
        headers: {
            'Language': 'en',
            'Accept-Language': 'en'
        },
        custom: true
    }

    const token: any = localStorage.getItem('tokens');

    // Add authorization header with the token
    if (token) {
        customConfig.headers['Authorization'] = `Bearer ${JSON.parse(token).accessToken}`;
    }
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/lookups`, customConfig);
    if (res.data) {

        return lookupsMapper(res.data, 'en')
    } else {
        return { error: true }
    }
}
export const getLookupsAr = async () => {
    let customConfig: any = {
        headers: {
            'Language': 'ar',
            'Accept-Language': 'ar'
        },
        custom: true
    }

    const token: any = localStorage.getItem('tokens');

    // Add authorization header with the token
    if (token) {
        customConfig.headers['Authorization'] = `Bearer ${JSON.parse(token).accessToken}`;
    }
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/lookups`, customConfig);
    if (res.data) {
        return lookupsMapper(res.data, 'ar')
    } else {
        return { error: true }
    }
}

export const getBrands = async () => {
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/brand/all`);
    if (res.data) {
        return res.data
    } else {
        return { error: true }
    }
}
export const getAgencyData = async (agencyId: any) => {
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/agency/${agencyId}`);
    if (res.data) {
        return res.data
    } else {
        return { error: true }
    }
}
export const getLookupsTranslations = (lookups: any) => {
    let translations: any = {}
    lookups && lookups[i18n.language]?.socialPlatforms?.forEach((p: any) => {
        p.socialPlatformServices.forEach((s: any) => {
            translations[s.key] = `${p.name} ${s.name}`
        })
    })
    lookups && lookups[i18n.language]?.campaignObjectives?.forEach((obj: any) => {
        translations[obj.key] = obj.name;
    })
    lookups && lookups[i18n.language]?.campaignStatuses?.forEach((stat: any) => {
        translations[stat.key] = stat.name;
    })
    lookups && lookups[i18n.language]?.lineItemBookingStatuses?.forEach((s: any) => {
        translations[s.key] = s.name;
    })
    lookups && lookups[i18n.language]?.countries?.forEach((s: any) => {
        translations[s.key] = s.name;
    })
    lookups && lookups[i18n.language]?.clientBrandRelationships?.forEach((s: any) => {
        translations[s.key] = s.name;
    })
    return translations
}