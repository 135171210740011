import axios from "axios";
import { forceLogout, logout } from "../Context/Actions/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { LastActiveDate } from "../Context/Actions/clients";
// Create an Axios instance
const api = axios.create();
// {
//   baseURL: process.env.REACT_APP_API_URL // Replace with your config variable name
// }
// Request interceptor
api.interceptors.request.use(
  (config: any) => {
    // Get the language from wherever you store it (e.g., context, local storage)
    const language = localStorage.getItem("i18nextLng") || "en"; // Replace with logic to get the language
    if (config.custom) {
      return config;
    } else {
      // Add language header
      config.headers["Accept-Language"] = language;
      config.headers["Language"] = language;

      // Get the token from wherever you store it (e.g., context, local storage)
      const token: any = localStorage.getItem("tokens");

      // Add authorization header with the token
      if (token) {
        config.headers["Authorization"] = `Bearer ${
          JSON.parse(token).accessToken
        }`;
      }

      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Handle error responses
    if (error.response?.status === 401) {
      // Refresh the token
      try {
        const refreshed = await refreshToken();

        if (refreshed?.status) {
          // Retry the original request
          error.config.headers["Authorization"] = refreshed.token;
          return api.request(error.config);
        } else {
          // Logout or perform any other action when user is not found
          forceLogout();
        }
      } catch (refreshError) {
        // Handle token refresh error, e.g., logout the user
        console.error("Failed to refresh token:", refreshError);
        // forceLogout();
      }
    }

    return Promise.reject(error);
  }
);

// Function to refresh the token
const refreshToken = async () => {
  const user: any = auth.currentUser
    ?.getIdToken(true)
    .then(function (idToken) {
      let tokens = JSON.parse(localStorage.getItem("tokens")!);
      tokens.accessToken = idToken;
      localStorage.setItem("tokens", JSON.stringify(tokens));
      LastActiveDate();
      return { status: true, token: idToken };
    })
    .catch(function (error) {
      return { status: false };
    });
  return user;
};

export default api;
