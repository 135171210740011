import { useState, useContext, useEffect } from "react";
import { AuthStateContext } from "../../../Context/MainContext";
import { resetPassword } from "../../../Context/Actions/auth";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../Auth.module.scss";

import { t } from "i18next";
import { Row, Col, Input, Form, notification } from "antd";
import type { FormItemProps } from "antd";
import React from "react";
import login_vector from "../../../assets/imgs/login-vector.png";
import Alert from "../../../Components/Alerts";
import {
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineXCircle,
} from "react-icons/hi";
import i18n from "../../../Utils/i18n/i18n";

const MyFormItemContext = React.createContext<(string | number)[]>([]);

interface MyFormItemGroupProps {
  prefix: string | number | (string | number)[];
  children: React.ReactNode;
}

function toArr(
  str: string | number | (string | number)[]
): (string | number)[] {
  return Array.isArray(str) ? str : [str];
}

const MyFormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );

  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

const MyFormItem = ({ name, ...props }: FormItemProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

  return <Form.Item name={concatName} {...props} />;
};
const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [form] = Form.useForm();
  const query = useQuery();
  const [api, contextHolder] = notification.useNotification();
  const { state, dispatch } = useContext(AuthStateContext);

  const onFinish = (user: { email: string; password: string }) => {
    if (
      password &&
      password.length >= 8 &&
      hasNumber(password) &&
      hasCapitalLetter(password)
    ) {
      setLoading(true);
      setError("");
      try {
        resetPassword(query.get("oobCode")!, password)
          .then((res: any) => {
            navigate("/login");
            dispatch({ type: "RESET_PASSWORD_SUCCEED" });
          })
          .catch((e: any) => {
            console.log(e.message);
          });
      } catch (error: any) {
        console.log(error.message);
      }
    }
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      form.resetFields(); // Trigger form validation on language change
      setPassword("");
      setConfirmPassword("");
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [form, i18n]);
  const hasNumber = (value: string) => {
    return /\d/.test(value);
  };
  const hasCapitalLetter = (value: string) => {
    return /[A-Z]/.test(value);
  };
  return (
    <div className={styles.login_containier}>
      <Row
        justify="space-between"
        align="middle"
        gutter={12}
        className={styles.loginRow}
      >
        <Col lg={8} xl={8} xxl={8} xs={20} push={4}>
          <h2 className={styles.login_header}>{t("reset_password_label")}</h2>
          {error && error !== "" && (
            <Alert
              message={t(error)}
              type="danger"
              icon={<HiOutlineXCircle />}
            />
          )}
          <Form
            autoComplete="off"
            validateTrigger="onSubmit"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={styles.login_form}
          >
            <div className="form-group">
              <label className="lavad_label">{t("new_password_label")}</label>
              <div className="relative">
                <Form.Item
                  name="password"
                  className="nomargin"
                  rules={[{ required: true, message: t("password_required") }]}
                >
                  <Input
                    type={hidePassword ? "password" : "text"}
                    name="password"
                    className="lavad-input lavad-input-lg"
                    placeholder={t("enter_password_placeholder")}
                    autoComplete="false"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                {hidePassword ? (
                  <HiOutlineEyeOff
                    onClick={() => setHidePassword(!hidePassword)}
                    className={styles.showPW}
                  />
                ) : (
                  <HiOutlineEye
                    onClick={() => setHidePassword(!hidePassword)}
                    className={styles.hidePW}
                  />
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="lavad_label">
                {t("confirm_new_password_label")}
              </label>
              <div className="relative">
                <Form.Item
                  name="confirmPassword"
                  className="nomargin"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: t("password_required"),
                    },
                    ({ getFieldValue }: any) => ({
                      validator(_: any, value: any) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("password_not_match"))
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type={hideConfirmPassword ? "password" : "text"}
                    name="confirmPassword"
                    className="lavad-input lavad-input-lg"
                    placeholder={t("enter_password_placeholder")}
                    autoComplete="false"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
                {hideConfirmPassword ? (
                  <HiOutlineEyeOff
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                    className={styles.showPW}
                  />
                ) : (
                  <HiOutlineEye
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                    className={styles.hidePW}
                  />
                )}
              </div>
            </div>
            <div className={styles.password_criteria}>
              <h4>{t("password_must_have")}</h4>
              <ul>
                <li
                  className={`${styles.password_criteria_item} + ${
                    password && password !== ""
                      ? password?.length >= 8
                        ? "success-text"
                        : "danger-text"
                      : "netural-text"
                  }`}
                >
                  {t("password_min_length")}
                </li>
                <li
                  className={`${
                    styles.password_criteria_item + password &&
                    password?.length > 0
                      ? hasNumber(password)
                        ? "success-text"
                        : "danger-text"
                      : "netural-text"
                  }`}
                >
                  {t("password_min_number")}
                </li>

                <li
                  className={`${
                    styles.password_criteria_item + password &&
                    password?.length > 0
                      ? hasCapitalLetter(password)
                        ? "success-text"
                        : "danger-text"
                      : "netural-text"
                  }`}
                >
                  {t("password_min_capital")}
                </li>
              </ul>
            </div>
            <button
              className={`lavad-btn lavad-btn-primary lavad-btn-lg btn-block ${
                loading ? "loading" : ""
              }`}
              type="submit"
            >
              {loading ? (
                <span className="icon-loader spin"></span>
              ) : (
                t("reset_password_label")
              )}
            </button>
          </Form>
        </Col>
        <Col lg={8} xl={8} xxl={8} xs={20} pull={2}>
          <div className="justify-end">
            <img
              className={styles.login_vector}
              src={login_vector}
              alt="login vector"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ResetPassword;
