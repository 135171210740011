import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { formatNumber, isValidURL } from "../../Utils/helpers";
import { Tooltip } from "antd";
import WarningImg from "../../assets/imgs/Attention.svg"


const InfluencerDetailsPlatformItem = ({
  platformImg,
  platformName,
  platfromLink,
  followersCount,
  isVerified,
  platformKey,
  isActive
}: {
  platformImg: string;
  platformName: string;
  platfromLink: string;
  followersCount: number;
  isVerified: boolean;
  platformKey: string;
  isActive: boolean;
}) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();

  return (
    <div className={styles.platform_item}>
      <Tooltip
        placement={"top"}
        trigger="hover"
        destroyTooltipOnHide={true}
        overlayStyle={{
          width: "auto",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        overlayClassName="platform_tooltip"
        title={platfromLink && isValidURL(platfromLink) ? t("platform_profile", { socialPlatform: platformName }) : t("no_available_link")}
      >
        <a
          href={platfromLink && isValidURL(platfromLink) ? platfromLink : undefined}
          rel="noreferrer"
          target="_blank"
        >
          <img src={platformImg} alt="" />
        </a>
      </Tooltip>
      <div className={styles.text_container}>
        <p className="title">
          {platformName}
          {isVerified ? <img className={styles.verfiedBadge} src={`/imgs/${platformKey.toLowerCase()}-badge.svg`} alt="verfied-flag" />
            : ""}
          {!Object.is(followersCount, null) && followersCount > 0 ? (followersCount > 0 && (<span className="body">
            (
            {
              formatNumber(
                followersCount
              )}{" "}
            {t("followers")})
          </span>)) : ''}

        </p>
        {isValidURL(platfromLink) && (
          <a
            target="_blank"
            href={platfromLink}
            className={styles.platform_link}
            rel="noreferrer"
          >
            {t("view_profile")}
          </a>
        )}
        {/* {!isActive ? <div className={styles.warning}>
          <img src={WarningImg} />
          <p>{t('deactivated_sm_warning')}</p>
        </div> : ""} */}
      </div>
    </div>
  );
};
export default InfluencerDetailsPlatformItem;
