import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { getLogs } from "../../Context/Actions/campaign";
import i18n from "../../Utils/i18n/i18n";
import { getLookupsTranslations } from "../../Context/Actions/lookups";
import dayjs from "dayjs";
import { AuthStateContext } from "../../Context/MainContext";
import { HiOutlineXCircle } from "react-icons/hi2";
import { notification } from "antd";
import { getLogsMocks } from "../../Context/Actions/clients";

const ActivityLogs = ({ entityId, entityKey }: { entityId: any, entityKey: any }) => {
  const [lookupsTranslations, setLookupsTranslations] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [logs, setLogs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [pageNumber, setPageNumber] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(20);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  let timeoutId: any;

  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const {
    state: { lookups, loading: loadingLookups },
  } = useContext(AuthStateContext);

  //TODO: Check pagination when API is deployed
  //TODO: Check new price cases

  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("error_label"),
      description: t("something_went_wrong"),
      icon: <HiOutlineXCircle className="danger-text" />,
      className: "no-wrap",
    });
  };

  useEffect(() => {
    const getAsyncLogs = async () => {
      if (!loadingMore) {
        setLoading(true);
      }
      const logs = await getLogs(entityKey, entityId, pageNumber, pageSize);
      // const logs = await getLogsMocks(entityKey, entityId, pageNumber, pageSize);
      if (!logs.error) {
        const data: any = logs.data;
        setTotalRecords(logs.totalRecords);
        setLogs((prev: any) => [...prev, ...data]);
        if (loadingMore) {
          setLoadingMore(false);
        }
      } else {
        openNotification()
      }
      setLoading(false);
    };
    getAsyncLogs();
  }, [pageNumber]);
  useEffect(() => {
    const handleOnChangeLanguage = () => {
      setLookupsTranslations(getLookupsTranslations(lookups));


    };
    i18n.on("languageChanged", handleOnChangeLanguage);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", handleOnChangeLanguage);
    };
  }, [i18n.language, loading]);
  useEffect(() => {
    setLookupsTranslations(getLookupsTranslations(lookups));
  }, [lookups]);

  console.log(lookupsTranslations, "lookupsTranslations")

  const returnPrice = (price: string) => {
    return `${Intl.NumberFormat().format(+price.split(" ")[0])} ${t(
      price.split(" ")[1]
    )}`;
  };
  const returnUser = (user: any) => {
    return user ? user.name : t("client");
  };

  const handleScroll = () => {
    if (containerRef && containerRef.current) {
      setShowScroll(true);
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current!;
      // console.log(scrollTop, clientHeight, scrollHeight, "boundries")
      console.log(scrollHeight - scrollTop, "diff")
      console.log(clientHeight, "diff2")
      // var bookmarkBarHeight = window.outerHeight - window.innerHeight;
      console.log(Math.ceil(scrollHeight - scrollTop), Math.ceil(window.innerHeight), "diff3")
      if (
        Math.ceil(scrollHeight - scrollTop) <= Math.ceil(window.innerHeight + 30) &&
        !loading &&
        totalRecords > logs.length
      ) {
        setLoadingMore(true);
        setPageNumber((prevPage) => prevPage + 1);
      }
    }
  };

  const mapLogs = () => {
    const lang = i18n.language;
    // TODO:
    return logs
      ?.sort(
        (a: any, b: any) =>
          b.auditInfo.ActionDateTime - a.auditInfo.ActionDateTime
      )
      .map((log: any) => {
        let data;
        if (entityKey == "Campaign") {
          if (
            log.auditInfo.actionType == "Change" &&
            !log.auditInfo.identifierProperty
          ) {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t("has_been_changed")}{" "}
                    <span className="lowercase">{t("from")}</span>{" "}
                    <span className="bold">
                      {
                        lookupsTranslations && lookupsTranslations[log.auditInfo.value.before] || log.auditInfo.value.before
                      }
                    </span>{" "}
                    <span className="lowercase">{t("to")}</span>{" "}
                    <span className="bold">
                      {
                        lookupsTranslations && lookupsTranslations[log.auditInfo.value.after] || log.auditInfo.value.after
                      }
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_changed")}{" "}
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    <span className="lowercase">{t("from")}</span>{" "}
                    <span className="bold">
                      {lookupsTranslations &&
                        lookupsTranslations[log.auditInfo.value.before]
                        || log.auditInfo.value.before}
                    </span>{" "}
                    <span className="lowercase">{t("to")}</span>{" "}
                    <span className="bold">
                      {lookupsTranslations &&
                        lookupsTranslations[log.auditInfo.value.after]
                        || log.auditInfo.value.after}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (
            log.auditInfo.actionType == "Change" &&
            log.auditInfo.identifierProperty
          ) {
            if (
              log?.auditInfo?.propertyName?.toLowerCase().includes("statuskey")
            ) {
              // status change
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}{" "}
                      </span> {t("status")}{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_changed")}{" "}
                      <span className="lowercase">{t("from")}</span>{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[log.auditInfo.value.before]}
                      </span>{" "}
                      <span className="lowercase">{t("to")}</span>{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[log.auditInfo.value.after]}
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_changed")} {t("log_status")}{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      <span className="lowercase">{t("from")}</span>{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[log.auditInfo.value.before]}
                      </span>{" "}
                      <span className="lowercase">{t("to")}</span>{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[log.auditInfo.value.after]}
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            } else {
              // price change
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("price")} {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_changed")}{" "}
                      <span className="lowercase">{t("from")}</span>{" "}
                      <span className="bold">
                        {returnPrice(log.auditInfo.value.before)}
                      </span>{" "}
                      <span className="lowercase">{t("to")}</span>{" "}
                      <span className="bold">
                        {returnPrice(log.auditInfo.value.after)}
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_changed")} {t("price")}{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      <span className="lowercase">{t("from")}</span>{" "}
                      <span className="bold">
                        {returnPrice(log.auditInfo.value.before)}
                      </span>{" "}
                      <span className="lowercase">{t("to")}</span>{" "}
                      <span className="bold">
                        {returnPrice(log.auditInfo.value.after)}
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            }
          } else if (log.auditInfo.actionType == "MinimalChange") {
            let changed = "has_been_changed";
            if (
              log.auditInfo.propertyName == "influencerAndServices.socialPlatforms" ||
              log.auditInfo.propertyName == "influencerAndServices.influencerTypeKeys"
            ) {
              changed = "have_been_changed";
            }
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t(changed)} {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_changed")}{" "}
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          }
          else if (log.auditInfo.actionType == "MinimalAdd") {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t('has_been_added')} {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_added")}{" "}
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          }
          else if (log.auditInfo.actionType == "MinimalRemove") {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t('has_been_removed')} {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_removed")}{" "}
                    <span className="bold">
                      {t(`logs_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (log.auditInfo.actionType == "Create") {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">{t("campaign")} </span>
                    {t("has_been_created")} {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_created")}{" "}
                    <span className="bold">{t("campaign")} </span>
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (log.auditInfo.actionType == "Enable") {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">{t("campaign_link")} </span>
                    {t("public_access_has_been")}{" "}
                    <span className="bold">{t("enabled")}</span> {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("public_access")}{" "}
                    <span className="bold">{t("campaign_link")} </span>
                    {t("became")} <span className="bold">{t("enabled")}</span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (log.auditInfo.actionType == "Disable") {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">{t("campaign_link")} </span>
                    {t("public_access_has_been")}{" "}
                    <span className="bold">{t("disabled")}</span> {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("public_access")}{" "}
                    <span className="bold">{t("campaign_link")} </span>
                    {t("became")} <span className="bold">{t("disabled")}</span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (log.auditInfo.actionType == "Add") {
            if (log.auditInfo.propertyName == "assignedInfluencers") {
              // add inf
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_added")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_added")} {t("influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            } else if (log.auditInfo.propertyName.includes("budgetAndTeam.campaignAssignedMembers")) {
              // add members
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("team_member")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "budgetAndTeam.campaignAssignedMembers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_added")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_added")} {t("team_member")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "budgetAndTeam.campaignAssignedMembers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );

            }
            else if (log.auditInfo.propertyName.includes("briefAndAttachments.attachments")) {
              // add attachment
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("attachment")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "briefAndAttachments.attachments.fileName"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_added")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_added")} {t("attachment")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "briefAndAttachments.attachments.fileName"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );

            } else if (log.auditInfo.propertyName.includes("campaignSummary.client")) {
              // add client
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("client")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.value.after
                        }
                      </span>{" "}
                      {t("has_been_added")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_added")} {t("client")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.value.after
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            }
            else {
              // add inf price
              if (log.auditInfo.identifierProperty) {
                data =
                  lang == "en" ? (
                    <div>
                      <span className={styles.logIcon}></span>
                      <p className="subtitle">
                        <span className="bold">
                          {lookupsTranslations &&
                            lookupsTranslations[
                            log.auditInfo.identifierProperty[
                            "assignedInfluencers.socialPlatformServices.key"
                            ]
                            ]}
                        </span>{" "}
                        {t("price")} {t("of_influencer")}{" "}
                        <span className="bold">
                          {
                            log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                            "assignedInfluencers.name"
                            ]
                          }
                        </span>{" "}
                        {t("has_been_added")} {t("by")}{" "}
                        <span className="bold">{returnUser(log.userInfo)}</span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <span className={styles.logIcon}></span>
                      <p className="subtitle">
                        {t("has_been_added")} {t("price")}{" "}
                        <span className="bold">
                          {lookupsTranslations &&
                            lookupsTranslations[
                            log.auditInfo.identifierProperty[
                            "assignedInfluencers.socialPlatformServices.key"
                            ]
                            ]}
                        </span>{" "}
                        {t("of_influencer")}{" "}
                        <span className="bold">
                          {
                            log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                            "assignedInfluencers.name"
                            ]
                          }
                        </span>{" "}
                        {t("by")}{" "}
                        <span className="bold">{returnUser(log.userInfo)}</span>
                      </p>
                    </div>
                  );
              } else {
                // old campaign new added fields
                data =
                  lang == "en" ? (
                    <div>
                      <span className={styles.logIcon}></span>
                      <p className="subtitle">
                        <span className="bold">
                          {t(`logs_${log.auditInfo.propertyName}`)}</span>{" "}

                        {t("has_been_added")} {t("by")}{" "}
                        <span className="bold">{returnUser(log.userInfo)}</span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <span className={styles.logIcon}></span>
                      <p className="subtitle">
                        {t("has_been_added")} <span className="bold">{t(`logs_${log.auditInfo.propertyName}`)}</span>{" "}
                        {t("by")}{" "}
                        <span className="bold">{returnUser(log.userInfo)}</span>
                      </p>
                    </div>
                  );
              }
            }
          } else if (log.auditInfo.actionType == "Remove") {
            if (log.auditInfo.propertyName == "assignedInfluencers") {
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_deleted")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_deleted")} {t("influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            } else if (log.auditInfo.propertyName.includes("briefAndAttachments.attachments")) {
              // add attachment
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("attachment")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "briefAndAttachments.attachments.fileName"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_deleted")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_deleted")} {t("attachment")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "briefAndAttachments.attachments.fileName"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );

            }
            else if (log.auditInfo.propertyName.includes("budgetAndTeam.campaignAssignedMembers")) {
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("team_member")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "budgetAndTeam.campaignAssignedMembers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_deleted")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_deleted")} {t("team_member")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "budgetAndTeam.campaignAssignedMembers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );

            }
            else if (log.auditInfo.propertyName.includes("campaignSummary.client")) {
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("client")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.value.before
                        }
                      </span>{" "}
                      {t("has_been_deleted")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_deleted")} {t("client")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.value.before
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            } else {
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("price")} {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_removed")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_removed")} {t("price")}{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            }
          } else if (log.auditInfo.actionType == "Select") {
            if (log.auditInfo.propertyName == "assignedInfluencers.isRecommended") {
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      <span className="bold">

                      </span>{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_recommended")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_recommended")}{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );

            } else {

              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_selected")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_selected")}{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            }
          } else if (log.auditInfo.actionType == "UnSelect") {
            if (log.auditInfo.propertyName == "assignedInfluencers.isRecommended") {
              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">

                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_unrecommended")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_unrecommended")}{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );

            } else {

              data =
                lang == "en" ? (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("has_been_unselected")} {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <span className={styles.logIcon}></span>
                    <p className="subtitle">
                      {t("has_been_unselected")}{" "}
                      <span className="bold">
                        {lookupsTranslations &&
                          lookupsTranslations[
                          log.auditInfo.identifierProperty[
                          "assignedInfluencers.socialPlatformServices.key"
                          ]
                          ]}
                      </span>{" "}
                      {t("of_influencer")}{" "}
                      <span className="bold">
                        {
                          log.auditInfo.identifierProperty && log.auditInfo.identifierProperty[
                          "assignedInfluencers.name"
                          ]
                        }
                      </span>{" "}
                      {t("by")}{" "}
                      <span className="bold">{returnUser(log.userInfo)}</span>
                    </p>
                  </div>
                );
            }
          }
        } else if (entityKey == "Client") {
          if (
            log.auditInfo.actionType == "Change" &&
            !log.auditInfo.identifierProperty
          ) {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    {t("has_been_changed")}{" "}
                    <span className="lowercase">{t("from")}</span>{" "}
                    <span className="bold">
                      {log.auditInfo.propertyName.toLowerCase().includes("fees") ? `${log.auditInfo.value.before}%` : lookupsTranslations &&
                        lookupsTranslations[log.auditInfo.value.before] ? lookupsTranslations[log.auditInfo.value.before]
                        : log.auditInfo.value.before}
                    </span>{" "}
                    <span className="lowercase">{t("to")}</span>{" "}
                    <span className="bold">
                      {log.auditInfo.propertyName.toLowerCase().includes("fees") ? `${log.auditInfo.value.after}%` : lookupsTranslations &&
                        lookupsTranslations[log.auditInfo.value.after] ? lookupsTranslations[log.auditInfo.value.after]
                        : log.auditInfo.value.after}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_changed")}{" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}{" "}
                    </span>
                    <span className="lowercase">{t("from")}</span>{" "}
                    <span className="bold">
                      {log.auditInfo.propertyName.toLowerCase().includes("fees") ? `${log.auditInfo.value.before}%` : lookupsTranslations &&
                        lookupsTranslations[log.auditInfo.value.before] ? lookupsTranslations[log.auditInfo.value.before]
                        : log.auditInfo.value.before}
                    </span>{" "}
                    <span className="lowercase">{t("to")}</span>{" "}
                    <span className="bold">
                      {log.auditInfo.propertyName.toLowerCase().includes("fees") ? `${log.auditInfo.value.after}%` : lookupsTranslations &&
                        lookupsTranslations[log.auditInfo.value.after] ?

                        lookupsTranslations[log.auditInfo.value.after]
                        : log.auditInfo.value.after}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (
            log.auditInfo.actionType == "Change" &&
            log.auditInfo.identifierProperty
          ) {

            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName.replace(".", "_")}`)}{" "}
                    </span>
                    {" "}
                    {t('of_brand')} {" "}
                    <span className="bold">
                      {log.auditInfo.identifierProperty['assignedbrands.name']}{" "}
                    </span>
                    {t("has_been_changed")}{" "}
                    <span className="lowercase">{t("from")}</span>{" "}
                    <span className="bold">
                      {
                        lookupsTranslations && lookupsTranslations[log.auditInfo.value.before] || log.auditInfo.value.before
                      }
                    </span>{" "}
                    <span className="lowercase">{t("to")}</span>{" "}
                    <span className="bold">
                      {
                        lookupsTranslations && lookupsTranslations[log.auditInfo.value.after] || log.auditInfo.value.after
                      }
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_changed")}{" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName.replace(".", "_")}`)}{" "}
                    </span>
                    {t('of_brand')} {" "}
                    <span className="bold">
                      {log.auditInfo.identifierProperty['assignedbrands.name']}{" "}
                    </span>
                    <span className="lowercase">{t("from")}</span>{" "}
                    <span className="bold">
                      {lookupsTranslations && lookupsTranslations[log.auditInfo.value.before] || log.auditInfo.value.before}
                    </span>{" "}
                    <span className="lowercase">{t("to")}</span>{" "}
                    <span className="bold">
                      {lookupsTranslations && lookupsTranslations[log.auditInfo.value.after] || log.auditInfo.value.after}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );

          } else if (log.auditInfo.actionType == "Create") {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">{t("client")} </span>
                    {t("has_been_created")} {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_created")}{" "}
                    <span className="bold">{t("client")} </span>
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (log.auditInfo.actionType == "Add" && !log.auditInfo.identifierProperty) {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("has_been_added")} {" "} {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_added")} {" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          }
          else if (log.auditInfo.actionType == "Add" && log.auditInfo.identifierProperty) {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {Object.values(log?.auditInfo?.identifierProperty)[0] as string}
                    </span>{" "}
                    {t("has_been_added")} {" "} <span className="lowercase">{t("to")}</span> {" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_added")} <span className="bold">
                      {Object.values(log?.auditInfo?.identifierProperty)[0] as string}
                    </span> {" "}  <span className="lowercase">{t("to")}</span> {" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (log.auditInfo.actionType == "Remove" && !log.auditInfo.identifierProperty) {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("has_been_removed")} {" "} {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_removed")} {" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          } else if (log.auditInfo.actionType == "Remove" && log.auditInfo.identifierProperty) {
            data =
              lang == "en" ? (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    <span className="bold">
                      {Object.values(log?.auditInfo?.identifierProperty)[0] as string}
                    </span>{" "}
                    {t("has_been_removed")} {" "}  <span className="lowercase">{t("from")}</span> {" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              ) : (
                <div>
                  <span className={styles.logIcon}></span>
                  <p className="subtitle">
                    {t("has_been_removed")}  <span className="bold">
                      {Object.values(log?.auditInfo?.identifierProperty)[0] as string}
                    </span> {" "} <span className="lowercase">{t("from")}</span> {" "}
                    <span className="bold">
                      {t(`logs_client_${log.auditInfo.propertyName}`)}
                    </span>{" "}
                    {t("by")}{" "}
                    <span className="bold">{returnUser(log.userInfo)}</span>
                  </p>
                </div>
              );
          }

        }
        return (
          <li className={styles.log}>
            {data}
            {/*TODO: local time*/}
            <span className={`body ${styles.creation_date} uppercase`}>
              {dayjs(log.auditInfo.actionDateTime)
                .local()
                .locale(i18n.language == "en" ? "en" : "ar")
                .format("D MMM YYYY • hh:mm A")}
            </span>
          </li>
        );
      });
  };
  return (
    <div
      onScroll={handleScroll}
      ref={containerRef}
      className={`${styles.activity} ${showScroll ? "showScroll" : ""} _lr-hide`}
    >
      {!loading && lookupsTranslations && !loadingLookups ? (
        <>
          <h3>{t("latest_changes")}</h3>
          <ul className={styles.timeline}>
            {mapLogs()}
            {loadingMore ? (
              <>
                {" "}
                <div>
                  <div className={`shimmer ${styles.shimmer_1}`}></div>
                  <div className={`shimmer ${styles.shimmer_2}`}></div>
                  <div className={`shimmer ${styles.shimmer_3}`}></div>
                </div>
                <div>
                  <div className={`shimmer ${styles.shimmer_1}`}></div>
                  <div className={`shimmer ${styles.shimmer_2}`}></div>
                  <div className={`shimmer ${styles.shimmer_3}`}></div>
                </div>
                <div>
                  <div className={`shimmer ${styles.shimmer_1}`}></div>
                  <div className={`shimmer ${styles.shimmer_2}`}></div>
                  <div className={`shimmer ${styles.shimmer_3}`}></div>
                </div>
              </>
            ) : (
              ""
            )}
          </ul>
        </>
      ) : (
        <div className={styles.loading_timeline}>
          <h3 className="shimmer"></h3>
          <div className={styles.shimmer_group}>
            <div>
              <div className={`shimmer ${styles.shimmer_1}`}></div>
              <div className={`shimmer ${styles.shimmer_2}`}></div>
              <div className={`shimmer ${styles.shimmer_3}`}></div>
            </div>
            <div>
              <div className={`shimmer ${styles.shimmer_1}`}></div>
              <div className={`shimmer ${styles.shimmer_2}`}></div>
              <div className={`shimmer ${styles.shimmer_3}`}></div>
            </div>{" "}
            <div>
              <div className={`shimmer ${styles.shimmer_1}`}></div>
              <div className={`shimmer ${styles.shimmer_2}`}></div>
              <div className={`shimmer ${styles.shimmer_3}`}></div>
            </div>{" "}
            <div>
              <div className={`shimmer ${styles.shimmer_1}`}></div>
              <div className={`shimmer ${styles.shimmer_2}`}></div>
              <div className={`shimmer ${styles.shimmer_3}`}></div>
            </div>{" "}
            <div>
              <div className={`shimmer ${styles.shimmer_1}`}></div>
              <div className={`shimmer ${styles.shimmer_2}`}></div>
              <div className={`shimmer ${styles.shimmer_3}`}></div>
            </div>{" "}
            <div>
              <div className={`shimmer ${styles.shimmer_1}`}></div>
              <div className={`shimmer ${styles.shimmer_2}`}></div>
              <div className={`shimmer ${styles.shimmer_3}`}></div>
            </div>{" "}
            <div>
              <div className={`shimmer ${styles.shimmer_1}`}></div>
              <div className={`shimmer ${styles.shimmer_2}`}></div>
              <div className={`shimmer ${styles.shimmer_3}`}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityLogs;