import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Layout, Select, Col, Row } from "antd";
import logo from "../assets/imgs/logo-white.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthStateContext } from "../Context/MainContext";
import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../Utils/firebase";
import { useTranslation } from "react-i18next";
import i18n from "../Utils/i18n/i18n";
import styles from "./layout.module.scss";
import { LastActiveDate } from "../Context/Actions/clients";

interface LayoutProps {
  children: ReactNode;
}
const { Option } = Select;

function renderDropdownMenu(menu: React.ReactNode) {
  return <div className="custom-dropdown">{menu}</div>;
}
const AnonymousLayout: React.FC<LayoutProps> = ({ children }) => {
  // decrale naviaget method to redierct from react router dom
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthStateContext);
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const location = useLocation();



  useEffect(() => {
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        console.log("user", user);
        if (user.reloadUserInfo.displayName || user.displayName) {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              userProfile: user,
              tokens: {
                accessToken: user.accessToken,
                refreshToken: user.refreshToken,
              },
            },
          });
          navigate("/");
        }
      } else {
        if (
          location.pathname !== "/reset-password" &&
          location.pathname !== "/forget-password" &&
          location.pathname !== "/auth" &&
          location.pathname !== "/check-mail"
        ) {
          navigate("/login");
        }
        dispatch({
          type: "SCREEN_LOADED",
        });
      }
    });
  }, [state.userLogged]);
  const handleChangeLanguage = (lng: string) => {
    changeLanguage(lng);
    dispatch({ type: "SET_LANG", payload: lng });
  };
  useEffect(() => {
    i18n.on("languageChanged", changeDir);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", changeDir);
    };
  }, []);
  const changeDir = () => {
    document.body.dir = dir();
  };
  const languageOptions = [
    { value: "en", label: `${t("english")}`, title: `${t("english")}` },
    { value: "ar", label: `${t("arabic")}`, title: `${t("arabic")}` },
  ];

  return (
    <Layout className={styles.anonymous_layout_container}>
      <Row justify="space-between">
        <Col>
          <div className="logo-anonynous">
            <img src={logo} alt="logo" />
          </div>
        </Col>
        <Col>
          <div className="anonymous-header d-flex-centered">
            <Select
              dropdownStyle={{ width: 160 }}
              defaultValue={state.lang || "en"}
              className={styles.anon_select_lang}
              onChange={handleChangeLanguage}
              style={{ padding: 0, width: 105 }}
              optionLabelProp="displayLabel"
            >
              {languageOptions?.map((item: any) => (
                <Option
                  key={item.label}
                  displayLabel={item.title}
                  value={item.value}
                  className={item.value === "ar" ? "ar_font" : "en_font"}
                >
                  {item.label}
                </Option>
              ))}
            </Select>

            {/* <Select
              dropdownRender={() =>
                languageOptions.map((item: any) => {
                  return <div></div>;
                })
              }
              defaultValue={state.lang || "en"}
              className={styles.anon_select_lang}
              onChange={handleChangeLanguage}
              style={{ padding: 0 }}
              options={[
                { value: "en", label: `${t("english")}` },
                { value: "ar", label: `${t("arabic")}` },
              ]}
            /> */}
          </div>
        </Col>
      </Row>
      {children}
    </Layout>
  );
};

export default AnonymousLayout;
