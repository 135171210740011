import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  ConfigProvider,
  Skeleton,
  Button,
  Popover,
  Select,
  Form,
  Modal,
  Input,
  notification,
} from "antd";
import styles from "./index.module.scss";
import { dir, t } from "i18next";
import { ColumnGroupType, ColumnsType } from "antd/es/table";
import { AnyObject } from "antd/es/_util/type";
import { useTranslation } from "react-i18next";
import avatarPlaceholder from "../../../../assets/imgs/avatar_placeholder.svg";
import { AiOutlineReload } from "react-icons/ai";

import {
  changeUserStatus,
  editMember,
  LastActiveDate,
  MembersListingData,
  resenInvitation,
} from "../../../../Context/Actions/clients";
import {
  HiOutlineEllipsisHorizontal,
  HiOutlineXCircle,
  HiXMark,
} from "react-icons/hi2";
import { HiOutlinePencil } from "react-icons/hi2";
import { HiOutlineMinusCircle } from "react-icons/hi2";
import { HiOutlineArrowRightOnRectangle } from "react-icons/hi2";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import { AuthStateContext } from "../../../../Context/MainContext";
import i18n from "../../../../Utils/i18n/i18n";
import { Footer } from "antd/es/layout/layout";
import { isVisible } from "@testing-library/user-event/dist/utils";
const { Option } = Select;

const MembersListing = ({
  data,
  sortDirections,
  setSortDirections,
  loading,
  updateMemberData,
  userRole,
}: any) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const {
    state: { lookups, brands, user },
    dispatch,
  } = useContext(AuthStateContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState<string | null>(null);

  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  const [resenInvitationModal, setResenInvitationModal] = useState(false);
  const [memberStatus, setMemberStatus] = useState("");

  const [selectedMember, setSelectedMember] = useState<any>(null);

  const [currentMember, setCurrentMember] = useState<MembersListingData | null>(
    null
  );
  const [editLoading, setEditLoading] = useState(false);
  const [tableData, setTableData] = useState(data);
  const [roleMember, setRoleMember] = useState("");
  const [form] = Form.useForm();
  const [loadingActive, setLoadingActivate] = useState(false);
  const roleDescriptions: { [key: string]: string } = {
    ADMIN: t("admin_description"),

    MEMBER: t("member_description"),
  };
  useEffect(() => {
    setTableData(data);
    console.log(data, "data");
  }, [data]);
  const handleEdit = (record: any) => {
    console.log("Record", record);
    setVisible(null);
    setSelectedMember(record);
    form.setFieldsValue({
      email: record.email,
      role: record.role,
    });
    setRoleMember(record.role)
    setIsModalVisible(true);
  };
  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const options = lookups?.[i18n.language]?.applicationRoles?.map(
    (role: any) => ({
      value: role.key,
      label: capitalizeFirstLetter(role.name),
      description: roleDescriptions[role.key],
    })
  );
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setCurrentMember(null);
  };
  const handleDeactivateClick = (record: any) => {
    setVisible(null);
    setSelectedMember(record);
    setDeactivateModalOpen(true);
  };

  const handleResendClick = (record: any) => {
    setVisible(null);
    setSelectedMember(record);
    setResenInvitationModal(true);
  };

  const handleVisibleChange = (newVisible: boolean, recordId: string) => {
    if (newVisible) {
      setVisible(recordId);
    } else {
      setVisible(null);
    }
  };
  // useEffect(() => {
  //   console.log("user", look);
  // });

  const getRoleKeyFromName = (roleName: string) => {
    return lookups[i18n.language]?.applicationRoles.find(
      (role: { name: string }) =>
        role.name.toLowerCase() === roleName.toLowerCase()
    )?.key;
  };
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      console.log("values", values);
      let roleKey = values.role;
      if (
        !lookups[i18n.language]?.applicationRoles.some(
          (role: { key: any }) => role.key === roleKey
        )
      ) {
        roleKey = getRoleKeyFromName(values.role);
      }
      if (!roleKey) {
        console.error("Could not determine role key");
        return;
      }
      await submitUpdate(roleKey);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const submitUpdate = async (roleKey: any) => {
    if (selectedMember) {
      try {
        setEditLoading(true);
        const result = await editMember(selectedMember.id, { roleKey });
        setRoleMember(roleKey);

        console.log("edit", roleKey);
        if (result.error) {
          notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: t("error_label"),
            description: t("something_went_wrong"),
            icon: <HiOutlineXCircle className="danger-text" />,
            className: "no-wrap",
          });
        } else {
          const updatedRole = lookups[i18n.language]?.applicationRoles.find(
            (role: { key: any }) => role.key === roleKey
          );
          if (!updatedRole) {
            console.error("Error: Role not found for key:", roleKey);
          } else {
            const capitalizedRoleName =
              updatedRole.name.charAt(0).toUpperCase() +
              updatedRole.name.slice(1);
            const updatedData = tableData.map((member: { id: any }) =>
              member.id === selectedMember.id
                ? {
                  ...member,
                  role: capitalizedRoleName,
                  roleKey: roleKey,
                }
                : member
            );
            setTableData(updatedData);
            notification.open({
              placement: dir() === "rtl" ? "topLeft" : "topRight",
              message: t("success_label"),
              description: t("edit_member"),
              icon: <HiOutlineCheckCircle className="success-text" />,
              className: "no-wrap",
            });
          }
        }
      } catch (error) {
        console.error("Error updating member:", error);
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      } finally {
        setEditLoading(false);
        setIsModalVisible(false);
      }
    }
    form.resetFields();
  };

  const handleDeactivateUser = async () => {
    setVisible(null);
    setEditLoading(true);
    if (selectedMember.status === "Active") {
      const result = await changeUserStatus(selectedMember.id, false);
      if (result.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      }

      if (!result.error) {
        setSelectedMember((prevMember: any) => ({
          ...prevMember,
          status: "Inactive",
        }));
        setTableData((prevData: any[]) =>
          prevData.map((member) =>
            member.id === selectedMember.id
              ? { ...member, status: "Inactive" }
              : member
          )
        );
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("success_label"),
          description: ` ${selectedMember.name}${" "}${t("deactivate_user")} `,
          icon: <HiOutlineCheckCircle className="success-text" />,
          className: "no-wrap",
        });
      }

      setDeactivateModalOpen(false);
      setEditLoading(false);
    }
  };

  const handleActivateUser = async () => {
    if (selectedMember?.status === "Inactive") {
      setLoadingActivate(true)
      const result = await changeUserStatus(selectedMember.id, true);

      if (result.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      } else {
        // If no error, update the status
        // setSelectedMember((prevMember: any) => ({
        //   ...prevMember,
        //   status: "Active",
        // }));
        setTableData((prevData: any[]) =>
          prevData.map((member) =>
            member.id === selectedMember.id
              ? { ...member, status: "Active" }
              : member
          )
        );

        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("success_label"),
          description: ` ${selectedMember.name}${" "}${t("activate_user")} `,
          icon: <HiOutlineCheckCircle className="success-text" />,
          className: "no-wrap",
        });
        setLoadingActivate(false)
        setVisible(null);


      }
    }
  };
  const handleResendInvitation = async () => {
    if (selectedMember) {
      const result = await resenInvitation(selectedMember.id);
      if (result.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      }
      if (!result.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("success_label"),
          description: t("resend_invitation"),
          icon: <HiOutlineCheckCircle className="success-text" />,
          className: "no-wrap",
        });
      }

      setResenInvitationModal(false);
    }
  };

  const popoverContent = (record: any) => {
    switch (record.status) {
      case "Active":
        return (
          <div>
            <Button
              type="link"
              className={styles.btnPopOver}
              onClick={() => handleEdit(record)}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <HiOutlinePencil style={{ width: "20px", height: "20px" }} />
              <span className={styles.popoverFont}>{t("edit")}</span>
            </Button>
            <Button
              type="link"
              className={styles.btnPopOver}
              onClick={() => handleDeactivateClick(record)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <HiOutlineMinusCircle style={{ width: "20px", height: "20px" }} />
              <span className={styles.popoverFont}>{t("deactivate")}</span>
            </Button>
          </div>
        );
      case "Inactive":
        return (
          <div>
            <Button
              type="link"
              className={styles.btnPopOver}
              onClick={() => handleEdit(record)}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <HiOutlinePencil style={{ width: "20px", height: "20px" }} />
              <span className={styles.popoverFont}>{t("edit")}</span>
            </Button>
            <Button
              type="link"
              className={styles.btnPopOver}
              onClick={handleActivateUser}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {!loadingActive ? <div className={styles.iconContainer}>
                <HiOutlineCheckCircle
                  style={{ width: "20px", height: "20px" }}
                />
                <span className={styles.popoverFont}>{t("activate")}</span>
              </div> : <span className="icon-loader spin"></span>}
            </Button>
          </div>
        );
      case "InviteSent":
        return (
          <div>
            <Button
              type="link"
              className={styles.btnPopOver}
              onClick={() => handleEdit(record)}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <HiOutlinePencil style={{ width: "20px", height: "20px" }} />
              <span className={styles.popoverFont}>{t("edit")}</span>
            </Button>
            <Button
              type="link"
              className={styles.btnPopOver}
              onClick={() => handleResendClick(record)}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <AiOutlineReload style={{ width: "20px", height: "20px" }} />
              <span className={styles.popoverFont}>{t("resend_invite")}</span>
            </Button>
          </div>
        );
    }
  };


  let columns: ColumnGroupType<AnyObject> | ColumnsType<AnyObject> = [
    {
      title: () => <div className={styles.tableTitle}>{t("name")}</div>,
      dataIndex: "name",
      key: "fullName",
      sorter: true,
      width: 336,
      fixed: dir() === "ltr" ? "left" : "right",
      render: (text: string, record: any) => {
        const { name, email } = record as { name: string; email: string };
        const initials =
          name?.trim().split(" ").length > 1
            ? name?.trim().split(" ")[0][0] + name?.trim().split(" ")[1][0]
            : name?.trim().split(" ")[0][0];

        return loading ? (
          <div>
            <Skeleton avatar paragraph={{ rows: 0 }} active />
          </div>
        ) : (
          <div className={styles.cellContent}>
            <div className={styles.userImg}>
              {initials ? (
                initials
              ) : (
                <img src={avatarPlaceholder} alt="Avatar" />
              )}
            </div>
            <div className={styles.userInfo}>
              <div className={styles.tableName}>{name}</div>
              <div className={styles.tableEmail}>{email}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => <div className={styles.tableTitle}>{t("user_role")}</div>,
      dataIndex: "role",
      key: "role",
      sorter: false,
      render: (role) => {
        const roleObj =
          lookups &&
          lookups[i18n.language].applicationRoles.find(
            (r: { key: any }) => r.key === role
          );
        return loading ? (
          <Skeleton.Button active style={{ width: "100px", height: "17px" }} />
        ) : (
          <div>
            {roleObj
              ? roleObj.name.charAt(0).toUpperCase() + roleObj.name.slice(1)
              : role}
          </div>
        );
      },
    },
    {
      title: () => <div className={styles.tableTitle}>{t("date_added")}</div>,
      dataIndex: "dateAdded",
      key: "dateAdded",
      sorter: false,
      render: (dateAdded: any) =>
        loading ? (
          <Skeleton.Button active style={{ width: "100px", height: "17px" }} />
        ) : (
          dateAdded
        ),
    },
    {
      title: () => <div className={styles.tableTitle}>{t("last_active")}</div>,
      dataIndex: "lastActive",
      key: "lastActive",
      sorter: false,
      render: (a: any) =>
        loading ? (
          <Skeleton.Button active style={{ width: "100px", height: "17px" }} />
        ) : a ? (
          a
        ) : (
          <span className="tableTextMember">-</span>
        ),
    },
    {
      title: () => <div className={styles.tableTitle}>{t("status")}</div>,
      dataIndex: "status",
      key: "status",
      sorter: false,
      render: (text: string, record: any) => {
        const { status } = record as { status: string };
        let statusClass;
        switch (status) {
          case "Active":
            statusClass = styles.active;

            break;
          case "Inactive":
            statusClass = styles.inactive;
            break;
          case "InviteSent":
            statusClass = styles.inviteSent;
            break;
        }
        console.log("Status:", status);
        return loading ? (
          <Skeleton.Button active style={{ width: "100px", height: "17px" }} />
        ) : (
          <div className={statusClass}>{t(status?.toLocaleLowerCase())}</div>
        );
      },
    },
    ...(user.role !== "MEMBER"
      ? [
        {
          title: "",
          dataIndex: "actions",
          key: "actions",
          render: (text: string, record: any) => {
            return loading ? (
              <Skeleton.Button
                active
                style={{ width: "40px", height: "17px" }}
              />
            ) : record.id === user.id ? null : (
              <Popover
                content={popoverContent(record)}
                trigger="click"
                placement="bottom"
                className="ant-popover navbarDropdown"
                open={visible === record.id}
                onOpenChange={(newVisible) =>
                  handleVisibleChange(newVisible, record.id)
                }
              >
                <span
                  onClick={() => setSelectedMember(record)}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <HiOutlineEllipsisHorizontal
                    style={{ width: "24px", height: "24px" }}
                  />
                </span>
              </Popover>
            );
          },
        },
      ]
      : []),
  ];
  console.log(sortDirections, "sort directions");

  return (
    <>
      <div className={styles.tableContainer}>
        {columns && (
          <Table
            className="membersTable"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            tableLayout="fixed"
            showSorterTooltip={false}
            onChange={(p, f, s: any) => {
              setSortDirections((prev: any) => ({
                name: [s.columnKey],
                direction: s.order ? s.order : "",
              }));
            }}
          />
        )}
      </div>

      <Modal
        title={t("edit_team_member")}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        footer={
          <Footer>
            <div className={styles.footerBtn}>
              <Button
                onClick={handleModalCancel}
                className="lavad-btn lavad-btn-md"
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={handleModalOk}
                className="lavad-btn lavad-btn-md lavad-btn-primary"
                style={{ width: "120px" }}
              >
                {editLoading ? (
                  <span className="icon-loader spin"></span>
                ) : (
                  t("save")
                )}
              </Button>
            </div>
          </Footer>
        }
        centered
        className="emailModal"
      >
        <Form form={form} layout="vertical">
          <div className={styles.editContentModal}>
            <div className={styles.fieldsContainer}>
              <Form.Item
                name="email"
                label={
                  <label data-private className={styles.label}>
                    {t("email")}
                  </label>
                }
              >
                <Input
                  disabled
                  className="lavad-input-lg"
                  style={{ width: "320px" }}
                />
              </Form.Item>
              <Form.Item
                name="role"
                label={
                  <label data-private className={styles.label}>
                    {t("role")}
                  </label>
                }
              >
                <div id="roledropdownedit">
                  <Select
                    placeholder={t("role")}
                    style={{ height: "40px", width: "175px" }}
                    optionLabelProp="label"
                    defaultActiveFirstOption={false}
                    rootClassName={`tableSelectContainer`}
                    value={roleMember}
                    onChange={(value) => {
                      form.setFieldsValue({ role: value });
                      setRoleMember(value);
                    }}
                    getPopupContainer={() =>
                      document.getElementById("roledropdownedit") ||
                      document.body
                    }
                    dropdownRender={(menu) => <div>{menu}</div>}
                    options={options}
                    optionRender={(option: any) => {
                      return (
                        <div>
                          <div className={styles.optionWrapper}>
                            <span className={styles.optionName}>
                              {capitalizeFirstLetter(option.data.label)}{" "}
                              <span className={styles.optionDescription}>
                                {option.data.description}
                              </span>
                            </span>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        closeIcon={<HiXMark height={24} width={24} />}
        centered
        onCancel={() => setDeactivateModalOpen(false)}
        className="deactivate_alert_modal"
        title={`${t("deactivate")} ${selectedMember?.name}${i18n.language === "ar" ? "؟" : "?"
          }`}
        open={deactivateModalOpen}
        maskClosable={false}
        footer={
          <div className={styles.alertModalFooter}>
            <Button
              onClick={() => setDeactivateModalOpen(false)}
              className="lavad-btn lavad-btn-md lavad-btn-outline"
              key="back"
            >
              {t("cancel")}
            </Button>
            <Button
              className="lavad-btn lavad-btn-md lavad-btn-danger-primary"
              style={{ width: "114px" }}
              key="submit"
              type="primary"
              onClick={() => {
                handleDeactivateUser();
              }}
            >
              {editLoading ? (
                <span className="icon-loader spin"></span>
              ) : (
                t("deactivate")
              )}
            </Button>
          </div>
        }
      >
        <p>
          <span className={styles.body}>
            {" "}
            {t("deactivate_body_highlight")}{" "}
          </span>
          <span className={styles.deactiveBody}>
            {t("deactivate_body-sub")}
          </span>
        </p>
        {/* <p className="body">{t("deactivate_body")}</p> */}
      </Modal>

      <Modal
        closeIcon={<HiXMark height={24} width={24} />}
        centered
        onCancel={() => setResenInvitationModal(false)}
        className="deactivate_alert_modal"
        title={t("resend_invitation_title")}
        open={resenInvitationModal}
        maskClosable={false}
        footer={
          <div className={styles.alertModalFooter}>
            <Button
              onClick={() => setResenInvitationModal(false)}
              className="lavad-btn lavad-btn-md lavad-btn-ghost"
              key="back"
            >
              {t("cancel")}
            </Button>
            <Button
              className="lavad-btn lavad-btn-md lavad-btn-primary"
              key="submit"
              type="primary"
              onClick={() => {
                handleResendInvitation();
              }}
            >
              {t("send")}
            </Button>
          </div>
        }
      >
        <p className="body">
          {t("resend_body")}{" "}
          <span className={styles.emailHighlight}>{selectedMember?.email}</span>
          .
        </p>
      </Modal>
    </>
  );
};

export default MembersListing;
