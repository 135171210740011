interface Alert {
    type: string,
    icon: any,
    message: string,
    className?: string
}
const Alert = (props: Alert) => {
    return (
        <div className={`lavad-alert ${props.type === 'danger' ? 'alert-danger' : props.type === 'success' ? 'alert-success' : 'alert-warning'} ${props.icon ? 'hasIcon' : ''} ${props.className ? props.className : ""}`}>
            {props.icon && props.icon}
            <p>{props.message}</p>
        </div>
    )
};
export default Alert