import { Collapse, Drawer } from "antd"
import { useTranslation } from "react-i18next";
import { HiOutlineXMark } from "react-icons/hi2"
import PlatformsAndServices from "./PlaformsAndAudience";
import styles from "./index.module.scss"
import api from "../../../../Utils/axiosInterceptor";
import { useContext, useEffect, useState } from "react";
import BasicInfo from "./BasicInfo";
import Badges from "./Badges";
import Brands from "./Brands";
import Industries from "./Industries";
import Tags from "./Tags";
import i18n from "../../../../Utils/i18n/i18n";
import { AuthStateContext } from "../../../../Context/MainContext";
import { getDefaultFiltersState } from "./Defaults";


const InfluencerFilters = ({
    localFilters, setLocalFilters, getInfluencersList, setFilters, filters, setInfluencersCount, opened, setOpened,


    setGeneratingPages,
    setInfluencerListLoading,
    setInfluencersList,
    pagesState,
    generatePages,
}: any) => {
    const {
        t,
        i18n: { dir },
    } = useTranslation();


    const [loading, setLoading] = useState(false)

    const onClose = () => {
        setOpened(false)
    };
    const {
        state,
        dispatch,
    } = useContext(AuthStateContext);

    const [clear, setClear] = useState(false);

    const [runApply, setRunApply] = useState(false);


    const returnCollapseItems = () => {
        return [{
            label: <div className={styles.title}><p>{t('plaforms_and_audience')}</p> <span>{Object.entries(localFilters.platform)
                .filter(([key, value]) => {
                    if (Array.isArray(value) && value.length == 2) {
                        return value[0] || value[1]
                    }
                    else if (Array.isArray(value)) {
                        return value.length > 0 || value.some(subValue => !!subValue);
                    } else {
                        return !!value;
                    }
                })
                .map(([key]) => key).length}</span></div>,
            children: <PlatformsAndServices setFilters={setLocalFilters} filters={localFilters} />
        }, {
            label: <div className={styles.title}><p>{t('basic_info')}</p> {Object.entries(localFilters.basicInfo)
                .filter(([key, value]) => {
                    if (Array.isArray(value) && value.length == 2) {
                        return value[0] || value[1]
                    }
                    else if (Array.isArray(value)) {
                        return value.length > 0 || value.some(subValue => !!subValue);
                    } else {
                        return !!value;
                    }
                })
                .map(([key]) => key).length != 0 ? <span>{Object.entries(localFilters.basicInfo)
                    .filter(([key, value]) => {
                        if (Array.isArray(value) && value.length == 2) {
                            return value[0] || value[1]
                        }
                        else if (Array.isArray(value)) {
                            return value.length > 0 || value.some(subValue => !!subValue);
                        } else {
                            return !!value;
                        }
                    })
                    .map(([key]) => key).length}</span> : ""}</div>,
            children: <BasicInfo setFilters={setLocalFilters} filters={localFilters} />
        }, {
            label: <div className={styles.title}><p>{t('badges')}</p>{Object.entries(localFilters.badges)
                .filter(([key, value]) => {
                    if (Array.isArray(value) && value.length == 2) {
                        return value[0] || value[1]
                    }
                    else if (Array.isArray(value)) {
                        return value.length > 0 || value.some(subValue => !!subValue);
                    } else {
                        return !!value;
                    }
                })
                .map(([key]) => key).length != 0 ? <span>{Object.entries(localFilters.badges)
                    .filter(([key, value]) => {
                        if (Array.isArray(value) && value.length == 2) {
                            return value[0] || value[1]
                        }
                        else if (Array.isArray(value)) {
                            return value.length > 0 || value.some(subValue => !!subValue);
                        } else {
                            return !!value;
                        }
                    })
                    .map(([key]) => key).length}</span> : ""}</div>,
            children: <Badges setFilters={setLocalFilters} filters={localFilters} />
        }, {
            label: <div className={styles.title}><p>{t('brands')}</p> {Object.values(localFilters.brands).filter((v: any) => typeof v == 'object' && !Object.is(v, null) ? !!v.length : !!v).length ? <span>{Object.values(localFilters.brands).filter((v: any) => typeof v == 'object' && !Object.is(v, null) ? !!v.length : !!v).length}</span> : ""}</div>,
            children: <Brands setFilters={setLocalFilters} filters={localFilters} />
        }, {
            label: <div className={styles.title}><p>{t('industries')}</p> {localFilters.suitable_industries.length ? <span>{1}</span> : ""}</div>,
            children: <Industries setFilters={setLocalFilters} filters={localFilters} />
        }, {
            label: <div className={styles.title}><p>{t('tags&safety')}</p> {Object.values(localFilters.tags).filter((v: any) => typeof v == 'object' && !Object.is(v, null) ? !!v.length : !!v).length ? <span>{Object.values(localFilters.tags).filter((v: any) => typeof v == 'object' && !Object.is(v, null) ? !!v.length : !!v).length}</span> : ""}</div>,
            children: <Tags setFilters={setLocalFilters} filters={localFilters} />
        }]
    }

    const clearFilters = () => {
        setLocalFilters(getDefaultFiltersState(state, i18n))
        setFilters(getDefaultFiltersState(state, i18n))
        setClear(true);
    }

    const runApplyFilters = () => {
        setRunApply(true);
        setFilters(localFilters)
    }

    const applyFilters = async () => {
        const currentFilters = filters;

        try {
            setLoading(true);
            const pages = await generatePages()
            console.log(pages, "generatePagesPages")

            if (pages.length) {
                let page = pages.pop()
                pagesState.current = pages
                console.log(currentFilters, "generatePagesList")
                await getInfluencersList({ page, pageSize: 12 });
                setLoading(false)
                if (clear) {
                    setClear(false)
                }
                if (runApply) {
                    setRunApply(false)
                }
                setOpened(false)

            } else {
                pagesState.current = []
                setInfluencersList([])
                setInfluencersCount(0)
                setInfluencerListLoading(false)
                setGeneratingPages(false)
                if (clear) {
                    setClear(false)
                }
                if (runApply) {
                    setRunApply(false)
                }
                setOpened(false)

            }
        } catch (e: any) {
            if (e.name === "CanceledError") {
                console.log('Request canceled');
            }
        }

    }


    const clearAll = async () => {
        try {
            setLoading(true);
            const pages = await generatePages()
            console.log(pages, "generatePagesPages")

            if (pages.length) {
                let page = pages.pop()
                pagesState.current = pages
                await getInfluencersList({ page, pageSize: 12 });
                setLoading(false)
                if (clear) {
                    setClear(false)
                }
                if (runApply) {
                    setRunApply(false)
                }
                setOpened(false)

            } else {
                pagesState.current = []
                setInfluencersList([])
                setInfluencersCount(0)
                setInfluencerListLoading(false)
                setGeneratingPages(false)
                if (clear) {
                    setClear(false)
                }
                if (runApply) {
                    setRunApply(false)
                }
                setOpened(false)

            }
        } catch (e: any) {
            if (e.name === "CanceledError") {
                console.log('Request canceled');
            }
        }

    }

    useEffect(() => {
        if (clear) {
            clearAll()
        }
    }, [clear])

    useEffect(() => {
        if (runApply) {
            applyFilters()
        }
    }, [runApply])




    return (
        <Drawer
            className="inf_filters_drawer"
            title={t("all_filters")}
            placement={dir() === "ltr" ? "right" : "left"}
            closable={true}
            onClose={onClose}
            closeIcon={<HiOutlineXMark className="close-drawer" />}
            open={opened}
            contentWrapperStyle={{ width: "36.75rem" }}
            footer={<div className={styles.footer}>
                <button className="lavad-btn lavad-btn-lg lavad-btn-outline" disabled={clear || loading} onClick={clearFilters}>{t('clear')}</button>
                <button disabled={loading || clear} className="lavad-btn lavad-btn-lg lavad-btn-primary" onClick={runApplyFilters}>{t('apply_filters')}</button>

            </div>}
        >
            <Collapse defaultActiveKey={[0, 1, 2, 3, 4, 5, 6]} className="inf_details_accordion inf_info" items={returnCollapseItems()}
            />

        </Drawer>
    )
}

export default InfluencerFilters