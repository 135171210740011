import api from "../Utils/axiosInterceptor";

export interface Campaign {
    campaignSummary: {
        name: string,
        campaignObjectiveKey: string,
        brandId: number,
        clientId: number
        clientRelationshipKey?: string
    },
    influencerAndServices: {
        campaignNicheKeys: string[],
        requestedInfluencers: number,
        influencerTypeKeys: string[],
        socialPlatformServices: string[]
    },
    budgetAndTeam: {
        budget: number,
        campaignLeadId: string,
        campaignAssignedMembers: string[]
    },
    briefAndAttachments: {
        brief: string,
        influencerDos: string,
        influencerDonts: string,
        attachments: any[]
    }

}

export const createCampaign = async (campaign: Campaign) => {
    try {
        const res = await api.post(`${process.env.REACT_APP_API_URL}api/campaign`, campaign);

        if (res.data) {
            return res.data
        } else {
            return { error: true }
        }
    } catch (err) {
        return { error: true }
    }

}

export const editCampaign = async (campaign: Campaign, campaignId: string) => {
    try {
        const res = await api.put(`${process.env.REACT_APP_API_URL}api/campaign/${campaignId}`, campaign);
        return { error: false }
    } catch (err) {
        return { error: true }
    }

}
