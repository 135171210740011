import React, { useContext, useEffect, useState } from 'react'
import styles from "./index.module.scss"
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Row, Select } from 'antd';
import InfluencerServicesCard from '../../InfluencerServicesCard';
import api from '../../../Utils/axiosInterceptor';
import { influencerMappedData } from '../../../Utils/helpers';
import { AuthStateContext } from '../../../Context/MainContext';
import i18n from '../../../Utils/i18n/i18n';
import { PiWarningCircle, PiWarningCircleFill } from 'react-icons/pi';
import SelectWithAll from './SelectWithAll';
import { HiOutlineUserGroup, HiUserGroup } from 'react-icons/hi2';
import WarningImg from "../../../assets/imgs/Attention.svg"



const Step2 = ({ setSelectedInfluencers, selectedInfluencers }: { setSelectedInfluencers: any, selectedInfluencers: any }) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const [showScroll, setShowScroll] = useState(false);
    let timeoutId: any;

    const { state } = useContext(AuthStateContext)


    const [loadingServices, setLoadingServices] = useState(false);






    const handleScroll = () => {
        setShowScroll(true);
        // clearTimeout(timeoutId);
        // timeoutId = setTimeout(() => {
        //   setShowScroll(false);
        // }, 500);

    };


    useEffect(() => {
        console.log("selectedInfs", selectedInfluencers)
        const getInfs = async () => {
            setLoadingServices(true)
            console.log("selectedBeforeGet", selectedInfluencers.map((a: any) => a.socialPlatforms))
            const res = await api.post(`${process.env.REACT_APP_API_URL}api/influencer/AdvertisingProducts`, { influencerIds: selectedInfluencers.filter((s: any) => s.selected).map((s: any) => s.basicInfo.id) })
            const newInfs = await Promise.all(res.data.data.map(async (s: any) => {
                const data = await influencerMappedData(state, s);
                return { ...data, selected: true };
            }))
            setSelectedInfluencers((prev: any) => newInfs.map((a: any) => ({ ...a, recommended: prev.find((s: any) => s.basicInfo.id == a.basicInfo.id).recommended })))
            setLoadingServices(false)
        }
        if (selectedInfluencers.find((a: any) => a.socialPlatforms.find((s: any) => !s.services))) {
            getInfs()
        }
    }, [])

    const handleOnChange = (key: any, prevValue: any, value: any) => {
        const longerArray = prevValue.length >= value.length ? prevValue : value;
        const shorterArray = prevValue.length < value.length ? prevValue : value;
        const action = longerArray.length == prevValue.length ? "remove" : "add"

        const diff = longerArray.filter((item: any) => !shorterArray.includes(item));

        setSelectedInfluencers((prevSelected: any) => prevSelected.map((inf: any) => {
            inf.influencerSocialPlatforms?.map((p: any) => {
                if (p.key == key) {
                    p.services?.map((s: any) => {
                        if (action == "add" && value.includes(s.key)) {
                            s.selected = true;
                        } else if (action == "remove" && diff.includes(s.key)) {
                            s.selected = false
                        }
                        return s;
                    })
                }
                return p;
            })
            return inf
        }))
    }

    const infsWithServices = () => {
        if (selectedInfluencers?.filter((s: any) => s.selected).length) {
            return selectedInfluencers?.filter((s: any) => s.selected).filter((inf: any) => {
                return inf?.influencerSocialPlatforms?.some((p: any) => {
                    return p?.services?.some((s: any) => s.selected);
                });
            }).length;
        } else {
            return 0
        }
    }

    const getServicesCheckedInAllInfluncers = (platform: any) => {
        const arr = selectedInfluencers?.map((inf: any) => {
            return inf?.socialPlatforms
                .find((p: any) => p?.key == platform)?.services
        }).flat();
        const keys = arr?.map((a: any) => a?.key)
        let selected: any = []
        keys?.forEach((key: any) => {
            if (arr?.filter((k: any) => k?.key == key)?.every((e: any) => e?.selected)) {
                selected.push(key)
            }
        })

        return Array.from(new Set(selected));
    }



    return (
        <Row gutter={24} className={styles.rowCont}>
            <Col lg={6} xl={6} md={6} sm={24}>
                <div className={styles.platformsContainer}>
                    <div className={styles.warning}>
                        <img src={WarningImg} />
                        <p>{t('step_2_warning')}</p>
                    </div>
                    <p className={styles.selectTxt}>{t('select_plats_services')}</p>
                    <div className={styles.platforms}>
                        {state?.lookups[i18n.language]?.socialPlatforms?.map((s: any) => {
                            if (selectedInfluencers.find((inf: any) => inf.socialPlatforms.find((ss: any) => ss.key == s.key))) {

                                return <SelectWithAll selected={getServicesCheckedInAllInfluncers(s.key)} onChange={(prev: any, v: any) => handleOnChange(s.key, prev, v)} prefixImg={s.logoUrl} options={s.socialPlatformServices?.map((s: any) => ({
                                    label: s.name,
                                    value: s.key
                                }))} />
                            }
                        }
                        )}
                    </div>
                </div>
            </Col>

            <Col lg={18} xl={18} md={18} sm={24}>
                <div
                    className={`${styles.scrollableContainer}`}
                    onScroll={handleScroll}
                >
                    {/* <div className={styles.influencer_wizard_heading + " " + "_lr-hide"} data-private>
                        <h2>{t("step2_heading")}</h2>
                        <h3>{t("step2_subtitle")}</h3>
                    </div> */}
                    <Row className={styles.assignedRow} justify="center"
                        align="stretch">
                        <Col span={24}>
                            <div className={styles.titleCont}>
                                <p className={`body ${styles.title} _lr-hide`} data-private><HiOutlineUserGroup /> <span>{t('assigned_influencers')}<span className={styles.number}>({`${selectedInfluencers?.filter((s: any) => s.selected).length ? selectedInfluencers?.filter((s: any) => s.selected).length : 0
                                    }`})</span></span></p>
                                <div><p><PiWarningCircle /> {t('influencers_with_services_selected')}</p> <span>{infsWithServices()}/{selectedInfluencers?.filter((s: any) => s.selected).length ? selectedInfluencers?.filter((s: any) => s.selected).length : 0}</span></div>
                            </div>
                            <div className={styles.cards_container}>
                                {selectedInfluencers.sort((a: any, b: any) => a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : 0).sort((a: any, b: any) => {
                                    if (a.recommended && !b.recommended) {
                                        return -1;
                                    } else if (!a.recommended && b.recommended) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                }).map((inf: any) => <InfluencerServicesCard loadingServices={loadingServices} isSelected={
                                    selectedInfluencers.length > 0 &&
                                        selectedInfluencers.findIndex(
                                            (i: any) => i.id === inf.basicInfo.id && i.selected
                                        ) > -1
                                        ? true
                                        : false
                                } showSelect={true} hasInfData={true} state="edit" key={inf.id} selectedInfs={selectedInfluencers} setSelectedInfs={setSelectedInfluencers} influencer={inf} />)}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>

    )
}

export default Step2
