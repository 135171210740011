import api from "../../Utils/axiosInterceptor";

export interface InfluencerListingCardInterface {
  selected?: boolean;
  basicInfo: any;
  id: number;
  name: string;
  avatarUrl: string;
  isFeatured: boolean;
  isAssignedToCampaign: boolean;
  influencerQualityKey: string;
  influencerNicheKeys: string[];
  influencerBrandSafetyFlagKeys?: string[];
  influencerSocialPlatforms: [
    {
      key: string;
      socialPlatformUrl: string;
      followersCount: number;
      engagementRate: number;
      viewRate: number;
      estimatedImpressions: number;
      services: [
        {
          key: string;
          basePrice: number;
          currencyKey: string;
          isAvailable: boolean;
        }
      ];
    }
  ];
}

export interface influencerReport {
  basicInfo: {
    issueTypeKey: string;
    comment: string;
  };
}

export const getAllInfluencers = async (campaignId: string, params: any) => {
  // influencerTypes: influencerType,
  //     influencerQuality: influencerQuality,
  //     socialPlatformService: socialPlatformService,
  //     niche: niche,
  //     keyword: keyword,
  let keyword = null;
  let influencerTypes = null;
  let influencerQuality = null;
  let socialPlatformService = null;
  let niche = null;
  let brandSafteyFlags = null;
  let countries = null;
  let ageGroups = null;
  let genders = null;
  let otherSegments = null;

  params.keyword && (keyword = encodeURIComponent(params.keyword));

  params.influencerTypes &&
    params.influencerTypes.length > 0 &&
    (influencerTypes = params.influencerTypes
      .map((t: any) => `&infType=${t}`)
      .join(""));

  params.influencerQuality &&
    params.influencerQuality.length > 0 &&
    (influencerQuality = params.influencerQuality
      .map((q: any) => `&iq=${q}`)
      .join(""));

  params.socialPlatformService &&
    params.socialPlatformService.length > 0 &&
    (socialPlatformService = params.socialPlatformService
      .map((s: any) => `&sps=${s}`)
      .join(""));
  params.brandSafteyFlags &&
    params.brandSafteyFlags.length > 0 &&
    (brandSafteyFlags = params.brandSafteyFlags
      .map((bsf: any) => `&bsf=${bsf}`)
      .join(""));

  params.niche &&
    params.niche.length > 0 &&
    (niche = params.niche.map((n: any) => `&niche=${n}`).join(""));

  params.countries &&
    params.countries.length > 0 &&
    (countries = params.countries.map((n: any) => `&ac=${n}`).join(""));
  params.genders &&
    params.genders.length > 0 &&
    (genders = params.genders.map((n: any) => `&ag=${n}`).join(""));
  params.ageGroups &&
    params.ageGroups.length > 0 &&
    (ageGroups = params.ageGroups.map((n: any) => `&aar=${n}`).join(""));
  params.otherSegments &&
    params.otherSegments.length > 0 &&
    (otherSegments = params.otherSegments
      .map((n: any) => `&aos=${n}`)
      .join(""));
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL
      }api/influencer/campaign/${campaignId}?page=${params.page}&pageSize=${params.pageSize
      }${keyword && keyword !== "" ? `&keyword=${keyword}` : ""}${influencerTypes && influencerTypes !== "" ? influencerTypes : ""
      }${socialPlatformService && socialPlatformService !== ""
        ? socialPlatformService
        : ""
      }${niche && niche !== "" ? niche : ""}${influencerQuality && influencerQuality !== "" ? influencerQuality : ""
      }${brandSafteyFlags && brandSafteyFlags !== "" ? brandSafteyFlags : ""}${countries && countries !== "" ? countries : ""
      }${ageGroups && ageGroups !== "" ? ageGroups : ""}${genders && genders !== "" ? genders : ""
      }${otherSegments && otherSegments !== "" ? otherSegments : ""}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const assignInfluencers = async (
  campaignId: string,
  assignedInfluencers: any
) => {
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/campaign/${campaignId}/lineitems`,
      assignedInfluencers
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
};
export const deleteInfluencer = async (
  campaignId: string,
  influencerId: string
) => {
  try {
    const res = await api.delete(
      `${process.env.REACT_APP_API_URL}api/campaign/${campaignId}/influencer/${influencerId}`
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
};

export const getInfluencerDetails = async (influencerId: number) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/influencer/${influencerId}`
    );

    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const reportInf = async (
  influencerId: string,
  influencer: influencerReport
) => {
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/influencer/${influencerId}/report`,
      influencer
    );
    return { error: false, res };
  } catch (err) {
    return { error: true };
  }
};
