import { Button, Drawer, Form, Input, Modal, notification, Radio, Select, Space, Spin, Upload } from 'antd';
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { AuthStateContext } from '../../Context/MainContext';
import { patterns } from '../../Utils/patterns';
import { HiOutlineMagnifyingGlass, HiOutlineMinusCircle, HiOutlinePlusCircle, HiOutlineXCircle } from 'react-icons/hi2';
import i18n from '../../Utils/i18n/i18n';
import { v4 as uuidv4 } from 'uuid'
import { isValidURL } from '../../Utils/helpers';
import { AiOutlineCloudUpload } from "react-icons/ai";
import uploadFileToBlob from '../../Utils/upload_azure_storage';

const AddBrand = ({ onSubmitHandler, errors, setErrors, clients, form, setBrandLogoLoading }: any) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();

    const {
        state: { lookups, brands },
        dispatch,
    } = useContext(AuthStateContext);

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            {label}
            {required ? <span className={styles.required}>*</span> : ""}
        </>
    );

    const [selectedClient, setSelectedClient] = useState({ id: '', type: '' })

    const [selectedPlatforms, setSelectedPlatforms] = useState<any>([{ key: '', url: '', uid: uuidv4() }])

    const { Option } = Select;

    const [clientsSearchResults, setClientsSearchResults] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);

    const [image, setImage] = useState("");
    const [loadingImage, setLoadingImage] = useState(false)




    const onClientsChange = (e: any) => {
        const value = e.target.value.toLowerCase();
        const filtered = clientsSearchResults.filter((c: any) =>
            c.label.toLowerCase().includes(value)
        );
        setFilteredClients(filtered);
    }



    // useEffect(() => {

    //     setClientsSearchResults(clients);
    //     setFilteredClients(clients);

    // }, [clients]);

    const platformTag = (uid: any) => {
        const platform = selectedPlatforms.find((a: any) => a.uid == uid);
        const hasKey = platform?.key;
        const hasUrl = platform?.url;

        const errorMessage = !hasKey && hasUrl
            ? t('platform_error')
            : hasKey && !hasUrl
                ? t('url_error')
                : !isValidURL(platform?.url) && platform?.url
                    ? t('website_pattern')
                    : "";
        return <div className={styles.brandContainer}>
            <div>
                <Input
                    addonBefore={<div className={styles.tagsContainer}>
                        <Select
                            allowClear
                            popupClassName="plat-drop-options"
                            className='plat-drop-brand'
                            defaultActiveFirstOption={false}
                            // className={`brands-select ${styles.marginTop}`}
                            virtual={false}
                            style={{ width: "108px" }}
                            showSearch={false}
                            size="large"
                            placeholder={t("platform")}
                            onInputKeyDown={(event) => {
                                if (event.keyCode === 8 || event.key === "Backspace") {
                                    return event.stopPropagation();
                                }
                            }}

                            // value={filters.platform.platform.key}
                            onChange={(platform: any) => {
                                console.log("platform", platform);
                                setSelectedPlatforms((prev: any) => prev.map((p: any) => p.uid == uid ? { ...p, key: platform } : p))
                            }}


                        >
                            {lookups[i18n.language]?.socialPlatforms?.filter((a: any) => a.key != "OtherServices").map((s: any) => (
                                <Option value={s.key} key={s.key} disabled={!!selectedPlatforms.find((a: any) => a.key == s.key)}>
                                    <div className={styles.platOptions}>
                                        <img src={`/imgs/${s.key.toLowerCase()}_square.svg`} alt={s.name} />
                                        <p>{s.name}</p>
                                    </div>
                                </Option>
                            ))}

                        </Select>
                    </div>}
                    className={`lavad-input-addon ltr-input lavad-input-addon-lg lavad-input-with-select ${errorMessage ? 'lavad-input-error' : ''}`}
                    placeholder={t("website_placeholder")}
                    onChange={(e) => { setSelectedPlatforms((prev: any) => prev.map((p: any) => p.uid == uid ? { ...p, url: e.target.value } : p)) }}

                />
                {selectedPlatforms?.length > 1 ? <HiOutlineMinusCircle onClick={() => { setSelectedPlatforms((prev: any) => prev.filter((a: any) => a.uid != uid)) }} /> : ""}
            </div>
            {errorMessage ? <p className='error'>{errorMessage}</p> : ''}   </div>
    }


    console.log("filteredClients", filteredClients)


    const handleUpload = async (file: any) => {
        if (!['png', 'jpg', 'jpeg'].includes(file.name.split(".")[file.name.split(".").length - 1].toLowerCase())) {
            notification.destroy()
            notification.open({
                placement: dir() === "rtl" ? "topLeft" : "topRight",
                message: t("error_label"),
                description: `${t("format_not_supported")} "${file.name.split(".")[file.name.split(".").length - 1].toLowerCase()}"`,
                icon: <HiOutlineXCircle className="danger-text" />,
                className: "no-wrap",
            });
            return;
        }
        try {
            setBrandLogoLoading(true)
            setLoadingImage(true)
            const uploadedImgUrl = await uploadFileToBlob("brand-logos", file);
            setLoadingImage(false)
            setBrandLogoLoading(false)

            setImage(uploadedImgUrl);
            form.setFieldValue("logo", uploadedImgUrl)

        } catch (error) {
            console.error('Upload failed:', error);
            setLoadingImage(false)
            setBrandLogoLoading(false)

        }


    };

    const removeImg = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setImage("")
        form.setFieldValue("logo", '')

    }

    console.log("errors", errors);

    useEffect(() => {
        if (errors.website || errors.nameAr || errors.nameEn) {
            form.validateFields()
        }
    }, [errors])


    return (
        <Form
            requiredMark={customizeRequiredMark}
            className={styles.createForm + ' ' + 'fixHeights'}
            form={form}
            layout="vertical"
            onFinish={(values) => onSubmitHandler({ ...values, selectedClient, selectedPlatforms, image })}
        >
            <Form.Item name="logo" rules={[{
                validator: (_, value) => {
                    if (!value || errors.logo) {
                        return Promise.reject(t('brand_logo_required'));
                    } else {
                        return Promise.resolve();
                    }
                },
            }]} className={styles.uploadContainer}>
                <div className={styles.uploader + ' ' + 'brandUploaders'}>
                    <Upload
                        beforeUpload={handleUpload}
                    >
                        <div className="brand-uploader"
                        >
                            {(!image || loadingImage) ? <button type="button" disabled={loadingImage}>
                                {!image && !loadingImage ? <AiOutlineCloudUpload /> : <span className="icon-loader spin"></span>}
                            </button> : <button className={styles.uploadedImage} type="button" disabled={loadingImage}>
                                <img src={image} alt='brand-logo' />
                            </button>
                            }
                        </div>
                    </Upload>
                    <div className={styles.info}>
                        <label>{t("brand_logo")} <span className={styles.required}>*</span></label>
                        <Upload
                            beforeUpload={handleUpload}

                        >{!image ? <button className={styles.defaultUpload} disabled={loadingImage} type='button'>{t("upload_image")}</button> : !image && loadingImage ? <Spin /> : <div className={styles.changeButtons}><button type='button' disabled={loadingImage} className={styles.change}>{t('change')}</button><button disabled={loadingImage} type='button' onClick={removeImg} className={styles.remove}>{t('remove')}</button></div>}
                        </Upload>
                    </div>
                </div>
                {/* {!!errors.logo && <p className='error'>{t('brand_logo_required')}</p>} */}
            </Form.Item>

            <div className={styles.inputGroup}>
                <Form.Item
                    label={<label data-private>{t("english_name")}</label>}
                    name="englishName"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && errors.nameEn) {
                                    return Promise.reject(t(errors.nameEn));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        {
                            required: true,
                            message: t("english_name_required"),
                            validator: (_, value) => {
                                if (!value?.trim().length) {
                                    return Promise.reject(t("english_name_required"));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        { pattern: RegExp(patterns.enRegex), message: t("legal_en_pattern") }
                    ]}
                >
                    <Input
                        className="lavad-input lavad-input-lg"
                        placeholder={t("english_name_placeholder")}
                        onChange={(e) => { setErrors((errs: any) => ({ ...errs, nameEn: "" })); }}

                    />
                </Form.Item>

                <Form.Item
                    label={<label data-private>{t("arabic_name")}</label>}
                    name="arabicName"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && errors.nameAr) {
                                    return Promise.reject(t(errors.nameAr));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        {
                            required: true,
                            message: t("arabic_name_required"),
                            validator: (_, value) => {
                                if (!value?.trim().length) {
                                    return Promise.reject(t("arabic_name_required"));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        { pattern: RegExp(patterns.arRegex), message: t("legal_ar_pattern") }
                    ]}
                >
                    <Input
                        className="lavad-input lavad-input-lg"
                        placeholder={t("arabic_name_placeholder")}
                        onChange={(e) => { setErrors((errs: any) => ({ ...errs, nameAr: "" })); }}

                    />
                </Form.Item>
            </div>
            <Form.Item
                label={<label data-private>{t("website")}</label>}
                name="website"
                rules={[
                    {
                        validator: (_, value) => {
                            if (value && errors.website) {
                                return Promise.reject(t(errors.website));
                            } else {
                                return Promise.resolve();
                            }
                        },
                    },
                    {
                        required: true,
                        message: t("website_required"),
                        validator: (_, value) => {
                            if (!value?.trim().length) {
                                return Promise.reject(t("website_required"));
                            } else {
                                return Promise.resolve();
                            }
                        },
                    },
                    { pattern: RegExp(patterns.url), message: t("website_pattern") }
                ]}
            >
                <Input
                    addonBefore={"http://"}

                    className="lavad-input-addon lavad-input-addon-lg ltr-whole-input"
                    placeholder={t("website_placeholder")}
                    onChange={(e) => { setErrors((errs: any) => ({ ...errs, website: "" })); }}

                />
            </Form.Item>
            <Form.Item
                label={<label data-private>{t("industry")}</label>}
                name="industry"
                rules={[
                    {
                        required: true,
                        message: t("industry_required"),
                    },
                ]}
            >
                <Select
                    placeholder={t('industry_placeholder')}
                    // suffixIcon={<HiOutlineMagnifyingGlass className='searchIconSvg' />}
                    defaultActiveFirstOption={false}
                    className="brands-select"
                    virtual={false}
                    allowClear
                    notFoundContent={
                        <div
                            role="listbox"
                            id="platforms_list"
                            className="ant-select-item-empty"
                        >
                            <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                <div className="ant-empty-image">
                                    <svg
                                        width="64"
                                        height="41"
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <ellipse
                                                fill="#f5f5f5"
                                                cx="32"
                                                cy="33"
                                                rx="32"
                                                ry="7"
                                            ></ellipse>
                                            <g fill-rule="nonzero" stroke="#d9d9d9">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    fill="#fafafa"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">
                                    {t("not_found")}
                                </div>
                            </div>
                        </div>
                    }
                    options={lookups && lookups[i18n.language]?.industries?.map((a: any) => ({ value: a.key, label: a.name }))}
                    mode='multiple'
                    maxTagCount={'responsive'}
                    size="large"
                />



            </Form.Item>
            {/* <Form.Item label={t('client')}>
                <div>
                    <Space.Compact className={"compactInputs"}>
                        <Select virtual={false} placement={i18n.language == "ar" ? 'bottomRight' : 'bottomLeft'}
                            dropdownRender={(menu) => (
                                <>
                                    <div className="lavad-input search-input">
                                        <HiOutlineMagnifyingGlass width={16} height={16} />
                                        <input
                                            onChange={onClientsChange}
                                            id="brandsSearch"
                                            placeholder={t("search")}
                                        />
                                    </div>
                                    {menu}
                                </>
                            )}
                            value={selectedClient.id || undefined}
                            onChange={(value) => setSelectedClient((prev: any) => ({ ...prev, id: value }))}
                            allowClear
                            options={filteredClients.map((a: any) => ({ value: a.id, label: a.label }))} className={`brands-select selectPrefix ${(selectedClient.type && !selectedClient.id) ? 'selectWithError' : ''}`}
                            suffixIcon={<HiOutlineMagnifyingGlass className='searchIconSvg prefix' />}
                            placeholder={t('assign_client')} style={{ width: '65%' }}></Select>
                        <Select virtual={false} value={selectedClient.type || undefined} onChange={(value) => { setSelectedClient((prev: any) => ({ ...prev, type: value })) }} allowClear options={lookups && lookups[i18n.language].clientBrandRelationships?.map((b: any) => ({ value: b.key, label: b.name }))} className={`brands-select brands-select-bold ${(!selectedClient.type && selectedClient.id) ? 'selectWithError' : ""}`} placeholder={t('association_type')} style={{ width: '35%' }} />
                    </Space.Compact>
                </div>
                {((selectedClient.id && !selectedClient.type) || (!selectedClient.id && selectedClient.type)) ? <p className='error'>{(selectedClient.type && !selectedClient.id) ? t('client_error') : (!selectedClient.type && selectedClient.id) ? t('type_error') : ""}</p>
                    : ""}
            </Form.Item> */}
            <Form.Item
                label={t('brand_type')}
                name="brandType"

            >
                <Radio.Group size="large" className="campaign-objectives radios radiosFit">
                    {lookups && lookups[i18n.language]?.brandTypes?.map((obj: any) => {
                        return (
                            <Radio.Button key={obj.key} value={obj.key}>
                                {obj.name}
                            </Radio.Button>
                        );
                    })}
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label={t('classification')}
                name="classification"

            >
                <Radio.Group size="large" className="campaign-objectives radios radiosFit">
                    {lookups && lookups[i18n.language]?.brandClassifications?.map((obj: any) => {
                        return (
                            <Radio.Button key={obj.key} value={obj.key}>
                                {obj.name}
                            </Radio.Button>
                        );
                    })}
                </Radio.Group>
            </Form.Item>
            <Form.Item label={t('platforms')}>
                <div className={styles.brands}>

                    <ul>
                        {selectedPlatforms.map((a: any) => <li key={a.uid}>{platformTag(a.uid)}</li>)}
                    </ul>
                    <button onClick={() => selectedPlatforms.every((a: any) => a.key && a.url && isValidURL(a.url)) ? setSelectedPlatforms([...selectedPlatforms, { key: "", url: '', uid: uuidv4() }]) : ''} type='button' className={`lavad-btn lavad-btn-lg lavad-btn-link lavad-btn-with-icon ${styles.addBrand}`}><HiOutlinePlusCircle />{t("add_platform")}</button>

                </div>
            </Form.Item>
        </Form >
    )
}

export default AddBrand