import { useState, useContext, useEffect } from "react";
import { AuthStateContext } from "../../../Context/MainContext";
import { resetPassword } from "../../../Context/Actions/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

import { t } from "i18next";
import { Row, Col, Input, Form, notification, Button } from "antd";
import type { FormItemProps } from "antd";
import React from "react";
import login_vector from "../../../assets/imgs/login-vector.png";
import Alert from "../../../Components/Alerts";
import {
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineXCircle,
} from "react-icons/hi";
import i18n from "../../../Utils/i18n/i18n";
import { signUp } from "../../../Context/Actions/auth";
import { signUpUser } from "../../../Context/Actions/clients";
import { set } from "lodash";
import { useTranslation } from "react-i18next";

const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const SignUp = ({ email, url }: { email: string; url: string }) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [form] = Form.useForm();
  const query = useQuery();
  const [api, contextHolder] = notification.useNotification();
  const { state, dispatch } = useContext(AuthStateContext);

  const onFinish = async (values: { name: string; password: string }) => {
    try {
      setLoading(true);
      const userData = {
        fullName: values.name,
        password: values.password,
      };
      const result = await signUpUser(userData);

      if (result) {
        navigate("/login");
      } else {
        console.error("Sign up failed:", result);
      }
    } catch (error) {
      console.log("Error during sign-up:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      form.resetFields(); // Trigger form validation on language change
      setPassword("");
      setConfirmPassword("");
      setUserName("");
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [form, i18n]);
  console.log("Email:", email);
  console.log("URL:", url);

  const handleSignUp = async () => {
    try {
      const result = await signUp(email, url);
      if (result?.user) {
        const idToken = await result.user.getIdToken();
        const refreshToken = result.user.refreshToken;
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem(
          "token",
          JSON.stringify({
            idToken,
            refreshToken,
          })
        );

        console.log("Tokens saved successfully");
      } else {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("signup_error"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
        console.log("Sign up result does not contain user info", result);
      }
      console.log("Sign up result:", result);
    } catch (error) {
      console.error("Error during sign-up:", error);
      setError("An error occurred during sign-up. Please try again.");
    }
  };
  useEffect(() => {
    if (url) {
      handleSignUp();
    }
  }, [url]);

  const hasNumber = (value: string) => {
    return /\d/.test(value);
  };
  const hasCapitalLetter = (value: string) => {
    return /[A-Z]/.test(value);
  };
  return (
    <div className={styles.login_containier}>
      <Row
        justify="space-between"
        align="middle"
        gutter={12}
        className={styles.loginRow}
      >
        <Col lg={8} xl={8} xxl={8} push={4} style={{ marginLeft: "-25px" }}>
          {/* <h2 className={styles.login_header}>
            {t("login_header")}{" "}
            <span className="icon-wave rotate-icon-rtl"></span>
            <h3 className={styles.subtitle}> {t("accept_invitation")} </h3>
          </h2> */}
          {error && error !== "" && (
            <Alert
              message={t(error)}
              type="danger"
              icon={<HiOutlineXCircle />}
            />
          )}
          <Form
            autoComplete="off"
            validateTrigger="onSubmit"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={styles.login_form}
            initialValues={{
              email: email || "",
            }}
          >
            <div>
              <h2 className={styles.login_header}>
                {t("login_header")}{" "}
                <span className="icon-wave rotate-icon-rtl"></span>
                <h3 className={styles.subtitle}> {t("accept_invitation")} </h3>
              </h2>
              <label className={styles.label}>{t("email")}</label>
              <div className="relative">
                <Form.Item name="email" className="nomargin">
                  <Input
                    name="email"
                    className={` ${styles.emailStyles} lavad-input lavad-input-lg `}
                    style={{ marginTop: "8px" }}
                    placeholder={t("example@gmail.com")}
                    disabled
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <label className={styles.label}>{t("name")}</label>
              <div className="relative">
                <Form.Item
                  name="name"
                  className="nomargin"
                  rules={[
                    {
                      required: true,
                      message: t("name_required"),
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim() === "") {
                          return Promise.reject(new Error(t("name_required")));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    name="name"
                    className="lavad-input lavad-input-lg lavad-line"
                    placeholder={t("enter_name")}
                    autoComplete="false"
                    style={{ marginTop: "8px" }}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      form.setFields([{ name: "name", errors: [] }]);
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="form-group">
              <label className={styles.label}>{t("Password")}</label>
              <div className="relative">
                <Form.Item
                  name="password"
                  className="nomargin"
                  rules={[
                    {
                      required: true,
                      // message: t("password_required"),

                      validator: (_, value) => {
                        if (!value || value.trim() === "") {
                          return Promise.reject(t("password_required"));
                        }
                        if (
                          value &&
                          (value.length < 8 ||
                            !hasNumber(value) ||
                            !hasCapitalLetter(value))
                        ) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type={hidePassword ? "password" : "text"}
                    name="password"
                    className="lavad-input lavad-input-lg lavad-line"
                    placeholder={t("enter_password_placeholder")}
                    autoComplete="false"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      form.setFields([{ name: "password", errors: [] }]);
                    }}
                  />
                </Form.Item>
                {hidePassword ? (
                  <HiOutlineEyeOff
                    onClick={() => setHidePassword(!hidePassword)}
                    className={styles.showPW}
                  />
                ) : (
                  <HiOutlineEye
                    onClick={() => setHidePassword(!hidePassword)}
                    className={styles.hidePW}
                  />
                )}
              </div>
            </div>
            <div className="form-group">
              <label className={styles.label}>{t("Confirm_password")}</label>
              <div className="relative">
                <Form.Item
                  name="confirmPassword"
                  className="nomargin"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: t("confirm_password_required"),
                    },
                    {
                      validator: (_, value) => {
                        if (
                          !value ||
                          value !== form.getFieldValue("password")
                        ) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type={hideConfirmPassword ? "password" : "text"}
                    name="confirmPassword"
                    className="lavad-input lavad-input-lg lavad-line"
                    placeholder={t("enter_confirm_password")}
                    autoComplete="false"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      form.setFields([{ name: "confirmPassword", errors: [] }]);
                    }}
                  />
                </Form.Item>
                {hideConfirmPassword ? (
                  <HiOutlineEyeOff
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                    className={styles.showPW}
                  />
                ) : (
                  <HiOutlineEye
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                    className={styles.hidePW}
                  />
                )}
              </div>
            </div>
            <div className={styles.password_criteria}>
              <h4>{t("password_must_have")}</h4>
              <ul>
                <li
                  className={`${styles.password_criteria_item} + ${password && password !== ""
                    ? password?.length >= 8
                      ? "success-700"
                      : "danger-700"
                    : "netural-text"
                    }`}
                >
                  {t("password_min_length")}
                </li>
                <li
                  className={`${styles.password_criteria_item + password &&
                    password?.length > 0
                    ? hasNumber(password)
                      ? "success-700"
                      : "danger-700"
                    : "netural-text"
                    }`}
                >
                  {t("password_min_number")}
                </li>
                <li
                  className={`${styles.password_criteria_item} + ${password && confirmPassword
                    ? password === confirmPassword
                      ? "success-700"
                      : "danger-700"
                    : "netural-text"
                    }`}
                >
                  {t("not_matching")}
                </li>
                <li
                  className={`${styles.password_criteria_item + password &&
                    password?.length > 0
                    ? hasCapitalLetter(password)
                      ? "success-700"
                      : "danger-700"
                    : "netural-text"
                    }`}
                >
                  {t("password_min_capital")}
                </li>
              </ul>
            </div>

            <button
              style={{ marginBottom: "24px" }}
              type="submit"
              className={`lavad-btn lavad-btn-primary lavad-btn-lg btn-block ${loading ? "loading" : ""
                }`}
            >
              {loading ? (
                <span className="icon-loader spin"></span>
              ) : (
                t("register")
              )}
            </button>
          </Form>
          <label className={styles.signInlabel}>
            {t("using_lavad")}{" "}
            <Link to="/login">
              <span className={styles.signInspan}>{t("sign_in")}</span>
            </Link>
          </label>
        </Col>
        <Col lg={8} xl={8} xxl={8} xs={20} pull={2}>
          <div className="justify-end">
            <img
              className={styles.login_vector}
              src={login_vector}
              alt="login vector"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SignUp;
