import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const InfluencerIndustries = ({ details }: any) => {
  const {
    t
  } = useTranslation();
  return (
    <>

      {details?.influencerIndustries && details?.influencerIndustries.length > 0 ? (
        <div className={styles.influencer_tag_contaier + " " + "_lr-hide"} data-private>
          {details?.influencerIndustries?.map(
            (industry: any, index: number) => (
              <span
                key={industry.key}
                className={styles.influencer_tag_item}
              >
                {industry.name}
              </span>
            )
          )}
        </div>
      ) : <p className={styles.nodata} data-private>{t('no_available_data')}</p>}
    </>
  );
};

export default InfluencerIndustries;
