import { Avatar, Card, List, Skeleton } from "antd";
import styles from "./index.module.scss";
const InfluenceraignCardSkeleton = (props: any) => {
  return (
    <>
      <Card className={styles.influencer_card}>
        <div className={styles.card_header}>
          <div className={`${styles.influencer_avatar} shimmer`}></div>
        </div>
        <div className={styles.card_body}>
          <div className={styles.card_text}>
            <div className={`${styles.influencer_name} shimmer`}></div>
            <div className={`${styles.influencer_interests} shimmer`}></div>
            <div className={`${styles.influencer_range} shimmer`}></div>
            {/* <div className={`${styles.influencer_state} shimmer`}></div> */}
          </div>
          <div className={`${styles.socialPlatforms_container}`}>
            <div className={`${styles.socialPlatform_item} shimmer`}></div>
            <div className={`${styles.socialPlatform_item} shimmer`}></div>
            <div className={`${styles.socialPlatform_item} shimmer`}></div>
            <div className={`${styles.socialPlatform_item} shimmer`}></div>
            <div className={`${styles.socialPlatform_item} shimmer`}></div>
            <div className={`${styles.socialPlatform_item} shimmer`}></div>
            <div className={`${styles.socialPlatform_item} shimmer`}></div>
          </div>
        </div>
      </Card>
    </>
  );
};
export default InfluenceraignCardSkeleton;
