import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Button, Col, notification, Row, Select } from "antd";
import nodata from "../../../assets/imgs/filters-no-data.svg";

import { HiOutlinePlus, HiOutlineXCircle } from "react-icons/hi";
import { AuthStateContext } from "../../../Context/MainContext";
import {
  getAllMembers,
  getApplicationUsers,
  LastActiveDate,
  MembersListingData,
  signUpUser,
} from "../../../Context/Actions/clients";
import EmailModal from "./EmailModal";
import i18n from "../../../Utils/i18n/i18n";
import MembersListing from "./MembersListingTable";
import dayjs from "dayjs";
import lavadText from "../../../assets/imgs/logo-white.svg";
import Settings from "..";
const MembersSettings = ({ onDataFetched }: any) => {
  const { Option } = Select;
  const [data, setData] = useState<any>([
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
  ]);

  const [applicationRoles, setApplicationRoles] = useState<any>([]);
  const [isModalClosed, setIsModalClosed] = useState(true);

  const {
    state: { lookups, brands, lang, user },
    dispatch,
  } = useContext(AuthStateContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [appUsers, setAppUsers] = useState<any>([]);
  const [members, setMembers] = useState([]);
  const [error, setError] = useState(false);
  const [userRole, setUserRole] = useState<string | null>(null);

  const [selectedFilters, setSelectedFilters] = useState<any>({
    status: null,
    userRole: null,
  });
  const [sortDirections, setSortDirections] = useState<any>({
    name: "",
    direction: "",
  });
  const [isFilteredMember, setIsFilteredMember] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();

  const handleClose = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    console.log("uuuuuu", user);
  });
  const setTableData = async () => {
    setLoading(true);
    const getAllMembersData: {
      totalRecords: number;
      data?: MembersListingData[];
      error: boolean;
    } = await getAllMembers(selectedFilters, sortDirections);
    if (getAllMembersData.error) {
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("error_label"),
        description: t("something_went_wrong"),
        icon: <HiOutlineXCircle className="danger-text" />,
        className: "no-wrap",
      });
    }
    const mappedData = getAllMembersData.data?.map((d) => {
      const obj = {
        id: d.basicInfo.id,
        email: d.basicInfo.email,
        name: d.basicInfo.fullName,
        dateAdded: dayjs(d.auditInfo?.creationDate)
          .local()
          .locale(i18n.language == "en" ? "en" : "ar")
          .format("MMM D, YYYY"),
        lastActive: d.auditInfo?.lastActiveDate
          ? dayjs(d.auditInfo.lastActiveDate)
            .local()
            .locale(i18n.language === "en" ? "en" : "ar")
            .format("MMM D, YYYY")
          : null,
        role: d.basicInfo.roleKey,
        status: d.basicInfo.statusKey,
      };
      return obj;
    });
    console.log("mappedData", mappedData)
    setData(mappedData);
    setLoading(false);

    onDataFetched(mappedData);

    const loggedInMember = getAllMembersData.data?.find(
      (member) => member.basicInfo.id === user.id
    );

    if (loggedInMember) {
      setUserRole(loggedInMember.basicInfo.roleKey);
    }
  };
  useEffect(() => {
    setTableData();
  }, [i18n.language, selectedFilters, sortDirections]);
  const handleMemberAdded = () => {
    setTableData();
  };

  const updateMemberData = (updatedMember: any) => {
    setData((prevData: any[]) =>
      prevData.map((member) =>
        member.id === updatedMember.id ? updatedMember : member
      )
    );
  };

  console.log(data, "selectedFilters");

  // useEffect(() => {
  //   const checkIfAdmin = selectedFilters.userRole.includes("ADMIN");
  //   setIsFilteredMember(checkIfAdmin);
  // }, [selectedFilters]);
  // const handleFilterChange = (filterType: string, value: string[]) => {
  //   setSelectedFilters((prevFilters: any) => ({
  //     ...prevFilters,
  //     [filterType]: value,
  //   }));
  // };

  return (
    <>
      <div className={styles.Container}>
        <h2 className={styles.header}> {t("team_settings")}</h2>
        <div className={styles.line}></div>
        <div className={styles.filters}>
          <div className={styles.labelFilter}>
            <label className={styles.label}>{t("status")}</label>
            <Select
              value={selectedFilters.status}
              onChange={(e) => {
                setSelectedFilters((prev: any) => ({
                  ...prev,
                  status: e,
                }));
              }}
              options={
                lookups &&
                lookups[i18n.language]?.applicationUserStatuses.map(
                  (status: any) => ({
                    value: status.key,
                    label: status.name,
                  })
                )
              }
              placeholder={t("choose_status")}
              className="brands-select"
              allowClear
              style={{ width: "202px" }}
            ></Select>
          </div>
          <div className={styles.labelFilter}>
            <label className={styles.label}>{t("user_role")}</label>
            <Select
              value={selectedFilters.userRole}
              onChange={(e) => {
                setSelectedFilters((prev: any) => ({
                  ...prev,
                  userRole: e,
                }));
              }}
              placeholder={t("choose_user_role")}
              className="brands-select"
              allowClear
              style={{ width: "202px" }}
              options={
                lookups &&
                lookups[i18n.language]?.applicationRoles.map((role: any) => ({
                  value: role.key,
                  label: role.name.charAt(0).toUpperCase() + role.name.slice(1),
                }))
              }
            ></Select>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.titlecontainer}>
            <h1 className={styles.sidetitle}>{t("current_members")}</h1>
            <h4 className={styles.secondtitle}>{t("current_members_title")}</h4>
          </div>
          <div className={styles.buttonContainer}>
            {user.role !== "MEMBER" && (
              <Button
                onClick={() => setIsModalVisible(true)}
                className="lavad-btn btn-icon lavad-btn-lg lavad-btn-primary"
                icon={<HiOutlinePlus />}
              >
                {t("invite_team")}
              </Button>
            )}
            {isModalVisible && <EmailModal
              // visible={isModalVisible}
              // onClose={handleClose}
              onMemberAdded={handleMemberAdded}
              setModalOpen={setIsModalVisible}
              modalOpen={isModalVisible}
            />}
          </div>
        </div>
        <div className={styles.tableWrapper}>
          {(!!data.length || loading) && (
            <MembersListing
              data={data}
              sortDirections={sortDirections}
              setSortDirections={setSortDirections}
              loading={loading}
              updateMemberData={updateMemberData}
              userRole={userRole}
            />
          )}
          {!data.length &&
            !loading &&
            (selectedFilters.status || selectedFilters.userRole) ? (
            <div className={styles.emptyStateWithFilters}>
              <img src={nodata} alt="no data" />
              <div className={styles.empty_state_text}>
                <h3>{t("no_results_found")}</h3>
                <p className="title">{t("no_results_subtitle")}</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default MembersSettings;
