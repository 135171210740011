import React, { useState, useContext, useEffect } from "react";
import { AuthStateContext } from "../../../Context/MainContext";
import { requestLogin } from "../../../Context/Actions/auth";
import { Link } from "react-router-dom";
import styles from "../Auth.module.scss";

import { dir, t } from "i18next";
import { Row, Col, Input, Form, notification } from "antd";
import type { FormItemProps } from "antd";
import login_vector from "../../../assets/imgs/login-vector.png";
import Alert from "../../../Components/Alerts";
import {
  HiOutlineCheckCircle,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineXCircle,
} from "react-icons/hi";
import i18n from "../../../Utils/i18n/i18n";

const MyFormItemContext = React.createContext<(string | number)[]>([]);

interface MyFormItemGroupProps {
  prefix: string | number | (string | number)[];
  children: React.ReactNode;
}

function toArr(
  str: string | number | (string | number)[]
): (string | number)[] {
  return Array.isArray(str) ? str : [str];
}

const MyFormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );

  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

const MyFormItem = ({ name, ...props }: FormItemProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

  return <Form.Item name={concatName} {...props} />;
};
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { state, dispatch } = useContext(AuthStateContext);
  const [form] = Form.useForm();
  const [hidePassword, setHidePassword] = useState(true);

  const onFinish = (user: { email: string; password: string }) => {
    console.log("userdata", user);
    dispatch({ type: "REQUEST_LOGIN" });
    setLoading(true);
    setError("");
    try {
      requestLogin(user.email, user.password).then((res: any) => {
        if (res?.message) {
          if (res.message.includes("invalid-login-credentials")) {
            setError("invalid_credentials");
          } else if (res.message.includes("user-disabled")) {
            setError("user_disabled");
          } else if (res.message.includes("attempts")) {
            setError("your_are_blocked");
          } else {
            setError("something_went_wrong");
          }
        } else {
          setError("");
          dispatch({ type: "USER_LOGGED" });
        }
        setLoading(false);
      });
    } catch (error: any) {
      setError("invalid_credentials");
    }
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      form.resetFields(); // Trigger form validation on language change
      setError("");
      setPassword(null);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [form, i18n]);
  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("success_label"),
      description: t("success_reset_password"),
      icon: <HiOutlineCheckCircle className="success-text" />,
    });
  };
  useEffect(() => {
    if (state.isResetPassword) {
      openNotification();
    }
  }, [state.isResetPassword]);

  return (
    <>
      {/* {state.loading && (
        <div className={styles.loader}>
          <h2>Loading</h2>
        </div>
      )} */}
      <div className={styles.login_containier}>
        <Row
          justify="space-between"
          align="middle"
          gutter={16}
          className={styles.loginRow}
        >
          <Col lg={8} xl={8} xxl={8} xs={20} push={4}>
            <h2 className={styles.login_header}>
              {t("login_header")}{" "}
              <span className="icon-wave rotate-icon-rtl"></span>
            </h2>
            {error && error !== "" && (
              <Alert
                message={t(error)}
                type="danger"
                icon={<HiOutlineXCircle />}
              />
            )}
            <Form
              autoComplete="off"
              validateTrigger="onSubmit"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className={styles.login_form}
            >
              <div className="form-group">
                <label className="lavad_label">{t("email_label")}</label>
                <Form.Item
                  className="nomargin"
                  name="email"
                  rules={[
                    { required: true, message: t("email_required") },
                    { type: "email", message: t("invalid_email") },
                  ]}
                >
                  <Input
                    name="email"
                    autoComplete="off"
                    type="text"
                    className="lavad-input lavad-input-lg"
                    placeholder={t("enter_email_placeholder")}
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <label className="lavad_label">{t("password_label")}</label>
                <div className="relative">
                  <Form.Item
                    className="nomargin relative"
                    name="password"
                    rules={[
                      { required: true, message: t("password_required") },
                    ]}
                  >
                    <Input
                      type={hidePassword ? "password" : "text"}
                      name="password"
                      className="lavad-input lavad-input-lg"
                      placeholder={t("enter_password_placeholder")}
                      autoComplete="false"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  {hidePassword ? (
                    <HiOutlineEyeOff
                      onClick={() => setHidePassword(!hidePassword)}
                      className={styles.showPW}
                    />
                  ) : (
                    <HiOutlineEye
                      onClick={() => setHidePassword(!hidePassword)}
                      className={styles.hidePW}
                    />
                  )}
                </div>
              </div>

              <button
                className={`lavad-btn lavad-btn-primary lavad-btn-lg btn-block ${loading ? "loading" : ""
                  }`}
                type="submit"
              >
                {loading ? (
                  <span className="icon-loader spin"></span>
                ) : (
                  t("login_label")
                )}
              </button>
            </Form>
            <Link className={styles.forgetpw} to="/forget-password">
              {t("forget_password_label")}
            </Link>
          </Col>
          <Col lg={8} xl={8} xxl={8} xs={20} pull={2}>
            <div className="justify-end">
              <img
                className={styles.login_vector}
                src={login_vector}
                alt="login vector"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default LoginPage;
