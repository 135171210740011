import api from "../../Utils/axiosInterceptor";

export interface Campaign {
  campaignSummary: {
    name: string;
    campaignObjectiveKey: string;
    brandId: number;
    clientId: number;
    clientRelationshipKey?: string,
  };
  influencerAndServices: {
    campaignNicheKeys: string[];
    requestedInfluencers: number;
    influencerTypeKeys: string[];
    socialPlatformServices: string[];
  };
  budgetAndTeam: {
    budget: number;
    campaignLeadId: string;
    campaignAssignedMembers: string[];
  };
  briefAndAttachments: {
    brief: string;
    influencerDos: string;
    influencerDonts: string;
    attachments: {
      url: string;
      fileName: string;
    }[];
  };
}

export interface CampaignFilter {
  campaignObjective?: Array<string>;
  campaignStatus?: Array<string>;
  brandId?: Array<number>;
  campaignLeadIds?: Array<number>;
  campaignAssignedMemberIds?: Array<number>;
  clientIds?: Array<number>;
  keyword?: string;
  page: number;
  pageSize: number;
  from?: number;
  to?: number;
}
export interface Brand {
  page: number;
  pageSize: number;
  search?: string
}

export interface CampaginListingItem {
  basicInfo: {
    id: number;
    name: string;
    brief: string;
    campaignStatusKey: string;
    campaignObjectiveKey: string;
    requestedInfluencersCount: number;
  };
  auditInfo: {
    created: {
      date: number;
    };
  };
  summary: {
    assignedInfluencersCount: number;
    cost: number;
  };
  clientInfo: {
    id: number;
    name: string;
  };
  brandInfo: {
    id: number;
    name: string;
    logoUrl: string;
  };
  teamInfo: {
    campaignLead: {
      id: string;
      name: string;
    };
    campaignAssignedMembers: {
      id: string;
      name: string;
    }[];
  };
}

export interface CampaignDetailsInterface {
  error?: boolean;
  name?: string;
  agencyInfo: {
    basicInfo: {
      id: number,
      name: string
    }
  }
  auditSummary?: {
    modificationInfo?: {
      modificationDate: number;
    };
  };
  briefAndAttachments: {
    brief: string;
    influencerDonts: string;
    influencerDos: string;
    attachments: {
      fileName: string,
      url: string,
      id: string
    }[]
  }
  budgetAndTeam: {
    budget: number,
    campaignLeadId: string;
    campaignAssignedMembers: {
      id: string,
      name: string,
      email: string
    }[]
  }
  campaignSummary: {
    brand: {
      id: number;
      name: {
        en: string;
        ar: string
      }
      logo: string;
      websiteUrl: string;
      label: string

    },
    campaignObjectiveKey: string,
    campaignStatusKey: string,
    id: string,
    name: string,
    client: {
      id: number,
      name: string
    }
  }
  influencerAndServices: {
    campaignNicheKeys: any;
    influencerTypeKeys: any;
    requestedInfluencers: number
    socialPlatforms:
    | Array<{
      key: string;
      servicesKeys: string[];
    }>
    | any;
  }
  shareLink: {
    status: boolean,
    url: string
  }

}


interface brand {
  basicInfo: {
    name: {
      ar: string;
      en: string;
    };
    websiteUrl: string;
    logoUrl: string;
  };
  metaData: {
    typeKey: string | null;
    classificationKey: string | null;
    industryKeys: string[];
  };
  clientInfo: {
    id: number;
    relationshipKey: string;
  } | null;
  socialPlatforms: {
    key: string;
    url: string;
  }[];
}

export const createCampaign = async (campaign: Campaign) => {
  const res = await api.post(
    `${process.env.REACT_APP_API_URL}api/campaign`,
    campaign
  );
  if (res.data) {
    return res.data;
  } else {
    return { error: true };
  }
};
export const getAllCampaigns = async (params: CampaignFilter, sortDirections: any) => {

  const res = await api.post(`${process.env.REACT_APP_API_URL
    }api/campaign/all
    `, {
    page: params.page,
    pageSize: params.pageSize,
    keyword: params.keyword ? params.keyword : '',
    ...(params.brandId?.length ? { brandIds: params.brandId } : {}),
    ...(params.campaignObjective?.length ? { campaignObjectiveKeys: params.campaignObjective } : {}),
    ...(params.campaignStatus?.length ? { campaignStatusKeys: params.campaignStatus } : {}),
    ...(params.from ? { from: params.from } : {}),
    ...(params.to ? { to: params.to } : {}),
    ...(params.clientIds?.length ? { clientIds: params.clientIds } : {}),
    ...(params.campaignLeadIds?.length ? { campaignLeadIds: params.campaignLeadIds } : {}),
    ...(params.campaignAssignedMemberIds?.length ? { campaignAssignedMemberIds: params.campaignAssignedMemberIds } : {}),
    ...(sortDirections.name && sortDirections.direction ? { sortBy: sortDirections.name[0], sortDirection: sortDirections.direction == "ascend" ? "asc" : "desc" } : {}),
  });
  if (res.data) {
    return res.data;
  } else {
    return { error: true };
  }
};
export const getCampaign = async (campaignId: string) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/campaign/${campaignId}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};
export const editCampaign = async (campaign: Campaign, campaignId: number) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/campaign/${campaignId}`,
      campaign
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
};
export const changeCampaignStatus = async (
  campaignStatusKey: string,
  campaignId: number
) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/campaign/${campaignId}/status/${campaignStatusKey}`
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
};
export const changeCampaignShareLinkStatus = async (
  publishStatus: boolean,
  campaignId: number
) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/campaign/${campaignId}/sharelink/${publishStatus}`
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
};
export const getCampaignAssignedInfluencers = async (campaignId: any, filters: any) => {

  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL
      }api/campaign/${campaignId}/influencer/all`
      , {
        ...(filters.status || filters.platform ? {
          "filters": {
            ...(filters.status ? {
              "lineItemBookingStatuses": [
                filters.status
              ]
            } : {}),
            ...(filters.platform ? {
              "socialPlatformKeys": [
                filters.platform
              ]
            } : {})
          }
        } : {})
      });
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const getCampaignSummary = async (campaignId: any, filters: any) => {

  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL
      }api/campaign/${campaignId}/summary`
      , {
        ...(filters.status || filters.platform ? {
          "filters": {
            ...(filters.status ? {
              "lineItemBookingStatuses": [
                filters.status
              ]
            } : {}),
            ...(filters.platform ? {
              "socialPlatformKeys": [
                filters.platform
              ]
            } : {})
          }
        } : {})
      });
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};


export const getCampaignAssignedInfluencerDetails = async (influencerId: number, campaignId: string) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL
      }api/campaign/${campaignId}/lineitems/influencer/${influencerId}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
}

export const getLogs = async (entityKey: string, entityId: string, pageNumber: number, pageSize: number) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_ACTIVITY_API_URL}api/${entityKey}/${entityId}/activity-log?page=${pageNumber}&pageSize=${pageSize}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
}
export const editCampaignAssignedInfluencerDetails = async (advertisingProducts: any, campaignId: string, influencerId: number, recommended: boolean) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL
      }api/campaign/${campaignId}/lineitems/influencer/${influencerId}`, { isRecommended: recommended, advertisingProducts })
    return { error: false };
  } catch (err) {
    return { error: true };
  }
}
export const changeLineItemStatus = async (
  lineItemId: string,
  status: string
) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/campaign/lineItem/${lineItemId}/${status}`
    );
    return { error: false };
  } catch (err) {
    return { error: true };
  }
}


export const exportCampaignAssignedInfluencers = async (campaignId: any) => {

  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL
      }api/campaign/${campaignId}/influencer/export/all`
      , {
        "filters": {
          "lineItemBookingStatuses": [
            "Accepted", "Pending"
          ]
        }
      });
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};


export const createBrand = async (brand: brand) => {
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL
      }api/brand`
      , brand);
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};

