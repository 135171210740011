import { Navigate } from "react-router-dom";
import { useAppContext } from "./Context/MainContext";
import AuthLayout from "./layout/AuthLayout";

function PrivateRoute({ children }: any) {
  const { state } = useAppContext();
  // Check if the user is authenticated, e.g., using a token in context
  return state.isAuthenticated ? <AuthLayout>{children}</AuthLayout> : <Navigate to="/login" />
}

export default PrivateRoute;
