import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { initialState, AccountReducer } from "./reducers/account";

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthStateContext = createContext<any>(null);
export const AuthDispatchContext = createContext<any>(undefined);

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within an AuthProvider");
  }
  
  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within an AuthProvider");
  }

  return context;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(AccountReducer, initialState);
  useEffect(() => {
    dispatch({
      type: "SET_USERDATA",
      payload:
        localStorage.getItem("currentUser") &&
        JSON.parse(localStorage.getItem("currentUser")!),
    });
  }, []);
  // const userProviderReference= useMemo(() => ({ state }), [state]);

  return <AuthStateContext.Provider value={{ state, dispatch }}>
            <AuthDispatchContext.Provider value={dispatch}>
           
            { children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
}

export const UserDetails = () => {
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    setUserData(
      localStorage.getItem("currentUser") &&
        JSON.parse(localStorage.getItem("currentUser")!)
    );
  }, []);

  return userData;
};

export function useAppContext() {
  return useContext(AuthStateContext);
}