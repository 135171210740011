import { Tag, Checkbox, Form, Select, ConfigProvider } from "antd";
import styles from "./index.module.scss";
import { useEffect, useState, useContext } from "react";
import { AuthStateContext } from "../../Context/MainContext";
import { getBrands } from "../../actions/lookups";
import i18n from "../../Utils/i18n/i18n";
import { HiOutlineArrowPathRoundedSquare, HiOutlineBriefcase, HiOutlineCalendar, HiOutlineCheckCircle, HiOutlineClock, HiOutlineMagnifyingGlass, HiOutlinePencilSquare, HiOutlineRocketLaunch, HiOutlineStopCircle, HiOutlineTrophy, HiOutlineUserCircle, HiOutlineUserGroup, HiOutlineXCircle } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import locale from "antd/locale/ar_EG";
import "dayjs/locale/ar";
import { HiOutlineTag } from "react-icons/hi";
import dayjs from "dayjs";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../Utils/firebase";
import { ClientData, getAllClientsListing } from "../../Context/Actions/clients";
import { getInitials } from "../../Utils/helpers";

const CampaignFilters = ({
    filters,
    setFilters,
    resetFilters,
    setResetFilters,
    language,
    style = "Form",
    loading,
    showFilters = true,
    localSelectedDate,
    setLocalSelectedDate

}: {
    filters: any;
    setFilters: any;
    resetFilters: any;
    setResetFilters: any;
    language: string;
    style?: 'Tags' | 'Form',
    loading: boolean;
    showFilters?: boolean;
    localSelectedDate: any;
    setLocalSelectedDate: any;
}) => {

    const { RangePicker } = DatePicker;
    const {
        state: { lookups, brands, users, user },
        dispatch,
    } = useContext(AuthStateContext);
    const [brandsSearchResults, setBrandsSearchResults] = useState<any>([]);
    const [clientsSearchResults, setClientsSearchResults] = useState<any>([]);
    const [membersSearchResults, setMembersSearchResults] = useState<any>([]);
    const [leadsSearchResults, setLeadsSearchResults] = useState<any>([]);



    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();

    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [selectedBrands, setSelectedBrands] = useState<any>([]);
    const [clients, setClients] = useState<any>([])

    const resetFiltersHandler = () => {
        setLocalSelectedDate(null);
        setSelectedDate(null);
        setFilters((prev: any) => ({
            ...prev,
            status: [],
            brand: [],
            client: [],
            campaignLead: [],
            teamMembers: [],
            creationDate: null,
        }));
    }

    useEffect(() => {
        const getClients = async () => {
            const response = await getAllClientsListing();
            const clientsData = response.data as ClientData[];
            const results = clientsData.map((client) => {
                const { basicInfo } = client;
                return {
                    value: basicInfo?.id,
                    label: basicInfo.name,
                };
            })
            setClients(clientsData)
            setClientsSearchResults(results)
        }
        getClients()
    }, [])

    useEffect(() => {
        if (users) {
            setMembersSearchResults(users?.map((b: any) => ({ value: b.id, label: b.name })))
            setLeadsSearchResults(users?.map((b: any) => ({ value: b.id, label: b.name })))
        }
    }, [users])

    useEffect(() => {
        if (resetFilters) {
            resetFiltersHandler()
            setResetFilters(false);
        }
    }, [resetFilters]);


    const onBrandsChange = (e: any) => {
        const v = e.target.value;
        const filtered: any = brands
            .filter((b: any) => (b.name.find((a: any) => a.languageKey == "en").value.toLowerCase().includes(v.toLowerCase()) || b.name.find((a: any) => a.languageKey == "ar").value.toLowerCase().includes(v.toLowerCase())))
            .map((b: any) => ({ logo: b.logoUrl, value: b.id, label: b.name.find((a: any) => a.languageKey == "en").value + ' - ' + b.name.find((a: any) => a.languageKey == "ar").value, disabled: selectedBrands.find((c: any) => c.key == b.id) }));
        setBrandsSearchResults(filtered);
    };

    const onClientsChange = (e: any) => {
        const v = e.target.value;
        const filtered: any = clients
            .filter((b: any) => (b.basicInfo.name.toLowerCase().includes(v.toLowerCase())))
            .map((client: any) => {
                const { basicInfo } = client;
                return {
                    value: basicInfo?.id,
                    label: basicInfo.name,
                };
            })
        setClientsSearchResults(filtered);
    };

    const onUsersChange = (e: any, type: 'members' | 'leads') => {
        const v = e.target.value;
        const filtered: any = users
            .filter((b: any) => (b.name.toLowerCase().includes(v.toLowerCase())))
            .map((b: any) => ({ value: b.id, label: b.name }));
        if (type == 'members') {
            setMembersSearchResults(filtered);
        } else {
            setLeadsSearchResults(filtered)
        }
    };


    useEffect(() => {
        console.log(brands, "brandsss");

        setBrandsSearchResults(
            brands.map((b: any) => ({ logo: b.logoUrl, value: b.id, label: b.name.find((a: any) => a.languageKey == "en").value + ' - ' + b.name.find((a: any) => a.languageKey == "ar").value, disabled: selectedBrands.find((c: any) => c.key == b.id) }))
        );
    }, [brands]);


    const setDates = (dates: any) => {
        if (dates.every((d: any) => !!d)) {
            setLocalSelectedDate(dates)
            const startOfDay = dayjs(dates[0]).startOf('day').utc();
            const endOfDay = dates.length > 1 && dayjs(dates[1]).endOf('day').utc();
            const formattedDates = [startOfDay, endOfDay];
            if (formattedDates.every((d: any) => !!d)) {
                console.log(dates, "dates")
                setFilters((state: any) => ({
                    ...state,
                    creationDate: formattedDates.map((d: any) => Number(dayjs(d).format("x"))),
                }));
            }
        }
        else {
            if (dates.every((d: any) => !d) && filters.creationDate.length) {
                setLocalSelectedDate([])
                setFilters((prev: any) => ({ ...prev, creationDate: [] }));
            }
        }
    };

    const returnStatusIcon = (status: any) => {
        if (status.toLowerCase() == "new") {
            return <HiOutlineRocketLaunch width={18}
                height={18} />
        } else if (status.toLowerCase() == "planning") {
            return <HiOutlinePencilSquare width={18}
                height={18} />
        } else if (status.toLowerCase() == "accepted") {
            return <HiOutlineCheckCircle width={18}
                height={18} />
        } else if (status.toLowerCase() == "waiting") {
            return <HiOutlineClock width={18}
                height={18} />
        } else if (status.toLowerCase() == "running") {
            return <HiOutlineArrowPathRoundedSquare width={18}
                height={18} />
        } else if (status.toLowerCase() == "cancelled") {
            return <HiOutlineXCircle width={18}
                height={18} />
        } else if (status.toLowerCase() == "paused") {
            return <HiOutlineStopCircle width={18}
                height={18} />
        } else if (status.toLowerCase() == "completed") {
            return <HiOutlineTrophy width={18}
                height={18} />
        }
    }

    const checkIfFiltersApplied = () => {
        const withoutSearch = { ...filters }
        delete withoutSearch.searchTerm
        return Object.values(withoutSearch).some(a => Array.isArray(a) ? !!a.length : !!a)
    }

    console.log("selectedDate", selectedDate)

    return (
        <div className={`${styles.filtersForm} ${!showFilters ? styles.hide : ""} ${style == "Tags" ? styles.filtersFormTags + ' ' + "campaignFilters" : ""}`}>
            <div className={styles.filtersHeader}>
                <h3>{t("filters")}</h3>
                {checkIfFiltersApplied() && <button disabled={!checkIfFiltersApplied() || loading} onClick={resetFiltersHandler} className="lavad-btn lavad-btn-link">{t("clear_filters")}</button>}
            </div>

            <div className={styles.tagsContainer}>
                <div className={styles.iconConatiner + " " + "_lr-hide"}>
                    <span className="icon-status"></span>
                    <p className="body">{t("status")}</p>
                </div>

                <Select
                    popupClassName="campaignFilters"
                    maxTagCount={"responsive"}
                    allowClear
                    defaultActiveFirstOption={false}
                    virtual={false}
                    notFoundContent={
                        <div
                            role="listbox"
                            id="platforms_list"
                            className="ant-select-item-empty"
                        >
                            <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                <div className="ant-empty-image">
                                    <svg
                                        width="64"
                                        height="41"
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <ellipse
                                                fill="#f5f5f5"
                                                cx="32"
                                                cy="33"
                                                rx="32"
                                                ry="7"
                                            ></ellipse>
                                            <g fill-rule="nonzero" stroke="#d9d9d9">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    fill="#fafafa"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">{t("not_found")}</div>
                            </div>
                        </div>
                    }
                    onChange={(status) => {
                        setFilters({ ...filters, status });
                    }}
                    value={filters.status}
                    showSearch={false}
                    mode="multiple"
                    style={{ width: "100%" }}
                    onInputKeyDown={(event) => {
                        if (event.keyCode === 8 || event.key === "Backspace") {
                            return event.stopPropagation();
                        }
                    }}
                    dropdownRender={(menu) => (
                        <>

                            {menu}
                        </>
                    )}
                    options={lookups && lookups[language]?.campaignStatuses?.map((b: any) => ({ value: b.key, label: b.name }))}
                    size="large"
                    placeholder={t("choose_status")}
                    optionRender={(option: any) => {
                        console.log(option)
                        return <div className={styles.opt}>{returnStatusIcon(option.value)} {option.label}</div>
                    }}
                />
            </div>
            <div className={`${styles.tagsContainer}`}>
                <div className={styles.iconConatiner + " " + "_lr-hide"}>
                    <HiOutlineCalendar className={styles.gapIcon} />
                    <p className="body">{t("creation_date")}</p>
                </div>
                {/* disabledDate={(current) => {
                            return current && current > dayjs().endOf('day');
                        }} */}
                <ConfigProvider locale={language == "ar" ? locale : undefined}>
                    <RangePicker
                        allowEmpty={false}
                        inputReadOnly
                        onCalendarChange={(e) => setDates(e)}
                        value={localSelectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        className={`lavad-input-lg`}
                        placeholder={[t("from"), t("to")]}
                        suffixIcon={<HiOutlineCalendar />}
                        format="DD/MM/YYYY"
                    />
                </ConfigProvider>
            </div>
            <div className={styles.tagsContainer}>
                <div className={styles.iconConatiner + " " + "_lr-hide"}>
                    <HiOutlineTag className={styles.gapIcon} />
                    <p className="body">{t("brand")}</p>
                </div>
                <Select
                    popupClassName="campaignFilters"

                    maxTagCount={"responsive"}

                    allowClear
                    defaultActiveFirstOption={false}
                    virtual={false}
                    notFoundContent={
                        <div
                            role="listbox"
                            id="platforms_list"
                            className="ant-select-item-empty"
                        >
                            <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                <div className="ant-empty-image">
                                    <svg
                                        width="64"
                                        height="41"
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <ellipse
                                                fill="#f5f5f5"
                                                cx="32"
                                                cy="33"
                                                rx="32"
                                                ry="7"
                                            ></ellipse>
                                            <g fill-rule="nonzero" stroke="#d9d9d9">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    fill="#fafafa"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">{t("not_found")}</div>
                            </div>
                        </div>
                    }
                    onChange={(brands) => {
                        setFilters({ ...filters, brand: brands });
                    }}
                    value={filters.brand}
                    showSearch={false}
                    mode="multiple"
                    style={{ width: "100%" }}
                    onInputKeyDown={(event) => {
                        if (event.keyCode === 8 || event.key === "Backspace") {
                            return event.stopPropagation();
                        }
                    }}
                    dropdownRender={(menu) => (
                        <>
                            <div className="lavad-input search-input">
                                <HiOutlineMagnifyingGlass width={16} height={16} />
                                <input
                                    placeholder={t("search")}
                                    onChange={onBrandsChange}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 8 || event.key === "Backspace") {
                                            return event.stopPropagation();
                                        }
                                    }}
                                />
                            </div>
                            {menu}
                        </>
                    )}
                    options={brandsSearchResults}
                    optionRender={(option: any) => {
                        return <div className={styles.brandOpt}>
                            <img src={option.data.logo} style={{ width: '32px', height: '32px', objectFit: 'contain' }} loading='lazy' />
                            {option.data.label}
                        </div>
                    }}
                    size="large"
                    placeholder={t("brand_placeholder")}
                />
            </div>
            <div className={styles.tagsContainer}>
                <div className={styles.iconConatiner + " " + "_lr-hide"}>
                    <HiOutlineBriefcase className={styles.gapIcon} />
                    <p className="body">{t("client")}</p>
                </div>
                <Select
                    popupClassName="campaignFilters"

                    maxTagCount={"responsive"}

                    allowClear
                    defaultActiveFirstOption={false}
                    virtual={false}
                    notFoundContent={
                        <div
                            role="listbox"
                            id="platforms_list"
                            className="ant-select-item-empty"
                        >
                            <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                <div className="ant-empty-image">
                                    <svg
                                        width="64"
                                        height="41"
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <ellipse
                                                fill="#f5f5f5"
                                                cx="32"
                                                cy="33"
                                                rx="32"
                                                ry="7"
                                            ></ellipse>
                                            <g fill-rule="nonzero" stroke="#d9d9d9">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    fill="#fafafa"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">{t("not_found")}</div>
                            </div>
                        </div>
                    }
                    onChange={(client) => {
                        setFilters({ ...filters, client });
                    }}
                    value={filters.client}
                    showSearch={false}
                    mode="multiple"
                    style={{ width: "100%" }}
                    onInputKeyDown={(event) => {
                        if (event.keyCode === 8 || event.key === "Backspace") {
                            return event.stopPropagation();
                        }
                    }}
                    dropdownRender={(menu) => (
                        <>
                            <div className="lavad-input search-input">
                                <HiOutlineMagnifyingGlass width={16} height={16} />
                                <input
                                    placeholder={t("search")}
                                    onChange={onClientsChange}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 8 || event.key === "Backspace") {
                                            return event.stopPropagation();
                                        }
                                    }}
                                />
                            </div>

                            {menu}
                        </>
                    )}
                    options={clientsSearchResults}
                    size="large"
                    placeholder={t("choose_clients")}
                />
            </div>
            <div className={styles.tagsContainer}>
                <div className={styles.iconConatiner + " " + "_lr-hide"}>
                    <HiOutlineUserCircle className={styles.gapIcon} />
                    <p className="body">{t("campaign_lead")}</p>
                </div>
                <Select
                    popupClassName="campaignFilters"

                    maxTagCount={"responsive"}

                    allowClear
                    defaultActiveFirstOption={false}
                    virtual={false}
                    notFoundContent={
                        <div
                            role="listbox"
                            id="platforms_list"
                            className="ant-select-item-empty"
                        >
                            <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                <div className="ant-empty-image">
                                    <svg
                                        width="64"
                                        height="41"
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <ellipse
                                                fill="#f5f5f5"
                                                cx="32"
                                                cy="33"
                                                rx="32"
                                                ry="7"
                                            ></ellipse>
                                            <g fill-rule="nonzero" stroke="#d9d9d9">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    fill="#fafafa"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">{t("not_found")}</div>
                            </div>
                        </div>
                    }
                    onChange={(leads) => {
                        setFilters({ ...filters, campaignLead: leads });
                    }}
                    value={filters.campaignLead}
                    showSearch={false}
                    mode="multiple"
                    style={{ width: "100%" }}
                    onInputKeyDown={(event) => {
                        if (event.keyCode === 8 || event.key === "Backspace") {
                            return event.stopPropagation();
                        }
                    }}
                    dropdownRender={(menu) => (
                        <>
                            <div className="lavad-input search-input">
                                <HiOutlineMagnifyingGlass width={16} height={16} />
                                <input
                                    placeholder={t("search")}
                                    onChange={(e) => onUsersChange(e, 'leads')}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 8 || event.key === "Backspace") {
                                            return event.stopPropagation();
                                        }
                                    }}
                                />
                            </div>

                            {menu}
                        </>
                    )}
                    options={leadsSearchResults}
                    size="large"
                    placeholder={t("choose_campaignLeads")}
                    optionRender={(option: any) => {
                        return <div className="user">
                            <div className="userImg">{getInitials(option.data.label)}</div>
                            <div className="userWithCurrentInfo">
                                <span>
                                    {option.data.label}
                                </span>
                                {option.data.value == user.id ? <span>
                                    {t('current_user')}
                                </span> : ""}
                            </div>
                        </div>
                    }}
                />
            </div>
            <div className={`${styles.tagsContainer} ${styles.removeBorders}`}>
                <div className={styles.iconConatiner + " " + "_lr-hide"}>
                    <HiOutlineUserGroup className={styles.gapIcon} />
                    <p className="body">{t("team_members")}</p>
                </div>
                <Select
                    popupClassName="campaignFilters"

                    maxTagCount={"responsive"}

                    allowClear
                    defaultActiveFirstOption={false}
                    virtual={false}
                    notFoundContent={
                        <div
                            role="listbox"
                            id="platforms_list"
                            className="ant-select-item-empty"
                        >
                            <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                <div className="ant-empty-image">
                                    <svg
                                        width="64"
                                        height="41"
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <ellipse
                                                fill="#f5f5f5"
                                                cx="32"
                                                cy="33"
                                                rx="32"
                                                ry="7"
                                            ></ellipse>
                                            <g fill-rule="nonzero" stroke="#d9d9d9">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    fill="#fafafa"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">{t("not_found")}</div>
                            </div>
                        </div>
                    }
                    onChange={(members) => {
                        setFilters({ ...filters, teamMembers: members });
                        setSelectedBrands(brands);
                    }}
                    value={filters.teamMembers}
                    showSearch={false}
                    mode="multiple"
                    style={{ width: "100%" }}
                    onInputKeyDown={(event) => {
                        if (event.keyCode === 8 || event.key === "Backspace") {
                            return event.stopPropagation();
                        }
                    }}
                    dropdownRender={(menu) => (
                        <>
                            <div className="lavad-input search-input">
                                <HiOutlineMagnifyingGlass width={16} height={16} />
                                <input
                                    placeholder={t("search")}
                                    onChange={(e) => onUsersChange(e, 'members')}
                                    onKeyDown={(event) => {
                                        if (event.keyCode === 8 || event.key === "Backspace") {
                                            return event.stopPropagation();
                                        }
                                    }}
                                />
                            </div>
                            {menu}
                        </>
                    )}
                    options={membersSearchResults}
                    size="large"
                    placeholder={t("choose_members")}
                    optionRender={(option: any) => {
                        return <div className="user">
                            <div className="userImg">{getInitials(option.data.label)}</div>
                            <div className="userWithCurrentInfo">
                                <span>
                                    {option.data.label}
                                </span>
                                {option.data.value == user.id ? <span>
                                    {t('current_user')}
                                </span> : ""}
                            </div>
                        </div>
                    }}
                />
            </div>
            {style == "Tags" && <button disabled={!checkIfFiltersApplied() || loading} onClick={resetFiltersHandler} className={`lavad-btn lavad-btn-link`}>{t("clear_filters")}</button>}


        </div>
    );
};

export default CampaignFilters;
