import { useTranslation } from 'react-i18next';
import styles from './index.module.scss'

import {
    HiOutlineArrowTrendingUp,
    HiOutlineCheckCircle,
    HiOutlineLightBulb,
    HiOutlineMegaphone,
    HiOutlinePencilSquare,
    HiOutlineXCircle,
    HiOutlineArrowUpOnSquare,
    HiOutlineArrowPathRoundedSquare,
    HiOutlineStopCircle,
    HiOutlineTrophy,
    HiOutlineRocketLaunch,
    HiOutlineClock as Hi2OutlineClock,
    HiOutlineUserGroup,
    HiOutlineBanknotes,
    HiOutlineBriefcase,
    HiCheckCircle,
    HiCalendar
} from "react-icons/hi2";
import { AuthStateContext } from '../../Context/MainContext';
import { useContext } from 'react';
import Placeholder from "../../assets/imgs/brand_placeholder.svg"
import i18n from '../../Utils/i18n/i18n';
import { getInitials, moneyFormatter } from '../../Utils/helpers';
import { CampaginListingItem } from '../../Context/Actions/campaign';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';


const CampaignListingCard = ({ data }: { data: CampaginListingItem }) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();

    const {
        state: { lookups, brands },
        dispatch,
    } = useContext(AuthStateContext);
    const returnStatusIcon = (status: any) => {
        console.log(status, "statusssss");
        if (status.toLowerCase() == "new") {
            return <HiOutlineRocketLaunch width={18} height={18} />;
        } else if (status.toLowerCase() == "planning") {
            return <HiOutlinePencilSquare width={18} height={18} />;
        } else if (status.toLowerCase() == "accepted") {
            return <HiOutlineCheckCircle width={18} height={18} />;
        } else if (status.toLowerCase() == "waiting") {
            return <Hi2OutlineClock width={18} height={18} />;
        } else if (status.toLowerCase() == "running") {
            return <HiOutlineArrowPathRoundedSquare width={18} height={18} />;
        } else if (status.toLowerCase() == "cancelled") {
            return <HiOutlineXCircle width={18} height={18} />;
        } else if (status.toLowerCase() == "paused") {
            return <HiOutlineStopCircle width={18} height={18} />;
        } else if (status.toLowerCase() == "completed") {
            return <HiOutlineTrophy width={18} height={18} />;
        }
    };
    const navigate = useNavigate();

    return (
        <Link to={`/campaign/${data?.basicInfo.id}`} className={styles.campaignCard} >
            <div className={`${styles.edge} ${styles[data?.basicInfo.campaignStatusKey.toLowerCase()]}`}></div>
            <div className={styles.head}>
                <span
                    className={`${styles.camp_status} ${data?.basicInfo.campaignStatusKey === "Cancelled"
                        ? "label-danger"
                        : (data?.basicInfo.campaignStatusKey === "Accepted" || data?.basicInfo.campaignStatusKey === "Completed")
                            ? "label-success"
                            : data?.basicInfo.campaignStatusKey === "Planning" ? "label-warning" : data?.basicInfo.campaignStatusKey === "Waiting" ? "label-warning-700" : data?.basicInfo.campaignStatusKey === "New" ? "label-info" : data?.basicInfo.campaignStatusKey === "Running" ? "label-info-700" : data?.basicInfo.campaignStatusKey === "Paused" ? "label-neutral" : ""
                        }`}
                >
                    {returnStatusIcon(data?.basicInfo.campaignStatusKey)}
                    {t(data?.basicInfo.campaignStatusKey.toLowerCase())}
                </span>
                <span className={styles.id}>{`${t("campaign_abbr_id")}${data?.basicInfo.id}`}</span>
            </div>
            <div className={styles.body}>
                <div className={styles.left}>
                    <img src={data?.brandInfo.logoUrl || Placeholder} alt="" />
                    <div>
                        <p className={styles.brandName}>{data?.brandInfo.name}</p>
                        <Link to={`/campaign/${data?.basicInfo.id}`} className={styles.campaignName}>{data?.basicInfo.name}</Link>
                        <p className={styles.creationDate}><HiCalendar />{`${t('created_on')}${dayjs(data?.auditInfo.created.date).locale(i18n.language).format("DD MMM YYYY")}`}</p>
                    </div>
                </div>
                <div className={styles.seperator}></div>
                <div className={styles.right}>
                    <div className={styles.infs}>
                        <div className={styles.requestedInfs}>
                            <HiOutlineUserGroup /> {t('requested_infs')} <div className={styles.cont}>{data?.basicInfo?.requestedInfluencersCount || "-"}</div>
                        </div>
                        <div className={styles.seperator}></div>
                        <div className={styles.assignedInfs}>
                            <HiOutlineCheckCircle /> {t('assigned')} <div className={styles.cont}>{data?.summary.assignedInfluencersCount || 0}</div>
                        </div>
                    </div>
                    <div className={styles.budget}><div><HiOutlineBanknotes /> <span >{data.summary.cost ? moneyFormatter(data.summary.cost) : "-"}</span></div> {!!data.summary.cost && <span className={styles.currency}>{i18n.language == "en" ? "SAR" : "ر.س"}</span>}</div>
                    {<div className={styles.client}><HiOutlineBriefcase /> <span>{data?.clientInfo.name || "-"}</span></div>}
                </div>

            </div>
            {!!data?.teamInfo?.campaignLead?.name && !!data?.teamInfo?.campaignAssignedMembers?.length && <div className={styles.footer}>
                <div className={styles.lead}>
                    <p>{t('campaign_lead')}</p>
                    {data?.teamInfo?.campaignLead?.name ? <div className={styles.user}>
                        <div className={styles.userImg}>{`${getInitials(data?.teamInfo.campaignLead.name)}`}</div>
                        {data?.teamInfo.campaignLead.name}
                    </div> : '-'}

                </div>
                <div className={styles.members}>
                    <p>{t('assigned_team')}</p>
                    <ul className={`${styles.stackedUsers}`}>
                        {data?.teamInfo.campaignAssignedMembers.slice(0, data?.teamInfo.campaignAssignedMembers.length > 6 ? 6 : undefined).map((member: any, index: any) => (
                            <li key={index}>
                                <div className={styles.userImg}>{getInitials(member.name)}</div>
                            </li>

                        ))}
                        {data?.teamInfo.campaignAssignedMembers.length! > 6 ? <li className={styles.user}>
                            <div className={`${styles.userImg} ${styles.userImg_num}`}>+{data?.teamInfo.campaignAssignedMembers.length - 6}</div>
                        </li> : ""}
                        {!data?.teamInfo.campaignAssignedMembers.length && '-'}

                    </ul>
                </div>

            </div>}
        </Link>
    )
}

export default CampaignListingCard