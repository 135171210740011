import { useTranslation } from "react-i18next";
import styles from "./index.module.scss"
import { HiOutlineInformationCircle, HiOutlineMagnifyingGlass, HiOutlineShare, HiOutlineBanknotes, HiOutlineGlobeAlt, HiOutlineUsers, HiOutlineSquares2X2, HiGlobeEuropeAfrica, HiOutlineGlobeEuropeAfrica, HiMapPin, HiOutlineMapPin, HiLanguage, HiOutlineLanguage, HiOutlineSquaresPlus, HiChartBar, HiOutlineChartBar, HiOutlineCheckBadge, HiOutlinePresentationChartBar } from "react-icons/hi2";
import { TbSquarePlus } from "react-icons/tb";


import { Checkbox, Select, Slider } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthStateContext } from "../../../../../Context/MainContext";
import i18n from "../../../../../Utils/i18n/i18n";
import { PiBaby, PiGenderIntersexLight } from "react-icons/pi";
import { GiAchievement, GiDiamondRing } from "react-icons/gi";
import api from "../../../../../Utils/axiosInterceptor";

import AchievementImg from "../../../../../assets/imgs/Achievement.svg"

const Brands = ({ setFilters, filters }: any) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups, brands },
        dispatch,
    } = useContext(AuthStateContext);




    return (
        <div className={styles.container}>
            <div className={styles.selectConatiner}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <img src={AchievementImg} alt="" className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("signed_exclusive_for")}</p>
                    </div>
                    <Select
                        labelInValue
                        mode="multiple"
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        showSearch={false}
                        maxTagCount={2}
                        allowClear
                        options={brands.map((s: any) => ({
                            label: s.name.find((a: any) => a.languageKey == i18n.language)?.value,
                            value: s.id
                        }))}
                        size="large"
                        placeholder={t("choose_brands")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(signed_exclusive_for) => {
                            setFilters({ ...filters, brands: { ...filters.brands, signed_exclusive_for: signed_exclusive_for } });
                        }}
                        value={filters.brands.signed_exclusive_for}
                    ></Select>

                    <Checkbox checked={filters.brands.exclude_selected_brands} onChange={e => {
                        setFilters({ ...filters, brands: { ...filters.brands, exclude_selected_brands: e.target.checked } })
                    }}>{t("exclude_selected_brands")}</Checkbox>

                </div>
            </div>
        </div>
    )
}

export default Brands