import "./App.scss";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./Views/auth/login/login";
import PrivateRoute from "./PrivateRoute";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import ForgetPassword from "./Views/auth/forgetpassword/forgetpassword";
import CheckMail from "./Views/auth/checkmail/checkmail";
import ResetPassword from "./Views/auth/resetpassword/resetpassword";
import CampaginListing from "./Views/Campagins/CampaginListing/CampaginListing";
import AnonymousLayout from "./layout/AnonymousLayout";
import { AuthStateContext } from "./Context/MainContext";
import CampaignDetails from "./Views/Campagins/CampaignDetails/CampaignDetails";
import ClientsListing from "./Views/Clients/ClientsListing";
import ClientDetails from "./Views/Clients/ClientDetails";
import NewCreateCampaign from "./Components/NewCreateCampaign";
import { ConfigProvider } from "antd";
import getPalette from "tailwindcss-palette-generator";
import Settings from "./Views/settings";
import CampaignListingCard from "./Components/CampaignListingCard";

import AuthHandler from "./Views/auth/authhandler";
function App() {
  const { state, dispatch } = useContext(AuthStateContext);
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  useEffect(() => {
    document.body.dir = dir();
  }, []);

  const changeTheme = (color: string) => {
    const palette = getPalette({ color, name: "primary" });
    document.documentElement.style.setProperty(
      "--primary-700",
      palette.primary["700"]
    );
    document.documentElement.style.setProperty(
      "--primary-600",
      palette.primary["600"]
    );
    document.documentElement.style.setProperty(
      "--primary-500",
      palette.primary["500"]
    );
    document.documentElement.style.setProperty(
      "--primary-400",
      palette.primary["400"]
    );
    document.documentElement.style.setProperty(
      "--primary-300",
      palette.primary["300"]
    );
    document.documentElement.style.setProperty(
      "--primary-200",
      palette.primary["200"]
    );
    document.documentElement.style.setProperty(
      "--primary-100",
      palette.primary["100"]
    );
    document.documentElement.style.setProperty(
      "--primary-50",
      palette.primary["50"]
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/auth/*"
            element={
              <AnonymousLayout>
                <AuthHandler />
              </AnonymousLayout>
            }
          />

          <Route
            path="/login"
            element={
              <AnonymousLayout>
                <Login />
              </AnonymousLayout>
            }
          />
          <Route
            path="/forget-password"
            element={
              <AnonymousLayout>
                <ForgetPassword />
              </AnonymousLayout>
            }
          />
          <Route
            path="/check-mail"
            element={
              <AnonymousLayout>
                <CheckMail />
              </AnonymousLayout>
            }
          />
          {/* 
          <Route
            path="/reset-password"
            element={
              <AnonymousLayout>
                <ResetPassword />
              </AnonymousLayout>
            }
          /> */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <CampaginListing />
              </PrivateRoute>
            }
          />
          <Route
            path="/campaign/:id"
            element={
              <PrivateRoute>
                <CampaignDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <PrivateRoute>
                <ClientsListing />
              </PrivateRoute>
            }
          />
          <Route
            path="/clients/:id"
            element={
              <PrivateRoute>
                <ClientDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          {/*TODO:Remove this*/}
          {/* <Route
            path="/dev"
            element={
              <PrivateRoute>
                <div style={{ padding: '100px' }}>

                  <CampaignListingCard />
                </div>
              </PrivateRoute>
            }
          /> */}


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
