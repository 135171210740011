import { useTranslation } from "react-i18next";
import styles from "./index.module.scss"
import { HiOutlineInformationCircle, HiOutlineMagnifyingGlass, HiOutlineShare, HiOutlineBanknotes, HiOutlineGlobeAlt, HiOutlineUsers, HiOutlineSquares2X2, HiGlobeEuropeAfrica, HiOutlineGlobeEuropeAfrica, HiMapPin, HiOutlineMapPin, HiLanguage, HiOutlineLanguage, HiOutlineSquaresPlus, HiChartBar, HiOutlineChartBar, HiOutlineCheckBadge, HiOutlinePresentationChartBar, HiOutlineSwatch } from "react-icons/hi2";
import { TbSquarePlus } from "react-icons/tb";


import { Checkbox, Select, Slider } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthStateContext } from "../../../../../Context/MainContext";
import i18n from "../../../../../Utils/i18n/i18n";
import { PiBaby, PiGenderIntersexLight } from "react-icons/pi";
import { GiDiamondRing } from "react-icons/gi";
import api from "../../../../../Utils/axiosInterceptor";

const Tags = ({ setFilters, filters }: any) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups, brands },
        dispatch,
    } = useContext(AuthStateContext);



    return (
        <div className={styles.container}>
            <div className={styles.selectConatiner}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineSwatch className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("tags")}</p>
                    </div>
                    <Select
                        labelInValue
                        mode="multiple"
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        showSearch={false}
                        maxTagCount={2}
                        allowClear
                        options={lookups[i18n.language]?.tags?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_tags")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(tags) => {
                            setFilters({ ...filters, tags: { ...filters.tags, tags } });
                        }}
                        value={filters.tags.tags}
                    ></Select>

                    <Checkbox checked={filters.tags.exclude_safety_flags} onChange={e => {
                        setFilters({ ...filters, tags: { ...filters.tags, exclude_safety_flags: e.target.checked } })
                    }}>{t("exclude_safety_flags")}</Checkbox>

                </div>
            </div>
        </div>
    )
}

export default Tags