
import React, { useContext, useEffect, useRef, useState } from 'react';
import LavadDrawer from '../LavadDrawer';
import { Col, Form, Input, List, notification, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";
import { HiOutlineMagnifyingGlass, HiOutlineXCircle } from 'react-icons/hi2';
import { getAllClientsListing } from '../../Context/Actions/clients';
import nodata from "../../assets/imgs/filters-no-data.svg";


interface ClientData {
  basicInfo: {
    id: number;
    name: string;
    legalName: {
      ar: string | null;
      en: string | null;
    } | null;
  };
}


const SearchClients = ({ openDrawer, setOpenDrawer, onSelectClient, loading, clients, onAddNew }: any) => {

  const { t, i18n: { changeLanguage, dir },
  } = useTranslation();
  const [clientsSearchResults, setClientsSearchResults] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<any>(null);
  const [filteredClients, setFilteredClients] = useState<any[]>([{ name: '' }, { name: '' }, { name: '' }, { name: '' }, { name: '' },]);

  useEffect(() => {

    setClientsSearchResults(clients);
    setFilteredClients(clients);

  }, [clients]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    const filtered = clientsSearchResults.filter((c) =>
      c.label.toLowerCase().includes(value)
    );
    setFilteredClients(filtered);
  };

  const handleClientClick = (client: any) => {
    onSelectClient(client);
    setOpenDrawer(false);
  };

  return (
    <div>
      {openDrawer && (
        <Row>
          <Col span={10} offset={12}>
            <LavadDrawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              title={t("search_clients")}
              cancelButtonText={t("close")}
              saveButtonText={"+ " + t("new_client")}
              saveAction={onAddNew}
            >
              <div className={styles.inputContainer}>
                <Input
                  ref={inputRef}
                  value={inputValue}
                  onChange={handleInputChange}
                  prefix={<HiOutlineMagnifyingGlass width={16} height={16} />}
                  placeholder={t("search_arabic_english")}
                  className="lavad-input"
                  size="large"
                />
              </div>
              <div style={{ overflowY: 'auto' }}>


                {filteredClients.length ? <List
                  dataSource={filteredClients}
                  renderItem={(item) => (
                    <List.Item key={item.id} className={styles.resultItem} onClick={() => handleClientClick(item)}>
                      {loading ? <div className={styles.clientSkeleton}><Skeleton className='clientSkeleton' active paragraph={{ rows: 0 }} /></div> : <div className={styles.clientName}>{item.label}</div>}
                      {/* <div className={styles.clientLegalName}>
                          {item.legalNameAr} 
                        </div> */}
                    </List.Item>
                  )}
                /> : !filteredClients.length && !loading ? <div className={styles.emptyStateWithFilters}>
                  <img src={nodata} alt="no data" />
                  <div className={styles.empty_state_text}>
                    <h3>{t("no_results_found")}</h3>
                    <p className="title">{t("no_results_subtitle")}</p>
                  </div>
                </div> : ''}

              </div>
            </LavadDrawer>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default SearchClients;