import { Avatar, Card, List, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import styles from "./campaign-details-skeleton.module.scss";
const CampaignDetailsSkeleton = (props: any) => {
    return (
        <div className={styles.container}>
            <div className={styles.card_header}>
                <div className={`${styles.camp_avatar} ${styles.shimmer}`}></div>
                <div className={styles.text_container}>
                    <div className={`${styles.camp_id} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_brand} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_id} ${styles.shimmer}`}></div>
                </div>
            </div>
            <div className={styles.body_group}>
                <div className={styles.card_body}>
                    <div className={`${styles.camp_date} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_name} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_desc} ${styles.shimmer}`}></div>
                </div>
                <div className={styles.card_body}>
                    <div className={`${styles.camp_date} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_name} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_desc} ${styles.shimmer}`}></div>
                </div>
                <div className={styles.card_body}>
                    <div className={`${styles.camp_date} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_name} ${styles.shimmer}`}></div>
                    <div className={`${styles.camp_desc} ${styles.shimmer}`}></div>
                </div>
            </div>
            {/* <Skeleton title={false} active avatar></Skeleton>
        <Skeleton.Button active ></Skeleton.Button>
        <Skeleton.Node active ></Skeleton.Node>
        <Skeleton.Input active style={{width:50,minWidth:50}}></Skeleton.Input> */}
        </div>
    );
};
export default CampaignDetailsSkeleton;
