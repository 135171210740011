import styles from "../Auth.module.scss";
import successIcon from "../../../assets/imgs/check-circle.svg";
import { t } from "i18next";
import { Row, Col } from "antd";
import login_vector from "../../../assets/imgs/login-vector.png";

const CheckMail = () => {
  return (
    <div className={styles.login_containier}>
      <Row justify="space-between" align="middle" gutter={12} className={styles.loginRow}>
        <Col lg={8} xl={8} xxl={8} xs={20} push={4}>
          <div className={styles.check_mail_header}>
            <img
              src={successIcon}
              className={`icon-check ${styles.check_mail_icon}`}
              alt="success"
            />
            <div className={styles.login_header}>
              <h2>{t("check_your_inbox")}</h2>
              <p className="subtitle">{t("check_mail_subtitle")}</p>
            </div>
          </div>
        </Col>
        <Col lg={8} xl={8} xxl={8} xs={20} pull={2}>
          <div className="justify-end">
            <img
              className={styles.login_vector}
              src={login_vector}
              alt="login vector"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CheckMail;
