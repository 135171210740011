import { Button, Modal, Row, Steps, Tooltip, notification } from "antd";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AssigneInfluencerList from "./Step1";
import Step2 from "./Step2";
import { HiOutlineXCircle, HiXMark } from "react-icons/hi2";
import Step3 from "./Step3";
import { assignInfluencers } from "../../Context/Actions/influencers";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import Alert from "../Alerts";
import i18n from "../../Utils/i18n/i18n";

const AssignInfluencersWizard = ({
  modalOpen,
  setModalOpen,
  campId,
  getDetails,
}: any) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [leavePageModalOpen, setLeavePageModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const confirmationMessage = "Are you sure you want to leave this page?";
      if (event) {
        event.returnValue = confirmationMessage;
      }
      return confirmationMessage;
    };
    if (modalOpen && selectedInfluencers?.length > 0) {
      window.onbeforeunload = handleBeforeUnload;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [modalOpen, selectedInfluencers]);
  useEffect(() => {
    const handleBackNavigation = () => {
      // Handle the back action here
      if (modalOpen && selectedInfluencers?.length > 0) {
        handleModalClose();
      } else {
        window.history.back();
      }
    };

    // Listen for the popstate event

    window.addEventListener("popstate", handleBackNavigation);
    const state = { key: "myComponent" };
    window.history.pushState(state, "");

    // Add a unique state to the history when component mounts

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, [modalOpen, selectedInfluencers]);

  const openErrorNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("error_label"),
      description: t("something_went_wrong"),
      icon: <HiOutlineXCircle className="danger-text" />,
      className: "no-wrap",
    });
  };

  const getSelectedInfluencers = (data: []) => {
    setSelectedInfluencers(data);
    setSelectedCount(data.length);
  };

  const { Step } = Steps;
  const steps = [
    {
      title: t("influencers"),
      content: (
        <AssigneInfluencerList
          getSelectedInfluencers={getSelectedInfluencers}
          setSelectedInfluencers={selectedInfluencers}
          setSelectedInfluencersFn={setSelectedInfluencers}
          campId={campId}
        />
      ),
    },
    {
      title: t("services"),
      content: (
        <Step2
          selectedInfluencers={selectedInfluencers}
          setSelectedInfluencers={setSelectedInfluencers}
        />
      ),
    },
    {
      title: t("summary"),
      content: (
        <Step3
          selectedInfluencers={selectedInfluencers}
          setSelectedInfluencers={setSelectedInfluencers}
        />
      ),
    },
  ];
  const next = async () => {
    if (!checkIfNextDisabled()) {
      if (currentStep < 2) {
        setCurrentStep((s) => s + 1);
      } else {
        // assignInfluencers
        const assignedInfluencers = selectedInfluencers.filter((s: any) => s.selected).map((inf: any) => {
          const influencerId = inf.id;
          const isRecommended = inf.recommended;
          let advertisingProducts: any = [];
          inf.influencerSocialPlatforms.forEach((p: any) => {
            p.services.forEach((s: any) => {
              if (s.selected) {
                advertisingProducts.push({
                  id: s.advertisingProductId,
                  price: s.proposedPrice ? +s.proposedPrice : null,
                  currency: s.currencyKey,
                });
              }
            });
          });
          return { influencerId, isRecommended, advertisingProducts };
        });
        setLoading(true);
        const res = await assignInfluencers(campId, {
          influencers: assignedInfluencers,
        });
        setLoading(false);
        if (res.error) {
          // setModalOpen(false)
          openErrorNotification();
        } else {
          openNotification();
          getDetails();
        }
      }
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep((s) => s - 1);
    }
  };

  const checkIfNextDisabled = () => {
    if (currentStep == 0) {
      return selectedInfluencers.filter((s: any) => s.selected).length == 0;
    } else if (currentStep == 1) {
      let eachHas = true;
      if (selectedInfluencers?.filter((s: any) => s.selected).length) {
        selectedInfluencers?.filter((s: any) => s.selected).forEach((inf: any) => {
          const has = inf?.influencerSocialPlatforms?.some((p: any) => {
            return p?.services?.some((s: any) => s.selected);
          });

          eachHas = eachHas && Boolean(has);
        });
        return !eachHas;
      } else {
        return true
      }
    }
  };
  const handleModalClose = () => {
    if (selectedCount) {
      setLeavePageModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };
  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("success_label"),
      description: t("influencers_assigned"),
      icon: <HiOutlineCheckCircle className="success-text" />,
      className: "no-wrap",
    });
  };
  return (
    <>
      <Modal
        open={modalOpen}
        onCancel={handleModalClose}
        className="create-modal"
        wrapClassName="fullscreen-modal"
        footer={
          <div className={styles.footer}>
            <div className={styles.footerBorder}>
              {currentStep > 0 ? (
                <button
                  onClick={prev}
                  className="lavad-btn lavad-btn-lg lavad-btn-outline"
                >
                  {t("back")}
                </button>
              ) : (
                <div></div>
              )}
              <div className={styles.next}>
                {currentStep < 2 && selectedInfluencers.filter((s: any) => s.selected).length > 0 && (
                  <span>
                    {selectedInfluencers.filter((s: any) => s.selected).length} {t("influencers_selected")}
                  </span>
                )}
                <Tooltip
                  placement={
                    i18n.language == "en" ? "topRight" : "topLeft"
                  }
                  trigger="click"
                  destroyTooltipOnHide={true}
                  overlayStyle={{
                    width: 240,
                    fontSize: 14,
                    fontFamily: "inherit",
                    fontWeight: 400,
                  }}
                  overlayInnerStyle={{
                    padding: 12,
                    textAlign: 'center'
                  }}
                  title={
                    currentStep == 1 && checkIfNextDisabled()
                      ? t("select_service_atLeast")
                      : ""
                  }
                >
                  <button
                    onClick={next}
                    className={`lavad-btn lavad-btn-lg lavad-btn-primary ${checkIfNextDisabled() ? 'disabled' : ''}`}
                  >
                    {loading ? (
                      <span className="icon-loader spin"></span>
                    ) : currentStep == 0 ? (
                      t("next")
                    ) : currentStep == 1 ? (
                      t("review_summary")
                    ) : (
                      t("assign_influencers")
                    )}
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        }
      >
        <div style={{ height: "100%", overflow: "hidden" }}>
          <div className={styles.stepsContainer}>
            <Steps current={currentStep} className="assign-inf-steps">
              {steps.map((s, index) => (
                <Step title={s.title} key={index} icon={index + 1} />
              ))}
            </Steps>
          </div>
          {steps[currentStep].content}
        </div>
      </Modal>
      <Modal
        closeIcon={<HiXMark height={24} width={24} />}
        centered
        onCancel={() => setLeavePageModalOpen(false)}
        className="alert_modal"
        title={t("leave_page")}
        open={leavePageModalOpen}
        footer={
          <div className={styles.alertModalFooter}>
            <Button
              onClick={() => setLeavePageModalOpen(false)}
              className="lavad-btn lavad-btn-md lavad-btn-outline"
              key="back"
            >
              {t("stay_and_select")}
            </Button>
            <Button
              className="lavad-btn lavad-btn-md lavad-btn-danger-primary"
              key="submit"
              type="primary"
              onClick={() => {
                setLeavePageModalOpen(false);
                setModalOpen(false);
              }}
            >
              {t("leave")}
            </Button>
          </div>
        }
      >
        <p className="body">
          {currentStep == 0
            ? t("selected_wont_saved")
            : t("selected_and_services_wont_saved")}
        </p>
      </Modal>
    </>
  );
};

export default AssignInfluencersWizard;
