import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const InfluencerTags = ({ details }: any) => {
  const {
    t
  } = useTranslation();
  return (
    <>
      {details?.influencerTags && details?.influencerTags.length > 0 ? (
        <div className={styles.influencer_tag_contaier + " " + "_lr-hide"} data-private>
          {details?.influencerTags?.map(
            (tag: any, index: number) => (
              <span
                key={tag.key}
                className={styles.influencer_tag_item}
              >
                {tag.name}
              </span>
            )
          )}
        </div>
      ) : <p className={styles.nodata} data-private>{t('no_available_data')}</p>}
    </>
  );
};

export default InfluencerTags;
