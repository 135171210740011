import { useTranslation } from "react-i18next";
import pageNotFound from "../../assets/imgs/404.svg";
import styles from "./index.module.scss";
const PageNotFound = () => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  return (
    <div className={styles.no_result_found}>
      <img src={pageNotFound} alt="not found campaign" />
      <h3>{t("page_not_found_title")}</h3>
      <p className="title">{t("page_not_found_subtitle")}</p>
    </div>
  );
};
export default PageNotFound;
