import { useEffect, useContext } from "react";
import Navbar from "../Components/Navbar/Navbar";
import { getBrands } from "../actions/lookups";
import { AuthStateContext } from "../Context/MainContext";
import i18n from "../Utils/i18n/i18n";

import { onAuthStateChanged } from "firebase/auth";
import { auth, remoteConfig } from "../Utils/firebase";
import {
  getAgencyData,
  getLookupsAr,
  getLookupsEn,
} from "../Context/Actions/lookups";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import LogRocket from "logrocket";

import styles from "./layout.module.scss";
import {
  getApplicationUsers,
  LastActiveDate,
} from "../Context/Actions/clients";
import { forceLogout, logout } from "../Context/Actions/auth";

const AuthLayout = ({ children }: any) => {
  const { state, dispatch } = useContext(AuthStateContext);

  const getAsyncBrands = async () => {
    const res = await getBrands();
    dispatch({ type: "SET_BRANDS", payload: res.data });
  };
  const getAppUsers = async () => {
    const users = await getApplicationUsers();
    dispatch({ type: "SET_USERS", payload: users.data });
  };
  const setLastActiveFn = async () => {
    await LastActiveDate();

  }
  useEffect(() => {
    onAuthStateChanged(auth, async (user: any) => {
      console.log(user, "user data");

      if (user) {
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            userProfile: {
              email: user.email,
              displayName: user.displayName,
              id: user.uid,
              agencyId: user.reloadUserInfo?.customAttributes
                ? JSON.parse(user.reloadUserInfo?.customAttributes)?.AgencyId
                : "-",
              role: user.reloadUserInfo?.customAttributes
                ? JSON.parse(user.reloadUserInfo?.customAttributes)?.RoleKey
                : "-",
            },
            tokens: {
              accessToken: user.accessToken,
              refreshToken: user.refreshToken,
            },
          },
        });


      } else {
        // forceLogout();
        logout()
      }
    });
  }, []);
  useEffect(() => {
    if (state.user?.agencyId) {
      const user = state.user;
      (getAgencyData(
        user.agencyId
      ).then((res: any) => {
        dispatch({ type: "AGENCY_DATA", payload: res.basicInfo });
        localStorage.setItem("agency", JSON.stringify(res.basicInfo));
        LogRocket.init(process.env.REACT_APP_LOGROCKET_ID!);
        LogRocket.identify(
          `User : ${user.email} - Agency : ${res?.basicInfo?.name}`
        );
      }));
      setLastActiveFn()
    }
  }, [state.user.agencyId])

  const fetchLookupsData = async () => {
    try {
      const resArPromise = getLookupsAr();
      const resEnPromise = getLookupsEn();

      const [resAr, resEn] = await Promise.all([resArPromise, resEnPromise]);

      return {
        en: resEn,
        ar: resAr,
      };

      // Continue with further processing or logic
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const setDefaults = async () => {
    dispatch({ type: "REQUEST_LOOKUPS" });
    let lookupsVersion: any = null;
    fetchLookupsData().then((res) => {
      if (res) {
        localStorage.setItem("lookups", JSON.stringify(res));
        fetchAndActivate(remoteConfig)
          .then(() => {
            lookupsVersion = getValue(
              remoteConfig,
              process.env.REACT_APP_LOOKUPS_VERSION!
            );

            localStorage.setItem("lookupsVersion", lookupsVersion._value);
          })
          .catch((err) => {
            console.log("Failed to fetch remote config", err);
          });

        dispatch({ type: "LOOKUPS_SUCCESS", payload: res });
      }
    });
  };
  useEffect(() => {
    let lookupsVersion: any = null;

    fetchAndActivate(remoteConfig).then(() => {
      lookupsVersion = getValue(
        remoteConfig,
        process.env.REACT_APP_LOOKUPS_VERSION!
      );
      if (
        !localStorage.getItem("lookups") ||
        !JSON.parse(localStorage.getItem("lookups")!) ||
        localStorage.getItem("lookupsVersion") != lookupsVersion._value
      ) {
        setDefaults();
      }
    });
  }, []);

  useEffect(() => {
    getAsyncBrands();
    getAppUsers();
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", () => {
      setDefaults();
      getAsyncBrands();
      getAppUsers();
    });
    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", () => {
        setDefaults();
        getAsyncBrands();
        getAppUsers();
      });
    };
  }, [i18n]);

  return (
    <>
      <Navbar></Navbar>
      <div className={styles.layout}>{children}</div>
    </>
  );
};

export default AuthLayout;
