import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import {
  HiOutlineDocumentText,
  HiOutlineUser,
  HiOutlineGlobeAsiaAustralia,
  HiOutlineMapPin,
  HiOutlineLanguage,
  HiOutlineUsers,
} from "react-icons/hi2";
import { PiGenderIntersexLight, PiBaby } from "react-icons/pi";
import { GiDiamondRing } from "react-icons/gi";
import { MdOutlinePets } from "react-icons/md";

const InfluencerBrandSafteyFlags = ({ details }: any) => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  return (
    <>
      {details?.influencerBrandSafetyFlagKeys ? (
        <div className={styles.influencer_status_contaier + " " + "_lr-hide"} data-private>
          {details?.influencerBrandSafetyFlagKeys?.map(
            (brandSaftey: any, index: number) => (
              <span
                key={brandSaftey.key}
                className={styles.influencer_status_item}
              >
                {brandSaftey.name}
              </span>
            )
          )}
        </div>
      ) : <p className={styles.nodata} data-private>{t('no_available_data')}</p>}
    </>
  );
};

export default InfluencerBrandSafteyFlags;
