import styles from "./Navbar.module.scss";
import authLogo from "../../assets/imgs/auth-lavad-logo.svg";
import ChangeLanguage from "../ChangeLanguage/Index";
import { Dropdown, Image } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { logout } from "../../Context/Actions/auth";
import { useContext } from "react";
import { AuthStateContext } from "../../Context/MainContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getInitials } from "../../Utils/helpers";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineArrowRightOnRectangle } from "react-icons/hi2";

const Navbar = () => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const { state, dispatch } = useContext(AuthStateContext);

  const logoutAction = async () => {
    try {
      logout();
      dispatch({
        type: "LOGOUT",
      });
    } catch (error: any) {
      console.log(error, "error");
    }
  };
  const items = [
    {
      className: "disableClicks",
      key: "2",
      label: (
        <div className={styles.userInfo}>
          <div className={styles.avatar}>
            {getInitials(state.user?.displayName)}
          </div>
          <div className={styles.info}>
            <p className={styles.userName}>{state.user?.displayName}</p>
            <p className={styles.email}>{state.user?.email}</p>
          </div>
        </div>
      ),
    },
    {
      label: (
        <Link className="body" to="/settings">
          <div className={styles.navItem}>
            <IoSettingsOutline />
            {t("settings")}
          </div>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <p onClick={logoutAction} className="body">
          <div className={styles.navItem}>
            <HiOutlineArrowRightOnRectangle />
            {t("logout")}
          </div>
        </p>
      ),
      key: "0",
    },
  ];
  console.log(state.user, "state.user");
  return (
    <div className={styles.navbar}>
      <Link className={styles.link} to="/">
        <img src={authLogo} width={125} height={32} alt="" />
      </Link>
      <div className={styles.navItems}>
        <div className={styles.navItemsItems}>
          <Link to="/" className={`subtitle ${styles.link}`}>
            {t("campaigns")}
          </Link>
          <Link to="/clients" className={`subtitle ${styles.link}`}>
            {t("clients")}
          </Link>
        </div>
        <div className={styles.navItemsDrops}>
          <ChangeLanguage className={"select_lang"} />
          <Dropdown
            menu={{ items, className: "navbarDropdown" }}
            trigger={["click"]}
          >
            <a className={styles.user} onClick={(e) => e.preventDefault()}>
              <div className={styles.user_profile}>
                <div className={styles.profileImg}>
                  {state?.user?.displayName?.split(" ").length > 1
                    ? `${state?.user?.displayName?.split(" ")[0][0]}${
                        state?.user?.displayName?.split(" ")[1][0]
                      }`
                    : state?.user?.displayName?.split(" ")[0][0]}
                </div>
                <p>
                  {t("hi")}{" "}
                  {state?.user?.displayName?.split(" ").length > 1
                    ? state?.user?.displayName.split(" ")[0]
                    : state?.user?.displayName}
                </p>
              </div>
              <DownOutlined style={{ color: "white" }} />
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
