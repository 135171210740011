import api from "../Utils/axiosInterceptor";


export const getLookups = async () => {
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/lookups`);
    if (res.data) {
        return res.data
    } else {
        return { error: true }
    }
}

export const getBrands = async () => {
    const res = await api.get(`${process.env.REACT_APP_API_URL}api/brand/all`);
    if (res.data) {
        return res.data
    } else {
        return { error: true }
    }
}