import { Card } from "antd";
import styles from "./campaign-card-skeleton.module.scss";
const CampaignCardSkeleton = (props: any) => {
  return (
    <div className={styles.camp_card}>
      <div className={styles.cardHeader}>
        <div className={`${styles.shimmer}`}></div>
        <div className={`${styles.shimmer}`}></div>
      </div>

      <div className={styles.card_body}>
        <div className={styles.left}>
          <div className={`${styles.camp_avatar} ${styles.shimmer}`}></div>
          <div>
            <div className={`${styles.shimmer}`}></div>
            <div className={`${styles.shimmer}`}></div>
            <div className={`${styles.shimmer}`}></div>
            <div className={`${styles.shimmer}`}></div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={`${styles.shimmer}`}></div>
          <div className={`${styles.shimmer}`}></div>
          <div className={`${styles.shimmer}`}></div>
        </div>
      </div>
      <div className={styles.cardFooter}>
        <div>

          <div className={`${styles.shimmer}`}></div>
          <div className={`${styles.shimmer}`}></div>
        </div>
        <div>

          <div className={`${styles.shimmer}`}></div>
          <div className={`${styles.shimmer}`}></div>
        </div>
      </div>

    </div>
  );
};
export default CampaignCardSkeleton;
