import { useEffect, useState } from "react";
import Service from "../Service/Index";
import styles from "./Index.module.scss";
import { Checkbox, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { formatNumber, isValidURL } from "../../../Utils/helpers";
import WarningImg from "../../../assets/imgs/Attention.svg"

const PlatformServices = ({
  updateCampaignAssignedInfluencersList,
  getChanged,
  lisitingAssigned = false,
  state,
  socialPlatform,
  infId,
  setSelectedInfs,
  selectedInfs,
  setSocialPlatform,
}: {
  updateCampaignAssignedInfluencersList?: any;
  getChanged?: (data: boolean) => void;
  lisitingAssigned?: boolean;
  state: string;
  socialPlatform: any;
  infId: any;
  setSelectedInfs: any;
  selectedInfs: any;
  setSocialPlatform?: any;
}) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setChecked(socialPlatform.services.every((s: any) => s.selected));
  }, [socialPlatform, selectedInfs]);
  const onCheckChange = (checked: boolean) => {
    setChecked(checked);
    setSelectedInfs((prevSelected: any) =>
      prevSelected.map((inf: any) => {
        if (inf.id == infId) {
          inf.influencerSocialPlatforms.map((p: any) => {
            if (p.key == socialPlatform.key) {
              p.services.map((s: any) => {
                s.selected = checked;
                return s;
              });
            }
            return p;
          });
        }
        return inf;
      })
    );
  };
  const onCheckEditInfluencerChange = (checked: boolean) => {
    let socialPlatformData = socialPlatform;

    getChanged!(true);
    setChecked(checked);
    socialPlatformData.services.map((s: any) => {
      s.selected = checked;
      return s;
    });
    setSocialPlatform(socialPlatformData);
  };
  const changeCheck = (data: any) => {
    let socialPlatformData = socialPlatform;
    socialPlatformData.services.map((s: any) => {
      if (s.key == data.key) {
        s = data;
      }
    });
    setChecked(socialPlatformData.services.every((s: any) => s.selected));
    setSocialPlatform(socialPlatformData);
  };
  return (
    <div className={styles.platformServices}>
      <div className={styles.platformContainer}>
        {state == "edit" ? (
          <div className={styles.check}>
            <Checkbox
              className="inf-checkbox"
              checked={checked}
              onChange={(e) => onCheckChange(e.target.checked)}
            />
          </div>
        ) : (
          ""
        )}
        {state == "editInfluencer" ? (
          <div className={styles.check}>
            <Checkbox
              className="inf-checkbox"
              checked={checked}
              onChange={(e) => onCheckEditInfluencerChange(e.target.checked)}
            />
          </div>
        ) : (
          ""
        )}
        <div className={styles.platform}>
          <Tooltip
            placement={"top"}
            trigger="hover"
            destroyTooltipOnHide={true}
            overlayStyle={{
              width: "auto",
              fontFamily: "inherit",
              fontWeight: 400,
            }}
            overlayClassName="platform_tooltip"
            title={socialPlatform.socialPlatformUrl && isValidURL(socialPlatform.socialPlatformUrl) ? t("platform_profile", { socialPlatform: socialPlatform.name }) : t("no_available_link")}
          >

            <a
              href={socialPlatform.socialPlatformUrl && isValidURL(socialPlatform.socialPlatformUrl) ? socialPlatform.socialPlatformUrl : undefined}
              rel="noreferrer"
              target="_blank"
            >
              <img loading="lazy" src={socialPlatform.logoUrl} alt="platform" />
            </a>
          </Tooltip>
          {!Object.is(socialPlatform.followersCount, null) && socialPlatform.followersCount > 0 && <p className="body _lr-hide" data-private>
            {formatNumber(
              socialPlatform.followersCount
            )}
          </p>}

        </div>
      </div>
      <div>
        <div className={styles.services}>
          {state == "edit"
            ? socialPlatform.services.map((s: any) => (
              <Service
                data={s}
                price={s.price || 0}
                platformKey={socialPlatform.key}
                influencerId={infId}
                setSelectedInfs={setSelectedInfs}
                key={s.advertisingProductId}
                serviceKey={s.key}
                name={s.name}
                state={state}
                currencyKey={s.currencyKey}
                basePrice={s.basePrice}
                checked={s.selected}
              />
            ))
            : ""}
          {state == "editInfluencer"
            ? socialPlatform.services.map((s: any) => (
              <Service
                changeCheck={changeCheck}
                getChanged={getChanged}
                data={s}
                price={s.price || 0}
                platformKey={socialPlatform.key}
                influencerId={infId}
                setSelectedInfs={setSelectedInfs}
                key={s.advertisingProductId}
                serviceKey={s.key}
                name={s.name}
                state={state}
                currencyKey={s.currencyKey}
                basePrice={s.basePrice}
                checked={s.selected}
              />
            ))
            : ""}
          {state == "actions"
            ? socialPlatform.services.map((s: any) => (
              <Service
                updateCampaignAssignedInfluencersList={
                  updateCampaignAssignedInfluencersList
                }
                data={s}
                lisitingAssigned={lisitingAssigned}
                platformKey={socialPlatform.key}
                influencerId={infId}
                setSelectedInfs={setSelectedInfs}
                key={s.advertisingProductId}
                serviceKey={s.key}
                name={s.name}
                state={state}
                currencyKey={s.currencyKey}
                basePrice={s.basePrice}
                checked={s.selected}
              />
            ))
            : ""}
          {state == "view"
            ? socialPlatform.services
              .filter((s: any) => s.selected)
              .map((s: any) => (
                <Service
                  data={s}
                  platformKey={socialPlatform.key}
                  influencerId={infId}
                  setSelectedInfs={setSelectedInfs}
                  key={s.advertisingProductId}
                  serviceKey={s.key}
                  name={s.name}
                  state={state}
                  currencyKey={s.currencyKey}
                  basePrice={s.basePrice}
                  checked={s.selected}
                />
              ))
            : ""}
        </div>
        {!socialPlatform.isActive ? <div className={styles.warning}>
          <img src={WarningImg} />
          <p>{t('deactivated_sm_warning')}</p>
        </div> : ""}

      </div>
    </div>

  );
};

export default PlatformServices;
