import React, { useState } from 'react'
import styles from "./index.module.scss"
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import InfluencerServicesCard from '../../InfluencerServicesCard';

const Step3 = ({ setSelectedInfluencers, selectedInfluencers }: { setSelectedInfluencers: any, selectedInfluencers: any }) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const [showScroll, setShowScroll] = useState(false);
    let timeoutId: any;


    const handleScroll = () => {
        setShowScroll(true);
        // clearTimeout(timeoutId);
        // timeoutId = setTimeout(() => {
        //   setShowScroll(false);
        // }, 500);

    };
    return (
        <div
            className={`${styles.scrollableContainer} ${showScroll ? "showScroll" : ""} `}
            onScroll={handleScroll}
        >
            <div className={styles.influencer_wizard_heading}>
                <h2>{t("step3_heading")}</h2>
                <h3>{t("step3_subtitle")}</h3>
            </div>
            <div className="container-fluid">
                <Row justify="center"
                    align="stretch">
                    <Col span={18}>
                        <p className={`body ${styles.title}`}>{`${selectedInfluencers.filter((s: any) => s.selected)?.length ? selectedInfluencers.filter((s: any) => s.selected)?.length : 0
                            } ${t("wizard_influencers")}`}</p>
                        <div className={styles.cards_container}>
                            {selectedInfluencers.filter((s: any) => s.selected).sort((a: any, b: any) => a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : 0).sort((a: any, b: any) => {
                                if (a.recommended && !b.recommended) {
                                    return -1;
                                } else if (!a.recommended && b.recommended) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }).map((inf: any) => <InfluencerServicesCard hasInfData={true} state="view" key={inf.id} selectedInfs={selectedInfluencers} setSelectedInfs={setSelectedInfluencers} influencer={inf} />)}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    )
}

export default Step3