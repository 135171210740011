// src/components/auth/AuthHandler.tsx
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ResetPassword from "../auth/resetpassword/resetpassword";
import SignUp from "./SignUpPage";

const AuthHandler: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");
  const oobCode = queryParams.get("oobCode");
  const continueUrl = queryParams.get("continueUrl");
  const language = queryParams.get("lang");
  const completeUrl = window.location.href;
  // console.log("oobCode:", oobCode);
  // console.log("oob", oobCode);
  // console.log("url", continueUrl);
  // console.log("lan", language);
  const getEmailFromUrl = (url: string | null) => {
    if (!url) return null;
    const urlParams = new URLSearchParams(new URL(url).search);
    const encodedEmail = urlParams.get("email");
    return encodedEmail ? decodeURIComponent(encodedEmail) : null;
  };

  const email = getEmailFromUrl(continueUrl);
  console.log("emmm", email);
  switch (mode) {
    case "resetPassword":
      return <ResetPassword />;

    case "signIn":
      return <SignUp email={email ?? ""} url={completeUrl} />;

    default:
      return <div>Invalid operation</div>;
  }
};

export default AuthHandler;
