import { Checkbox, Button, Dropdown, Space, InputNumber, Input, notification } from 'antd'
import styles from "./Index.module.scss"
import { useState, useEffect, useContext } from "react"
import { HiOutlineCheckCircle, HiOutlineChevronDown, HiOutlineXCircle } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';
import { AuthStateContext } from '../../../Context/MainContext';
import { changeLineItemStatus } from '../../../Context/Actions/campaign';
import i18n from '../../../Utils/i18n/i18n';

const Service = ({ updateCampaignAssignedInfluencersList, changeCheck, getChanged, lisitingAssigned = false, isDetailsBox = false, state, basePrice, checked = false, price = 0, currencyKey, name, serviceKey, setSelectedInfs = null, influencerId, platformKey, data }: { updateCampaignAssignedInfluencersList?: any, changeCheck?: any, lisitingAssigned?: boolean, isDetailsBox?: boolean, state: string, basePrice: number, checked?: boolean, price?: any, currencyKey: any, name: string, serviceKey: string, getChanged?: (data: boolean) => void, setSelectedInfs: any, influencerId: any, platformKey: any, data: any }) => {
    const [checkedState, setCheckedState] = useState(false);
    const [localPrice, setLocalPrice] = useState("");
    const [bookingLineItemStatus, setBookingLineItemStatus] = useState<any>([])
    const [showNumber, setShowNumber] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups },
    } = useContext(AuthStateContext)
    useEffect(() => {
        setCheckedState(checked)
        if (!checked) {
            setCheckedState(data?.selected)
        }
    }, [checked, data])

    useEffect(() => {
        if (data.proposedPrice) {
            setLocalPrice(data.proposedPrice)
        }
        if (state === "actions") {

            setLocalPrice(data.price)
        }
        if (price > 0) {
            setLocalPrice(price)
        }
    }, [data])

    useEffect(() => {
        let lineItemStatus: any = []
        lookups && lookups[i18n.language]?.lineItemBookingStatuses.map((status: any) => {
            lineItemStatus.push({
                label: status.name,
                key: status.key,
                disabled: status.key == data.bookingStatusKey
            })
        })
        setBookingLineItemStatus(lineItemStatus)

    }, [lookups, data])

    const onCheckChange = (checked: boolean) => {
        setCheckedState(checked)
        setSelectedInfs((prevSelected: any) => {
            const newInfs = prevSelected.map((inf: any) => {
                if (inf.id == influencerId) {
                    inf.influencerSocialPlatforms.map((p: any) => {
                        if (p.key == platformKey) {
                            p.services.map((s: any) => {
                                if (s.advertisingProductId == data.advertisingProductId) {
                                    s.selected = checked;
                                }
                                return s;
                            })
                        }
                        return p;
                    })
                }
                return inf
            })
            return newInfs;
        })
    }
    const onPriceChange = (price: any) => {
        if (/^\d+(\.\d{0,2})?$/.test(price)) {
            setLocalPrice(price)
            setSelectedInfs((prevSelected: any) => {
                const newInfs = prevSelected.map((inf: any) => {
                    if (inf.id == influencerId) {
                        inf.influencerSocialPlatforms.map((p: any) => {
                            if (p.key == platformKey) {
                                p.services.map((s: any) => {
                                    if (s.advertisingProductId == data.advertisingProductId) {
                                        s.proposedPrice = price;
                                    }
                                    return s;
                                })
                            }
                            return p;
                        })
                    }
                    return inf
                })
                return newInfs;
            })
        }
        if (!price.length) {
            setLocalPrice(price)
            setSelectedInfs((prevSelected: any) => prevSelected.map((inf: any) => {
                if (inf.id == influencerId) {
                    inf.influencerSocialPlatforms.map((p: any) => {
                        if (p.key == platformKey) {
                            p.services.map((s: any) => {
                                if (s.advertisingProductId == data.advertisingProductId) {
                                    s.proposedPrice = price;
                                }
                                return s;
                            })
                        }
                        return p;
                    })
                }
                return inf
            }))
        }
    }
    const onPriceEditInfluencerChange = (value: any) => {
        getChanged!(true)
        if (/^\d+(\.\d{0,2})?$/.test(value)) {
            setLocalPrice(value)
            data.price = (value)
        }
        if (value.length == 0) {
            setLocalPrice(value)
            data.price = (null)
        }

    }
    const onCheckEditInfluencerChange = (checked: boolean) => {
        getChanged!(true)
        setCheckedState(checked)
        data.selected = checked
        changeCheck(data)
    }

    const serviceStatusSuccess = (success: boolean) => {
        notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: success ? t("success_label") : t("error_label"),
            description: success ? t("service_status_updated_succ") : t("something_went_wrong"),
            icon: success ? <HiOutlineCheckCircle className="success-text" /> : <HiOutlineXCircle className="danger-text" />,
            className: "no-wrap",
        });
    };

    const changeStatus = async (key: string) => {
        setStatusLoading(true)
        const res = await changeLineItemStatus(data.lineItemId, key)
        if (res.error) {
            serviceStatusSuccess(false)
            setStatusLoading(false)
        } else {
            await updateCampaignAssignedInfluencersList()
            setStatusLoading(false)
            serviceStatusSuccess(true)
        }
    };


    return (
        <div className={`${styles.service_container} ${isDetailsBox ? styles.details_container : ""} ${lisitingAssigned ? styles.assigned_container : ""} serviceContainer`}>
            {state == "edit" ? <div className={styles.check}>
                <Checkbox className={`${styles.checkBox} inf-checkbox`} checked={checkedState} onChange={(e) => onCheckChange(e.target.checked)} />
            </div> : ""}
            {state == "editInfluencer" ? <div className={styles.check}>
                <Checkbox className={`${styles.checkBox} inf-checkbox`} checked={checkedState} onChange={(e) => onCheckEditInfluencerChange(e.target.checked)} />
            </div> : ""}
            <div className={`${styles.service} ${state == "view" ? styles.view : ""} ${isDetailsBox ? styles.details : ""}`}>
                <p className='body _lr-hide' data-private>{name}</p>
                {((state == "view") && localPrice && Number(localPrice) > 0) ? <p className={`${styles.price} body _lr-hide`} data-private>{new Intl.NumberFormat().format(+localPrice)}  <span className={styles.curr}>{t(`${currencyKey}`)}</span></p> : ""}


                {((state == "actions") && localPrice) ? <p className={`${styles.price} body _lr-hide`} data-private>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+localPrice)} <span>{t(`${currencyKey}`)}</span></p> : ""}


                {state == "edit" ? <Input onFocus={() => setShowNumber(true)} onBlur={() => setShowNumber(false)} suffix={t(`${currencyKey}`)} value={showNumber ? localPrice ? localPrice : "" : localPrice ? Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 2,
                }).format(+localPrice) : ""} onChange={(e) => onPriceChange(e.target.value)} className={`lavad-input ${styles.numberInput} servicePadding ${!localPrice ? 'placeholderCurr' : ""}`} type="text" placeholder={`${String(new Intl.NumberFormat().format(basePrice))}`} /> : ""}


                {state == "editInfluencer" ? <Input onFocus={() => setShowNumber(true)} onBlur={() => setShowNumber(false)} suffix={t(`${currencyKey}`)} value={showNumber ? localPrice ? localPrice : "" : localPrice ? Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 2,
                }).format(+localPrice) : ""} onChange={(e) => onPriceEditInfluencerChange(e.target.value)} className={`lavad-input ${styles.numberInput} servicePadding ${!localPrice ? 'placeholderCurr' : ""}`} type="text" placeholder={`${String(new Intl.NumberFormat().format(basePrice))}`} /> : ""}

            </div>
            {state == "actions" ? <Dropdown
                disabled={statusLoading}
                trigger={["click"]}
                className={`actions-dropdown`}
                menu={{
                    items: bookingLineItemStatus,
                    className: 'actions-dropdown-menu',
                    onClick: (e) => {
                        changeStatus(e.key);
                    }
                }}
            >
                <Button disabled={statusLoading}>
                    {statusLoading ? <span className="icon-loader spin"></span> : <Space>
                        {
                            bookingLineItemStatus?.find((s: any) => s.key == data.bookingStatusKey)?.label
                        }
                        <HiOutlineChevronDown width={16} height={16} />
                    </Space>}
                </Button>
            </Dropdown> : ""}
        </div>
    )
}

export default Service