import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Select, Space } from "antd";
import styles from "./index.module.scss";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Footer } from "antd/es/layout/layout";
import { AuthStateContext } from "../../../../Context/MainContext";
import i18n from "../../../../Utils/i18n/i18n";
import { Label } from "recharts";
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlinePlusCircle,
  HiOutlineTrash,
  HiOutlineXCircle,
} from "react-icons/hi2";
import avatarPlaceholder from "../../../../assets/imgs/avatar_placeholder.svg";
import { width } from "@mui/system";
import { sendEmailInvitation } from "../../../../Context/Actions/clients";
import { error } from "logrocket";
import { CheckOutlined } from "@ant-design/icons";

const EmailModal = ({ modalOpen, onMemberAdded, setModalOpen }: any) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const {
    state: { lookups, brands },
    dispatch,
  } = useContext(AuthStateContext);
  const [email, setEmail] = useState<string>("");

  const roleDescriptions: { [key: string]: string } = {
    ADMIN: t("admin_description"),

    MEMBER: t("member_description"),
  };
  const [form] = Form.useForm();
  const [members, setMembers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [emailErrors, setEmailErrors] = useState<{ [key: string]: string }>({});
  const { Option } = Select;

  const addMember = async (formData: any) => {
    const values = formData;
    const isDuplicate = members.some((member) => member.email === values.email);

    if (isDuplicate) {
      setEmailErrors((prev) => ({
        ...prev,
        email: `${t("email")} ${values.email} ${t("is_duplicated")}`,
      }));
      return;
    } else {
      const newMember = { email: values.email, roleKey: values.role };
      setMembers([...members, newMember]);
      setEmailErrors({});
      form.resetFields();
    }
  };
  useEffect(() => {
    console.log("members", members);
  });

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.email) {
      setEmailErrors((prev) => ({
        ...prev,
        email: "",
      }));
    }
  };

  const isInviteDisabled = () => {
    console.log(
      Object.keys(emailErrors).length,
      "Object.keys(emailErrors).length ",
      loading,
      members.length === 0
    );

    return loading || members.length === 0;
  };
  console.log("logi", emailErrors);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setEmailErrors({});
  };
  const removeMember = (index: number) => {
    setMembers((prevMembers) => prevMembers.filter((_, i) => i !== index));

    setEmailErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index];
      const reindexedErrors = Object.keys(updatedErrors).reduce(
        (acc: { [key: number]: string }, key: string) => {
          const errorIndex = parseInt(key, 10);
          acc[errorIndex > index ? errorIndex - 1 : errorIndex] =
            updatedErrors[errorIndex];
          return acc;
        },
        {}
      );

      return reindexedErrors;
    });
  };
  useEffect(() => {
    console.log("EmailModal modalOpen changed:", modalOpen);
  }, [modalOpen]);
  const handleCancel = () => {
    setModalOpen(false);
    setLoading(false);
    form.resetFields();
    setMembers([]);
    setEmailErrors({});
  };

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleInvite = async () => {
    const invitationData = {
      applicationUsers: members,
    };
    setLoading(true);
    try {
      const result = await sendEmailInvitation(invitationData);

      if (result.error) {
        const apiErrors = result.errors || [];
        const emailErrors: { [key: number]: string } = {};

        apiErrors.forEach((error: any) => {
          const fieldMatch = error.field.match(
            /applicationUsers\[(\d+)\].email/
          );
          if (fieldMatch) {
            const index = parseInt(fieldMatch[1], 10);
            if (error.key === "Duplicated") {
              emailErrors[index] = t("user_already_exists");
            } else {
              emailErrors[index] = t("something_went_wrong");
            }
          }
        });

        console.log("Email Errors:", emailErrors);

        const hasUserAlreadyExistsError = Object.values(emailErrors).some(
          (error) => error === t("user_already_exists")
        );

        if (hasUserAlreadyExistsError) {
          console.log("Cannot close modal, user already exists");
          setModalOpen(true);
          setLoading(false);
        } else {
          Object.keys(emailErrors).forEach((index) => {
            form.setFields([
              {
                name: ["members", Number(index), "email"],
                errors: [emailErrors[Number(index)]],
              },
            ]);
          });

          notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: t("error_label"),
            description: t("something_went_wrong"),
            icon: <HiOutlineXCircle className="danger-text" />,
            className: "no-wrap",
          });
        }

        setEmailErrors(emailErrors);
      } else {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("success_label"),
          description: t("invite_sent"),
          icon: <HiOutlineCheckCircle className="success-text" />,
          className: "no-wrap",
        });
        setLoading(false);
        form.resetFields();
        setMembers([]);
        onMemberAdded();
        setModalOpen(false);
      }
    } catch (error) {
      setErrorMessages([t("something_went_wrong")]);
    }
  };

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const options = lookups?.[i18n.language]?.applicationRoles?.map(
    (role: any) => ({
      value: role.key,
      label: capitalizeFirstLetter(role.name),
      description: roleDescriptions[role.key],
    })
  );
  console.log("Email Errors:", emailErrors);
  return (
    <Modal
      open={modalOpen}
      onCancel={handleCancel}
      maskClosable={false}
      footer={
        <div className={styles.footerBtn}>
          <Button className="lavad-btn lavad-btn-md" onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button
            className={`lavad-btn lavad-btn-md lavad-btn-primary ${loading ? "loading" : " "
              }`}
            disabled={isInviteDisabled()}
            onClick={handleInvite}
            style={{ width: "120px" }}
          >
            {loading ? <span className="icon-loader spin"></span> : t("invite")}
          </Button>
        </div>
      }
      centered
      className="emailModal"
      title={t("add_team_member")}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={addMember}
        onValuesChange={handleFormChange}
      >
        <div className={styles.emailModalContent}>
          <div className={styles.formItems}>
            <Form.Item
              name="email"
              label={<label className={styles.label}>{t("email")}</label>}
              rules={[
                { required: true, message: t("email_required") },
                { type: "email", message: t("invalid_email") },
              ]}
              className="removeStars"
              validateStatus={emailErrors.email ? "error" : undefined}
              help={emailErrors.email ? emailErrors.email : undefined}
            >
              <Input
                autoComplete="off"
                type="text"
                className={`lavad-input lavad-input-lg ${styles.customWidth}`}
                placeholder={t("Ex: example@gmail.com")}
                onChange={handleEmailChange}
              />
            </Form.Item>

            <Form.Item
              label={<label className={styles.label}>{t("role")}</label>}
              name="role"
              rules={[{ required: true, message: t("role_required") }]}
              className="removeStars"
            >
              <div id="roledropdown">
                <Select
                  placeholder={t("role")}
                  style={{ height: "40px", width: "110px" }}
                  // optionLabelProp="label"
                  defaultActiveFirstOption={false}
                  rootClassName={`tableSelectContainer`}
                  // mode="multiple"
                  getPopupContainer={() =>
                    document.getElementById("roledropdown") || document.body
                  }
                  dropdownRender={(menu) => <div>{menu}</div>}
                  onChange={(value) => {
                    form.setFieldsValue({ role: value });
                  }}
                  options={options}
                  optionRender={(option: any) => {
                    return (
                      <div>
                        <div className={styles.optionWrapper}>
                          <span className={styles.optionName}>
                            {capitalizeFirstLetter(option.data.label)}{" "}
                            <span className={styles.optionDescription}>
                              {option.data.description}
                            </span>
                          </span>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                className={`${styles.addBtnIcon} emailBtn`}
                onClick={() => form.submit()}
              >
                <HiOutlinePlusCircle
                  style={{ width: "24px", height: "24px" }}
                />
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className={styles.memberListContainer}>
        {members.map((member, index) => (
          <div key={index} className={styles.memberItem}>
            <div className={styles.memberInfoAndError}>
              <div className={styles.memberInfo}>
                <img src={avatarPlaceholder} alt="Avatar" />
                <span className={styles.memberEmail}>{member.email}</span>
              </div>
              {emailErrors[index] && (
                <p className={'error'}>{emailErrors[index]}</p>
              )}
            </div>
            <span onClick={() => removeMember(index)} className={styles.remove}>
              {t("remove")}
            </span>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default EmailModal;
