import React, { useContext, useEffect, useRef, useState } from "react";
import LavadDrawer from "../LavadDrawer";
import { AutoComplete, Col, Form, Input, List, Row, Select, Skeleton, Space } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { AuthStateContext } from "../../Context/MainContext";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import i18n from "../../Utils/i18n/i18n";
import { Brand } from "../../Context/Actions/campaign";
import nodata from "../../assets/imgs/filters-no-data.svg";

const BrandSearch = ({ openDrawer, setOpenDrawer, onSelectBrand, intialValue, onAddNew }: any) => {
  const { t } = useTranslation();
  const [brandsSearchResults, setBrandsSearchResults] = useState<any[]>([{ name: '' }, { name: '' }, { name: '' }, { name: '' }, { name: '' },]);
  const [inputValue, setInputValue] = useState(intialValue);

  const inputRef = useRef(null);

  const [filteredBrands, setFilteredBrands] = useState<any[]>([]);
  const [brandListLoading, setBrandListLoading] = useState(false);
  const [campaignsParams, setCampaignParams] = useState<Brand>({
    page: 1,
    pageSize: 6,
  });

  // const [showSpinner, setShowSpinner] = useState(false);
  // const [page, setPage] = useState(1);
  // const [hasMore, setHasMore] = useState(true);

  const {
    state: { brands },
  } = useContext(AuthStateContext);

  useEffect(() => {
    // Fetch and set brands search results
    const results = brands.map((b: { name: any[]; id: any; logoUrl: any }) => {
      const englishName = b.name.find(
        (a: { languageKey: string }) => a.languageKey === "en"
      )?.value;
      const arabicName = b.name.find(
        (a: { languageKey: string }) => a.languageKey === "ar"
      )?.value;
      return {
        logo: b.logoUrl,
        value: b.id,
        label: `${englishName} - ${arabicName}`,
      };
    });
    console.log("brands", results);
    setBrandsSearchResults(results);
    setFilteredBrands(results);
  }, [brands]);

  // const getBrandList = async(params : Brand)=>{
  //   setBrandListLoading(true);
  // }

  // const onBrandsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const v = e.target.value.toLowerCase();
  //   setInputValue(v);

  //   const filtered = brandsSearchResults.filter((b: { label: string }) =>
  //     b.label.toLowerCase().includes(v)
  //   );

  //   setBrandsSearchResults(filtered);
  //   setShowDropdown(true);
  // };
  // const onBrandsChange = (e: { target: { value: any; }; }) => {
  //   const v = e.target.value;
  //   setInputValue(v);
  //   const filtered = brands
  //     .filter((b: { name: string; }) => b.name.toLowerCase().includes(v.toLowerCase()))
  //     .map((b: { id: any; name: any; }) => ({ value: b.id, label: b.name }));
  //   setBrandsSearchResults(filtered);
  //   setShowDropdown(true);
  // };
  // const handleOptionClick = (option: BrandOption) => {
  //   setInputValue(option.label);
  //   setShowDropdown(false);
  //   // Here you can add any additional logic for when a brand is selected
  // };
  // useEffect(() => {
  //   const handleClickOutside = (event: { target: any; }) => {
  //     if (
  //       dropdownRef.current &&
  //       !dropdownRef.current.contains(event.target) &&
  //       inputRef.current &&
  //       !inputRef.current.input.contains(event.target)
  //     ) {
  //       setShowDropdown(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    // Filter based on input value
    const filtered = brandsSearchResults.filter((b: { label: string }) =>
      b.label.toLowerCase().includes(value)
    );
    setFilteredBrands(filtered);
  };
  const handleBrandClick = (brand: any) => {
    onSelectBrand(brand);
  };

  return (
    <div>
      {openDrawer && (
        <>
          <Row>
            <Col span={10} offset={12}>
              <LavadDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                title={t("search_brands")}
                cancelButtonText={t("close")}
                saveButtonText={'+ ' + `${t("new_brand")}`}
                saveAction={onAddNew}
              >
                <div className={styles.inputContainer}>
                  <Input
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleInputChange}
                    prefix={<HiOutlineMagnifyingGlass width={16} height={16} />}
                    placeholder={t("search_arabic_english")}
                    className="lavad-input"
                    size="large"
                  />
                </div>
                <div
                  className={styles.resultsContainer}
                  style={{ overflowY: "auto" }}
                >
                  {filteredBrands.length ? <List
                    dataSource={filteredBrands}
                    renderItem={(item) => (

                      <List.Item
                        key={item.value}
                        className={styles.resultItem}
                        onClick={() => handleBrandClick(item)}
                      >
                        {brands.length ? <>
                          <img
                            loading="lazy"
                            src={item.logo}
                            alt={item.label}
                            className={styles.logo}
                          />
                          <div className={styles.brandName}>{item.label}</div>
                        </>
                          : <div className={styles.brandSkeleton + ' ' + 'drawerBrandSkeleton'}><Skeleton.Button active style={{ width: '56px', height: '56px' }} /><Skeleton active paragraph={{ rows: 0 }} /></div>}
                      </List.Item>
                    )}
                  /> : !filteredBrands.length && inputValue ? <div className={styles.emptyStateWithFilters}>
                    <img src={nodata} alt="no data" />
                    <div className={styles.empty_state_text}>
                      <h3>{t("no_results_found")}</h3>
                      <p className="title">{t("no_results_subtitle")}</p>
                    </div>
                  </div> : ""}
                </div>
              </LavadDrawer>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default BrandSearch;
