import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { AuthStateContext } from "../../Context/MainContext";
import { useContext, useEffect, useState } from "react";
import { getInitials } from "../../Utils/helpers";
import { Col, Row } from "antd";
import { HiOutlinePencil, HiOutlineSquares2X2 } from "react-icons/hi2";
import GeneralSettings from "../../Components/GeneralSettings";
import MembersSettings from "./MembersSettings";

const Settings = () => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const {
    state: { lookups, brands, user },
    dispatch,
  } = useContext(AuthStateContext);

  const [activeTab, setActiveTab] = useState("general");
  const [membersData, setMembersData] = useState<any[]>([]);
  const handleMembersData = (data: any[]) => {
    setMembersData(data);
  };
  useEffect(() => {
    // Set the default tab to 'members' if the user is a MEMBER
    if (user && user.role === "MEMBER" && activeTab === "general") {
      setActiveTab("members");
    }
  }, [user, activeTab]);

  const [edit, setEdit] = useState(false);
  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>{t("settings")}</div>
      <Row className={styles.cont} gutter={48}>
        <Col span={6}>
          <div className={styles.profile}>
            <div className={styles.avatar}>
              {getInitials(user?.displayName)}
            </div>
            <div className={styles.info}>
              <h3>{user?.displayName}</h3>
              <p className="body">{user?.email}</p>
            </div>
            <div className={styles.seperator}></div>
            <div className={styles.tit}>
              <HiOutlineSquares2X2 /> {t("workspace_settings")}
            </div>
            <ul className={styles.tabs}>
              {user && user.role !== "MEMBER" && (
                <li
                  className={activeTab === "general" ? styles.active : ""}
                  onClick={() => setActiveTab("general")}
                >
                  {t("general")}
                </li>
              )}
              <li
                className={activeTab === "members" ? styles.active : ""}
                onClick={() => setActiveTab("members")}
              >
                {t("members")}
              </li>
            </ul>
          </div>
        </Col>
        {activeTab === "general" && user.role !== "MEMBER" && (
          <Col span={16} className={styles.settings}>
            <GeneralSettings />
          </Col>
        )}
        {activeTab === "members" && (
          <Col
            span={18}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            className={styles.settings}
          >
            <MembersSettings onDataFetched={handleMembersData} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Settings;
