import { useState, useContext, useEffect } from "react";
import { AuthStateContext } from "../../../Context/MainContext";
import { checkUserEmail, forgotPassword } from "../../../Context/Actions/auth";
import { Link, useNavigate } from "react-router-dom";
import styles from "../Auth.module.scss";

import { t } from "i18next";
import { Row, Col, Input, Form } from "antd";
import type { FormItemProps } from "antd";
import React from "react";
import login_vector from "../../../assets/imgs/login-vector.png";
import Alert from "../../../Components/Alerts";
import { HiOutlineXCircle } from "react-icons/hi";
import i18n from "../../../Utils/i18n/i18n";

const MyFormItemContext = React.createContext<(string | number)[]>([]);

interface MyFormItemGroupProps {
  prefix: string | number | (string | number)[];
  children: React.ReactNode;
}

function toArr(
  str: string | number | (string | number)[]
): (string | number)[] {
  return Array.isArray(str) ? str : [str];
}

const MyFormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );

  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

const MyFormItem = ({ name, ...props }: FormItemProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

  return <Form.Item name={concatName} {...props} />;
};
const ForgetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch } = useContext(AuthStateContext);
  const [form] = Form.useForm();
  const onFinish = (user: { email: string }) => {
    dispatch({ type: "REQUEST_LOGIN" });
    setLoading(true);
    setError("");
    checkUserEmail(user.email)
      .then((res) => {
        console.log("forget", res.exists);
        if (res?.exists) {
          forgotPassword(user.email)
            .then(() => {
              setError("");
              navigate("/check-mail");
            })
            .catch((e: any) => {
              setError("something_went_wrong");
              setLoading(false);
            });
        } else {
          setError("something_went_wrong");
          setLoading(false);
        }
      })
      .catch((e: any) => {
        console.error("Error from checkUserEmail:", e);

        if (e.response && e.response.status === 404) {
          setError("something_went_wrong");
        } else {
          setError("something_went_wrong");
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      form.resetFields();
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [form, i18n]);
  return (
    <>
      {/* {state.loading && (
        <div className={styles.loader}>
          <h2>Loading</h2>
        </div>
      )} */}
      <div className={styles.login_containier}>
        <Row
          justify="space-between"
          align="middle"
          gutter={12}
          className={styles.loginRow}
        >
          <Col lg={8} xl={8} xxl={8} xs={20} push={4}>
            <div className={styles.login_header}>
              <h2>{t("enter_your_email")}</h2>
              <p className="subtitle">{t("reset_password_subtitle")}</p>
            </div>
            {error && error !== "" && (
              <Alert
                message={t(error)}
                type="danger"
                icon={<HiOutlineXCircle />}
              />
            )}
            <Form
              autoComplete="off"
              validateTrigger="onSubmit"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className={styles.login_form}
            >
              <div className="form-group">
                <label className="lavad_label">{t("email_label")}</label>
                <Form.Item
                  className="nomargin"
                  name="email"
                  rules={[
                    { required: true, message: t("email_required") },
                    { type: "email", message: t("invalid_email") },
                  ]}
                >
                  <Input
                    name="email"
                    type="text"
                    className="lavad-input lavad-input-lg"
                    placeholder={t("enter_email_placeholder")}
                  />
                </Form.Item>
              </div>

              <button
                className={`lavad-btn lavad-btn-primary lavad-btn-lg btn-block ${
                  loading ? "loading" : ""
                }`}
                type="submit"
              >
                {loading ? (
                  <span className="icon-loader spin"></span>
                ) : (
                  t("send_label")
                )}
              </button>
            </Form>
            <Link className={styles.forgetpw} to="/login">
              {t("back_login")}
            </Link>
          </Col>
          <Col lg={8} xl={8} xxl={8} xs={20} pull={2}>
            <div className="justify-end">
              <img
                className={styles.login_vector}
                src={login_vector}
                alt="login vector"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ForgetPassword;
