import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import InfluencerPlaceholder from "../../../assets/imgs/influencer_placeholder.svg"


const InfluencerRelations = ({ details }: any) => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  return (
    <>
      {details?.relationships ? (
        <ul className={styles.relationships + " " + "_lr-hide"} data-private>
          {details?.relationships?.map(
            (r: any) => (
              <li
                key={r.key}
              >
                <img src={r.imageUrl || InfluencerPlaceholder} alt="inf-img" onError={(e: any) => {
                  e.target.src = InfluencerPlaceholder;
                }} />
                <div>
                  <p className="body">{r.name}</p>
                  <span className="body">{r.typeKey}</span>
                </div>
              </li>
            )
          )}
        </ul>
      ) : <p className={styles.nodata} data-private>{t('no_available_data')}</p>}
    </>
  );
};

export default InfluencerRelations;
