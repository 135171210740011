import { useTranslation } from "react-i18next";
import styles from "./index.module.scss"
import dayjs from "dayjs";
import i18n from "../../../Utils/i18n/i18n";

const InfluencerExclusiveBrands = ({ details }: any) => {
    const {
        t
    } = useTranslation();

    return (
        <>
            {details?.exclusiveBrands ? (
                <ul className={styles.exclusiveBrands + " " + "_lr-hide"} data-private>
                    {details?.exclusiveBrands?.map(
                        (obj: any) => (
                            <li
                                key={obj.brandInfo.name}
                            >
                                <span className={"body" + " " + styles.title}>{obj.brandInfo.name}  {obj.brandInfo.industryKeys && obj.brandInfo.industryKeys.length > 0 && '\u00a0'} {obj.brandInfo.industryKeys && obj.brandInfo.industryKeys.length ? obj.brandInfo.industryKeys.join(", ") : ''} - {obj.expirationDate < Date.now() ? <span className={styles.expired}>{t('expired')}</span> : <span className={styles.active}>{t('active')}</span>}
                                </span>
                                <span className={"body" + " " + styles.expirationDate}>{t("expiry_date") + " " + dayjs(obj.expirationDate).locale(i18n.language == "en" ? "en" : "ar-SA").format("DD MMM YYYY")}</span>
                            </li>
                        )
                    )}
                </ul>
            ) : <p className={styles.nodata} data-private>{t('no_available_data')}</p>}
        </>
    )
}

export default InfluencerExclusiveBrands