import api from "../../Utils/axiosInterceptor";

export interface agency {
    basicInfo: {
        logoUrl: string | null;
        primaryColor: string;
        name: string;
        websiteUrl: string;
    }
}

export const editAgencyData = async (agencyId: any, agency: agency) => {
    const res = await api.put(`${process.env.REACT_APP_API_URL}api/agency/${agencyId}`, agency);
    if (!res.data.error) {
        return res.data
    } else {
        return { error: true }
    }
}