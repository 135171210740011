import { Tag, Form, Select, DatePicker, Button, Tooltip } from "antd";
import styles from "./Index.module.scss";
import { useEffect, useState, useContext, useRef } from "react";
import {
  HiOutlineAdjustmentsHorizontal,
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineMagnifyingGlass,
  HiOutlineShare,
  HiOutlineSquaresPlus,
  HiOutlineUsers,
} from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import "dayjs/locale/ar";
import {
  HiOutlineAdjustments,
  HiOutlineColorSwatch,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { PiGenderIntersexLight } from "react-icons/pi";
import { AuthStateContext } from "../../../../../Context/MainContext";
import i18n from "../../../../../Utils/i18n/i18n";
import { IoCloseOutline } from "react-icons/io5";
import { getDefaultFiltersState } from "../Defaults";



const useDebounce = (callback: any, delay: any) => {
  const timerRef: any = useRef(null);

  const debouncedCallback = (...args: any) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
};

const ListingFilters = ({
  getInfluencersList,
  setFilters,
  filters,
  setOpened,
  getSelectedFilters,
  localFilters,
  setLocalFilters,
  generatePages,
  pagesState,
  setInfluencersCount,
  setInfluencersList,
  setInfluencerListLoading,
  setGeneratingPages,
  influencerListLoading
}: any) => {
  const { CheckableTag } = Tag;
  const [form] = Form.useForm();
  const tagsData = ["Movies", "Books", "Music", "Sports"];
  const { RangePicker } = DatePicker;
  const {
    state: { lookups, brands },
    dispatch,
  } = useContext(AuthStateContext);
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    search: "",
    type: [],
    niche: [],
    platform: [],
    genders: [],
  });
  const [activeFilters, setActiveFilters] = useState<boolean>(false);
  const [selectedTypes, setSelectedTypes] = useState<any>([]);
  const [selectedNiches, setSelectedNiches] = useState<any>([]);
  const [selectedGenders, setSelectedGenders] = useState<any>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<any>([]);
  const [socialPlatformsSearchResults, setSocialPlatformsSearchResults] =
    useState([]);
  const [socialPlatforms, setSocialPlatforms] = useState([]);
  const [platformsEmpty, setPlatformsEmpty] = useState(false);
  const [nichesSearchResults, setNichesSearchResults] = useState<any>([]);
  const [typesSearchResults, setTypesSearchResults] = useState<any>([]);
  const [gendersSearchResults, setGendersSearchResults] = useState<any>([]);


  const debounce = <T extends (...args: any[]) => void>(
    callback: T,
    delay: number
  ) => {
    let timeoutId: NodeJS.Timeout;

    return (...args: Parameters<T>) => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };
  const debouncedCall = debounce((query: string) => {
    if (query.length > 2) {
      setState((state: any) => ({ ...state, search: query }));
    } else if (!query.length) {
      setState((state: any) => ({ ...state, search: "" }));
    }
  }, 1000);

  const handleChange = (tag: string, checked: boolean, type: string) => {
    if (type == "type") {
      const nextSelectedTags = checked
        ? [...state.type, tag]
        : state.type.filter((t: any) => t !== tag);
      setState((state: any) => ({ ...state, type: nextSelectedTags }));
    }
  };

  const ref = useRef<any>();

  const { Option } = Select;

  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFilterResults = async () => {

    try {
      setLoading(true);
      const pages = await generatePages()
      console.log(pages, "generatePagesPages")

      if (pages.length) {
        let page = pages.pop()
        pagesState.current = pages
        await getInfluencersList({ page, pageSize: 12 });
        setLoading(false)

      } else {
        pagesState.current = []
        setInfluencersList([])
        setInfluencersCount(0)
        setInfluencerListLoading(false)
        setGeneratingPages(false)

      }
    } catch (e: any) {
      if (e.name === "CanceledError") {
        console.log('Request canceled');
      }
    }
  }
  const debouncedGetFilterResults = useDebounce(getFilterResults, 800);

  useEffect(() => {
    if (changed) {
      debouncedGetFilterResults()
    }
  }, [filters.platform.platform, filters.platform.inf_types, filters.basicInfo.niches, filters.basicInfo.age_group, filters.basicInfo.gender, changed])


  const selectedFilters = getSelectedFilters(filters).filter((o: any) => Array.isArray(o.label) ? o.label[0] || o.label[1] : !!o.label)
  console.log("selectedFilters: ", selectedFilters)

  const [showMore, setShowMore] = useState(true);
  const [run, setRun] = useState(false);

  const removeFilter = (filter: any) => {
    setRun(true)
    const state = { lookups };
    let defValue = (getDefaultFiltersState(state, i18n) as any)[filter.p][filter.key];
    if (filter.key !== filter.p) {
      if (filter.key.toLowerCase() == "service") {
        setFilters((filters: any) => ({ ...filters, [filter.p]: { ...filters[filter.p], [filter.key]: defValue, service_price: [0, 0] } }));
        setLocalFilters((filters: any) => ({ ...filters, [filter.p]: { ...filters[filter.p], [filter.key]: defValue, service_price: [0, 0] } }));
      } else if (filter.key.toLowerCase() == "residency") {
        {
          setFilters((filters: any) => ({ ...filters, [filter.p]: { ...filters[filter.p], [filter.key]: defValue, city: [] } }));
          setLocalFilters((filters: any) => ({ ...filters, [filter.p]: { ...filters[filter.p], [filter.key]: defValue, city: [] } }));
        }

      } else {
        setFilters((filters: any) => ({ ...filters, [filter.p]: { ...filters[filter.p], [filter.key]: defValue } }));
        setLocalFilters((filters: any) => ({ ...filters, [filter.p]: { ...filters[filter.p], [filter.key]: defValue } }));
      }
    } else {
      defValue = (getDefaultFiltersState(state, i18n) as any)[filter.p];
      setFilters((filters: any) => ({ ...filters, [filter.p]: defValue }));
      setLocalFilters((filters: any) => ({ ...filters, [filter.p]: defValue }));
    }
  };

  const runFilters = async () => {
    getFilterResults();
    setRun(false)
  }

  useEffect(() => {
    if (run) {
      runFilters()
    }
  }, [run, filters])


  const [searchTerm, setSearchTerm] = useState("");

  const [search, setSearch] = useState(false);


  const getSearchResults = async () => {
    await getFilterResults()
    setSearch(false)
  }

  useEffect(() => {
    if (search) {
      getSearchResults()
    }
  }, [search])


  const [runClearAll, setRunClearAll] = useState(false);


  useEffect(() => {
    const get = async () => {
      await getFilterResults()
      setRunClearAll(false)
    }
    if (runClearAll) {
      get()
    }
  }, [filters, runClearAll])

  console.log("lookups[i18n.language]?.ageRanges", lookups[i18n.language]?.ageRanges)

  return (
    <div className={`${styles.filtersContainer}`}>
      <div className={styles.formWrapper}>
        <div className={`${styles.filtersForm}`}>
          <div className={styles.tagsContainer}>
            <Select
              popupClassName="plat-drop-options"
              labelInValue
              className='plat-drop'
              defaultActiveFirstOption={false}
              // className={`brands-select ${styles.marginTop}`}
              virtual={false}
              style={{ width: "108px" }}
              showSearch={false}
              size="large"
              placeholder={t("platform_placeholder")}
              onInputKeyDown={(event) => {
                if (event.keyCode === 8 || event.key === "Backspace") {
                  return event.stopPropagation();
                }
              }}

              value={filters.platform.platform.key}
              onChange={(platform: any) => {
                console.log(platform, "platform =")
                setFilters((filters: any) => ({
                  ...filters, platform: {
                    ...filters.platform, service: [],
                    service_price: [0, 0], platform: { key: platform.key, label: lookups[i18n.language]?.socialPlatforms?.find((a: any) => a.key == platform.key).name }
                  }
                }));
                setLocalFilters((filters: any) => ({
                  ...filters, platform: {
                    ...filters.platform, service: [],
                    service_price: [0, 0], platform: { key: platform.key, label: lookups[i18n.language]?.socialPlatforms?.find((a: any) => a.key == platform.key).name }
                  }
                }));
                setChanged(true)
              }}


            >
              {lookups[i18n.language]?.socialPlatforms?.map((s: any) => (<Option value={s.key}>
                <div className={styles.platOptions}>
                  <img src={s.logoUrl} alt={s.name} />
                  <p>{s.name}</p>
                </div>

              </Option>))}

            </Select>
          </div>
          <div className={styles.tagsContainer}>
            <div className={styles.iconConatiner}>
              <span className="icon-group"></span>

              <p className={`body ${styles.keepCase}`}>
                {t("influencer_types")}
              </p>
            </div>
            <Select
              labelInValue
              allowClear
              defaultActiveFirstOption={false}
              virtual={false}
              value={filters.platform.inf_types}
              maxTagCount={1}
              showSearch={false}
              className={styles.marginTop + " " + 'lavad-md-drop'}
              mode="multiple"
              style={{ width: "100%" }}
              onInputKeyDown={(event) => {
                if (event.keyCode === 8 || event.key === "Backspace") {
                  return event.stopPropagation();
                }
              }}

              size="large"
              placeholder={t("types_placeholder")}
              options={lookups[i18n.language]?.influencerTypes?.map((s: any) => ({
                label: s.name,
                value: s.key
              }))}
              onChange={(inf_types) => {
                setFilters((filters: any) => ({ ...filters, platform: { ...filters.platform, inf_types } }))
                setLocalFilters((filters: any) => ({ ...filters, platform: { ...filters.platform, inf_types } }))
                setChanged(true)

              }}

              optionRender={(option: any) => (
                <>{option.label} <span className='select_label_span'>({t(option.value + '_info')})</span></>
              )}
            />
          </div>
          <div className={styles.tagsContainer}>
            <div className={styles.iconConatiner}>
              <HiOutlineSquaresPlus className={styles.group} />

              <p className={`body ${styles.keepCase}`}>{t("inf_niches")}</p>
            </div>
            <Select
              labelInValue
              allowClear
              defaultActiveFirstOption={false}
              virtual={false}

              onChange={(niches) => {
                setFilters((filters: any) => ({ ...filters, basicInfo: { ...filters.basicInfo, niches } }))
                setLocalFilters((filters: any) => ({ ...filters, basicInfo: { ...filters.basicInfo, niches } }))
                setChanged(true)
              }}
              maxTagCount={1}
              showSearch={false}
              className={styles.marginTop + " " + 'lavad-md-drop'}
              mode="multiple"
              style={{ width: "100%" }}
              onInputKeyDown={(event) => {
                if (event.keyCode === 8 || event.key === "Backspace") {
                  return event.stopPropagation();
                }
              }}

              options={lookups[i18n.language]?.niches?.map((s: any) => ({
                label: s.name,
                value: s.key
              }))}
              value={filters.basicInfo.niches}
              size="large"
              placeholder={t("niche_placeholder")}
            />
          </div>
          <div className={styles.tagsContainer}>
            <div className={styles.iconConatiner}>
              <HiOutlineUsers className={styles.group} />
              <p className={`body ${styles.keepCase}`}>{t("influencer_age_groups")}</p>
            </div>
            <Select
              labelInValue
              mode="multiple"
              virtual={false}
              className={`brands-select ${styles.marginTop} lavad-md-drop`}
              allowClear
              defaultActiveFirstOption={false}
              onChange={(age_group) => {
                setFilters((filters: any) => ({ ...filters, basicInfo: { ...filters.basicInfo, age_group } }))
                setLocalFilters((filters: any) => ({ ...filters, basicInfo: { ...filters.basicInfo, age_group } }))
                setChanged(true)
              }}
              maxTagCount={1}
              value={filters.basicInfo.age_group}
              showSearch={false}
              style={{ width: "100%" }}
              onInputKeyDown={(event) => {
                if (event.keyCode === 8 || event.key === "Backspace") {
                  return event.stopPropagation();
                }
              }}

              options={lookups[i18n.language]?.ageRanges?.map((s: any) => ({
                label: s.name,
                value: s.key
              }))}

              size="large"
              placeholder={t("choose_age_group")}
            />
          </div>

          <div className={styles.tagsContainer}>
            <div className={styles.iconConatiner}>
              <PiGenderIntersexLight className={styles.group} />
              <p className={`body ${styles.keepCase}`}>{t("inf_genders")}</p>
            </div>
            <Select
              labelInValue
              mode="multiple"
              virtual={false}
              className={`brands-select ${styles.marginTop} lavad-md-drop`}
              allowClear
              defaultActiveFirstOption={false}
              onChange={(gender) => {
                setFilters((filters: any) => ({ ...filters, basicInfo: { ...filters.basicInfo, gender } }))
                setLocalFilters((filters: any) => ({ ...filters, basicInfo: { ...filters.basicInfo, gender } }))
                setChanged(true)
              }}
              maxTagCount={1}

              value={filters.basicInfo.gender}
              showSearch={false}
              style={{ width: "100%" }}
              onInputKeyDown={(event) => {
                if (event.keyCode === 8 || event.key === "Backspace") {
                  return event.stopPropagation();
                }
              }}

              options={lookups[i18n.language]?.genders?.map((s: any) => ({
                label: s.name,
                value: s.key
              }))}
              size="large"
              placeholder={t("genders_placeholder")}
            />
          </div>
        </div>
        <Button
          className="lavad-btn btn-icon lavad-btn-md lavad-btn-outline"
          icon={<HiOutlineAdjustmentsHorizontal />}
          onClick={() => setOpened(true)}
        >
          {t("all_filters")}
        </Button>

      </div>
      <div className={styles.selectedFilters}>
        <ul ref={ref} className={styles.tags}>
          {(showMore ? selectedFilters.slice(0, 10) : selectedFilters).map((o: any) => <li className={styles.selectedTag}>{typeof o.label == 'boolean' ? o.property : `${o.property}: ${o.label}`} <IoCloseOutline onClick={() => removeFilter(o)} /></li>)}
          {ref?.current?.clientHeight >= 80 && selectedFilters.length > 10 && showMore ? <li onClick={() => setShowMore(false)} className={styles.showBtn}>{t('show_more')} <HiOutlineChevronDown /> </li> : ""}
          {ref?.current?.clientHeight >= 80 && selectedFilters.length > 10 && !showMore ? <li onClick={() => setShowMore(true)} className={styles.showBtn}>{t('show_less')} <HiOutlineChevronUp /></li> : ""}

        </ul>
        <button onClick={() => { setFilters(getDefaultFiltersState({ lookups }, i18n)); setLocalFilters(getDefaultFiltersState({ lookups }, i18n)); setRunClearAll(true) }} className={"lavad-btn lavad-btn-lg lavad-btn-link" + " " + styles.clearBtn}>{t("clear_filters")}</button>
      </div>
      <div className={styles.inputWrapper}>
        <input value={searchTerm} onKeyDown={e => {
          if (e.code == "Enter") {
            setFilters((filters: any) => ({ ...filters, searchTerm }));
            setLocalFilters((filters: any) => ({ ...filters, searchTerm }));
            setSearch(true);
          }
        }} className="lavad-input lavad-input-xl" placeholder={t('search_inf')} onChange={(e: any) => {
          setSearchTerm(e.target.value)
        }} />
        <button disabled={loading} className={"lavad-btn lavad-btn-xl lavad-btn-primary" + " " + styles.searchBtn} onClick={() => {
          setFilters((filters: any) => ({ ...filters, searchTerm }));
          setLocalFilters((filters: any) => ({ ...filters, searchTerm }));
          setSearch(true);
        }}>{t('search')}</button>
      </div>
    </div >
  );
};

export default ListingFilters;





