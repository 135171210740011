import { Button, Drawer, Form } from 'antd';
import { dir, t } from 'i18next';
import { useState } from 'react';
import styles from "./index.module.scss";
import { HiOutlineChevronLeft } from 'react-icons/hi2';


const LavadDrawer = ({ open, onClose, children, headerClassName, title, cancelButtonText, saveButtonText, saveAction }: any) => {

  const [form] = Form.useForm();


  const [editLoading, setEditLoading] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [openEdit, setOpenEdit] = useState(false);

  const openEditDrawer = async () => {

    setOpenEdit(true)
  }
  const handleCancel = () => {
    onClose();
  };


  return (


    < Drawer
      placement={dir() === "ltr" ? "right" : "left"}
      mask={false}

      footer={< div className={styles.footer} >
        <button className="lavad-btn lavad-btn-lg lavad-btn-outline" onClick={handleCancel}>{cancelButtonText || t('cancel')}</button>
        <button disabled={editLoading || fileList.find((a: any) => a.status == "uploading")} onClick={() => saveAction ? saveAction() : form.submit()} className="lavad-btn lavad-btn-lg lavad-btn-primary-outline" >{editLoading ?
          <span className="icon-loader spin"></span> : (saveButtonText || t('save_changes'))}</button>

      </div >} rootClassName='lavad-drawer-wrapper lavad-drawer-same-modal-level' className='lavad-drawer' open={open}
      headerStyle={{ padding: 0 }}
      title={<div className={`${styles.header} ${headerClassName} `}>{title}</div>}  >
      <div className={styles.contentWrapper}>
        {children}
      </div>

    </Drawer >





  );
};

export default LavadDrawer;
