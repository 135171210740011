import { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AuthStateContext } from "../../../Context/MainContext";
import { auth } from "../../../Utils/firebase";
import i18n from "../../../Utils/i18n/i18n";
import {
  InfluencerListingCardInterface,
  getAllInfluencers,
} from "../../../Context/Actions/influencers";
import styles from "./index.module.scss";
import {
  Row,
  Col,
  notification,
} from "antd";

import nodata from "../../../assets/imgs/filters-no-data.svg";
import noresults from "../../../assets/imgs/noresults.svg";
import InfluenceraignCardSkeleton from "../../influencer-card-skeleton";
import InfluencerListingCard from "../../InfluencerListingCard";
import InfluencerFilters from "./Filters/index";
import { HiOutlineXCircle } from "react-icons/hi";
import InfluencerDetails from "../../InfluencerDetails";
import api from "../../../Utils/axiosInterceptor";
import ListingFilters from "./Filters/ListingFilters/Index";
import { getDefaultFiltersState } from "./Filters/Defaults";
import Alert from "../../Alerts";


const AssigneInfluencerList = (props: any) => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const { state, dispatch } = useContext(AuthStateContext);
  const [isEnded, setIsEnded] = useState(false);
  const [influencerListLoading, setInfluencerListLoading] = useState(false);
  // const [influencersParams, setInfluencerParams] = useState<any>({
  //   page: 1,
  //   pageSize: 12,
  // });
  const [selectedInfluencers, setSelectedInfluencers] = useState<any>([]);
  const [scrollableContainer, setScrollableContainer] = useState(0);
  const [influencersList, setInfluencersList] = useState<
    Array<InfluencerListingCardInterface>
  >([]);
  const [influencersCount, setInfluencersCount] = useState<any>(null);
  const [resetFilters, setResetFilters] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);
  const [isListScrolling, setIsListScrolling] = useState(false);

  const [opened, setOpened] = useState(false);

  const [stopAdditionalRequests, setStopAdditionalRequests] = useState(false);




  const [filters, setFilters] = useState<any>(getDefaultFiltersState(state, i18n))
  const [localFilters, setLocalFilters] = useState<any>(getDefaultFiltersState(state, i18n))

  const pagesState = useRef<any>([])

  const sortNames = ['name']
  const sortDirections = ["asc", "desc"]

  const randomSortName = useRef(sortNames[Math.floor(Math.random() * sortNames.length)]);
  const randomSortDirection = useRef(sortDirections[Math.floor(Math.random() * sortDirections.length)]);

  const applyFiltersAbortController: any = useRef(null);
  const generatePagesAbortController: any = useRef(null);

  const [generatingPages, setGeneratingPages] = useState(false)

  // console.log("Random sorting", randomSortName.current, randomSortDirection.current)


  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("error_label"),
      description: t("something_went_wrong"),
      icon: <HiOutlineXCircle className="danger-text" />,
      className: "no-wrap",
    });
  };

  const applyFilters = async (params: any, fromPagination: boolean) => {
    // setLoading(true)
    if (!fromPagination) {
      if (applyFiltersAbortController.current) {
        applyFiltersAbortController.current.abort();
      }
      applyFiltersAbortController.current = new AbortController();
    }

    try {
      const res = await api.post(`${process.env.REACT_APP_API_URL}api/influencer/all/campaign/${props.campId}`, {
        searchTerm: filters.searchTerm, filters: {
          basicInfo: {
            nationalityKeys: filters.basicInfo.nationality?.key ? [
              filters.basicInfo.nationality.key
            ] : [],
            residency: filters.basicInfo.residency?.key ? { cityKeys: filters.basicInfo.city.length ? filters.basicInfo.city.map((c: any) => (c.key)) : null, countryKey: filters.basicInfo.residency?.key }
              : null,
            ageGroupKeys: filters.basicInfo.age_group ? filters.basicInfo.age_group.map((c: any) => c.key)

              : [],
            speakingLanguageKeys: filters.basicInfo.speaking_language ? filters.basicInfo.speaking_language.map((k: any) => k.key) : [],
            genderKeys: filters.basicInfo.gender ?
              filters.basicInfo.gender.map((c: any) => c.key)
              : [],
            maritalStatusKeys: filters.basicInfo.marital_status ? [
              filters.basicInfo.marital_status.key
            ] : [],
            haschildern: typeof filters.basicInfo.children?.key == 'boolean' ? filters.basicInfo.children.key : null,
            hasPets: typeof filters.basicInfo.pets?.key == 'boolean' ? filters.basicInfo.pets.key : null
          },
          metaData: {
            nicheKeys: filters.basicInfo.niches ?
              filters.basicInfo.niches.map((c: any) => c.key)
              : [],
            commitementLevelKeys: filters.badges.commitment_level ?
              filters.badges.commitment_level.map((k: any) => k.key)
              : [],
            hasMawthok: typeof filters.badges.mawthok?.key == 'boolean' ? filters.badges.mawthok.key : null,
            allowInsightSharing: typeof filters.badges.insights_sharing?.key == 'boolean' ? filters.badges.insights_sharing.key : null,
            tagKeys: filters.tags?.tags?.map((c: any) => c.key),
            industryKeys: filters.suitable_industries ?
              filters.suitable_industries.map((c: any) => c.key)
              : [],
            excludeHasSafetyFlags: typeof filters.tags.exclude_safety_flags == 'boolean' ? filters.tags.exclude_safety_flags : null
          },
          socialPlatform: {
            socialPlatformKey: filters.platform.platform.key,
            isVerified: filters.platform.filters_show_verfied_only ? true : null,
            socialPlatformServiceKeys: filters.platform.service?.length ? filters.platform.service.map((c: any) => c.key) : [],
            servicePrice: filters.platform.service_price[0] || filters.platform.service_price[1] ? {
              from: filters.platform.service_price[0] ? filters.platform.service_price[0] : 0,
              to: filters.platform.service_price[1] ? filters.platform.service_price[1] : 0
            } : null,
            influencerTypeKeys: filters.platform.inf_types?.length ? filters.platform.inf_types.map((c: any) => c.key) : [],
            audienceSegmentationFilters: {
              countryKeys: filters.platform.aud_country ? [
                filters.platform.aud_country.key
              ] : [],
              ageGroupKeys: filters.platform.aud_age_group ? [
                filters.platform.aud_age_group.key
              ] : [],
              genderKeys: filters.platform.aud_gender ? [
                filters.platform.aud_gender.key
              ] : [],
              otherSegmentKeys: filters.platform.other_segments ? filters.platform.other_segments.map((c: any) => c.key) : []
            }
          },
          Brands: {
            isExcluded: typeof filters.brands.exclude_selected_brands == 'boolean' ? filters.brands.exclude_selected_brands : null,
            brandIds: filters.brands.signed_exclusive_for ? filters.brands.signed_exclusive_for.map((c: any) => c.key) : []
          }
        },
        pagination: {
          pageSize: params.pageSize,
          page: params.page
        },
        // sortBy: randomSortName.current,
        // sortOrder: randomSortDirection.current
      }, fromPagination ? {} : { signal: applyFiltersAbortController.current.signal })
      // console.log(res)
      // if (res.status == 200 && res?.data?.data) {
      //   setInfluencersList(res.data.data);
      //   setInfluencersCount(res.data.totalRecords);
      // } else {
      //   setInfluencersList([]);
      //   setInfluencersCount(0);
      // }
      // setLoading(false)
      // setOpened(false)
      if (res.data && res.status == 200) {
        return res.data;
      } else {
        openNotification()
        return { error: true };
      }
    } catch (e: any) {
      if (e.name === "CanceledError") {
        console.log('Apply Filters request canceled');
      } else {
        openNotification();
      }
    } finally {
      applyFiltersAbortController.current = null;
    }

  }


  const getInfluencersList = async (
    params: any,
    fromPagination: boolean = false,
  ) => {
    let count = 0;
    let total = 0;
    setInfluencerListLoading(true);
    if (!fromPagination) {
      setInfluencersList([]);
      setInfluencersCount(null);
    }
    await applyFilters(params, fromPagination).then((res) => {
      setInfluencerListLoading(false);
      if (res.error) {
        openNotification();
        setInfluencersList([]);
        setInfluencersCount(0);
      } else {
        fromPagination
          ? setInfluencersList((list) => [...list, ...res.data.sort((a: any, b: any) => Math.random() - 0.5)])
          : setInfluencersList(res.data.sort((a: any, b: any) => Math.random() - 0.5));
        setInfluencersCount(res.totalRecords);

        if (
          fromPagination &&
          (influencersList.length === influencersCount || res.data.length === 0)
        ) {
          setIsEnded(true);
        } else {
          setIsEnded(false);
        }

        count = res.data.length || 0;
        total = res.totalRecords
      }
    });
    props?.setSelectedInfluencers.length > 0 &&
      setSelectedInfluencers(props.setSelectedInfluencers.map((a: any) => ({ ...a, selected: typeof a.selected == "boolean" ? a.selected : true })))

    if (count < 12 && total > 12 && pagesState.current.length > 0) {
      //it was the last page hit the api again
      console.log("hit the API again")
      let newItems = [...pagesState.current];
      let page = newItems.pop()
      pagesState.current = newItems
      getInfluencersList({ page, pageSize: 12 }, true);
    } else {
      return;
    }


  };
  useEffect(() => {
    const getIntial = async () => {
      try {
        const pages: any = await generatePages()
        if (pages.length) {
          let page = pages.pop()
          pagesState.current = pages
          getInfluencersList({ page, pageSize: 12 });
        } else {
          pagesState.current = []
          setInfluencersList([])
          setInfluencersCount(0)
        }
      } catch (e: any) {
        if (e.name === "CanceledError") {
          console.log('Request canceled');
        }
      }

    }
    getIntial()
  }, []);
  useEffect(() => {
    const handleLanguageChange = async () => {
      try {
        const pages: any = await generatePages()
        if (pages.length) {
          let page = pages.pop()
          pagesState.current = pages
          getInfluencersList({ page, pageSize: 12 }, false);
        }
      } catch (e: any) {
        if (e.name === "CanceledError") {
          console.log('Request canceled');
        }
      }
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      // Clean up the event listeners and the auth state change subscription
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n, auth]);
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    console.log(pagesState.current, "pagesState.current")
    let tempParams = { page: '', pageSize: 12 };
    setScrollableContainer(event.currentTarget.scrollTop);
    const { scrollTop } = event.currentTarget;
    setIsListScrolling(scrollTop > 140);
    if (
      (Math.round(event.currentTarget.scrollTop) +
        event.currentTarget.clientHeight +
        30 >
        event.currentTarget.scrollHeight) &&
      (!influencerListLoading
      ) &&
      pagesState.current.length > 0
    ) {
      setInfluencerListLoading(true)
      let newItems = [...pagesState.current];
      let page = newItems.pop()
      pagesState.current = newItems
      tempParams.page = page;
      getInfluencersList(tempParams, true);
    }
  };

  const handleToggleSelectInfluencer = (data: any, add: boolean) => {
    let selectedInfluencersTemp = selectedInfluencers;
    if (add) {
      selectedInfluencersTemp.push({ ...data, selected: true });
      setSelectedInfluencers(selectedInfluencersTemp);
    } else {
      selectedInfluencersTemp.splice(
        selectedInfluencers.findIndex((s: any) => s.id === data.id),
        1
      );
      setSelectedInfluencers(selectedInfluencersTemp);
    }
    props.getSelectedInfluencers(selectedInfluencersTemp);
  };

  const handleToggleRecommendInfluencer = (data: any, value: boolean) => {
    let selectedInfluencersTemp = selectedInfluencers;
    console.log(selectedInfluencersTemp.find((s: any) => s.id === data.id), "true")
    const newArr = selectedInfluencersTemp.map((inf: any) => {
      if (inf.id == data.id) {
        return { ...inf, recommended: value }
      } else {
        return inf
      }
    })
    setSelectedInfluencers(newArr);
    props.getSelectedInfluencers(newArr);
  };

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [influencerDetails, setInfluencerDetails] = useState<any>(null);
  const showDrawer = (e: any, infData: any) => {
    if (e.target.type !== "checkbox") {
      setInfluencerDetails(infData);
    }
  };

  const onClose = () => {
    setOpen(false);
    setInfluencerDetails(null);
  };

  const [error, setError] = useState("");


  interface Data {
    [key: string]: any;
  }

  function getSelectedFilters(obj: Data): { property: string; label: string }[] {
    return Object.entries(obj).flatMap(([key, value]) => {
      if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        // Flatten object properties
        return Object.entries(value).map(([subKey, subValue]: [string, any]) => {
          const label = (Array.isArray(subValue) && subValue.every(item => typeof item === "object" && item !== null && "label" in item)) ?
            subValue.map((item: any) => item.label).join(", ") :
            typeof subValue === "object" && subValue !== null && "label" in subValue ?
              `${subValue.label}` :
              subValue;
          console.log(label, "label")
          return {
            key: subKey,
            p: key,
            property: `${t(subKey)}`,
            label: label && subKey == "service_price" && (label[0] || label[1]) ? label.join(" - ") : label
          };
        });
      } else {
        console.log(key, "key")
        // Flatten non-object properties
        const label = typeof value === "object" && value !== null && "label" in value && "key" in value ?
          `${value.label} (${value.key})` :
          value;

        return {
          key: key,
          p: key,
          property: t(key),
          label: Array.isArray(label) ? label.map((l: any) => l.label).join(", ") : label
        };
      }
    });
  }


  console.log(getSelectedFilters(filters), "filters")


  useEffect(() => {
    console.log(selectedInfluencers, "selectedInflu")
    if (selectedInfluencers.filter((s: any) => !s.selected).length) {
      setSelectedInfluencers(selectedInfluencers.filter((s: any) => s.selected))
    }
  }, [selectedInfluencers])


  const generatePages = async () => {
    if (generatePagesAbortController.current) {
      generatePagesAbortController.current.abort();
    }
    generatePagesAbortController.current = new AbortController();

    try {
      setGeneratingPages(true)
      setInfluencerListLoading(true)
      setInfluencersCount(0)
      setInfluencersList([])

      const res = await api.post(`${process.env.REACT_APP_API_URL}api/influencer/all/campaign/${props.campId}`, {
        searchTerm: filters.searchTerm, filters: {
          basicInfo: {
            nationalityKeys: filters.basicInfo.nationality?.key ? [
              filters.basicInfo.nationality.key
            ] : [],
            residency: filters.basicInfo.residency?.key ? { cityKeys: filters.basicInfo.city.length ? filters.basicInfo.city.map((c: any) => (c.key)) : null, countryKey: filters.basicInfo.residency?.key }
              : null,
            ageGroupKeys: filters.basicInfo.age_group ? filters.basicInfo.age_group.map((c: any) => c.key)

              : [],
            speakingLanguageKeys: filters.basicInfo.speaking_language ? filters.basicInfo.speaking_language.map((k: any) => k.key) : [],
            genderKeys: filters.basicInfo.gender ?
              filters.basicInfo.gender.map((c: any) => c.key)
              : [],
            maritalStatusKeys: filters.basicInfo.marital_status ? [
              filters.basicInfo.marital_status.key
            ] : [],
            haschildern: typeof filters.basicInfo.children?.key == 'boolean' ? filters.basicInfo.children.key : null,
            hasPets: typeof filters.basicInfo.pets?.key == 'boolean' ? filters.basicInfo.pets.key : null
          },
          metaData: {
            nicheKeys: filters.basicInfo.niches ?
              filters.basicInfo.niches.map((c: any) => c.key)
              : [],
            commitementLevelKeys: filters.badges.commitment_level ?
              filters.badges.commitment_level.map((k: any) => k.key)
              : [],
            hasMawthok: typeof filters.badges.mawthok?.key == 'boolean' ? filters.badges.mawthok.key : null,
            allowInsightSharing: typeof filters.badges.insights_sharing?.key == 'boolean' ? filters.badges.insights_sharing.key : null,
            tagKeys: filters.tags?.tags?.map((c: any) => c.key),
            industryKeys: filters.suitable_industries ?
              filters.suitable_industries.map((c: any) => c.key)
              : [],
            excludeHasSafetyFlags: typeof filters.tags.exclude_safety_flags == 'boolean' ? filters.tags.exclude_safety_flags : null
          },
          socialPlatform: {
            socialPlatformKey: filters.platform.platform.key,
            isVerified: filters.platform.filters_show_verfied_only ? true : null,
            socialPlatformServiceKeys: filters.platform.service?.length ? filters.platform.service.map((c: any) => c.key) : [],
            servicePrice: filters.platform.service_price[0] || filters.platform.service_price[1] ? {
              from: filters.platform.service_price[0] ? filters.platform.service_price[0] : 0,
              to: filters.platform.service_price[1] ? filters.platform.service_price[1] : 0
            } : null,
            influencerTypeKeys: filters.platform.inf_types?.length ? filters.platform.inf_types.map((c: any) => c.key) : [],
            audienceSegmentationFilters: {
              countryKeys: filters.platform.aud_country ? [
                filters.platform.aud_country.key
              ] : [],
              ageGroupKeys: filters.platform.aud_age_group ? [
                filters.platform.aud_age_group.key
              ] : [],
              genderKeys: filters.platform.aud_gender ? [
                filters.platform.aud_gender.key
              ] : [],
              otherSegmentKeys: filters.platform.other_segments ? filters.platform.other_segments.map((c: any) => c.key) : []
            }
          },
          Brands: {
            isExcluded: typeof filters.brands.exclude_selected_brands == 'boolean' ? filters.brands.exclude_selected_brands : null,
            brandIds: filters.brands.signed_exclusive_for ? filters.brands.signed_exclusive_for.map((c: any) => c.key) : []
          }
        },
        pagination: {
          pageSize: 1,
          page: 1
        }
      }, { signal: generatePagesAbortController.current.signal })
      if (res.data.totalRecords > 0) {
        const pages = Math.ceil(res.data.totalRecords / 12)
        const pagesArr = Array.from({ length: pages }, (_, i) => i + 1);
        setInfluencersCount(res.data.totalRecords)
        for (let i = pagesArr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [pagesArr[i], pagesArr[j]] = [pagesArr[j], pagesArr[i]];
        }
        setGeneratingPages(false)
        console.log(pagesArr, "pagesArr")
        return pagesArr;
      } else {
        setInfluencerListLoading(false)
        setGeneratingPages(false)

        return []
      }

    } catch (e: any) {
      if (e.name === "CanceledError") {
        console.log('Generate Pages request canceled');
      } else {
        openNotification();
      }
    } finally {
      generatePagesAbortController.current = null;
    }
  }

  return (

    <div
      onScroll={handleScroll}
      id="scrollableContainer"
      className={styles.scrollableContainer}
    >
      {error && error !== "" && (
        <Alert
          className={styles.genericError}
          message={t(error)}
          type="danger"
          icon={<HiOutlineXCircle />}
        />
      )}
      {/* <div className={styles.influencer_wizard_heading + " " + "_lr-hide"}>
        <h2>{t("step1_heading")}</h2>
        <h3>{t("step1_subtitle")}</h3>
      </div> */}
      <ListingFilters
        setGeneratingPages={setGeneratingPages}
        setInfluencerListLoading={setInfluencerListLoading}
        setInfluencersList={setInfluencersList}
        setInfluencersCount={setInfluencersCount}
        pagesState={pagesState}
        generatePages={generatePages}
        localFilters={localFilters}
        setLocalFilters={setLocalFilters}
        getSelectedFilters={getSelectedFilters}
        stopAdditionalRequests={stopAdditionalRequests}
        setStopAdditionalRequests={setStopAdditionalRequests}
        setOpened={setOpened}
        getInfluencersList={getInfluencersList} setFilters={setFilters} filters={filters}
        influencerListLoading={influencerListLoading}
      />


      <div className="container-fluid pt-32">
        {/* <Row
          justify="start"
          align="stretch"
          gutter={24}
          className={styles.container_row}
        > */}

        {/* </Row> */}
        <Row
          justify="start"
          align="stretch"
          gutter={24}
          className={styles.container_row}
        >

          {(state.loading || !state.lookups) &&
            Array(12)
              .fill(null)
              .map((item, index) => (
                <Col lg={6} xl={6} xxl={6} xs={12} key={index} className="_lr-hide" data-private>
                  <InfluenceraignCardSkeleton />
                </Col>
              ))}
          {!state.loading &&
            state.lookups &&
            !influencerListLoading &&
            influencersCount === 0 &&
            hasFilter === false ? (
            <>
              <div className={styles.influencer_listing_header + " " + "_lr-hide"} data-private>
                <p className="body">{`${influencersCount ? influencersCount : 0
                  } ${t("matched_influencers")}`}</p>
              </div>
              <div
                className={`${styles.empty_state_no_data} ${styles.no_data_height} _lr-hide`}
              >
                <img src={nodata} alt="no data" />
                <div className={styles.empty_state_text}>
                  <h3>{t("no_results_found")}</h3>
                  <p className="title">{t("no_results_subtitle")}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <Col lg={24} xl={24} xxl={24} xs={24} className="p_r_0">
                <div className={styles.influencer_listing_header + " " + "_lr-hide"} data-private>
                  <p className="body">{`${influencersCount ? influencersCount : 0
                    } ${t("matched_influencers")}`}</p>
                </div>
                {hasFilter &&
                  !influencerListLoading &&
                  influencersList.length === 0 ? (
                  <div className={styles.empty_state_no_data + " " + "_lr-hide"}>
                    <img src={noresults} alt="no data" />
                    <div className={styles.empty_state_text}>
                      <h3>{t("no_results_found")}</h3>
                      <p className="title">{t("try_different_search")}</p>
                    </div>
                  </div>
                ) : (
                  <Row justify="start" align="stretch" gutter={[24, 24]}>
                    {!generatingPages && influencersList?.length > 0 &&
                      influencersList.map((item, index) => (
                        <Col lg={6} xl={6} xxl={6} xs={12} key={index}>
                          <InfluencerListingCard
                            setDetailsLoading={setLoading}
                            openDrawer={() => setOpen(true)}
                            openInfDetails={showDrawer}
                            selectedInfluencer={
                              props.setSelectedInfluencers.length > 0 &&
                                props.setSelectedInfluencers.findIndex(
                                  (inf: any) => inf.id === item.basicInfo.id && inf.selected
                                ) > -1
                                ? true
                                : false
                            }
                            recommendedInfluencer={
                              props.setSelectedInfluencers.length > 0 &&
                                props.setSelectedInfluencers.findIndex(
                                  (inf: any) => inf.id === item.basicInfo.id && inf.recommended
                                ) > -1
                                ? true
                                : false
                            }
                            handleToggleSelectInfluencer={
                              handleToggleSelectInfluencer
                            }
                            handleToggleRecommendInfluencer={
                              handleToggleRecommendInfluencer
                            }
                            data={item}
                            selectedInfs={selectedInfluencers}
                            setInfs={setInfluencersList}
                            setSelectedInfs={props.setSelectedInfluencersFn}

                          />
                        </Col>
                      ))}
                    {influencerListLoading &&
                      Array(8)
                        .fill(null)
                        .map((item, index) => (
                          <Col lg={6} xl={6} xxl={6} xs={12} key={index} className="_lr-hide" data-private>
                            <InfluenceraignCardSkeleton />
                          </Col>
                        ))}
                  </Row>
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
      {(influencerDetails || loading) && (
        <InfluencerDetails
          container=".fullscreen-modal"
          influencerDetails={influencerDetails}
          onClose={onClose}
          open={open}
          loading={loading}
        />
      )}
      {opened && <InfluencerFilters
        setGeneratingPages={setGeneratingPages}
        setInfluencerListLoading={setInfluencerListLoading}
        setInfluencersList={setInfluencersList}
        setInfluencersCount={setInfluencersCount}
        pagesState={pagesState}
        generatePages={generatePages}
        localFilters={localFilters}
        setLocalFilters={setLocalFilters}
        stopAdditionalRequests={stopAdditionalRequests}
        setStopAdditionalRequests={setStopAdditionalRequests}
        opened={opened} setOpened={setOpened} getInfluencersList={getInfluencersList} setFilters={setFilters} filters={filters} />}
    </div>

  );
};
export default AssigneInfluencerList;
