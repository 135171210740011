import PlatformServices from "./PlatformServices/Index";
import styles from "./index.module.scss";
import {
  HiBolt,
  HiCheck,
  HiCheckBadge,
  HiMiniMinusCircle,
  HiOutlineBolt,
  HiOutlineCheckCircle,
  HiOutlinePencilSquare,
  HiOutlineTrash,
  HiOutlineXCircle,
  HiPlus,
  HiStar,
  HiXMark,
} from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import imgPlaceholder from "../../assets/imgs/influencer_placeholder.svg";
import { useContext, useEffect, useState } from "react";
import EditAssignedInfluencer from "../EditAssignedInfluencer/Index";
import { getCampaignAssignedInfluencerDetails } from "../../Context/Actions/campaign";
import { influencerMappedData } from "../../Utils/helpers";
import { AuthStateContext } from "../../Context/MainContext";
import { Button, Modal, notification } from "antd";
import { dir } from "i18next";
import { deleteInfluencer, getInfluencerDetails } from "../../Context/Actions/influencers";
import { HiShieldCheck } from "react-icons/hi2";
import InfluencerDetails from "../InfluencerDetails";
import CampaignCardSkeleton from "../campaign-card-skeleton/campaign-card-skeleton";

const InfluencerServicesCard = ({
  updateCampaignAssignedInfluencersList,
  setInfluencerData,
  campaignId,
  getCampaignAssignedInfluencersList,
  lisitingAssigned = false,
  state,
  influencer,
  selectedInfs,
  setSelectedInfs,
  setInfluencerMappedObj,
  getChanged,
  hasInfData,
  showSelect,
  isSelected,
  loadingServices,
  setDetails
}: {
  updateCampaignAssignedInfluencersList?: any;
  setInfluencerData?: any;
  lisitingAssigned?: boolean;
  state: any;
  influencer: any;
  selectedInfs: any;
  setSelectedInfs: any;
  getCampaignAssignedInfluencersList?: () => void;
  campaignId?: string;
  setInfluencerMappedObj?: (data: any) => void;
  getChanged?: (data: boolean) => void;
  hasInfData?: boolean;
  showSelect?: boolean;
  isSelected?: boolean;
  loadingServices?: boolean
  setDetails?: any
}) => {
  const { t } = useTranslation();
  const [influencerDetailsObj, setInfluencerDetailsObj] = useState<any>(null);

  const [influencerLoading, setInfluencerLoading] = useState<boolean>(false);
  const [detailsLoading, setDetailsLoading] = useState<boolean>(false);

  const [showEditAssignedInfluencer, setShowEditAssignedInfluencer] =
    useState<boolean>(false);

  //details drawer

  const [open, setOpen] = useState(false);
  const [influencerDetails, setInfluencerDetails] = useState<any>(null);
  const showDrawer = async (e: any) => {
    if (e.target.type !== "checkbox" && state !== "editInfluencer") {
      setOpen(true);
      await getCampaignInfluencerDetails(true);
    }
  };

  const onClose = () => {
    setOpen(false);
    setInfluencerDetails(null);
  };

  //details drawer

  const context = useContext(AuthStateContext);

  const handleImgError = (e: any) => {
    e.target.src = imgPlaceholder;
  };
  const onSuccess = () => {
    setShowEditAssignedInfluencer(false);
  };

  useEffect(() => {
    setInfluencerMappedObj && setInfluencerMappedObj(influencer);
    setInfluencerDetailsObj(influencer);
    if (setDetails) {
      setDetails(influencer)
    }
  }, [influencer]);
  const getCampaignInfluencerDetails = async (openDetails: boolean = false) => {
    !openDetails ? setInfluencerLoading(true) : setDetailsLoading(true);
    try {

      const res = openDetails ? await getInfluencerDetails(
        influencer?.id,
      ) : await getCampaignAssignedInfluencerDetails(
        influencer?.id,
        campaignId!
      );
      if (!res.error) {
        influencerMappedData(context.state, res)
          .then((mappedObj: any) => {
            setInfluencerDetailsObj({ ...mappedObj });
          })
          .then((res) => {
            if (!openDetails) {
              setShowEditAssignedInfluencer(true);
            }
          });
      } else {
        openErrorNotification();
        setInfluencerLoading(false);
        setDetailsLoading(false);
        return { error: true };
      }
    } catch (err) {
      openErrorNotification();
      setInfluencerLoading(false);
      setDetailsLoading(false);
      return { error: true };
    }
    setInfluencerLoading(false);
    setDetailsLoading(false);
  };
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("success_label"),
      description: t("influencers_removed"),
      icon: <HiOutlineCheckCircle className="success-text" />,
      className: "no-wrap",
    });
  };
  const openErrorNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("error_label"),
      description: t("something_went_wrong"),
      icon: <HiOutlineXCircle className="danger-text" />,
      className: "no-wrap",
    });
  };
  const deleteInf = async () => {
    setLoadingDelete(true);
    const res = await deleteInfluencer(campaignId as string, influencer.id);
    setLoadingDelete(false);

    if (!res.error) {
      setDeleteModalOpen(false);
      openNotification();
      if (getCampaignAssignedInfluencersList) {
        getCampaignAssignedInfluencersList();
      }
    } else {
      setDeleteModalOpen(false);
      openErrorNotification();
    }
  };
  const setSocialPlatform = (platform: any) => {
    let influencerUpdated = influencer;
    influencerUpdated.influencerSocialPlatforms.map((p: any) => {
      if ((platform.key = p.key)) {
        p = platform;
      }
    });
    setInfluencerData(influencerUpdated);
  };

  const markAsRecommended = (e: any, v: any) => {
    setInfluencerDetailsObj((prev: any) => ({ ...prev, recommended: v }))
    if (setDetails) {
      setDetails((prev: any) => ({ ...prev, recommended: v }))
    }
    getChanged!(true)
  }


  return (
    <>
      <div
        className={`${styles.inf} ${state == "editInfluencer" ? styles.editInfluencerWrapper : ""
          }  ${showSelect && !(influencer.selected
            || isSelected) ? `${styles.notselected} cardNotSelected` : ''}`}
      >
        {showSelect ? <div className={styles.influencer_card_custom_check_container}>
          <label className={styles.influencer_card_custom_check}>
            <input
              type="checkbox"
              checked={
                influencer.selected
                || isSelected
              }
              onChange={(e) => setSelectedInfs((prevSelected: any) =>
                prevSelected.map((inf: any) => {
                  if (inf.id == influencer.basicInfo.id) {
                    return { ...inf, selected: e.target.checked }
                  }
                  return inf;
                })
              )}
            />
            <span className={styles.checkmark}>
              <HiCheck className={styles.checkIcon} />
              <HiPlus className={styles.addIcon} />
            </span>
          </label>
        </div> : ""}
        <div className={styles.infoContainer}>
          <div className={styles.imgContainer}>
            <img
              onClick={(e: React.MouseEvent<HTMLDivElement>) => showDrawer(e)}
              src={influencer.avatarUrl}
              alt=""
              onError={handleImgError}
            />
            {/* <div><HiStar /><p className='body'>{influencer.influencerQualityKey.name}</p></div> */}
          </div>
          {state == "actions" ? (
            <div className={styles.actions}>
              <button
                disabled={influencerLoading}
                onClick={() => getCampaignInfluencerDetails()}
                className={`${styles.edit} ${influencerLoading ? "loading" : ""
                  }`}
              >
                {influencerLoading ? (
                  <span className={`icon-loader spin ${styles.spinner}`}></span>
                ) : (
                  <HiOutlinePencilSquare />
                )}
              </button>
              <button
                onClick={() => setDeleteModalOpen(true)}
                className={styles.delete}
              >
                <HiOutlineTrash />
              </button>
            </div>
          ) : (
            ""
          )}
          <div className={styles.info}>
            <p
              onClick={(e: React.MouseEvent<HTMLDivElement>) => showDrawer(e)}
              className={`title ${styles.infName}`}
            >
              {influencer.name}
              {/* {influencer.isFeatured ? <HiShieldCheck /> : ""} */}
              {(influencerDetailsObj?.recommended || influencer?.recommended) && (state == "actions" || state == "edit" || state == "view") ? <span className={styles.recommended}><HiBolt className={styles.bolt} />
                <span className={styles.txt}>{t('recommended')}</span></span> : ""}
            </p>
            {influencer.influencerNicheKeys &&

              <p className={`body ${styles.niches} _lr-hide`} data-private>
                {influencer.influencerNicheKeys
                  .map((n: any) => n.name)
                  .join(", ")}
              </p>
            }
            {state == "editInfluencer" && !influencerDetailsObj?.recommended ? <div onClick={(e) => markAsRecommended(e, true)} className={styles.markAsRecommended}>
              <HiOutlineBolt />
              <button>{t("markAsRecommended")}</button>
            </div> : ""}
            {state == "editInfluencer" && influencerDetailsObj?.recommended ? <div className={styles.markAsNotRecommended} onClick={(e) => markAsRecommended(e, false)}>
              <HiBolt className={styles.bolt} />
              <HiMiniMinusCircle className={styles.minus} />
              <button ><span className={styles.showOnHover}>{t('not_recommended')} </span> <span className={styles.hideOnHover}>{t('recommended')}</span></button>
            </div> : ""}
            {/* {influencer.influencerBrandSafetyFlagKeys?.length &&
              state !== "actions" ? (
              <div className={styles.badges}>
                {influencer.influencerBrandSafetyFlagKeys?.map((b: any) => (
                  <div className={`${styles.badge}`}>{b.name}</div>
                ))}
              </div>
            ) : (
              ""
            )} */}
          </div>

        </div>
        <div className={styles.spacer + " " + "_lr-hide"} data-private>
          <span>{t("platforms_services")}</span>
          <div></div>
        </div>
        <div className={styles.services + ' ' + 'services'}>
          {state == "edit"
            ? influencer.influencerSocialPlatforms.map((sp: any) => {
              return sp.services?.length ? (
                <PlatformServices
                  state={state}
                  selectedInfs={selectedInfs}
                  key={sp.key}
                  setSelectedInfs={setSelectedInfs}
                  socialPlatform={sp}
                  infId={influencer.id}
                />
              ) : loadingServices ? (
                <div className={styles.platformLoading}>
                  <div className={styles.platformContainer}>
                    <div className={'shimmer' + ' ' + styles.check}>
                    </div>
                    <div className={'shimmer' + ' ' + styles.platform}></div>
                  </div>
                  <div className={styles.services}>
                    <div className="shimmer"></div>
                    <div className="shimmer"></div>
                    <div className="shimmer"></div>
                  </div>
                </div>
              ) : ""
            })
            : ""}
          {state == "editInfluencer"
            ? influencer.influencerSocialPlatforms.map(
              (sp: any, index: number) => {
                return sp.services?.length ? (
                  <PlatformServices
                    setSocialPlatform={setSocialPlatform}
                    getChanged={getChanged}
                    state={state}
                    selectedInfs={selectedInfs}
                    key={sp.key + index}
                    setSelectedInfs={setSelectedInfs}
                    socialPlatform={sp}
                    infId={influencer.id}
                  />
                ) : (
                  ""
                );
              }
            )
            : ""}
          {state == "view"
            ? influencer.influencerSocialPlatforms
              .filter((sp: any) => sp.services.some((s: any) => s.selected))
              .map((sp: any) => (
                <PlatformServices
                  state={state}
                  selectedInfs={selectedInfs}
                  key={sp.key}
                  setSelectedInfs={setSelectedInfs}
                  socialPlatform={sp}
                  infId={influencer.id}
                />
              ))
            : ""}
          {state == "actions"
            ? influencer.influencerSocialPlatforms.map((sp: any) => (
              <PlatformServices
                updateCampaignAssignedInfluencersList={
                  updateCampaignAssignedInfluencersList
                }
                lisitingAssigned={lisitingAssigned}
                state={state}
                selectedInfs={null}
                key={sp.key}
                setSelectedInfs={null}
                socialPlatform={sp}
                infId={influencer.id}
              />
            ))
            : ""}
        </div>
      </div>
      {showEditAssignedInfluencer && (
        <EditAssignedInfluencer
          modalOpen={showEditAssignedInfluencer}
          influencerData={influencerDetailsObj}
          setModalOpen={setShowEditAssignedInfluencer}
          onSuccess={onSuccess}
          getInfluencerList={getCampaignAssignedInfluencersList}
          campaignId={campaignId || ""}
        />
      )}
      <Modal
        closeIcon={<HiXMark height={24} width={24} />}
        centered
        onCancel={() => setDeleteModalOpen(false)}
        className="alert_modal"
        title={`${t("remove")} ${influencer.name}${t("?")}`}
        open={deleteModalOpen}
        footer={
          <div className={styles.alertModalFooter}>
            <Button
              onClick={() => setDeleteModalOpen(false)}
              className="lavad-btn lavad-btn-md lavad-btn-outline"
              key="back"
            >
              {t("keep")}
            </Button>
            <Button
              className={`lavad-btn lavad-btn-md lavad-btn-danger-primary ${styles.deleteBtn}`}
              disabled={loadingDelete}
              key="submit"
              type="primary"
              onClick={deleteInf}
            >
              {loadingDelete ? (
                <span className="icon-loader spin"></span>
              ) : (
                t("remove")
              )}
            </Button>
          </div>
        }
      >
        <p className="body">{t("remove_inf_confirmation")}</p>
      </Modal>
      {(influencerDetailsObj ||
        influencer) && open && (
          <InfluencerDetails
            container="body"
            influencerDetails={influencerDetailsObj}
            onClose={onClose}
            open={open}
            loading={detailsLoading}
          />
        )}
    </>
  );
};

export default InfluencerServicesCard;
