import { HiOutlineChartBar, HiOutlinePresentationChartBar } from "react-icons/hi2";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { HiOutlineBadgeCheck } from "react-icons/hi";
import { useEffect } from "react";
import HighCommited from "../../../assets/imgs/commited-high.svg"
import MidCommited from "../../../assets/imgs/commited-mid.svg"
import LowCommited from "../../../assets/imgs/commited-low.svg"
import Mawthooq from "../../../assets/imgs/Mawthooq.svg"
import Insights from "../../../assets/imgs/insights.svg"




const InfluencerBadges = ({ details }: any) => {
    const {
        t
    } = useTranslation();
    const getCommitStatus = (status: any) => {
        if (status === "High") {
            return { img: <img src={HighCommited} alt="" />, status, text: t('highly_commited') }
        }
        else if (status === "Low") {
            return { img: <img src={LowCommited} alt="" />, status, text: t('low_commited') }
        }
        else if (status === "Moderate") {
            return { img: <img src={MidCommited} alt="" />, status, text: t('moderate_commited') }
        }
        else {
            return { img: '', status: "" }
        }
    }
    return <ul className={styles.influencer_badges + " " + "_lr-hide"} data-private>
        {details?.metaData?.commitmentLevel?.key ? <li className={styles.item}>
            <div className={styles.commited + ' ' + styles[getCommitStatus(details?.metaData?.commitmentLevel?.key).status]}>
                {getCommitStatus(details?.metaData?.commitmentLevel?.key).img}
                <span>{getCommitStatus(details?.metaData?.commitmentLevel?.key).text}</span>
            </div>
        </li> : ""}
        {details?.metaData?.hasMawthok ? <li>
            <div className={styles.mawthooq}>
                <img src={Mawthooq} alt="" />
                <span>{t('mawthooq')}</span>
            </div>
        </li> : ""}
        {details?.metaData?.allowInsightsSharing ? <li>
            <div className={styles.insights}>
                <img src={Insights} alt="" />
                <span>{t('shares_insights')}</span>

            </div>
        </li> : ""}

    </ul>
};

export default InfluencerBadges;
