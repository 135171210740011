import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthStateContext } from "../../../Context/MainContext";

import CampaignCard from "../../../Components/campaign-card/campaign-card";
import CampaignCardSkeleton from "../../../Components/campaign-card-skeleton/campaign-card-skeleton";
import { Button, Col, ConfigProvider, Input, Row, Skeleton, Table, Tooltip, notification } from "antd";
// import CreateCampaign from "../../../Components/CreateCampaign/Index";
import {
  CampaginListingItem,
  CampaignFilter,
  getAllCampaigns,
} from "../../../Context/Actions/campaign";
import styles from "./CampaginListing.module.scss";
import { HiOutlinePlus, HiOutlineXCircle } from "react-icons/hi";
import { Title } from "../../../Components/title-header/title-header";
import CreateCampaignFilters from "../../../Components/CreateCampaignFilters";
import i18n from "../../../Utils/i18n/i18n";
import { auth } from "../../../Utils/firebase";

import nodata from "../../../assets/imgs/nodata.svg";
import noresults from "../../../assets/imgs/noresults.svg";
import NewCreateCampaign from "../../../Components/NewCreateCampaign";
import CreateCampaign from "../../../Components/CreateCampaign/Index";
import CampaignFilters from "../../../Components/CampaignFilters";
import CampaignListingCard from "../../../Components/CampaignListingCard";
import { HiOutlineAdjustmentsHorizontal, HiOutlineArrowPathRoundedSquare, HiOutlineCheckCircle, HiOutlineChevronLeft, HiOutlineChevronRight, HiOutlineClock, HiOutlineInformationCircle, HiOutlineListBullet, HiOutlineMagnifyingGlass, HiOutlinePencilSquare, HiOutlineRocketLaunch, HiOutlineStopCircle, HiOutlineTrophy } from "react-icons/hi2";
import ViewGrid from "../../../assets/imgs/ViewGridOutline.svg"
import { ColumnsType } from "antd/es/table";
import { AnyObject } from "antd/es/_util/type";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { getInitials, moneyFormatter } from "../../../Utils/helpers";
import CampaignsEmptyState from '../../../assets/imgs/client_campaigns_empty.svg'



function CampaginListing() {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const { state, state: { lookups }, dispatch } = useContext(AuthStateContext);
  const [showCreate, setShowCreate] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [campaignListLoading, setCampaignListLoading] = useState(false);
  const [campaignsParams, setCampaignParams] = useState<CampaignFilter>({
    page: 1,
    pageSize: 12,
  });
  const scrollableContainer = useRef(null);
  const [layout, setLayout] = useState<"list" | "table">("list")
  const [campaignsList, setCampaignsList] = useState<
    Array<CampaginListingItem> | any
  >([
  ]);
  const [campaignsCount, setCampaignsCount] = useState(null);
  const [resetFilters, setResetFilters] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);


  const [pagination, setPagination] = useState({ page: 1, pageSize: 12, total: 0 })

  const [campaignSortDirections, setCampaignSortDirections] = useState<any>({ name: '', direction: '' })

  const [showFilters, setShowFilters] = useState(false)

  const [searchTerm, setSearchTerm] = useState("")

  const [reset, setReset] = useState(false)

  const navigate = useNavigate();

  const tableRef = useRef(null)

  const [filters, setFilters] = useState<any>({
    searchTerm: '',
    status: [],
    brand: [],
    client: [],
    campaignLead: [],
    teamMembers: [],
    creationDate: null,
  });

  const [localSelectedDate, setLocalSelectedDate] = useState([])



  const getCampaignsList = async (
    params: CampaignFilter,
    fromPagination: boolean = false,
    sortDirections: any
  ) => {
    setCampaignListLoading(true);
    if (!fromPagination) {
      setCampaignsList(layout == "table" ? Array.from({ length: 12 }, () => ({ load: 'load' })) : []
      );
      setCampaignsCount(null);
    }
    try {
      await getAllCampaigns(params, sortDirections).then((res) => {
        if (res.error) {
          notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: t("error_label"),
            description: t("something_went_wrong"),
            icon: <HiOutlineXCircle className="danger-text" />,
            className: "no-wrap",
          });
        } else {
          setCampaignListLoading(false);
          if (res.data.length < params.pageSize && fromPagination) {
            setIsEnded(true);
          } else {
            setIsEnded(false);
          }
          fromPagination
            ? setCampaignsList([...campaignsList, ...res.data])
            : setCampaignsList(res.data);


          setPagination(prev => ({ ...prev, total: res.totalRecords }))

          setCampaignsCount(res.totalRecords);
        }
      });
    }
    catch (error) {
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("error_label"),
        description: t("something_went_wrong"),
        icon: <HiOutlineXCircle className="danger-text" />,
        className: "no-wrap",
      });
      if (layout == "list") {
        setCampaignListLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      campaignsParams.page = 1;
      getCampaignsList(campaignsParams, false, layout == "table" ? campaignSortDirections : { name: '', direction: '' });
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      // Clean up the event listeners and the auth state change subscription
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [campaignsParams, i18n, auth]);
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    let tempParams = campaignsParams;
    const container = event.currentTarget
    const scrollBuffer = 300;
    if (
      Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < scrollBuffer &&
      !campaignListLoading &&
      !isEnded
    ) {
      tempParams.page++;
      setCampaignParams(tempParams);
      getCampaignsList(tempParams, true, layout == "table" ? campaignSortDirections : { name: '', direction: '' });
    }
  };

  useEffect(() => {
    if (layout == "list") {
      const { client, campaignLead, teamMembers, brand, creationDate, status, searchTerm } = filters;
      if (scrollableContainer.current) {
        (scrollableContainer.current as HTMLDivElement).scrollTo({ top: 0, behavior: 'smooth' })
      }
      setCampaignParams({
        page: 1,
        pageSize: 12,
        brandId: brand,
        campaignLeadIds: campaignLead,
        clientIds: client,
        campaignAssignedMemberIds: teamMembers,
        campaignStatus: status,
        to: creationDate ? creationDate[1] : 0,
        from: creationDate ? creationDate[0] : 0,
        keyword: searchTerm,
      });
      if (
        campaignLead.length > 0 ||
        teamMembers.length > 0 ||
        client.length > 0 ||
        brand.length > 0 ||
        status.length > 0 ||
        creationDate ||
        searchTerm !== ""
      ) {
        setHasFilter(true);
        getCampaignsList({
          page: 1,
          pageSize: 12,
          brandId: brand,
          campaignLeadIds: campaignLead,
          clientIds: client,
          campaignAssignedMemberIds: teamMembers,
          campaignStatus: status,
          to: creationDate ? creationDate[1] : 0,
          from: creationDate ? creationDate[0] : 0,
          keyword: searchTerm,
        }, false, { name: '', direction: '' });
      } else {
        setHasFilter(false)
        getCampaignsList({ page: 1, pageSize: 12 }, false, { name: '', direction: '' });
      }
    }
  }, [filters, layout])

  useEffect(() => {
    if (layout === "table") {
      setReset(true)
      setPagination({ page: 1, pageSize: 12, total: 0 })
    }
  }, [filters, layout])

  useEffect(() => {
    if (layout === "table") {
      const { client, campaignLead, teamMembers, brand, creationDate, status, searchTerm } = filters;

      const filtersApplied =
        campaignLead.length > 0 ||
        teamMembers.length > 0 ||
        client.length > 0 ||
        brand.length > 0 ||
        status.length > 0 ||
        creationDate ||
        searchTerm !== "";

      setHasFilter(filtersApplied);

      getCampaignsList({
        page: reset ? 1 : pagination.page,
        pageSize: pagination.pageSize,
        brandId: brand,
        campaignLeadIds: campaignLead,
        clientIds: client,
        campaignAssignedMemberIds: teamMembers,
        campaignStatus: status,
        to: creationDate ? creationDate[1] : 0,
        from: creationDate ? creationDate[0] : 0,
        keyword: searchTerm,
      }, false, campaignSortDirections);

      setReset(false);
    }
  }, [campaignSortDirections, layout, reset, i18n.language]);





  const onSuccess = () => {
    setResetFilters(true);
    setCampaignParams({ page: 1, pageSize: 12 });
    getCampaignsList({ page: 1, pageSize: 12 }, false, {});
  };

  const getFiltersCount = () => {
    const withoutSearch = { ...filters }
    delete withoutSearch.searchTerm
    return Object.entries(withoutSearch)
      .filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0 || value.some(subValue => !!subValue);
        } else {
          return !!value;
        }
      })
      .map(([key]) => key).length
  }


  const returnStatusClass = (status: string) => {
    let labelClass = "";
    if (status === "Cancelled") {
      labelClass = "label-danger";
    } else if (status === "Accepted" || status === "Completed") {
      labelClass = "label-success";
    } else if (status === "Planning") {
      labelClass = "label-warning";
    } else if (status === "Waiting") {
      labelClass = "label-warning-700";
    } else if (status === "New") {
      labelClass = "label-info";
    } else if (status === "Running") {
      labelClass = "label-info-700";
    } else if (status === "Paused") {
      labelClass = "label-neutral";
    } else {
      labelClass = "";
    }
    return labelClass
  }
  const returnStatusIcon = (status: any) => {
    if (status.toLowerCase() == "new") {
      return <HiOutlineRocketLaunch width={18} height={18} />;
    } else if (status.toLowerCase() == "planning") {
      return <HiOutlinePencilSquare width={18} height={18} />;
    } else if (status.toLowerCase() == "accepted") {
      return <HiOutlineCheckCircle width={18} height={18} />;
    } else if (status.toLowerCase() == "waiting") {
      return <HiOutlineClock width={18} height={18} />;
    } else if (status.toLowerCase() == "running") {
      return <HiOutlineArrowPathRoundedSquare width={18} height={18} />;
    } else if (status.toLowerCase() == "cancelled") {
      return <HiOutlineXCircle width={18} height={18} />;
    } else if (status.toLowerCase() == "paused") {
      return <HiOutlineStopCircle width={18} height={18} />;
    } else if (status.toLowerCase() == "completed") {
      return <HiOutlineTrophy width={18} height={18} />;
    }
  };

  let campaignColumns: ColumnsType<AnyObject> | undefined = [
    {
      title: t('creationDate'),
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (a: any, _) => {
        return campaignListLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : dayjs(a).locale(i18n.language).format("MMM D, YYYY")
      },
      className: 'fs_12',
      sorter: true,
      fixed: dir() == 'ltr' ? 'left' : 'right',

    },

    {
      title: t('campaignNameId'),
      dataIndex: 'name',
      key: 'campaignName',
      render: (a: any, _: any) => campaignListLoading ? <Skeleton className='campaign-details-skeleton' active paragraph={{ rows: 1 }} /> : a ? <Tooltip title={a}><div className={styles.campaignName}><p><Link to={`/campaign/${_.id}`} className='changeOnHover'>{a}</Link></p> <span>#{_.id}</span></div></Tooltip> : <span className='alignText'>-</span>,
      fixed: dir() == 'ltr' ? 'left' : 'right',


    },
    {
      title: t('brand_details'),
      dataIndex: 'brand',
      key: 'brand',
      sorter: true,
      render: (obj: any) => campaignListLoading ? <Skeleton className='campaign-brand-skeleton' active avatar paragraph={{ rows: 0 }} /> : obj ? <div className={styles.brand}><img src={obj.logo} alt='brand' /> <p>{obj.name}</p></div> : <span className='alignText' > -</span>,
    },
    {
      title: t('client'),
      dataIndex: 'client',
      key: 'client',
      sorter: true,
      render: (a: any) => campaignListLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <p className={styles.client}>{a}</p> : <span className='alignText'>-</span>
    },
    {
      title: t('campaign_lead'),
      dataIndex: 'campaignLead',
      key: 'campaignLead',
      render: (owner: any) => campaignListLoading ? <Skeleton avatar paragraph={{ rows: 0 }} active /> : owner ? <div className={styles.user}>
        <div className={styles.userImg}>{getInitials(owner)}</div>
        <p>{owner}</p>
      </div> : <span className='alignText'>-</span>,
      sorter: true,
    },
    {
      title: t('requested_infs'),
      dataIndex: 'requestedInfs',
      key: 'requestedInfluencersCount',
      sorter: true,
      render: (a: any) => campaignListLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? a : <span className='alignText'>-</span>
    },
    {
      title: t('assigned_infs'),
      dataIndex: 'assignedInfs',
      key: 'assignedInfluencersCount',
      sorter: true,
      render: (a: any) => campaignListLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <span className={styles.assignedInfs}>{a}</span> : <span className={styles.assignedInfs}>0</span>
    },
    {
      title: <p className={styles.totalSelling}><span>{t('cost')}</span> <Tooltip title={t('total_cost_selling_price')}><HiOutlineInformationCircle /></Tooltip></p>,
      dataIndex: 'cost',
      key: 'cost',
      sorter: true,
      render: (a: any) => campaignListLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <p className={styles.sellPrice}>{moneyFormatter(a)} <span>{i18n.language == "en" ? "SAR" : "ر.س"}</span></p> : <span className='alignText'>-</span>
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'campaignStatus',
      sorter: true,
      render: (a: any) => campaignListLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <div className={`${returnStatusClass(a)} ${styles.status}`}>{returnStatusIcon(a)}<span className='fs_12'>{lookups && lookups[i18n.language]?.campaignStatuses?.find(
        (s: any) => s.key.toLowerCase() === a.toLowerCase()
      )?.name}</span></div> : <span className='alignText'>-</span>
    },

  ];

  const renderCampaigns = () => {
    const mappedData = campaignsList?.map((d: any) => {
      const obj = {
        client: d?.clientInfo?.name,
        campaignLead: d?.teamInfo?.campaignLead.name,
        creationDate: d?.auditInfo?.created.date, name: d?.basicInfo?.name, id: d?.basicInfo?.id, brand: { name: d?.brandInfo?.name, logo: d?.brandInfo?.logoUrl }, requestedInfs: d?.basicInfo?.requestedInfluencersCount, assignedInfs: d?.summary?.assignedInfluencersCount, cost: d?.summary?.cost, status: d?.basicInfo?.campaignStatusKey
      }
      return obj

    })
    if (!campaignsList.length && hasFilter) {
      return <div className={styles.emptyStateWithFilters}>
        <img src={nodata} alt="no data" />
        <div className={styles.empty_state_text}>
          <h3>{t("no_results_found")}</h3>
          <p className="title">{t("no_results_subtitle")}</p>
        </div>
      </div>
    }
    return <div className={styles.tableContainer}><ConfigProvider theme={{
      components: {
        Table: {
          borderColor: '#EEE',
          rowHoverBg: '#F5F6FF',
          headerColor: '#1F1F1F',
          headerSplitColor: "transparent",


        },
      },
    }}>{<Table ref={tableRef} className='campaignsTable extra' pagination={{
      hideOnSinglePage: true,
      defaultPageSize: pagination?.pageSize, total: pagination?.total, current: pagination?.page, onChange(page, pageSize) {
        if (page != pagination.page) {
          setPagination((prev: any) => ({ ...prev, page, pageSize }))
        }
      },
    }} scroll={{ x: 1440, scrollToFirstRowOnChange: true }} showSorterTooltip={false} onChange={(p, f, s: any) => {
      setCampaignSortDirections((prev: any) => ({ name: [s.columnKey], direction: s.order ? s.order : "" }));
      // getCampaignsList({ ...filters, page: pagination.page, pageSize: pagination.pageSize }, false, { name: [s.columnKey], direction: s.order ? s.order : "" })

    }} columns={campaignColumns} dataSource={campaignListLoading ? Array.from({ length: 12 }, () => ({ load: 'load' })) : mappedData} />}</ConfigProvider></div>
  }

  const changeLayout = (style: 'table' | 'list') => {
    setCampaignSortDirections({ name: '', direction: '' })
    setLayout(style)
    setPagination({ page: 1, pageSize: 12, total: 0 })
    setShowFilters(false)
    // if (style == 'table') {
    //   getCampaignsList({ ...filters, page: 1, pageSize: 12 }, false, campaignSortDirections)
    // }
    // setFilters({
    //   searchTerm: '',
    //   status: [],
    //   brand: [],
    //   client: [],
    //   campaignLead: [],
    //   teamMembers: [],
    //   creationDate: null,
    // })
  }

  const debounce = <T extends (...args: any[]) => void>(callback: T, delay: number) => {
    let timeoutId: NodeJS.Timeout | undefined;

    return (...args: Parameters<T>) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  const debouncedCall = useCallback(
    debounce((query: string) => {
      if (query.length >= 2) {
        setFilters((state: any) => ({ ...state, searchTerm: query }));
        setCampaignParams((prev: any) => ({ ...prev, page: 1 }));
      } else if (!query.length) {
        setFilters((state: any) => ({ ...state, searchTerm: "" }));
        setCampaignParams((prev: any) => ({ ...prev, page: 1 }));
      }
    }, 1000),
    [] // Empty dependency array ensures this function is only created once
  );


  useEffect(() => {
    const handleScroll = (e: any) => {
      const target = e.currentTarget;
      const eles = document.querySelectorAll('.ant-table-cell-fix-right:nth-of-type(2)')
      const parent = document.querySelector('.ant-table')
      if (target.scrollLeft == 0) {
        parent?.classList.add('ant-table-fixed-column-gapped')
        eles?.forEach(el => el.classList.remove('ant-table-cell-fix-right-last'))
      } else {
        parent?.classList.remove('ant-table-fixed-column-gapped')
        eles?.forEach(el => el.classList.add('ant-table-cell-fix-right-last'))
      }
    };

    const addScrollListener = () => {
      const tableContentElement = document.querySelector('.ant-table-content');
      if (tableContentElement) {
        tableContentElement.addEventListener('scroll', handleScroll);
        return tableContentElement;
      }
    };

    let element = addScrollListener();

    const observer = new MutationObserver(() => {
      if (!element) {
        element = addScrollListener();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <div className={`container-fluid ${styles.cont} ${layout == "list" ? styles.list : ''}`}>
        <Row
          justify="start"
          align="stretch"
          gutter={[24, 24]}
          className={styles.container_row}
        >
          {!campaignListLoading && !hasFilter && lookups && !state.loading &&
            (campaignsList.length === 0) &&
            hasFilter === false ? (
            <div
              className={`${styles.empty_state_no_data} ${styles.no_data_height} ${styles.emptyCampaigns}`}
            >
              <h2 className={`title _lr-hide ${styles.title}`}>{`${t("campaigns")}`}</h2>
              <div>
                <img src={nodata} alt="no data" data-private />
                <div className={styles.empty_state_text + " " + "_lr-hide"}>
                  <h3>{t("no_created_campaigns")}</h3>
                  <p className="title">{t("no_data_campaigns_subtitle")}</p>
                </div>
                <Button
                  onClick={() => setShowCreate(true)}
                  className="lavad-btn btn-icon lavad-btn-lg lavad-btn-primary"
                  icon={<HiOutlinePlus />}
                >
                  {t("create_campaign")}
                </Button>
              </div>
            </div>
          ) : (
            layout == "list" ?
              <>
                <Col lg={18} xl={18} xxl={18} xs={12} className="m_r_0">

                  <div className={styles.campagin_listing_header}>
                    <h2 className={`title _lr-hide ${styles.title}`}>{`${t("campaigns")}`}</h2>
                    <Button
                      onClick={() => setShowCreate(true)}
                      className="lavad-btn btn-icon lavad-btn-lg lavad-btn-primary"
                      icon={<HiOutlinePlus />}
                    >
                      {t("create_campaign")} {campaignListLoading}
                    </Button>
                  </div>
                  <div className={styles.searchHeader}>
                    <div>
                      <Input value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); debouncedCall(e.target.value) }} placeholder={t('search_by_camp_id_name')} prefix={<HiOutlineMagnifyingGlass color='#8E8E8E' />} className={`lavad-input lavad-input-lg ${styles.searchInput}`} />
                      <div className={styles.changeLayout}>
                        <button onClick={() => changeLayout("list")} className={layout == "list" ? styles.active : ""}><HiOutlineListBullet /></button>
                        <button onClick={() => changeLayout("table")} className={layout != "list" ? styles.active : ""}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="ViewGridOutline">
                            <g id="Group 1000002882">
                              <path id="Rectangle 3467600 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M17.5 4.5H7.5C6.11929 4.5 5 5.61929 5 7V17C5 18.3807 6.11929 19.5 7.5 19.5H17.5C18.8807 19.5 20 18.3807 20 17V7C20 5.61929 18.8807 4.5 17.5 4.5ZM7.5 3C5.29086 3 3.5 4.79086 3.5 7V17C3.5 19.2091 5.29086 21 7.5 21H17.5C19.7091 21 21.5 19.2091 21.5 17V7C21.5 4.79086 19.7091 3 17.5 3H7.5Z" />
                              <path id="Rectangle 3467602 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M20 10.5H5V17C5 18.3807 6.11929 19.5 7.5 19.5H17.5C18.8807 19.5 20 18.3807 20 17V10.5ZM3.5 9V17C3.5 19.2091 5.29086 21 7.5 21H17.5C19.7091 21 21.5 19.2091 21.5 17V9H3.5Z" />
                              <path id="Rectangle 3467601 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M17.5 4.5H11V19.5H17.5C18.8807 19.5 20 18.3807 20 17V7C20 5.61929 18.8807 4.5 17.5 4.5ZM9.5 3V21H17.5C19.7091 21 21.5 19.2091 21.5 17V7C21.5 4.79086 19.7091 3 17.5 3H9.5Z" />
                            </g>
                          </g>
                        </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  {hasFilter
                    && lookups && !state.loading &&
                    !campaignListLoading &&
                    campaignsList.length === 0 ? (
                    <div className={styles.empty_state_no_data + " " + "_lr-hide"}>
                      <img src={noresults} alt="no data" />
                      <div className={styles.empty_state_text}>
                        <h3>{t("no_results_found")}</h3>
                        <p className="title">{t("try_different_search")}</p>
                      </div>
                    </div>
                  ) : (
                    <div
                      ref={scrollableContainer}
                      onScroll={handleScroll}
                      id="scrollableContainer"
                      className={styles.scrollableContainer}
                    >
                      <Row className={styles.campaigns} justify="start" align="stretch" gutter={[24, 24]}>
                        {campaignsList?.length > 0 &&
                          campaignsList.map((item: any, index: number) => (
                            <Col lg={24} xl={24} xxl={24} xs={24} key={index}>
                              <CampaignListingCard data={item} />
                            </Col>
                          ))}
                        {campaignListLoading &&
                          Array(campaignsParams.page === 1 ? 12 : 3)
                            .fill(null)
                            .map((item, index) => (
                              <Col lg={24} xl={24} xxl={24} xs={24} key={index}>
                                <CampaignCardSkeleton />
                              </Col>
                            ))}
                      </Row>
                    </div>
                  )}
                </Col>
                <Col lg={6} xl={6} xxl={6} xs={12} className={styles.filtersCont}>

                  <CampaignFilters
                    loading={campaignListLoading}
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                    setResetFilters={setResetFilters}
                    language={i18n.language}
                    localSelectedDate={localSelectedDate}
                    setLocalSelectedDate={setLocalSelectedDate}
                  />

                </Col>
              </> :
              <>
                <Col lg={24} xl={24} xxl={24} xs={24} className="m_r_0">

                  <div className={styles.campagin_listing_header}>
                    <h2 className={`title _lr-hide ${styles.title}`}>{`${t("campaigns")}`}</h2>
                    <Button
                      onClick={() => setShowCreate(true)}
                      className="lavad-btn btn-icon lavad-btn-lg lavad-btn-primary"
                      icon={<HiOutlinePlus />}
                    >
                      {t("create_campaign")} {campaignListLoading}
                    </Button>
                  </div>
                  <div className={styles.searchHeader}>
                    <div>
                      <div className={styles.withFilters}>
                        <Input value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); debouncedCall(e.target.value) }} placeholder={t('search_by_camp_id_name')} prefix={<HiOutlineMagnifyingGlass color='#8E8E8E' />} className={`lavad-input lavad-input-lg ${styles.searchInput}`} />
                        <button onClick={() => setShowFilters(prev => !prev)} className="lavad-btn lavad-btn-primary-outline lavad-btn-lg btn-icon"><HiOutlineAdjustmentsHorizontal />{t("filters")} {!!getFiltersCount() && !showFilters && <span>{getFiltersCount()}</span>}</button>
                      </div>
                      <div className={styles.changeLayout}>
                        <button onClick={() => setLayout("list")} className={layout != "table" ? styles.active : ""}><HiOutlineListBullet /></button>
                        <button onClick={() => setLayout("table")} className={layout == "table" ? styles.active : ""}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="ViewGridOutline">
                            <g id="Group 1000002882">
                              <path id="Rectangle 3467600 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M17.5 4.5H7.5C6.11929 4.5 5 5.61929 5 7V17C5 18.3807 6.11929 19.5 7.5 19.5H17.5C18.8807 19.5 20 18.3807 20 17V7C20 5.61929 18.8807 4.5 17.5 4.5ZM7.5 3C5.29086 3 3.5 4.79086 3.5 7V17C3.5 19.2091 5.29086 21 7.5 21H17.5C19.7091 21 21.5 19.2091 21.5 17V7C21.5 4.79086 19.7091 3 17.5 3H7.5Z" />
                              <path id="Rectangle 3467602 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M20 10.5H5V17C5 18.3807 6.11929 19.5 7.5 19.5H17.5C18.8807 19.5 20 18.3807 20 17V10.5ZM3.5 9V17C3.5 19.2091 5.29086 21 7.5 21H17.5C19.7091 21 21.5 19.2091 21.5 17V9H3.5Z" />
                              <path id="Rectangle 3467601 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M17.5 4.5H11V19.5H17.5C18.8807 19.5 20 18.3807 20 17V7C20 5.61929 18.8807 4.5 17.5 4.5ZM9.5 3V21H17.5C19.7091 21 21.5 19.2091 21.5 17V7C21.5 4.79086 19.7091 3 17.5 3H9.5Z" />
                            </g>
                          </g>
                        </svg>
                        </button>
                      </div>
                    </div>
                    <CampaignFilters
                      showFilters={showFilters}
                      loading={campaignListLoading}
                      style="Tags"
                      filters={filters}
                      setFilters={setFilters}
                      resetFilters={resetFilters}
                      setResetFilters={setResetFilters}
                      language={i18n.language}
                      localSelectedDate={localSelectedDate}
                      setLocalSelectedDate={setLocalSelectedDate}
                    />
                  </div>
                  {hasFilter &&
                    !campaignListLoading &&
                    campaignsList.length === 0 ? (
                    <div className={styles.empty_state_no_data + " " + "_lr-hide"}>
                      <img src={noresults} alt="no data" />
                      <div className={styles.empty_state_text}>
                        <h3>{t("no_results_found")}</h3>
                        <p className="title">{t("try_different_search")}</p>
                      </div>
                    </div>
                  ) : (
                    <div
                    >
                      <Row justify="start" align="stretch">

                        <Col lg={24} xl={24} xxl={24} xs={24} >
                          {renderCampaigns()}
                        </Col>

                      </Row>
                    </div>
                  )}
                </Col>

              </>
          )}
        </Row>
        {showCreate && (
          <NewCreateCampaign
            modalOpen={showCreate}
            setModalOpen={setShowCreate}
            onSuccess={onSuccess}

          />
        )}
      </div >
    </>
  );
}
export default CampaginListing;
