import { Button, Modal, notification } from "antd";
import styles from "./Index.module.scss";
import {
  Dispatch,
  useState,
  SetStateAction,
  useEffect,
  useRef,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi2";
import i18n from "../../Utils/i18n/i18n";
import Alert from "../Alerts";
import InfluencerServicesCard from "../InfluencerServicesCard";
import {
  editCampaignAssignedInfluencerDetails,
  getCampaignAssignedInfluencerDetails,
  getCampaignAssignedInfluencers,
} from "../../Context/Actions/campaign";
import { influencerMappedData } from "../../Utils/helpers";
import { AuthStateContext } from "../../Context/MainContext";
import { dir, t } from "i18next";

const EditAssignedInfluencer = ({
  influencerData,
  modalOpen,
  setModalOpen,
  onSuccess,
  getInfluencerList,
  campaignId,
}: {
  influencerData: any;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  getInfluencerList?: () => void;
  campaignId: string;
}) => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  const { state } = useContext(AuthStateContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [noChanges, setNoChanges] = useState(true);
  const [servicesCount, setServicesCount] = useState(0);
  const scrollable = useRef<any>(null);
  const [influencerMappedObj, setInfluencerMappedObj] = useState<any>(null);
  const [selectedServices, setSelectedServices] = useState<any>(null);
  const resetScrollPosition = () => {
    if (scrollable.current) {
      scrollable.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    resetScrollPosition();
    // getCampaignInfluencerDetails();

    if (document.querySelector("#scrollable")) {
      document.querySelector("#scrollable")!.scrollTop = 0;
    }
    return () => {
      resetScrollPosition();
      if (document.querySelector("#scrollable")) {
        document.querySelector("#scrollable")!.scrollTop = 0;
      }
    };
  }, [modalOpen]);

  const cancel = () => {
    setError("");
    setLoading(false);
    setModalOpen(false);
  };
  // useEffect(() => {
  //   if (noChanges != checkSelectedServices(influencerMappedObj)) {
  //     setNoChanges(checkSelectedServices(influencerMappedObj));
  //   }
  // }, [noChanges]);


  const [details, setDetails] = useState<any>(null)

  const onSubmitButton = async () => {
    const selectedServices =
      influencerMappedObj?.influencerSocialPlatforms.reduce(
        (acc: any, platform: any) => {
          platform.services.forEach((service: any) => {
            if (service.selected) {
              const {
                advertisingProductId: id,
                price,
                currencyKey: currency,
              } = service;
              acc.push({ id, price, currency });
            }
          });
          return acc;
        },
        []
      )
      ;
    console.log(selectedServices, "selectedServices")
    setSelectedServices(selectedServices);
    if (selectedServices?.length > 0) {
      setLoading(true);
      try {
        const res = await editCampaignAssignedInfluencerDetails(
          selectedServices,
          campaignId!,
          influencerData.id,
          details!.recommended
        );
        if (!res.error) {
          openNotification(true);
          setModalOpen(false);
          getInfluencerList!();
        } else {
          openNotification(false);
        }
        setLoading(false);
      } catch (err) {
        openNotification(false);
        setLoading(false);
      }
    }
  };

  const openNotification = (success: boolean) => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: success ? t("success_label") : t("error_label"),
      description: success
        ? t("success_edit_influencer")
        : t("something_went_wrong"),
      icon: success ? (
        <HiOutlineCheckCircle className="success-text" />
      ) : (
        <HiOutlineXCircle className="danger-text" />
      ),
      className: "no-wrap",
    });
  };

  const setInfluencerMappedFun = (data: any) => {
    setInfluencerMappedObj(data);
    setServicesCount(checkSelectedServices(data))
  };
  const checkSelectedServices = (influencerObj: any) => {
    let count = 0;
    if (influencerObj) {
      influencerObj.influencerSocialPlatforms.map((platform: any) => {
        platform.services.map((s: any) => {
          s.selected && count++
        })
      })
    }

    return count; // No selected services found, return false
  };
  const getChangedData = (value: boolean) => {
    setNoChanges(!value);

  };
  useEffect(() => {
    console.log(details, "detaaaails")
  }, [details])

  return (
    <Modal
      open={modalOpen}
      onCancel={cancel}
      className="editInfluencerModal"
      centered
      title={t("edit_influencer_services")}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      footer={() => (
        <>
          <Button
            className="lavad-btn lavad-btn-md lavad-btn-ghost"
            onClick={cancel}
          >
            {t("cancel")}
          </Button>
          <Button
            className={`lavad-btn lavad-btn-md lavad-btn-primary ${loading ? "loading" : ""
              } ${styles.editBtn}`}
            htmlType="submit"
            onClick={onSubmitButton}
            disabled={noChanges || servicesCount == 0}
          >
            {loading ? (
              <span className="icon-loader spin"></span>
            ) : (
              t("save_changes")
            )}

          </Button>
        </>
      )}
    >
      {error && error !== "" && (
        <Alert
          className={styles.genericError}
          message={t(error)}
          type="danger"
          icon={<HiOutlineXCircle />}
        />
      )}
      {influencerData && (
        <InfluencerServicesCard
          state="editInfluencer"
          key={1}
          selectedInfs={null}
          setSelectedInfs={null}
          influencer={influencerData}
          getCampaignAssignedInfluencersList={getInfluencerList}
          setInfluencerMappedObj={setInfluencerMappedFun}
          getChanged={getChangedData}
          setInfluencerData={setInfluencerMappedFun}
          setDetails={setDetails}
        />
      )}
    </Modal>
  );
};

export default EditAssignedInfluencer;
