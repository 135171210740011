import styles from "./index.module.scss";

import { useTranslation } from "react-i18next";


const InfluencerServicesCardShimmer = () => {

  return (
      <div className={`${styles.inf}`}>
        <div className={styles.infoContainer}>
          <div className={styles.imgContainer}>
            <div className={`${styles.infImg} shimmer`}>
            </div>
          </div>
        
          <div className={styles.info}>
            <p className={`shimmer ${styles.infName}`}>
              
            </p>
            <p className={`shimmer ${styles.niches}`}>
              
            </p>
             
          </div>
        </div>
        <div className={styles.spacer}>
          <span className={` shimmer ${styles.platformsTitle}`}></span>
          <div></div>
        </div>
        <div className={styles.services}>
          <div className={styles.socialItemContainer}>
            <div className={` shimmer ${styles.socialPlatform}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
           
          </div>
          <div className={styles.socialItemContainer}>
            <div className={` shimmer ${styles.socialPlatform}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
           
          </div>
          <div className={styles.socialItemContainer}>
            <div className={` shimmer ${styles.socialPlatform}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
           
          </div>
          <div className={styles.socialItemContainer}>
            <div className={` shimmer ${styles.socialPlatform}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
            <div className={` shimmer ${styles.socialPlatformservice}`}></div>
           
          </div>
         
        </div>
      </div>
  );
};

export default InfluencerServicesCardShimmer;
