import { error } from "logrocket";
import api from "../../Utils/axiosInterceptor";

interface LegalName {
  ar: string;
  en: string;
}

interface BasicInfo {
  id?: string;
  name: string;
  legalName: LegalName;
  countryKey: string;
  attachments: {
    url: string;
    fileName: string;
  }[];
  ownerId: number | null;
  vatNumber?: string;
  commercialRegistration?: string;
  agencyFees: number | null;
}

interface Brand {
  id: number;
  clientRelationshipKey: string;
}
export interface ClientListingData {
  auditInfo: {
    created: {
      byApplicationUserId: string;
      date: number;
    };
  };
  basicInfo: {
    id: number;
    name: string;
    legalName: {
      ar: string;
      en: string;
    };
    countryKey: string;
    vatNumber: string;
    agencyFees: number;
    ownerId: string;
    owner: {
      name: string;
      id: string;
    };
  };
  assignedbrands: {
    brand: {
      id: number;
      logoUrl: string;
    };
  }[];
  campaignsSummary: {
    totalCount: number;
    totalAcceptedCount: number;
  };
}

export interface MembersListingData {
  auditInfo: {
    creationDate: number;
    lastActiveDate: number;
  };
  basicInfo: {
    id: string;
    fullName: string;
    email: string;
    roleKey: string;
    statusKey: string;
  };
}

export interface ClientData {
  basicInfo: BasicInfo;
  brands: Brand[];
}

export interface GetClientInterface {
  auditInfo: {
    created: {
      byApplicationUserId: string;
      date: number;
      by: {
        id: string;
        name: string;
      };
    };
  };
  basicInfo: {
    id: number;
    name: string;
    legalName: {
      ar: string;
      en: string;
    };
    countryKey: string;
    vatNumber: string;
    commercialRegistration: string;
    agencyFees: number;
    owner: {
      id: string;
      name: string;
    };
    attachments: string[];
  };
  assignedbrands: {
    brand: {
      id: number;
      name: {
        languageKey: string;
        value: string;
      }[];
    };
    clientRelationshipKey: string;
  }[];
}

export interface ClientBrandItem {
  basicInfo: {
    id: number;
    logoUrl: string;
    name: {
      ar: string;
      en: string;
    };
    websiteUrl: string;
  };
  metaData: {
    typeKey: string;
  };
  clientInfo: {
    relationshipKey: string;
  };
  campaignsSummary: {
    totalCount: number;
    totalAcceptedCount: number;
    totalSellingPrice: number;
  };
}

export interface ClientCampaignItem {
  basicInfo: {
    id: number;
    name: string;
    requestedInfluencersCount: number;
    status: string;
  };
  brandInfo: {
    id: number;
    name: {
      ar: string;
      en: string;
    };
    logoUrl: string;
  };
  auditInfo: {
    created: {
      date: number;
    };
  };
  summary: {
    assignedInfluencersCount: number;
    cost: number;
  };
}
export interface MemberData {
  roleKey: string;
}
export interface signUpUserData {
  fullName: string;
  password: string;
}
export const getApplicationUsers = async () => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/application-user/lookup`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};
export const getAllClientsListing = async () => {
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/client/lookup`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const createClient = async (client: ClientData) => {
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/client`,
      client
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};

export const editClient = async (client: ClientData, clientId: string) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/client/${clientId}`,
      client
    );
    if (res.status == 200) {
      return { error: false };
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};

export const getClient = async (clientId: string) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/client/${clientId}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};

export const getAllClients = async (
  filters: any,
  sortDirections: any,
  page: any,
  pageSize: any
) => {
  let locFilters: any = {
    pagination: {
      pageSize: pageSize,
      page: page,
    },
    searchTerm: filters.search,
  };
  if (sortDirections.name && sortDirections.direction) {
    locFilters.sorting = {
      sortBy: sortDirections.name[0],
      sortDirection: sortDirections.direction == "ascend" ? "asc" : "desc",
    };
  }
  if (filters.country.length) {
    locFilters.filters = {
      ...locFilters.filters,
      countryKeys: filters.country,
    };
  }
  if (filters.accountOwner.length) {
    locFilters.filters = {
      ...locFilters.filters,
      ownerIds: filters.accountOwner,
    };
  }
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/client/all`,
      locFilters
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};
export const getAllMembers = async (filters: any, sortDirections: any) => {
  let locFilters: any = {};

  if (sortDirections.name && sortDirections.direction) {
    locFilters.sorting = {
      sortBy: sortDirections.name[0],
      sortDirection: sortDirections.direction === "ascend" ? "asc" : "desc",
    };
  }

  if (filters.userRole) {
    locFilters.filters = {
      ...locFilters.filters,
      roleKeys: [filters.userRole],
    };
  }
  if (filters.status) {
    locFilters.filters = {
      ...locFilters.filters,
      statusKeys: [filters.status],
    };
  }
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/application-user/all`,
      locFilters
    );

    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};
export const getClientCampaignListing = async (
  filters: any,
  sortDirections: any,
  page: any,
  pageSize: any,
  clientId: any
) => {
  let locFilters: any = {
    pageSize: pageSize,
    page: page,
    searchTerm: filters.search,
  };
  if (sortDirections.name && sortDirections.direction) {
    locFilters.sortBy = sortDirections.name[0];
    locFilters.sortDirection =
      sortDirections.direction == "ascend" ? "asc" : "desc";
  }
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/client/${clientId}/campaign/all`,
      { params: locFilters }
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const getClientBrandsListing = async (
  filters: any,
  sortDirections: any,
  page: any,
  pageSize: any,
  clientId: any
) => {
  let locFilters: any = {
    pageSize: pageSize,
    page: page,

    searchTerm: filters.search,
  };
  if (sortDirections.name && sortDirections.direction) {
    locFilters.sortBy = sortDirections.name[0];
    locFilters.sortDirection =
      sortDirections.direction == "ascend" ? "asc" : "desc";
  }
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/client/${clientId}/brand/all`,
      { params: locFilters }
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export const getLogsMocks = async (
  entityKey: string,
  entityId: string,
  pageNumber: number,
  pageSize: number
) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_ACTIVITY_API_URL}api/${entityKey}/${entityId}/activity-log-campaign-mocking?page=${pageNumber}&pageSize=${pageSize}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

interface InvitationData {
  applicationUsers: {
    email: string;
    roleKey: string;
  }[];
}
export const sendEmailInvitation = async (invitation: InvitationData) => {
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/application-user/invite-many`,
      invitation
    );
    if (res.status === 200) {
      return { error: false, errors: undefined };
    } else {
      return { error: true, errors: [] };
    }
  } catch (err: any) {
    console.log("API Error:", err);
    return { error: true, errors: err.response?.data?.errors || [] };
  }
};
export const changeUserStatus = async (
  applicationUserId: string,
  activeStatus: boolean
) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/application-user/${applicationUserId}/active/${activeStatus}`
    );
    if (res.status == 200) {
      return { error: false };
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};
export const resenInvitation = async (applicationUserId: string) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/application-user/${applicationUserId}/resend-invitation`
    );
    if (res.status == 200) {
      return { error: false };
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};
export const editMember = async (
  applicationUserId: string,
  member: MemberData
) => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/application-user/${applicationUserId}`,
      member
    );
    if (res.status == 200) {
      return { error: false };
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};
export const LastActiveDate = async () => {
  try {
    const res = await api.put(
      `${process.env.REACT_APP_API_URL}api/application-user-profile/last-active-date`
    );
    console.log("API Response:", res);
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};
export const signUpUser = async (user: signUpUserData) => {
  try {
    // const tokens = localStorage.getItem("token");
    // const token = tokens ? JSON.parse(tokens) : null;
    // const idToken = token?.idToken;

    const tokens = localStorage.getItem("token");
    const token = tokens ? JSON.parse(tokens) : null;
    const idToken = token?.idToken;
    // const token:any = localStorage.getItem('tokens');

    const res = await api.post(
      `${process.env.REACT_APP_API_URL}api/application-user-profile/sign-up`,
      user,

      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (res) {
      return res;
    } else {
      return { error: true };
    }
  } catch (err: any) {
    console.log("eer", error);

    return { error: true, errors: err.response.data.errors };
  }
};
export const getClientBrandRelationship = async (clientId: string, brandId: string) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}api/client/${clientId}/relationship/${brandId}`
    );
    if (res.data) {
      return res.data;
    } else {
      return { error: true };
    }
  } catch (err: any) {
    return { error: true, errors: err.response.data.errors };
  }
};