import { initializeApp } from 'firebase/app';
import {
  getAuth
} from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APP_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const remoteConfig =app && getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 5000;

