import { useTranslation } from "react-i18next";
import styles from "./index.module.scss"
import { HiOutlineInformationCircle, HiOutlineMagnifyingGlass, HiOutlineShare, HiOutlineBanknotes, HiOutlineGlobeAlt, HiOutlineUsers, HiOutlineSquares2X2 } from "react-icons/hi2";
import { TbSquarePlus } from "react-icons/tb";


import { Checkbox, Select, Slider } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthStateContext } from "../../../../../Context/MainContext";
import i18n from "../../../../../Utils/i18n/i18n";
import { PiGenderIntersexLight } from "react-icons/pi";

const PlatformsAndServices = ({ setFilters, filters }: any) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups, brands },
        dispatch,
    } = useContext(AuthStateContext);
    const [socialPlatforms, setSocialPlatforms] = useState([]);


    useEffect(() => {
        setSocialPlatforms(
            lookups && lookups[i18n.language]?.socialPlatforms?.map((s: any) => ({
                label: s.name,
                value: s.key
            }))
        );
    }, [lookups, i18n]);


    return (
        <div className={styles.container}>
            {/* <div className={styles.note}><HiOutlineInformationCircle /><p className="body">{t("filters_choose_platform_first")}</p></div> */}
            <div className={styles.selectConatiner}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineShare className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("platforms_services")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        showSearch={false}
                        maxTagCount={1}
                        allowClear
                        options={socialPlatforms}
                        size="large"
                        placeholder={t("choose_platform")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}

                        onChange={(platform) => {
                            setFilters({
                                ...filters, platform: {
                                    ...filters.platform, platform: platform ? platform : {
                                        key: "Snapchat",
                                        label: lookups[i18n.language]?.socialPlatforms?.find(
                                            (s: any) => s.key == "Snapchat"
                                        ).name,
                                    },
                                    service: [],
                                    service_price: [0, 0]
                                }
                            });
                        }}
                        value={filters.platform.platform}
                    ></Select>
                    <Checkbox checked={filters.platform.filters_show_verfied_only} onChange={e => {
                        setFilters({ ...filters, platform: { ...filters.platform, filters_show_verfied_only: e.target.checked } })
                    }}>{t("filters_show_verfied_only")}</Checkbox>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <TbSquarePlus className={styles.gapIcon + " " + "flip-vertical"} />
                        <p className={`body ${styles.keepCase}`}>{t("service")}</p>
                    </div>
                    <Select
                        mode="multiple"
                        maxTagCount={2}
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.socialPlatforms?.find((p: any) => p.key == filters.platform.platform.key)?.socialPlatformServices?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_service")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(service) => {
                            console.log("service", service)
                            if (!service.length) {
                                setFilters({ ...filters, platform: { ...filters.platform, service, service_price: [0, 0] } })
                            } else {
                                setFilters({ ...filters, platform: { ...filters.platform, service } })
                            }
                        }}
                        value={filters.platform.service}
                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineBanknotes className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("service_price")}</p>
                    </div>
                    <Slider reverse={i18n.language == "en" ? false : true} range value={filters.platform.service_price} defaultValue={[0, 0]} disabled={!filters.platform.service?.length} min={0} max={1000000} onChange={s => setFilters({ ...filters, platform: { ...filters.platform, service_price: s } })} />

                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <span className="icon-group filters_icon"></span>
                        <p className={`body ${styles.keepCase}`}>{t("inf_types")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        mode="multiple"
                        maxTagCount={2}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.influencerTypes?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_inf_type")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(infTypes) => {
                            setFilters({ ...filters, platform: { ...filters.platform, inf_types: infTypes } })
                        }}
                        value={filters.platform.inf_types}

                        optionRender={(option: any) => (
                            <>{option.label} <span className='select_label_span'>({t(option.value + '_info')})</span></>
                        )}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineGlobeAlt className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("aud_country")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.countries?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_aud_country")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(aud_country) => {
                            setFilters({ ...filters, platform: { ...filters.platform, aud_country } })

                        }}
                        value={filters.platform.aud_country}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <PiGenderIntersexLight className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("aud_gender")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.audienceGenders?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_aud_gender")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(aud_gender) => {
                            setFilters({ ...filters, platform: { ...filters.platform, aud_gender } })
                        }}
                        value={filters.platform.aud_gender}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineUsers className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("aud_age_group")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.ageRanges?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_age_group")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(aud_age_group) => {
                            setFilters({ ...filters, platform: { ...filters.platform, aud_age_group } })
                        }}
                        value={filters.platform.aud_age_group}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineSquares2X2 className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("other_segments")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        mode="multiple"
                        maxTagCount={2}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.audienceSegments?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_other_segments")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(other_segments) => {
                            setFilters({ ...filters, platform: { ...filters.platform, other_segments } })
                        }}
                        value={filters.platform.other_segments}

                    ></Select>
                </div>
            </div>
        </div>
    )
}

export default PlatformsAndServices