import EditCampaign from "../../../Components/EditCampaign/Index";
import { useTranslation } from "react-i18next";
import styles from "./CampaignDetails.module.scss";
import {
  HiOutlineDocumentText,
  HiOutlinePencil,
  HiOutlineShare,
  HiOutlineArrowUpOnSquare,
  HiOutlinePencilSquare,
  HiOutlineClock,
  HiOutlineArrowPathRoundedSquare,
  HiOutlineStopCircle,
  HiOutlineTrophy,
  HiOutlineRocketLaunch,
  HiOutlineXMark,
  HiShare,
  HiOutlineChevronUp,
  HiExclamationTriangle,
  HiOutlineExclamationTriangle,
} from "react-icons/hi2";
import { useEffect, useState, useContext, useRef } from "react";
import {
  CampaignDetailsInterface,
  changeCampaignStatus,
  exportCampaignAssignedInfluencers,
  getCampaign,
  getCampaignAssignedInfluencers,
  getCampaignSummary,
} from "../../../Context/Actions/campaign";
import { Link, useParams } from "react-router-dom";
import i18n from "../../../Utils/i18n/i18n";
import { AuthStateContext } from "../../../Context/MainContext";
import {
  Row,
  Col,
  Dropdown,
  Space,
  notification,
  MenuProps,
  Button,
  Slider,
  Drawer,
  Collapse,
  Radio,
  Select,
  Tooltip,
  Typography,
  Progress,
  Flex,
  Spin,
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import {
  HiOutlineCheckCircle,
  HiOutlineChevronDown,
  HiOutlineDownload,
  HiOutlineExternalLink,
  HiOutlineLink,
  HiOutlinePlus,
  HiOutlineUserGroup,
  HiOutlineXCircle,
} from "react-icons/hi";
import ShareLink from "../../../Components/shareLink/Index";
import AssignInfluencersWizard from "../../../Components/AssignInfluencersWizard";
import BrandPlaceholderImg from "../../../assets/imgs/brand_placeholder.svg";
import assingedInfluencerEmptyState from "../../../assets/imgs/assingedInfluencerEmptyState.svg";
import PageNotFound from "../../../Components/PageNotFound";
import InfluencerServicesCard from "../../../Components/InfluencerServicesCard";
import {
  exportExcelInfs,
  generateCardHeader,
  generatePowerPointSlides,
  getFileExtension,
  influencerMappedData,
  influencerMappedDataPPT,
  isValidURL,
  mapExportExcelInfsData,
  moneyFormatter,
} from "../../../Utils/helpers";
import ActivityLogs from "../../../Components/Activitylogs";
import InfluencerServicesCardShimmer from "../../../Components/InfluencerServicesCardShimmer";
import pptxgen from "pptxgenjs";
import PPTExport from "../../../Components/pptExport/Index";
import ExportModal from "../../../Components/ExportModal";

import { TbCloudDownload } from "react-icons/tb";
import { PiClockClockwise, PiClockClockwiseBold } from "react-icons/pi";
import { RiFileChartLine, RiListCheck2 } from "react-icons/ri";



import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import arabicLocale from "dayjs/locale/ar";
import updateLocale from "dayjs/plugin/updateLocale";

import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip as Bartip, Legend, ResponsiveContainer, Text } from 'recharts';
import CampaignSummary from "./CampaignSummary";

import { v4 as uuidv4 } from 'uuid'
import NewCreateCampaign from "../../../Components/NewCreateCampaign";





dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);





const CampaignDetails = () => {
  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const params = useParams();
  const {
    state: { lookups, users },
    dispatch,
    state,

  } = useContext(AuthStateContext);
  const [details, setDetails] = useState<CampaignDetailsInterface | null>(null);
  const [assignedInfluencers, setAssignedInfluencers] = useState<any | null>(
    null
  );
  const [editDetails, setEditDetails] =
    useState<CampaignDetailsInterface | null>(null);
  const [loading, setLoading] = useState(false);
  const [assingedInfluencersLoading, setAssingedInfluencersLoading] =
    useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [hasError, setHasError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [showExportModal, setShowExportModal] = useState(false)

  const [showLogs, setShowLogs] = useState(false)

  const { Option } = Select;

  const [filters, setFilters] = useState({ status: "", platform: "" });

  const { Paragraph } = Typography;

  const [summary, setSummary] = useState<any>(null);
  const [summaryLoading, setSummaryLoading] = useState(false);

  const [showAll, setShowAll] = useState(false)



  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("error_label"),
      description: t("something_went_wrong"),
      icon: <HiOutlineXCircle className="danger-text" />,
      className: "no-wrap",
    });
  };
  const getCampaignDetails = async () => {

    setLoading(true);

    let c: CampaignDetailsInterface = await getCampaign(params.id as string);
    // console.log("detail" , c)
    let editDetails = JSON.parse(JSON.stringify(c));
    // console.log(editDetails,"edit details");

    if (c.error) {
      openNotification();
      setHasError(true);

    } else {

      c.name = c.campaignSummary.name;

      c.campaignSummary.campaignObjectiveKey = lookups && lookups[i18n.language].campaignObjectives.find(
        (o: any) => o.key == c.campaignSummary.campaignObjectiveKey
      ).name;

      c.influencerAndServices.campaignNicheKeys = c.influencerAndServices.campaignNicheKeys.map(
        (k: any, i: any) =>
          lookups && lookups[i18n.language]?.niches.find((t: any) => t.key == k)
            ?.name
      ).join(", ");

      c.influencerAndServices.influencerTypeKeys = c.influencerAndServices.influencerTypeKeys.map(
        (k: any, i: any) =>
          <Tooltip title={t(lookups[i18n.language]?.influencerTypes.find((t: any) => t.key == k)
            .key + '_info')}>
            {lookups && lookups[i18n.language]?.influencerTypes.find((t: any) => t.key == k)
              .name}{i + 1 < c.influencerAndServices.influencerTypeKeys.length ? ', ' : ''}
          </Tooltip>
      );
      c.influencerAndServices.socialPlatforms = c.influencerAndServices.socialPlatforms.map((p: any) => {
        const sp = lookups && lookups[i18n.language].socialPlatforms.find(
          (s: any) => p.key == s.key
        );
        const services = p.servicesKeys.map(
          (k: string) =>
            sp.socialPlatformServices.find((sps: any) => sps.key == k).name
        );
        return {
          key: sp.key,
          logo: sp.logoUrl,
          name: sp.name,
          services: services,
          servicesKeys: p.servicesKeys,
        };
      });
      console.log("users", users)
      c.budgetAndTeam.campaignLeadId = users.length && users.find((a: any) => a.id == c.budgetAndTeam.campaignLeadId)?.name.trim()
      editDetails.budgetAndTeam.budget = Number(editDetails.budgetAndTeam.budget);


      const campaignDetails = {
        auditSummary: editDetails.auditSummary,
        agencyInfo: editDetails.agencyInfo,
        campaignSummary: {
          ...editDetails.campaignSummary,
          campaignObjectiveKey: lookups?.[i18n.language]?.campaignObjectives.find(
            (o: any) => o.key === editDetails.campaignSummary.campaignObjectiveKey
          )?.key || editDetails.campaignSummary.campaignObjectiveKey,
        },
        influencerAndServices: {
          ...editDetails.influencerAndServices,
          influencerTypeKeys: editDetails.influencerAndServices.influencerTypeKeys.map((key: string) =>
            lookups?.[i18n.language]?.influencerTypes.find((type: any) => type.key === key)?.key || key
          ),

          socialPlatforms: editDetails.influencerAndServices.socialPlatforms.map((platform: any) => {
            const sp = lookups?.[i18n.language]?.socialPlatforms.find(
              (s: any) => platform.key === s.key
            );
            const services = platform.servicesKeys.map((key: string) =>
              sp?.socialPlatformServices.find((sps: any) => sps.key === key)?.name || key
            );
            return {
              key: platform.key,
              servicesKeys: platform.servicesKeys,
              name: sp?.name || platform.key,
              logo: sp?.logoUrl || '',
              services: services,
            };
          }),
        },
        budgetAndTeam: editDetails.budgetAndTeam,
        briefAndAttachments: editDetails.briefAndAttachments,
        shareLink: editDetails.shareLink,
      }

      console.log(editDetails, "editDetailseditDetails");


      setDetails(c);
      setEditDetails(campaignDetails);
      setStatus(c.campaignSummary.campaignStatusKey);
      console.log(campaignDetails, "campaign detailssss")
    }
    setLoading(false);
  };

  // console.log("morrr" , editDetails);

  const getCampaignSummaryFn = async () => {
    setSummary(null);
    setSummaryLoading(true)
    await getCampaignSummary(params.id, filters).then((res: any) => {
      console.log(res, "campaignSummary")
      setSummary(res)
      if (res.error) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      }
      setSummaryLoading(false)
    })
  }

  const getCampaignAssignedInfluencersList = async () => {
    getCampaignSummaryFn()
    setModalOpen(false);
    setAssingedInfluencersLoading(true);
    try {
      await getCampaignAssignedInfluencers(params.id, filters).then((res: any) => {
        const secondRes = JSON.parse(JSON.stringify(res))
        const mainRes = JSON.parse(JSON.stringify(res))
        const assignedInfluencers: any[] = [];

        if (mainRes.data?.length > 0) {
          let influencers = res.data;
          Promise.all(
            influencers.map(async (influencer: any) => {
              const mappedInfluencer = await influencerMappedData(
                state,
                influencer
              );
              assignedInfluencers.push(mappedInfluencer);
            })
          );
          setAssignedInfluencers(assignedInfluencers);

        }
        else {
          setAssignedInfluencers([]);

        }

        if (res.error) {
          notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: t("error_label"),
            description: t("something_went_wrong"),
            icon: <HiOutlineXCircle className="danger-text" />,
            className: "no-wrap",
          });
        }

      })

    } catch (error) {
      // Handle any errors here
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("error_label"),
        description: t("something_went_wrong"),
        icon: <HiOutlineXCircle className="danger-text" />,
        className: "no-wrap",
      });
    }
    setAssingedInfluencersLoading(false);
  };

  const updateCampaignAssignedInfluencersList = async () => {
    getCampaignSummaryFn()

    try {
      await getCampaignAssignedInfluencers(params.id, filters).then((res: any) => {
        const secondRes = JSON.parse(JSON.stringify(res))
        const mainRes = JSON.parse(JSON.stringify(res))
        const assignedInfluencers: any[] = [];
        const assignedInfluencersppt: any[] = [];

        if (mainRes.data?.length > 0) {
          let influencers = res.data;
          Promise.all(
            influencers.map(async (influencer: any) => {
              const mappedInfluencer = await influencerMappedData(
                state,
                influencer
              );
              assignedInfluencers.push(mappedInfluencer);
            })
          );
        }

        setAssignedInfluencers(assignedInfluencers);

        if (res.error) {
          notification.open({
            placement: dir() === "rtl" ? "topLeft" : "topRight",
            message: t("error_label"),
            description: t("something_went_wrong"),
            icon: <HiOutlineXCircle className="danger-text" />,
            className: "no-wrap",
          });
        }

      })

    } catch (error) {
      // Handle any errors here
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("error_label"),
        description: t("something_went_wrong"),
        icon: <HiOutlineXCircle className="danger-text" />,
        className: "no-wrap",
      });
    }
  };
  const imgRef = useRef(null);
  useEffect(() => {
    const handleOnChangeLanguage = () => {
      setLoading(true);
      getCampaignDetails();
      getCampaignAssignedInfluencersList()
    };
    i18n.on("languageChanged", handleOnChangeLanguage);

    return () => {
      // Clean up the event listener when the component unmounts
      i18n.off("languageChanged", handleOnChangeLanguage);
    };
  }, [i18n.language, state.loading, filters, users]);
  useEffect(() => {
    if (lookups && users.length) {
      setLoading(true);
      getCampaignDetails();
      getCampaignAssignedInfluencersList()
    }
  }, [lookups, users]);

  useEffect(() => {
    getCampaignAssignedInfluencersList()
  }, [
    filters
  ])
  useEffect(() => {
    console.log("useEffect running");
    getCampaignDetails();
  }, []);

  const onSuccess = () => {
    setShowEdit(false);
  };

  const changeStatus = async (key: string) => {
    setStatusLoading(true);
    await changeCampaignStatus(key, +details?.campaignSummary.id!).then(async (res) => {
      setStatusLoading(false);
      if (res.error == false) {
        setStatus(key);
        await campaignStatusSuccess(true);
      } else {
        await campaignStatusSuccess(false);
      }
    });
  };

  const campaignStatusSuccess = (success: boolean) => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: success ? t("success_label") : t("error_label"),
      description: success
        ? t("success_edit_campaign_status")
        : t("something_went_wrong"),
      icon: success ? (
        <HiOutlineCheckCircle className="success-text" />
      ) : (
        <HiOutlineXCircle className="danger-text" />
      ),
      className: "no-wrap",
    });
  };
  const [showEdit, setShowEdit] = useState(false);
  const [showShareLink, setShowShareLink] = useState(false);
  const [showPPTExportLink, setShowPPTExportLink] = useState(false);


  const setLinkStatus = (status: boolean) => {
    let obj: any = editDetails;
    obj!["shareLink"]["status"] = status;
    setEditDetails(obj);
    setDetails(obj);
  };

  const returnStatusIcon = (status: any) => {
    if (status.toLowerCase() == "new") {
      return <HiOutlineRocketLaunch width={18}
        height={18} className="info-600" />
    } else if (status.toLowerCase() == "planning") {
      return <HiOutlinePencilSquare width={18}
        height={18} className="warning-600" />
    } else if (status.toLowerCase() == "accepted") {
      return <HiOutlineCheckCircle width={18}
        height={18} className="success-600" />
    } else if (status.toLowerCase() == "waiting") {
      return <HiOutlineClock width={18}
        height={18} className="warning-700" />
    } else if (status.toLowerCase() == "running") {
      return <HiOutlineArrowPathRoundedSquare width={18}
        height={18} className="info-600" />
    } else if (status.toLowerCase() == "cancelled") {
      return <HiOutlineXCircle width={18}
        height={18} className="danger-600" />
    } else if (status.toLowerCase() == "paused") {
      return <HiOutlineStopCircle width={18}
        height={18} className="neutral-600" />
    } else if (status.toLowerCase() == "completed") {
      return <HiOutlineTrophy width={18}
        height={18} className="success-600" />
    }
  }

  const returnClassName = (status: any) => {
    return (status.toLowerCase() == "planning")
      ? "dropwarning" : status.toLowerCase() == "waiting" ? "dropwarning-700" :
        (status.toLowerCase() == "accepted" || status.toLowerCase() == "completed")
          ? "dropsuccess"
          :
          status.toLowerCase() == "cancelled"
            ? "dropdanger"
            : (status.toLowerCase() == "running" || status.toLowerCase() == "new") ? "dropinfo" : status.toLowerCase() == "paused" ? "dropneutral" : ""

  }


  // const CustomTooltip = ({ active, payload, label }: any) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip">
  //         <p className="label">{`${label} : ${payload[0].value}`}</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };


  const downloadData = async (fileURL: any, fileName: any) => {
    try {
      // Fetch data from an API or other source
      const response = await fetch(fileURL);
      const blob = await response.blob(); // Convert response to Blob

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      const filename = 'file'; // Desired file name

      // Create a temporary <a> element
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      // Append the <a> element to the body
      document.body.appendChild(a);
      a.click(); // Simulate a click to start the download

      // Remove the <a> element from the body
      document.body.removeChild(a);
      URL.revokeObjectURL(url); // Release the Blob URL

    } catch (error) {
      console.error('Error downloading data:', error);
    }
  }

  const renderUploadedItem = (file: any) => {
    let extension = getFileExtension(file.name);
    extension = (extension == "jpeg" || extension == "jpg" || extension == "png" || extension == "gif" ||
      extension == "svg" || !extension) ? "img" : (extension == "doc" || extension == "docx") ? 'doc' : (extension == "ppt" || extension == "pptx") ? "ppt" : extension;
    return <div className={styles.uploadedImg}><div><img src={`/imgs/${extension.toLowerCase()}.svg`} alt='' />  <div className={styles.txt}>
      <p>{file.name.split(".")[0]}</p>
      {getFileExtension(file.name) ? <span>.{getFileExtension(file.name)}</span> : ""}
    </div></div><HiOutlineDownload onClick={() => downloadData(file.url, file.name)} /></div>
  }

  const getInitials = (name: string) => {
    const trimmedName = name?.trim();
    const nameParts = trimmedName.split(' ');
    return nameParts.length > 1
      ? nameParts[0][0] + nameParts[1][0]
      : nameParts[0][0];
  }


  return (
    <>
      {hasError ? (
        <PageNotFound />
      ) : (
        <Row className={styles.row}>
          <Col span={6} className={styles.col}>
            <div className={styles.Header}>
              {(loading) ? <div className={styles.paddingCont}>
                <div className={styles.logo}>
                  <div className={`${styles.loadingImg} shimmer`}></div>
                  <div className={styles.header_info}>
                    <h3 className={`${styles.loadingname} shimmer`}></h3>

                    <h3 className={`${styles.loadingname} shimmer`}></h3>

                    <h3 className={`${styles.loadingname} shimmer`}></h3>
                  </div>
                </div>



              </div> : <>

                <div className={styles.paddingCont}>
                  <div className={styles.head}>
                    <img
                      data-private
                      ref={imgRef}
                      src={details?.campaignSummary?.brand?.logo}
                      alt="brand"
                      onError={() => {
                        if (imgRef && imgRef.current) {
                          (imgRef.current as any).src = BrandPlaceholderImg;
                        }
                      }}
                    />
                    <div className={styles.actions}>
                      <button
                        className="lavad-btn-icon lavad-btn-outline btn-icon"
                        onClick={() => setShowLogs(true)}
                      >
                        <PiClockClockwise width={18} height={18} />{" "}
                      </button>
                      <button
                        className="lavad-btn-icon lavad-btn-outline btn-icon"
                        onClick={() => setShowEdit(true)}
                      >
                        <HiOutlinePencil width={18} height={18} />{" "}
                      </button>

                    </div>
                  </div>
                  <h3 data-private>{i18n.language == "en" ? details?.campaignSummary?.brand?.name.en : details?.campaignSummary?.brand?.name.ar} {details?.campaignSummary?.brand?.websiteUrl && isValidURL(details?.campaignSummary?.brand?.websiteUrl) ? <a target="_blank" href={details?.campaignSummary?.brand?.websiteUrl}><HiOutlineExternalLink /></a> : ""}</h3>
                  <h2 data-private>{details?.campaignSummary?.name}</h2>
                  <Dropdown
                    trigger={["click"]}
                    className={`status-dropdown ${returnClassName(status)}`}
                    menu={{
                      items: lookups && lookups[
                        i18n.language
                      ]?.campaignStatuses.map((s: any) => ({
                        label: <span className="dropdown-span">{returnStatusIcon(s.key)} {s.name}</span>,
                        key: s.key,
                        disabled: s.key == status,
                      })),
                      onClick: (e) => {
                        changeStatus(e.key);
                      },
                    }}
                  >
                    <Button>
                      {statusLoading ? (
                        <span className="icon-loader spin"></span>
                      ) : (
                        <Space>
                          {returnStatusIcon(status)}
                          {
                            lookups && lookups[
                              i18n.language
                            ]?.campaignStatuses.find(
                              (s: any) => s.key == status
                            )?.name
                          }
                          <HiOutlineChevronDown
                            width={18}
                            height={18}
                          />
                        </Space>
                      )}
                    </Button>
                  </Dropdown>
                  <div className={`${styles.lastActivity} body`}>
                    {t("latest_activity_on")}{" "}
                    {dayjs
                      .utc(
                        details?.auditSummary?.modificationInfo
                          ?.modificationDate
                      )
                      .local().locale(i18n.language)
                      .format("DD/MM/YYYY  hh:mm A")}
                  </div>
                </div>

                <Collapse defaultActiveKey={1} items={[{

                  key: '1',
                  label: <p className="alignItems"><RiListCheck2 /> {t('brief&attachments')}</p>,
                  children: <div className={styles.collapseDetails}>
                    <div className={styles.collapseDetailsHead}>
                      <div>
                        {!loading ? <>
                          <p>{t('brief')}</p>
                          <Paragraph className={styles.dflex} ellipsis={{
                            rows: 4, expandable: details?.briefAndAttachments?.brief.length! >= 159 ? 'collapsible' : false, symbol: (expanded) => expanded ? <div className={styles.show}>{t('show_less')}  <HiOutlineChevronUp
                              width={18}
                              height={18}
                            /></div> : <div className={styles.show}>{t('show_more')}  <HiOutlineChevronDown
                              width={18}
                              height={18}
                            /></div>
                          }}>{details?.briefAndAttachments?.brief}</Paragraph></> : <div className={styles.loadingCont}>
                          <h3 className={`${styles.loadingname} shimmer`}></h3> <h3 className={`${styles.loadingname} shimmer`}></h3></div>}
                      </div>


                    </div>
                    {!loading && details?.briefAndAttachments?.influencerDos ? <div className={styles.collapseDetailsHead}>
                      <div>
                        {!loading ? <>
                          <p className={styles.pWithIcon}><HiOutlineCheckCircle /> {t("dos'")}</p>
                          <Paragraph className={styles.dflex} ellipsis={{
                            rows: 4, expandable: details?.briefAndAttachments?.influencerDos.length! >= 159 ? 'collapsible' : false, symbol: (expanded) => expanded ? <div className={styles.show}>{t('show_less')}  <HiOutlineChevronUp
                              width={18}
                              height={18}
                            /></div> : <div className={styles.show}>{t('show_more')}  <HiOutlineChevronDown
                              width={18}
                              height={18}
                            /></div>
                          }}>{details?.briefAndAttachments?.influencerDos}</Paragraph></> : <div className={styles.loadingCont}>
                          <h3 className={`${styles.loadingname} shimmer`}></h3> <h3 className={`${styles.loadingname} shimmer`}></h3></div>}
                      </div>


                    </div> : ''}
                    {!loading && details?.briefAndAttachments?.influencerDonts ? <div className={styles.collapseDetailsHead}>
                      <div>
                        {!loading ? <>
                          <p className={styles.pWithIcon}><HiOutlineXCircle /> {t("donts'")}</p>
                          <Paragraph className={styles.dflex} ellipsis={{
                            rows: 4, expandable: details?.briefAndAttachments?.influencerDonts.length! >= 159 ? 'collapsible' : false, symbol: (expanded) => expanded ? <div className={styles.show}>{t('show_less')}  <HiOutlineChevronUp
                              width={18}
                              height={18}
                            /></div> : <div className={styles.show}>{t('show_more')}  <HiOutlineChevronDown
                              width={18}
                              height={18}
                            /></div>
                          }}>{details?.briefAndAttachments?.influencerDonts}</Paragraph></> : <div className={styles.loadingCont}>
                          <h3 className={`${styles.loadingname} shimmer`}></h3> <h3 className={`${styles.loadingname} shimmer`}></h3></div>}
                      </div>


                    </div> : ""}
                    {!loading && details?.briefAndAttachments?.attachments?.length ? <div className={styles.collapseDetailsHead}>
                      <div>
                        {!loading ? <>
                          <p>{t("attachments")}</p>
                          <div className={styles.attachments}>
                            {details?.briefAndAttachments?.attachments?.map((a: any) => ({ url: a.url, uid: uuidv4(), name: a.fileName, percent: 100, status: 'done' })).map((file: any) => renderUploadedItem(file))}
                          </div>
                        </> : <div className={styles.loadingCont}>
                        </div>}
                      </div>

                    </div> : ""}



                  </div>,
                }]}
                  className="inf_details_accordion inf_info details_collapse noborder"
                />

                <Collapse items={[{
                  key: '1',
                  label: <p className="alignItems"><RiListCheck2 /> {t('campaign_details')}</p>,
                  children: <div className={styles.collapseDetails + ' ' + styles.collapseDetails_reduced}>
                    <div className={styles.collapseDetailsHead + ' ' + styles.collapseDetailsHeadFlex}>
                      <div>
                        <p>{t('create_campaign_objective')}</p>
                        <span>{details?.campaignSummary.campaignObjectiveKey}</span>
                      </div>
                      {!!details?.budgetAndTeam.budget && <div>
                        <p>{t('budget')}</p>
                        <span>{moneyFormatter(details?.budgetAndTeam.budget)} {t('sar')}</span>
                      </div>}
                      {!!details?.influencerAndServices?.requestedInfluencers && <div>
                        <p>{t('influencers')}</p>
                        <span>{details?.influencerAndServices?.requestedInfluencers}</span>
                      </div>}
                      <div>
                        <p>{t('influencer_type')}</p>
                        <span className={styles.gap}>{details?.influencerAndServices?.influencerTypeKeys.map((e: any) => e)}</span>
                      </div>

                    </div>

                    {!!details?.campaignSummary.client?.id && <div className={styles.collapseDetailsHead}>
                      <div>
                        <p>{t('client')}</p>
                        <Tooltip title={t('view_client_page')}>
                          <Link className={styles.client} to={`/clients/${details?.campaignSummary.client.id}`}>{details?.campaignSummary.client.name}</Link>
                        </Tooltip>
                      </div>


                    </div>}

                    <ul className={styles.plats}>
                      {details?.influencerAndServices?.socialPlatforms.map((p: any) => (
                        <li className={`${styles.socialPlatform}`}>
                          <img src={`/imgs/${p.key.toLowerCase()}_square.svg`} alt={p.name} />
                          <div>
                            <span className={styles.plaform}>{p.name}</span>
                            <span className={styles.services}>
                              {p.services.map((s: string) => s).join(", ")}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {details?.influencerAndServices?.campaignNicheKeys && <div className={styles.collapseDetailsHead}>
                      <div>
                        <p>{t('niche')}</p>
                        <span className="fs_14">{details?.influencerAndServices.campaignNicheKeys}</span>
                      </div>
                    </div>}
                    {details?.budgetAndTeam?.campaignLeadId && <div className={styles.collapseDetailsHead}>
                      <div>
                        <p>{t('campaign_lead')}</p>
                        <div className={styles.user}>
                          <div className={styles.userImg}>{`${details?.budgetAndTeam?.campaignLeadId && getInitials(details?.budgetAndTeam?.campaignLeadId)}`}</div>
                          {details?.budgetAndTeam?.campaignLeadId}
                        </div>
                      </div>
                    </div>}
                    {!!details?.budgetAndTeam?.campaignAssignedMembers?.length && <div className={styles.collapseDetailsHead}>
                      <div>
                        <p>{t('assigned_members')} ({details?.budgetAndTeam?.campaignAssignedMembers.length})</p>
                        <ul className={`${styles.stackedUsers} ${showAll ? styles.usersList : ''}`}>
                          {details?.budgetAndTeam?.campaignAssignedMembers.slice(0, showAll ? undefined : 6).map((member: any, index: any) => (
                            <li key={index} className={showAll || details?.budgetAndTeam?.campaignAssignedMembers.length == 1 ? styles.user : undefined}>
                              {showAll || details?.budgetAndTeam?.campaignAssignedMembers.length == 1 ? (
                                <>
                                  <div className={styles.userImg}>
                                    {getInitials(member.name)}
                                  </div>
                                  {member.name}
                                </>
                              ) : (
                                <div className={styles.userImg}>{getInitials(member.name)}</div>
                              )}
                            </li>

                          ))}
                          {!showAll && details?.budgetAndTeam?.campaignAssignedMembers?.length! > 6 ? <li className={showAll ? styles.user : undefined}>
                            <div className={`${styles.userImg} ${styles.userImg_num}`}>+{details?.budgetAndTeam?.campaignAssignedMembers?.length! - 6}</div>
                          </li> : ""}
                        </ul>
                        {details?.budgetAndTeam?.campaignAssignedMembers.length > 1 ? showAll ? <div onClick={() => setShowAll(false)} className={styles.show}>{t('show_less')}  <HiOutlineChevronUp width={18} height={18} /></div> : <div onClick={() => setShowAll(true)} className={styles.show}>{t('show_all')}  <HiOutlineChevronDown width={18} height={18} /> </div> : ''}
                      </div>
                    </div>}

                  </div>,
                }]}
                  className="inf_details_accordion inf_info details_collapse"
                />
              </>
              }



            </div>



          </Col>
          <Col span={18} className={styles.col}>
            <div className={styles.campaignHeader}>
              <div className={styles.cont}>
                <Radio.Group onChange={(e) => setFilters({ ...filters, status: e.target.value })} value={filters.status} className="campaignStatus">
                  <Radio.Button value="">{t('all')}</Radio.Button>
                  <Radio.Button value="Pending">{t('pending')}</Radio.Button>
                  <Radio.Button value="Accepted">{t('accepted')}</Radio.Button>
                  <Radio.Button value="Rejected">{t('rejected')}</Radio.Button>
                </Radio.Group>
                <div className={styles.verticalSeperator}></div>
                <Select
                  onChange={(v: any) => setFilters({ ...filters, platform: v.key })}
                  value={filters.platform}
                  popupClassName="plat-drop-options"
                  labelInValue
                  className='plat-drop-campaign'
                  defaultActiveFirstOption={false}
                  // className={`brands-select ${styles.marginTop}`}
                  virtual={false}
                  showSearch={false}
                  size="large"
                  placeholder={t("platform_placeholder")}
                  onInputKeyDown={(event) => {
                    if (event.keyCode === 8 || event.key === "Backspace") {
                      return event.stopPropagation();
                    }
                  }}




                >
                  <Option value={""}>
                    <div className={styles.platOptions}>
                      <HiOutlineShare />
                      <p>{t("all_platforms")}</p>
                    </div>

                  </Option>
                  {lookups && lookups[i18n.language]?.socialPlatforms?.map((s: any) => (<Option value={s.key}>
                    <div className={styles.platOptions}>
                      <img src={`/imgs/${s.key.toLowerCase()}_square.svg`} alt={s.name} />
                      <p>{s.name}</p>
                    </div>

                  </Option>))}

                </Select>
              </div>
              <button
                className="lavad-btn lavad-btn-lg lavad-btn-primary-outline btn-icon"
                onClick={() => setShowExportModal(true)}
              >
                <TbCloudDownload width={18} height={18} />{" "}
                <span>{t("Export")}</span>
              </button>
            </div>
            <div className={styles.CampaignDetails}>
              <>
                <Collapse defaultActiveKey={'1'} className="inf_details_accordion inf_info details_collapse ads_collapse" items={[{
                  label: <p className="alignItems"><RiFileChartLine /> {t('ads_summary')} <span>({!filters.platform ? t('all_platforms') : lookups && lookups[i18n.language].socialPlatforms.find((k: any) => k.key == filters.platform).name})</span></p>,
                  key: 1,
                  children:
                    <CampaignSummary summary={summary} loading={summaryLoading} />

                }]
                } />

                <div className={styles.assignedInfluencers}>
                  <div className={styles.assignedInfluencers_header}>
                    <h5 className="_lr-hide" data-private>
                      <HiOutlineUserGroup />
                      <p>{t("assigned_influencers")}  <span className={styles.infNumber}>({assignedInfluencers?.length || 0})</span></p>
                    </h5>
                    {assignedInfluencers?.length > 0 && (
                      <Button
                        onClick={() => setModalOpen(true)}
                        className="lavad-btn btn-icon lavad-btn-lg lavad-btn-primary"
                        icon={<HiOutlinePlus />}
                      >
                        {t("assign_influencers")}
                      </Button>
                    )}
                  </div>
                  <div className={styles.assignedInfluencersListWrapper}>
                    {assingedInfluencersLoading && (
                      <InfluencerServicesCardShimmer />
                    )}
                    {assignedInfluencers?.length > 0 && lookups &&
                      !assingedInfluencersLoading &&
                      assignedInfluencers?.map((item: any) => (
                        <InfluencerServicesCard
                          state="actions"
                          key={item.id}
                          selectedInfs={null}
                          setSelectedInfs={null}
                          influencer={item}
                          lisitingAssigned={true}
                          campaignId={params.id}
                          getCampaignAssignedInfluencersList={
                            getCampaignAssignedInfluencersList
                          }
                          updateCampaignAssignedInfluencersList={
                            updateCampaignAssignedInfluencersList
                          }
                        />
                      ))}

                    {assignedInfluencers?.length == 0 &&
                      !assingedInfluencersLoading && (
                        <div className={styles.empty_assigned_influencers}>
                          <img
                            src={assingedInfluencerEmptyState}
                            alt="no data"
                          />
                          {(filters.platform || filters.status) ? <><div className={styles.empty_state_text}>
                            <h3>{t("no_assigned_with_filters")}</h3>

                          </div>
                          </> : <><div className={styles.empty_state_text}>
                            <h3>{t("no_assgined_influencers")}</h3>
                            <p className="title">
                              {t("assign_influencer_now")}
                            </p>
                          </div>
                            <Button
                              onClick={() => setModalOpen(true)}
                              className="lavad-btn btn-icon lavad-btn-lg lavad-btn-primary"
                              icon={<HiOutlinePlus />}
                            >
                              {t("assign_influencers")}
                            </Button></>}
                        </div>
                      )}
                  </div>
                </div>
              </>

            </div>

          </Col>


          {modalOpen && (
            <AssignInfluencersWizard
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              campId={params.id}
              getDetails={getCampaignAssignedInfluencersList}
            />
          )}

          {showEdit && (
            <NewCreateCampaign
              modalOpen={showEdit}
              setModalOpen={setShowEdit}
              onSuccess={onSuccess}
              isEdit={true}
              campaignDetails={editDetails}
              getDetails={getCampaignDetails}

            />
          )}
          {/* {showShareLink && (
            <ShareLink
              modalOpen={showShareLink}
              data={editDetails}
              setModalOpen={setShowShareLink}
              onSuccess={() => setShowShareLink(false)}
              setDetails={setLinkStatus}
            />
          )}
          {showPPTExportLink && (
            <PPTExport
              campaginId={params.id}
              modalOpen={showPPTExportLink}
              setModalOpen={setShowPPTExportLink}
              onSuccess={() => setShowPPTExportLink(false)}
              campaignDetails={details}
            />
          )} */}
          {showExportModal && <ExportModal campaginId={params.id} modalOpen={showExportModal}
            setModalOpen={setShowExportModal} campaignDetails={details} data={editDetails}
            setDetails={setLinkStatus} />}
        </Row >
      )}
      {
        showLogs && <Drawer
          title={null}
          placement={dir() === "ltr" ? "right" : "left"}
          closable={true}
          closeIcon={<HiOutlineXMark className="close-drawer" />}
          open={showLogs}
          onClose={() => setShowLogs(false)}
          contentWrapperStyle={{ width: "427px" }}
          className="activity-drawer"

        >
          <ActivityLogs entityKey={'Campaign'} entityId={params.id} />


        </Drawer>
      }
    </>
  );
};

export default CampaignDetails;
