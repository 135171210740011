import { Button, Col, Form, Input, notification, Progress, Row, Select, Space, Spin, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss'
import { HiOutlineMagnifyingGlass, HiOutlineMinusCircle, HiOutlinePlusCircle, HiOutlineTrash, HiOutlineUser, HiOutlineXCircle, HiOutlineXMark } from "react-icons/hi2";
import { FiPaperclip, FiPercent } from "react-icons/fi";

import { useContext, useEffect, useState } from 'react';
import { AuthStateContext } from '../../Context/MainContext';
import i18n from '../../Utils/i18n/i18n';

import { v4 as uuidv4 } from 'uuid';
import uploadFileToBlob from '../../Utils/upload_azure_storage';
import { ClientData, getApplicationUsers } from '../../Context/Actions/clients';
import { patterns } from '../../Utils/patterns';
import { getFileExtension } from '../../Utils/helpers';

const CreateClient = ({ setFormValues, form, onSubmitHandler, fileList, setFileList, selectedBrands, setSelectedBrands, isEdit, details, errors, appUsers, setErrors, openStatus, fromCampaign }: any) => {
    const allowedExtensions = ["xlsx", "csv", "txt", "ppt", "pdf", "png", "jpg", "jpeg", "doc", "pptx", "docx"]
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();

    const {
        state: { lookups, brands, user },
        dispatch,
    } = useContext(AuthStateContext);

    const { Dragger } = Upload;

    const [loadingBrands, setLoadingBrands] = useState(false)
    const [brandsOpen, setBrandsOpen] = useState(false)




    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            {label}
            {required ? <span className={styles.required}>*</span> : ""}
        </>
    );
    const brandTag = (uid: any) => <div className={styles.brandContainer}>
        <div>
            <Space.Compact className={"compactInputs" + ' ' + (selectedBrands?.length > 1 && !isEdit ? 'reducedCompacts' : '')}>

                <Select

                    // onDropdownVisibleChange={(open) => { setBrandsOpen(open); setLoadingBrands(!open) }}
                    // onClick={() => !brandsOpen ? setLoadingBrands(true) : setLoadingBrands(false)}
                    virtual={false} placement={i18n.language == "ar" ? 'bottomRight' : 'bottomLeft'} dropdownRender={(menu) => (
                        <>
                            <div className="lavad-input search-input">
                                <HiOutlineMagnifyingGlass width={16} height={16} />
                                <input
                                    onChange={onBrandsChange}
                                    id="brandsSearch"
                                    placeholder={t("search")}
                                />
                            </div>
                            {menu}
                        </>
                    )} value={selectedBrands.find((a: any) => a.uid == uid).key ? selectedBrands.find((a: any) => a.uid == uid).key : undefined} onChange={(value) => { setFormValues((prev: any) => ({ ...prev, brand: "change" })); setSelectedBrands((prev: any) => prev.map((a: any) => a.uid == uid ? { ...a, key: value } : a)) }} allowClear options={brandsSearchResults} className={`brands-select select-text-max-width selectPrefix ${isEdit ? 'disabledSelectField' : ''} ${((!selectedBrands.find((a: any) => a.uid == uid).key && selectedBrands.find((a: any) => a.uid == uid).type) || (selectedBrands.find((a: any) => a.uid == uid).key && !selectedBrands.find((a: any) => a.uid == uid).type)) ? 'selectWithError' : ''}`} suffixIcon={<HiOutlineMagnifyingGlass className='searchIconSvg prefix' />}
                    placeholder={t('assign_brands')} style={{ width: '70%' }} optionRender={(option: any) => {
                        return <div className={styles.brandOpt}>
                            <img src={option.data.logo} style={{ width: '32px', height: '32px', objectFit: 'contain' }} loading='lazy' />
                            {option.data.label}
                        </div>
                    }}></Select>
                <Select virtual={false} value={selectedBrands.find((a: any) => a.uid == uid).type ? selectedBrands.find((a: any) => a.uid == uid).type : undefined} onChange={(value) => { setFormValues((prev: any) => ({ ...prev, brand: "change" }));; setSelectedBrands((prev: any) => prev.map((a: any) => a.uid == uid ? { ...a, type: value } : a)) }} options={lookups && lookups[i18n.language].clientBrandRelationships?.map((b: any) => ({ value: b.key, label: b.name }))} className={`brands-select brand-type ${((!selectedBrands.find((a: any) => a.uid == uid).key && selectedBrands.find((a: any) => a.uid == uid).type) || (selectedBrands.find((a: any) => a.uid == uid).key && !selectedBrands.find((a: any) => a.uid == uid).type)) ? 'selectWithError' : ""}`} placeholder={t('association_type')} style={{ width: '30%' }} />
            </Space.Compact>
            {selectedBrands?.length > 1 && !isEdit ? <HiOutlineMinusCircle onClick={() => { setFormValues((prev: any) => ({ ...prev, brand: "change" })); setSelectedBrands((prev: any) => prev.filter((a: any) => a.uid != uid)) }} /> : ""}
        </div>
        {
            ((!selectedBrands.find((a: any) => a.uid == uid).key && selectedBrands.find((a: any) => a.uid == uid).type) || (selectedBrands.find((a: any) => a.uid == uid).key && !selectedBrands.find((a: any) => a.uid == uid).type)) ? <p className='error'>{!selectedBrands.find((a: any) => a.uid == uid).key && selectedBrands.find((a: any) => a.uid == uid).type ? t('brand_error') : selectedBrands.find((a: any) => a.uid == uid).key && !selectedBrands.find((a: any) => a.uid == uid).type ? t('type_error') : ""}</p>
                : ""
        }    </div >

    const renderUploadedItem = (originNode: any, file: any, fileList: any, actions: any) => {
        let extension = getFileExtension(file.name);
        extension = (extension == "jpeg" || extension == "jpg" || extension == "png" || !extension) ? "img" : (extension == "doc" || extension == "docx") ? 'doc' : (extension == "ppt" || extension == "pptx") ? "ppt" : extension;
        if (file.percent < 100 && file.status == "uploading") {
            return <div className={styles.uploadedImg}><div><img src={require(`../../../public/imgs/${extension.toLowerCase()}.svg`)} alt='' /> <div className={`${styles.nameWithProgress} nameWithProgress`}>
                <div className={styles.txt}>
                    <p>{file.name.split(".")[0]}</p>
                    <span>.{file.name.split(".")[file.name.split(".").length - 1]}</span>
                </div>
                <Progress strokeColor={"#6C73FF"} trailColor={"#EEEEEE"} percent={file.percent} /></div></div><HiOutlineXMark onClick={() => setFileList((prev: any) => prev.filter((f: any) => f.uid != file.uid))} /></div>

        }
        return <div className={styles.uploadedImg}><div><img src={require(`../../../public/imgs/${extension.toLowerCase()}.svg`)} alt='' />  <div className={styles.txt}>
            <p>{file.name.split(".")[0]}</p>
            {getFileExtension(file.name) ? <span>.{getFileExtension(file.name)}</span> : ""}
        </div></div><HiOutlineTrash onClick={() => { setFormValues((prev: any) => ({ ...prev, attachment: "change" }));; setFileList((prev: any) => prev.filter((f: any) => f.uid != file.uid)) }} /></div>
    }

    const [showAdditionalFields, setShowAdditionalFields] = useState(true);



    const [wrongFileExtension, setWrongFileExtension] = useState(false)


    const [brandsSearchResults, setBrandsSearchResults] = useState<any>([])
    const [countriesSearchResults, setCountriesSearchResults] = useState<any>([])

    useEffect(() => {
        if (!fromCampaign) {
            setBrandsSearchResults(
                brands.map((b: any) => ({ logo: b.logoUrl, value: b.id, label: b.name.find((a: any) => a.languageKey == "en").value + ' - ' + b.name.find((a: any) => a.languageKey == "ar").value, disabled: selectedBrands.find((c: any) => c.key == b.id) }))
            );
        }
    }, [brands, selectedBrands, openStatus, i18n.language, fromCampaign]);

    // comment
    useEffect(() => {
        setCountriesSearchResults(
            lookups && lookups[i18n.language].countries.map((country: any) => ({ value: country.key, label: country.name, iso: country.isoTwoCode })).sort((a: any, b: any) => {
                if (a.value === "SAU") return -1;
                if (b.value === "SAU") return 1;
                return 0;
            })
        );

    }, [i18n.language, openStatus]);

    const onBrandsChange = (e: any) => {
        const v = e.target.value;
        const filtered: any = brands
            .filter((b: any) => (b.name.find((a: any) => a.languageKey == "en").value.toLowerCase().includes(v.toLowerCase()) || b.name.find((a: any) => a.languageKey == "ar").value.toLowerCase().includes(v.toLowerCase())))
            .map((b: any) => ({ logo: b.logoUrl, value: b.id, label: b.name.find((a: any) => a.languageKey == "en").value + ' - ' + b.name.find((a: any) => a.languageKey == "ar").value, disabled: selectedBrands.find((c: any) => c.key == b.id) }));
        setBrandsSearchResults(filtered);
    };

    const onCountriesChange = (e: any) => {
        const v = e.target.value;
        const filtered: any = lookups && lookups[i18n.language].countries
            .filter((b: any) => b.name.toLowerCase().includes(v.toLowerCase()))
            .map((b: any) => ({ value: b.key, label: b.name, iso: b.isoTwoCode })).sort((a: any, b: any) => {
                if (a.value === "SAU") return -1;
                if (b.value === "SAU") return 1;
                return 0;
            });
        setCountriesSearchResults(filtered);
    };


    useEffect(() => {
        if (isEdit) {
            setShowAdditionalFields(details?.basicInfo.countryKey == "SAU")
            form.setFieldsValue({
                name: details?.basicInfo.name,
                legalAr: details?.basicInfo.legalName.ar,
                legalEn: details?.basicInfo.legalName.en,
                country: details?.basicInfo.countryKey,
                vat: details?.basicInfo.vatNumber,
                cr: details?.basicInfo.commercialRegistration,
                agency_fees: details?.basicInfo.agencyFees,
                account_owner: details?.basicInfo.ownerId,
            })

        }
    }, [isEdit, details])


    const handleUpload = async (file: any) => {
        setWrongFileExtension(false)
        console.log(file.name.split(".")[file.name.split(".").length - 1].toLowerCase(), "fileeeee");

        if (!allowedExtensions.includes(file.name.split(".")[file.name.split(".").length - 1].toLowerCase())) {
            setWrongFileExtension(true)
            notification.destroy()
            notification.open({
                placement: dir() === "rtl" ? "topLeft" : "topRight",
                message: t("error_label"),
                description: `${t("format_not_supported")} "${file.name.split(".")[file.name.split(".").length - 1].toLowerCase()}"`,
                icon: <HiOutlineXCircle className="danger-text" />,
                className: "no-wrap",
            });
            return;
        }
        try {
            file.percent = 0;
            file.status = "uploading"
            setFileList((prevList: any) => [
                ...prevList,
                file
            ]);
            const uploadedImgUrl = await uploadFileToBlob("client-attachments", file, (percent) => {
                setFileList((prevList: any) =>
                    prevList.map((item: any) => {
                        if (item.uid === file.uid) {
                            item.percent = percent
                        }
                        return item
                    }
                    )
                );
            });
            console.log(uploadedImgUrl, "uploadImage");
            // change happened
            setFormValues((prev: any) => ({ ...prev, attachment: "change" }));

            setFileList((prevList: any) =>
                prevList.map((item: any) => {
                    if (item.uid === file.uid) {
                        item.status = "done";
                        item.percent = 99;
                        item.url = uploadedImgUrl
                    }
                    return item
                }

                )
            );

        } catch (error) {
            console.error('Upload failed:', error);
        }


    };


    useEffect(() => {
        if (errors.cr || errors.vat || errors.legalEn || errors.legalAr) {
            form.validateFields()
        }
    }, [errors])

    return (
        <Form
            requiredMark={customizeRequiredMark}
            className={styles.createForm + ' ' + 'fixHeights'}
            form={form}
            layout="vertical"
            onFinish={onSubmitHandler}
        >

            <Form.Item
                label={<label data-private>{t("display_name")}</label>}
                name="name"
                rules={[
                    {
                        required: true,
                        message: t("display_name_required"),
                        validator: (_, value) => {
                            if (!value?.trim().length) {
                                return Promise.reject(t("display_name_required"));
                            } else {
                                return Promise.resolve();
                            }
                        },
                    },



                ]}
            >
                <Input
                    onChange={(e) => setFormValues((prev: any) => ({ ...prev, name: e.target.value }))}
                    className="lavad-input lavad-input-lg"
                    placeholder={t("display_name_placeholder")}
                />
            </Form.Item>
            <div className={styles.inputGroup}>
                <Form.Item
                    label={<label data-private>{t("legal_ar")}</label>}
                    name="legalAr"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (value && errors.legalAr) {
                                    return Promise.reject(t(errors.legalAr));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        {
                            required: true,
                            message: t("legal_ar_required"),
                            validator: (_, value) => {
                                if (!value?.trim().length) {
                                    return Promise.reject(t("legal_ar_required"));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        { pattern: RegExp(patterns.arRegex), message: t("legal_ar_pattern") }
                    ]}
                >
                    <Input
                        className="lavad-input lavad-input-lg"
                        placeholder={t("legal_ar_placeholder")}
                        onChange={(e) => { setErrors((errs: any) => ({ ...errs, legalAr: "" })); setFormValues((prev: any) => ({ ...prev, legalAr: e.target.value })) }}

                    />
                </Form.Item>

                <Form.Item
                    label={<label data-private>{t("legal_en")}</label>}
                    name="legalEn"
                    rules={[
                        {

                            validator: (_, value) => {
                                if (value && errors.legalEn) {
                                    return Promise.reject(t(errors.legalEn));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        {
                            required: true,
                            message: t("legal_en_required"),
                            validator: (_, value) => {
                                if (!value?.trim().length) {
                                    return Promise.reject(t("legal_ar_required"));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                        { pattern: RegExp(patterns.enRegex), message: t("legal_en_pattern") },
                    ]}
                >
                    <Input
                        className="lavad-input lavad-input-lg"
                        placeholder={t("legal_en_placeholder")}
                        onChange={(e) => { setErrors((errs: any) => ({ ...errs, legalEn: "" })); setFormValues((prev: any) => ({ ...prev, legalEn: e.target.value })) }}

                    />
                </Form.Item>
            </div>



            <Form.Item
                label={<label data-private>{t("country")}</label>}
                name="country"
                initialValue={details?.basicInfo?.countryKey || "SAU"}
                rules={[
                    {
                        required: true,
                        message: t("country_required"),
                    },
                ]}
            >
                <Select
                    defaultValue={details?.basicInfo?.countryKey || "SAU"}
                    onChange={(value) => {
                        form.setFieldValue("country", value)
                        setShowAdditionalFields(value == "SAU")
                        setFormValues((prev: any) => ({ ...prev, country: value }))
                    }}
                    suffixIcon={<HiOutlineMagnifyingGlass className='searchIconSvg' />}
                    defaultActiveFirstOption={false}
                    className="brands-select"
                    virtual={false}
                    dropdownRender={(menu) => (
                        <>
                            <div className="lavad-input search-input">
                                <HiOutlineMagnifyingGlass width={16} height={16} />
                                <input
                                    onChange={onCountriesChange}
                                    id="brandsSearch"
                                    placeholder={t("search")}
                                />
                            </div>
                            {menu}
                        </>
                    )}
                    notFoundContent={
                        <div
                            role="listbox"
                            id="platforms_list"
                            className="ant-select-item-empty"
                        >
                            <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                <div className="ant-empty-image">
                                    <svg
                                        width="64"
                                        height="41"
                                        viewBox="0 0 64 41"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <ellipse
                                                fill="#f5f5f5"
                                                cx="32"
                                                cy="33"
                                                rx="32"
                                                ry="7"
                                            ></ellipse>
                                            <g fill-rule="nonzero" stroke="#d9d9d9">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    fill="#fafafa"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">
                                    {t("not_found")}
                                </div>
                            </div>
                        </div>
                    }
                    options={countriesSearchResults}
                    optionRender={(option: any) => {
                        return <div className={styles.opt}>
                            <img src={`/imgs/${option.data.iso.toLowerCase()}.svg`} />
                            {option.data.label}
                        </div>
                    }
                    }
                    size="large"
                />

                {showAdditionalFields ? <div className={`${styles.inputGroup} ${styles.vat}`}>
                    <Form.Item
                        label={<label data-private>{t("vat")}</label>}
                        name="vat"
                        rules={[
                            {

                                validator: (_, value) => {
                                    if (value && errors.vat) {
                                        return Promise.reject(t(errors.vat));
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}

                    >
                        <Input
                            className="lavad-input lavad-input-lg"
                            placeholder={t("vat/trn_placeholder")}
                            onChange={(e) => {
                                setErrors((errs: any) => ({ ...errs, vat: "" })); setFormValues((prev: any) => ({ ...prev, vat: e.target.value }))
                            }}

                        />
                    </Form.Item>
                    <Form.Item
                        label={<label data-private>{t("cr")}</label>}
                        name="cr"
                        rules={[
                            {

                                validator: (_, value) => {
                                    if (value && errors.cr) {
                                        return Promise.reject(t(errors.cr));
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}

                    >
                        <Input
                            onChange={(e) => {
                                setErrors((errs: any) => ({ ...errs, cr: "" })); setFormValues((prev: any) => ({ ...prev, cr: e.target.value }))
                            }}
                            className="lavad-input lavad-input-lg"
                            placeholder={t("cr_placeholder")}
                        />
                    </Form.Item>
                </div> : ""}

            </Form.Item>

            {!fromCampaign && <> <div className={styles.inputGroup}>
                <Form.Item
                    label={<label data-private>{t("agency_fees")}</label>}
                    name="agency_fees"
                    rules={[
                        {

                            validator: (_, value) => {
                                if (value && (!/^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/.test(value) || value < 0)) {
                                    return Promise.reject(t("fees_min"));
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ]}

                >
                    <Input
                        suffix={<FiPercent color='#8E8E8E' />}
                        className="lavad-input lavad-input-lg"
                        placeholder={t("agency_fees_placeholder")}
                        onChange={(e) => {
                            setFormValues((prev: any) => ({ ...prev, fees: e.target.value }))
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label={<label data-private>{t("account_owner")}</label>}
                    name="account_owner"
                    initialValue={user.id}

                >
                    <Select
                        defaultValue={user.id}
                        placeholder={t('select_account_owner')}
                        suffixIcon={<HiOutlineUser color='#8E8E8E' className='searchIconSvg' />}
                        defaultActiveFirstOption={false}
                        className="brands-select showSuffix"
                        virtual={false}
                        notFoundContent={
                            <div
                                role="listbox"
                                id="platforms_list"
                                className="ant-select-item-empty"
                            >
                                <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                    <div className="ant-empty-image">
                                        <svg
                                            width="64"
                                            height="41"
                                            viewBox="0 0 64 41"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g
                                                transform="translate(0 1)"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <ellipse
                                                    fill="#f5f5f5"
                                                    cx="32"
                                                    cy="33"
                                                    rx="32"
                                                    ry="7"
                                                ></ellipse>
                                                <g fill-rule="nonzero" stroke="#d9d9d9">
                                                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                    <path
                                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                        fill="#fafafa"
                                                    ></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="ant-empty-description">
                                        {t("not_found")}
                                    </div>
                                </div>
                            </div>
                        }
                        options={appUsers.map((user: any) => ({ value: user.id, label: user.name }))}
                        optionRender={(option: any) => {
                            return <div className={styles.user}>
                                <div className={styles.userImg}>{`${option.data.label.trim().split(" ").length > 1 ? option.data.label.trim().split(" ")[0][0] + option.data.label.trim().split(" ")[1][0] : option.data.label.trim().split(" ")[0][0]}`}</div>
                                {option.data.label}
                            </div>
                        }
                        }
                        size="large"
                        labelRender={(props: any) => {
                            const { label, value } = props;
                            return <div className={styles.user}>
                                <div className={styles.userImg}>{`${label?.trim().split(" ").length > 1 ? label?.trim().split(" ")[0][0] + label?.trim().split(" ")[1][0] : label?.trim().split(" ")[0][0]}`}</div>
                                <span>{label}</span>
                            </div>
                        }}
                        onChange={(value) => {
                            setFormValues((prev: any) => ({ ...prev, owner: value }))
                        }}
                    />
                </Form.Item>
            </div>
                {isEdit && selectedBrands.every((a: any) => a.key && a.type) && <Form.Item label={t('brands')}>
                    <div className={styles.brands}>

                        <ul>
                            {selectedBrands.map((a: any) => <li key={a.uid}>{brandTag(a.uid)}</li>)}
                        </ul>
                        {!isEdit && <button onClick={() => selectedBrands.every((a: any) => a.key && a.type) ? setSelectedBrands([...selectedBrands, { key: "", type: '', uid: uuidv4() }]) : ''} type='button' className={`lavad-btn lavad-btn-lg lavad-btn-link lavad-btn-with-icon ${styles.addBrand}`}><HiOutlinePlusCircle />{t("add_brand")}</button>
                        }
                    </div>
                </Form.Item>}
                <Form.Item label={t('attach_files')}>
                    <Dragger multiple fileList={fileList} beforeUpload={handleUpload} itemRender={renderUploadedItem} className={'uploader'}>
                        <FiPaperclip />
                        <div className={styles.info}>
                            <p>{t("drag_and_drop")}</p>
                            <p>
                                {t('client_formats')}
                            </p>
                        </div>
                    </Dragger>
                </Form.Item>
            </>}
        </Form>
    )
}

export default CreateClient