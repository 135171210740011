import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../assets/imgs/clients_empty.svg";
import { Button, Drawer, Form, Input, Modal, notification, Select } from "antd";
import {
  HiOutlineCheckCircle,
  HiOutlineGlobeAlt,
  HiOutlineMagnifyingGlass,
  HiOutlinePlus,
  HiUserCircle,
} from "react-icons/hi2";
import CreateClient from "../../../Components/CreateClient";
import { v4 as uuidv4 } from "uuid";
import {
  ClientListingData,
  createClient,
  getAllClients,
  getApplicationUsers,
} from "../../../Context/Actions/clients";
import { FiPercent } from "react-icons/fi";
import { AuthStateContext } from "../../../Context/MainContext";
import i18n from "../../../Utils/i18n/i18n";
import ClientListingTable from "../../../Components/ClientListing";
import dayjs from "dayjs";
import nodata from "../../../assets/imgs/filters-no-data.svg";
import AddBrand from "../../../Components/AddBrand";

const ClientsListing = () => {
  const [form] = Form.useForm();

  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const [data, setData] = useState<any>([
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [openCreate, setOpenCreate] = useState(false);

  const [fileList, setFileList] = useState<any>([]);
  const [selectedBrands, setSelectedBrands] = useState<any>([
    { key: "", type: "", uid: uuidv4() },
  ]);

  const [createLoading, setCreateLoading] = useState(false);

  const [createErrors, setCreateErrors] = useState({
    vat: "",
    cr: "",
    legalEn: "",
    legalAr: "",
  });

  const [appUsers, setAppUsers] = useState<any>([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    legalEn: "",
    legalAr: "",
    vat: "",
    cr: "",
    fees: "",
    owner: "",
    country: "",
  });
  const [selectedFilters, setSelectedFilters] = useState({
    country: [],
    accountOwner: [],
    search: "",
  });

  const [sortDirections, setSortDirections] = useState<any>({
    name: "",
    direction: "",
  });

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [loading, setLoading] = useState(true);

  const {
    state: { lookups, brands },
    dispatch,
  } = useContext(AuthStateContext);

  useEffect(() => {
    const getUsers = async () => {
      const users = await getApplicationUsers();
      setAppUsers(users.data);
      console.log("users", users.data);
    };
    getUsers();
  }, [i18n.language, lookups]);

  const setTableData = async () => {
    setLoading(true);
    const getAllClientsData: {
      totalRecords: number;
      data: ClientListingData[];
    } = await getAllClients(
      selectedFilters,
      sortDirections,
      pagination.page,
      pagination.pageSize
    );
    const mappedData = getAllClientsData.data.map((d) => {
      const obj = {
        id: d.basicInfo.id,
        name: {
          name: d.basicInfo.name,
          legalName:
            i18n.language == "en"
              ? d.basicInfo?.legalName?.en
              : d.basicInfo?.legalName?.ar,
          country:
            lookups &&
            lookups[i18n.language].countries.find(
              (c: any) => c.key == d.basicInfo.countryKey
            ).name,
        },
        brands: d.assignedbrands?.map((b) => ({ img: b.brand.logoUrl })),
        accountManager: d.basicInfo.owner?.name,
        campaigns: d.campaignsSummary?.totalCount,
        acceptedCampaigns: {
          a: d.campaignsSummary?.totalAcceptedCount,
          b: `${(
            (d.campaignsSummary?.totalAcceptedCount /
              d.campaignsSummary?.totalCount) *
            100
          ).toFixed(0)}%`,
        },
        fees: d.basicInfo?.agencyFees,
        creationDate: dayjs(d.auditInfo?.created?.date)
          .local()
          .locale(i18n.language == "en" ? "en" : "ar")
          .format("MMM D, YYYY"),
        vat: d.basicInfo?.vatNumber ? `${d.basicInfo.vatNumber}` : "",
      };
      return obj;
    });
    setData(mappedData);
    setPagination((prev) => ({
      ...prev,
      total: getAllClientsData.totalRecords,
    }));
    setLoading(false);
  };

  useEffect(() => {
    setTableData();
  }, [i18n.language, lookups, selectedFilters, sortDirections]);

  const onSubmitHandler = async () => {
    // setCreateErrors({ vat: "vat", cr: "cr" })
    // submit handler
    if (
      !selectedBrands.find((a: any) => (a.type && !a.key) || (!a.type && a.key))
    ) {
      setCreateLoading(true);
      const values = form.getFieldsValue();
      const client = await createClient({
        basicInfo: {
          agencyFees: +values.agency_fees,
          name: values.name,
          countryKey: values.country,
          vatNumber:
            values.country == "SAU" ? (values.vat ? values.vat : null) : null,
          attachments: fileList.map((file: any) => ({
            url: file.url,
            fileName: file.name,
          })),
          commercialRegistration:
            values.country == "SAU" ? (values.cr ? values.cr : null) : null,
          legalName: { en: values.legalEn, ar: values.legalAr },
          ownerId: values.account_owner,
        },
        brands: selectedBrands
          .filter((b: any) => b.key && b.type)
          .map((a: any) => ({ id: a.key, clientRelationshipKey: a.type })),
      });
      if (!client.error) {
        cancel();
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("success_label"),
          description: t("client_created"),
          icon: <HiOutlineCheckCircle className="success-text" />,
          className: "no-wrap",
        });
        setTableData();
      } else {
        setCreateErrors({
          cr: client.errors.find(
            (e: any) => e.field == "basicInfo.commercialRegistration"
          )
            ? "cr_err"
            : "",
          vat: client.errors.find((e: any) => e.field == "basicInfo.vatNumber")
            ? "vat_err"
            : "",
          legalAr: client.errors.find(
            (e: any) => e.field == "basicInfo.legalName.ar"
          )
            ? "legalAr_err"
            : "",
          legalEn: client.errors.find(
            (e: any) => e.field == "basicInfo.legalName.en"
          )
            ? "legalEn_err"
            : "",
        });
      }
      setCreateLoading(false);
    }
  };

  const cancel = () => {
    setOpenCreate(false);
    setCreateErrors({ vat: "", cr: "", legalAr: "", legalEn: "" });
    form.resetFields();
    setSelectedBrands([{ key: "", type: "", uid: uuidv4() }]);
    setFileList([]);
  };

  const checkIfHasData = () => {
    // add if fields change
    const hasValue =
      Object.values(formValues).some((a: any) => !!a) ||
      !!fileList.length ||
      !!selectedBrands.find((a: any) => a.type || a.key);
    if (hasValue) {
      setModalOpen(true);
    } else {
      cancel();
    }
    // if(form.getFieldsValue)
  };
  useEffect(() => {
    const hasValue =
      Object.values(formValues).some((a: any) => !!a) ||
      !!fileList.length ||
      !!selectedBrands.find((a: any) => a.type || a.key);
    window.onbeforeunload = function (e) {
      if (hasValue && openCreate) {
        return "Please click 'Stay on this Page' if you did this unintentionally";
      }
    };
  }, [openCreate, formValues]);

  console.log(selectedFilters, "selectedFilters");
  return (
    <div className={styles.wrapper}>
      <div className={styles.pageHeader}>
        <div className={styles.topSection}>
          <h2 className={styles.tit}>{t("clients")}</h2>
          {!!data.length && (
            <Button
              onClick={() => setOpenCreate(true)}
              icon={<HiOutlinePlus style={{ width: "18px", height: "18px" }} />}
              className="lavad-btn lavad-btn-lg lavad-btn-primary lavad-btn-with-icon"
            >
              {t("add_client")}
            </Button>
          )}{" "}
        </div>
        {(!!data.length ||
          (!data.length &&
            (selectedFilters.country.length ||
              selectedFilters.accountOwner.length ||
              selectedFilters.search))) && (
          <div className={styles.filterSection + " " + "fixHeights"}>
            <div>
              <p>{t("filter_by")}</p>
              <div className={styles.leftSection}>
                <Select
                  value={selectedFilters.country}
                  onChange={(e) => {
                    setSelectedFilters((prev: any) => ({
                      ...prev,
                      country: e,
                    }));
                    setPagination((prev: any) => ({ ...prev, page: 1 }));
                  }}
                  allowClear
                  labelRender={(props: any) => {
                    const { label, value, title, key } = props;
                    return (
                      <div className={styles.opt + " " + styles.optWithPadding}>
                        <img src={`/imgs/${title?.toLowerCase()}.svg`} />
                        {lookups &&
                          lookups[i18n.language].countries.find(
                            (a: any) => a.key == value
                          ).name}
                      </div>
                    );
                  }}
                  mode="multiple"
                  options={
                    lookups &&
                    lookups[i18n.language].countries.map((country: any) => ({
                      value: country.key,
                      label: country.name,
                      iso: country.isoTwoCode,
                      title: country.isoTwoCode,
                    }))
                  }
                  optionRender={(option: any) => {
                    return (
                      <div className={styles.opt}>
                        <img
                          src={`/imgs/${option?.data?.iso?.toLowerCase()}.svg`}
                        />
                        {option.data.label}
                      </div>
                    );
                  }}
                  defaultActiveFirstOption={false}
                  virtual={false}
                  style={{ minWidth: "235px" }}
                  maxTagCount={2}
                  placeholder={
                    <div className="placeholder_with_icon">
                      <HiOutlineGlobeAlt />
                      <span>{t("country")}</span>
                    </div>
                  }
                  className="brands-select clients-listing-select"
                ></Select>
                <div className="vertical_seperator"></div>
                <Select
                  onChange={(e) => {
                    setSelectedFilters((prev: any) => ({
                      ...prev,
                      accountOwner: e,
                    }));
                    setPagination((prev: any) => ({ ...prev, page: 1 }));
                  }}
                  mode="multiple"
                  defaultActiveFirstOption={false}
                  virtual={false}
                  style={{ minWidth: "235px" }}
                  options={appUsers.map((user: any) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  optionRender={(option: any) => {
                    return (
                      <div className={styles.user}>
                        <div className={styles.userImg}>{`${
                          option?.data?.label.trim().split(" ").length > 1
                            ? option.data.label.trim().split(" ")[0][0] +
                              option.data.label.trim().split(" ")[1][0]
                            : option.data.label.trim().split(" ")[0][0]
                        }`}</div>
                        {option.data.label}
                      </div>
                    );
                  }}
                  allowClear
                  size="large"
                  labelRender={(props: any) => {
                    const { label, value } = props;
                    return (
                      <div
                        className={styles.user + " " + styles.optWithPadding}
                      >
                        {label}
                      </div>
                    );
                  }}
                  maxTagCount={2}
                  placeholder={
                    <div className="placeholder_with_icon">
                      <HiUserCircle />
                      <span>{t("account_owner")}</span>
                    </div>
                  }
                  className="brands-select clients-listing-select"
                ></Select>
              </div>
            </div>
            <Input
              onChange={(e) => {
                setSelectedFilters((prev) => ({
                  ...prev,
                  search: e.target.value,
                }));
                setPagination((prev: any) => ({ ...prev, page: 1 }));
              }}
              placeholder={t("search_by_vat_id_name")}
              prefix={<HiOutlineMagnifyingGlass color="#8E8E8E" />}
              className={`lavad-input lavad-input-lg ${styles.searchInput}`}
            />
          </div>
        )}
      </div>
      {!data.length &&
        !selectedFilters.country.length &&
        !selectedFilters.accountOwner.length &&
        !selectedFilters.search && (
          <div className={styles.emptyState}>
            <div className={styles.data}>
              <img src={EmptyState} alt="" />
              <p className="subtitle">{t("clients_empty_state")}</p>
              <p className="body">{t("add_client_now")}</p>
              <Button
                onClick={() => setOpenCreate(true)}
                icon={
                  <HiOutlinePlus style={{ width: "18px", height: "18px" }} />
                }
                className="lavad-btn lavad-btn-lg lavad-btn-primary lavad-btn-with-icon"
              >
                {t("add_client")}
              </Button>
            </div>
          </div>
        )}
      {(!!data.length || loading) && (
        <ClientListingTable
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          sortDirections={sortDirections}
          setSortDirections={setSortDirections}
          data={data}
        />
      )}
      {!data.length &&
      !loading &&
      (selectedFilters.country.length ||
        selectedFilters.accountOwner.length ||
        selectedFilters.search) ? (
        <div className={styles.emptyStateWithFilters}>
          <img src={nodata} alt="no data" />
          <div className={styles.empty_state_text}>
            <h3>{t("no_results_found")}</h3>
            <p className="title">{t("no_results_subtitle")}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      {openCreate && (
        <Drawer
          placement={dir() === "ltr" ? "right" : "left"}
          onClose={() => {}}
          footer={
            <div className={styles.footer}>
              <button
                onClick={checkIfHasData}
                className="lavad-btn lavad-btn-lg lavad-btn-outline"
              >
                {t("cancel")}
              </button>
              <button
                disabled={
                  createLoading ||
                  fileList.find((a: any) => a.status == "uploading")
                }
                onClick={() => form.submit()}
                className="lavad-btn lavad-btn-lg lavad-btn-primary"
              >
                {createLoading ? (
                  <span className="icon-loader spin"></span>
                ) : (
                  t("add")
                )}
              </button>
            </div>
          }
          rootClassName="lavad-drawer-wrapper"
          className="lavad-drawer"
          open={openCreate}
          title={t("add_new_client")}
        >
          <div className={styles.contentWrapper}>
            <CreateClient
              setFormValues={setFormValues}
              openStatus={openCreate}
              setErrors={setCreateErrors}
              appUsers={appUsers}
              errors={createErrors}
              loading={createLoading}
              fileList={fileList}
              setFileList={setFileList}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              onSubmitHandler={onSubmitHandler}
              form={form}
            />
          </div>
        </Drawer>
      )}
      <Modal
        mask={false}
        open={modalOpen}
        classNames={{ wrapper: "incZ" }}
        centered
        className="report-modal incZ"
        title={t("exit_without_saving")}
        onCancel={() => setModalOpen(false)}
        footer={
          <div className="btn-group align-items-end">
            <button
              onClick={() => setModalOpen(false)}
              className="lavad-btn lavad-btn-md lavad-btn-outline"
            >
              {t("keep_editing")}
            </button>
            <button
              onClick={() => {
                cancel();
                setModalOpen(false);
              }}
              className="lavad-btn lavad-btn-md lavad-btn-danger-primary"
            >
              {t("yes_leave")}
            </button>
          </div>
        }
      >
        <p className={styles.modal_text}>{t("sure_cancel")}</p>
      </Modal>
    </div>
  );
};

export default ClientsListing;
