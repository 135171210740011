import styles from "./index.module.scss";
import { HiBolt, HiMiniMinusCircle, HiOutlineBanknotes, HiOutlineBolt } from "react-icons/hi2";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthStateContext } from "../../Context/MainContext";
import InfluenceraignCardSkeleton from "../influencer-card-skeleton";
import { HiBadgeCheck, HiCheck, HiPlus, HiStar } from "react-icons/hi";
import { HiShieldCheck } from "react-icons/hi2";
import {
  formatNumber,
  influencerMappedData,
  isValidURL,
} from "../../Utils/helpers";
import influencerPlaceholder from "../../assets/imgs//influencer_placeholder.svg";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { getInfluencerDetails } from "../../Context/Actions/influencers";

const InfluencerListingCard = (props: any) => {
  const { t } = useTranslation();
  const { state } = useContext(AuthStateContext);
  const [cardLoading, setCardLoading] = useState(true);
  const [influencerData, setinfluencerData] = useState<any>(null);
  const [influencerDetails, setInfluencerDetails] = useState<any>(null);
  const [influencerLoading, setInfluencerLoading] = useState<boolean>(false);

  const fillCardData = async () => {
    influencerMappedData(state, props.data).then((res: any) => {
      setinfluencerData(res);
      setCardLoading(false);
    });
  };
  const getInfluencerFullDetails = async (
    e: React.MouseEvent<HTMLDivElement>,
    influenerId: number
  ) => {
    setInfluencerLoading(true);
    props.openDrawer();
    props.setDetailsLoading(true);
    try {
      const res = await getInfluencerDetails(influenerId);
      if (!res.error) {
        const mappedObj = await influencerMappedData(state, res);
        setInfluencerDetails(mappedObj);
        props.setDetailsLoading(false);
        props.openInfDetails(e, mappedObj);
      } else {
        setInfluencerLoading(false);
        return { error: true };
      }
    } catch (err) {
      setInfluencerLoading(false);
      return { error: true };
    }
    setInfluencerLoading(false);
  };
  useEffect(() => {
    if (props.data || state.lookups) {
      fillCardData();
    }
  }, [props.data]);

  const toggleSelectInfluencer = (e: any) => {
    e.stopPropagation();

    props.handleToggleSelectInfluencer(influencerData, e.target.checked);
  };
  const handleImgError = (e: any) => {
    e.target.src = influencerPlaceholder;
  };
  const markAsRecommended = (e: any, value: boolean) => {
    e.stopPropagation();
    console.log(unmarkRef.current, markRef.current, "reeeef")
    props.handleToggleRecommendInfluencer(influencerData, value)

    // if (value) {
    //   unmarkRef.current!.blur()
    // } else {
    //   markRef.current!.blur()
    // }

  }
  const unmarkRef = useRef<HTMLButtonElement>(null);
  const markRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      {cardLoading || influencerData == null ? (
        <InfluenceraignCardSkeleton />
      ) : (
        <div
          // onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          //   props.openInfDetails(e, influencerData)
          // }
          className={`${props.selectedInfluencer ? styles.selected_card : ""} ${styles.influencer_listing_card
            } ${influencerData.metaData?.isAssignedToCampaign
              ? styles.selected_card_opacity
              : ""
            }`}
        >
          <div className={styles.influencer_card_custom_check_container}>
            <label className={styles.influencer_card_custom_check}>
              <input
                type="checkbox"
                checked={
                  (props.selectedInfluencer) ||
                  influencerData.metaData?.isAssignedToCampaign
                }
                onChange={(e) => toggleSelectInfluencer(e)}
                disabled={influencerData.metaData?.isAssignedToCampaign}
              />
              <span className={styles.checkmark}>
                <HiCheck className={styles.checkIcon} />
                <HiPlus className={styles.addIcon} />
              </span>
            </label>
          </div>
          {(props.selectedInfluencer || influencerData.metaData?.isAssignedToCampaign) && !props.recommendedInfluencer ? <div onClick={(e) => markAsRecommended(e, true)} className={styles.markAsRecommended}>
            <HiOutlineBolt />
            <button ref={markRef} >{t("mark_recommended")}</button>
          </div> : ""}
          {(props.selectedInfluencer || influencerData.metaData?.isAssignedToCampaign) && (props.recommendedInfluencer || influencerData.metaData?.isRecommended) ? <div onClick={(e) => markAsRecommended(e, false)} className={styles.markAsNotRecommended}>
            <HiBolt className={styles.bolt} />
            <HiMiniMinusCircle className={styles.minus} />
            <button ref={unmarkRef} >{t('not_recommended')}</button>
          </div> : ""}
          <div className={styles.card_body}>
            <div className={styles.img_container}>
              <Tooltip
                placement={"top"}
                trigger="hover"
                destroyTooltipOnHide={true}
                overlayStyle={{
                  width: "auto",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                overlayClassName="platform_tooltip"
                title={t("view_profile")}
              >
                <img
                  src={influencerData?.avatarUrl || influencerPlaceholder}
                  onError={handleImgError}
                  alt={influencerData?.name}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                    getInfluencerFullDetails(e, influencerData?.id)
                  }
                />
              </Tooltip>
              {/* <div className={styles.influencer_quality}>
                <HiStar />
                <span>{influencerData?.influencerQualityKey?.name}</span>
              </div> */}
            </div>

            <div className={styles.card_text}>
              <h4 className={styles.influencer_name}>
                <Tooltip
                  placement={"top"}
                  trigger="hover"
                  destroyTooltipOnHide={true}
                  overlayStyle={{
                    width: "auto",
                    fontFamily: "inherit",
                    fontWeight: 400,
                  }}
                  overlayClassName="platform_tooltip"
                  title={t("view_profile")}
                >
                  <span
                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                      getInfluencerFullDetails(e, influencerData?.id)
                    }
                  >
                    {influencerData?.name}
                  </span>
                </Tooltip>
                {/* {influencerData?.isFeatured && <HiShieldCheck />} */}
              </h4>
              {influencerData?.influencerNicheKeys && (
                <p
                  className={styles.influencer_niche + " " + "_lr-hide"}
                  data-private
                >
                  {influencerData?.influencerNicheKeys
                    .map((niche: any) => {
                      return niche.name;
                    })
                    .join(", ")}{" "}
                </p>
              )}

              <p
                className={styles.influencer_price_range + " " + "_lr-hide"}
                data-private
              >
                <HiOutlineBanknotes />
                {influencerData?.influencerSocialPlatforms?.maxBasePrice > 0 ? (
                  <span>
                    {" "}
                    {influencerData?.influencerSocialPlatforms?.minBasePrice &&
                      formatNumber(
                        influencerData?.influencerSocialPlatforms?.minBasePrice
                      )}{" "}
                    ~{" "}
                    {influencerData?.influencerSocialPlatforms?.maxBasePrice &&
                      formatNumber(
                        influencerData?.influencerSocialPlatforms.maxBasePrice
                      )}
                  </span>
                ) : (
                  <span className={styles.noPrices}>
                    {t("no_available_prices")}
                  </span>
                )}
              </p>
              {/* <div className={styles.influencer_status_contaier}>
                {influencerData?.influencerBrandSafetyFlagKeys.map(
                  (brandSaftey: any, index: number) =>
                    index < 2 && (
                      <span
                        key={index}
                        className={styles.influencer_status_item}
                      >
                        {brandSaftey.name}
                      </span>
                    )
                )}
                {influencerData?.influencerBrandSafetyFlagKeys.length > 2 && (
                  <span className={styles.influencer_status_more_item}>
                    +{influencerData?.influencerBrandSafetyFlagKeys.length - 2}
                  </span>
                )}
              </div> */}
            </div>
          </div>

          <div className={styles.card_footer}>
            <div className={styles.socialplatforms_container}>
              {influencerData?.influencerSocialPlatforms.map(
                (platform: any, index: number) =>
                  platform &&
                  (influencerData?.influencerSocialPlatforms <= 6 ? (
                    <Tooltip
                      placement={"top"}
                      trigger="hover"
                      destroyTooltipOnHide={true}
                      overlayStyle={{
                        width: "auto",
                        fontFamily: "inherit",
                        fontWeight: 400,
                      }}
                      overlayClassName="platform_tooltip"
                      title={
                        platform.socialPlatformUrl &&
                          isValidURL(platform.socialPlatformUrl)
                          ? t("platform_profile", {
                            socialPlatform: platform.name,
                          })
                          : t("no_available_link")
                      }
                    >
                      <a
                        href={
                          platform.socialPlatformUrl &&
                            isValidURL(platform.socialPlatformUrl)
                            ? platform.socialPlatformUrl
                            : undefined
                        }
                        key={index + platform.name}
                        target="_blank"
                      >
                        <img
                          src={platform.logoUrl}
                          className={styles.socialplatform_item}
                          alt={platform.name}
                        />
                      </a>
                    </Tooltip>
                  ) : (
                    index < 6 && (
                      <Tooltip
                        placement={"top"}
                        trigger="hover"
                        destroyTooltipOnHide={true}
                        overlayStyle={{
                          width: "auto",
                          fontFamily: "inherit",
                          fontWeight: 400,
                        }}
                        overlayClassName="platform_tooltip"
                        title={
                          platform.socialPlatformUrl &&
                            isValidURL(platform.socialPlatformUrl)
                            ? t("platform_profile", {
                              socialPlatform: platform.name,
                            })
                            : t("no_available_link")
                        }
                      >
                        <a
                          href={
                            platform.socialPlatformUrl &&
                              isValidURL(platform.socialPlatformUrl)
                              ? platform.socialPlatformUrl
                              : undefined
                          }
                          key={index + platform.name}
                          target="_blank"
                        >
                          <img
                            src={platform.logoUrl}
                            className={styles.socialplatform_item}
                            alt={platform.name}
                          />
                        </a>
                      </Tooltip>
                    )
                  ))
              )}
              {influencerData?.influencerSocialPlatforms?.length >= 7 && (
                <span className={styles.influencer_socialplatform_more_item}>
                  +{influencerData?.influencerSocialPlatforms.length - 6}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default InfluencerListingCard;
