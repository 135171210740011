import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./assets/fonts/fontello/css/fontello.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./Utils/i18n/i18n";
import { AuthProvider } from "./Context/MainContext";
import getPalette from "tailwindcss-palette-generator";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const loadTheme = () => {
//   const primaryColor = localStorage.getItem("primaryColor");
//   if (primaryColor) {
//     const palette = getPalette({ color: primaryColor, name: 'primary' })
//     document.documentElement.style.setProperty('--primary-700', palette.primary["700"])
//     document.documentElement.style.setProperty('--primary-600', palette.primary["600"])
//     document.documentElement.style.setProperty('--primary-500', palette.primary["500"])
//     document.documentElement.style.setProperty('--primary-400', palette.primary["400"])
//     document.documentElement.style.setProperty('--primary-300', palette.primary["300"])
//     document.documentElement.style.setProperty('--primary-200', palette.primary["200"])
//     document.documentElement.style.setProperty('--primary-100', palette.primary["100"])
//     document.documentElement.style.setProperty('--primary-50', palette.primary["50"])
//   }
// }

// loadTheme()

root.render(
  <I18nextProvider i18n={i18n}>
    <AuthProvider>
      <Suspense>
        <App />
      </Suspense>
    </AuthProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
