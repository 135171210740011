import { useTranslation } from "react-i18next";
import styles from "./index.module.scss"
import { HiOutlineInformationCircle, HiOutlineMagnifyingGlass, HiOutlineShare, HiOutlineBanknotes, HiOutlineGlobeAlt, HiOutlineUsers, HiOutlineSquares2X2, HiGlobeEuropeAfrica, HiOutlineGlobeEuropeAfrica, HiMapPin, HiOutlineMapPin, HiLanguage, HiOutlineLanguage, HiOutlineSquaresPlus } from "react-icons/hi2";
import { TbSquarePlus } from "react-icons/tb";


import { Checkbox, Select, Slider } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthStateContext } from "../../../../../Context/MainContext";
import i18n from "../../../../../Utils/i18n/i18n";
import { PiBaby, PiGenderIntersexLight } from "react-icons/pi";
import { GiDiamondRing } from "react-icons/gi";

const BasicInfo = ({ setFilters, filters }: any) => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups, brands },
        dispatch,
    } = useContext(AuthStateContext);
    const [socialPlatforms, setSocialPlatforms] = useState([]);

    useEffect(() => {
        setSocialPlatforms(
            lookups && lookups[i18n.language]?.socialPlatforms?.map((s: any) => ({
                label: s.name,
                value: s.key
            }))
        );
    }, [lookups, i18n]);


    return (
        <div className={styles.container}>
            <div className={styles.selectConatiner}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineGlobeEuropeAfrica className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("nationality")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        showSearch={false}
                        maxTagCount={1}
                        allowClear
                        options={lookups[i18n.language]?.countries?.map((s: any) => ({
                            label: s.nationality,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_nationality")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(nationality) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, nationality } });
                        }}
                        value={filters.basicInfo.nationality}
                    ></Select>

                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineMapPin className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("residency")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.countries?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_residency")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(residency) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, residency, city: [] } })
                        }}
                        value={filters.basicInfo.residency}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineMapPin className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("city")}</p>
                    </div>
                    <Select
                        labelInValue
                        mode="multiple"
                        maxTagCount={2}
                        disabled={!filters.basicInfo.residency}
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.countries?.find((a: any) => a.key == filters.basicInfo.residency?.key)?.cities.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_city")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(city) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, city } })
                        }}
                        value={filters.basicInfo.city}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineUsers className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("age_group")}</p>
                    </div>
                    <Select
                        labelInValue
                        mode="multiple"
                        maxTagCount={2}
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.ageRanges?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_age_group")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(age_group) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, age_group } })
                        }}
                        value={filters.basicInfo.age_group}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineSquaresPlus className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("niches")}</p>
                    </div>
                    <Select
                        labelInValue
                        mode="multiple"
                        maxTagCount={2}
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.niches?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_niches")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(niches) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, niches } })

                        }}
                        value={filters.basicInfo.niches}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <HiOutlineLanguage className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("speaking_language")}</p>
                    </div>
                    <Select
                        mode="multiple"
                        maxTagCount={2}
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.speakingLanguages?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_speaking_language")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(speaking_language) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, speaking_language } })
                        }}
                        value={filters.basicInfo.speaking_language}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <PiGenderIntersexLight className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("gender")}</p>
                    </div>
                    <Select
                        labelInValue
                        mode="multiple"
                        maxTagCount={2}
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.genders?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_gender")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(gender) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, gender } })
                        }}
                        value={filters.basicInfo.gender}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <GiDiamondRing className={styles.gapIcon} />
                        <p className={`body ${styles.keepCase}`}>{t("marital_status")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={lookups[i18n.language]?.maritalStatuses?.map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_marital_status")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(marital_status) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, marital_status } })
                        }}
                        value={filters.basicInfo.marital_status}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <PiBaby className={styles.gapIcon} />

                        <p className={`body ${styles.keepCase}`}>{t("children")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={[{ key: true, name: t('yes') }, { key: false, name: t('no') }].map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_children")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(children) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, children } })
                        }}
                        value={filters.basicInfo.children}

                    ></Select>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <img src={'/imgs/pets.svg'} alt="pets" className={styles.icon} />
                        <p className={`body ${styles.keepCase}`}>{t("pets")}</p>
                    </div>
                    <Select
                        labelInValue
                        defaultActiveFirstOption={false}
                        rootClassName={styles.social_platform_filters}
                        popupMatchSelectWidth={window.innerWidth == 1440 ? 300 : true}
                        popupClassName="socialPlatform-dropdown"
                        virtual={false}
                        filterOption={true}
                        className={styles.marginTop}
                        style={{ width: "100%" }}
                        allowClear
                        options={[{ key: true, name: t('yes') }, { key: false, name: t('no') }].map((s: any) => ({
                            label: s.name,
                            value: s.key
                        }))}
                        size="large"
                        placeholder={t("choose_pets")}
                        onInputKeyDown={(event) => {
                            if (event.keyCode === 8 || event.key === "Backspace") {
                                return event.stopPropagation();
                            }
                        }}
                        onChange={(pets) => {
                            setFilters({ ...filters, basicInfo: { ...filters.basicInfo, pets } })
                        }}
                        value={filters.basicInfo.pets}

                    ></Select>
                </div>
            </div>
        </div>
    )
}

export default BasicInfo