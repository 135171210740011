export const getDefaultFiltersState = (state: any, i18n: any) => {
  return {
    searchTerm: "",
    platform: {
      platform: {
        key: "Snapchat",
        label: state?.lookups[i18n.language]?.socialPlatforms?.find(
          (s: any) => s.key == "Snapchat"
        ).name,
      },
      service: [],
      aud_country: null,
      aud_gender: null,
      aud_age_group: null,
      other_segments: [],
      inf_types: [],
      filters_show_verfied_only: null,
      service_price: [0, 0],
    },
    basicInfo: {
      nationality: null,
      residency: null,
      city: [],
      age_group: [],
      niches: [],
      speaking_language: [],
      gender: [],
      marital_status: null,
      children: null,
      pets: null,
    },
    badges: {
      commitment_level: [],
      mawthok: null,
      insights_sharing: null,
    },
    brands: {
      signed_exclusive_for: [],
      exclude_selected_brands: null,
    },
    suitable_industries: [],
    tags: {
      tags: [],
      exclude_safety_flags: null,
    },
  };
};
