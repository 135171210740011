import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import {
  CollapseProps,
  Collapse,
  Drawer,
  Tabs,
  TabsProps,
  Modal,
  Radio,
  Input,
  RadioChangeEvent,
  Button,
  Tooltip,
  notification,
} from "antd";
import {
  HiOutlineBanknotes,
  HiOutlineUsers,
  HiOutlineXCircle,
  HiOutlineXMark,
  HiShieldCheck,
} from "react-icons/hi2";
import influencerPlaceholder from "../../assets/imgs//influencer_placeholder.svg";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import InfluencerDetailsPlatformItem from "../InfluencerDetailsPlatformItem";
import Service from "../InfluencerServicesCard/Service/Index";
import { formatNumber } from "../../Utils/helpers";
import { IoIosMale, IoIosFemale } from "react-icons/io";
import CampaignDetailsSkeleton from "../campaign-details-skeleton/campaign-details-skeleton";
import InfluencerBasicInfo from "./InfluencerBasicInfo";
import InfluencerBrandSafteyFlags from "./InfluencerBrandSafteyFlags";
import InfluencerBadges from "./InfluencerBadges";
import InfluencerTags from "./InfluencerTags";
import InfluencerIndustries from "./InfluencerIndustries";
import InfluencerRelations from "./InfluencerRelations";
import InfluencerExclusiveBrands from "./InfluencerExclusiveBrands";

import { TbMessageReport } from "react-icons/tb";

import MailImg from "../../assets/imgs/mail.svg";
import { AuthStateContext } from "../../Context/MainContext";
import { reportInf } from "../../Context/Actions/influencers";
import BasicInfo from "../AssignInfluencersWizard/Step1/Filters/BasicInfo";

const InfluencerDetails = (props: any) => {
  const {
    t,
    i18n: { dir, language },
  } = useTranslation();
  const {
    state: { lookups, brands },
    dispatch,
  } = useContext(AuthStateContext);
  const [socialItems, setSocialItems] = useState<CollapseProps["items"]>([]);
  const [tabsItems, setTabsItems] = useState<TabsProps["items"]>([]);
  const [isScrolling, setIsScrolling] = useState(false);

  const [showScroll, setShowScroll] = useState(false);

  const handleScroll = () => {
    setShowScroll(true);
  };
  console.log(props.influencerDetails?.influencerSocialPlatforms);
  useEffect(() => {
    console.log("loo", lookups);
  });
  const handleSocialPlatformsData = async () => {
    const tempItems: CollapseProps["items"] = [];
    // Use Promise.all to map promises and await them
    await Promise.all(
      props.influencerDetails.influencerSocialPlatforms?.map(
        async (socialItem: any, index: any) => {
          if (socialItem.isActive) {
            if (socialItem.services?.length > 0) {
              const services = await Promise.all(
                socialItem.services.map(async (service: any, index: any) => (
                  <Service
                    isDetailsBox={true}
                    data={service}
                    platformKey={socialItem.key}
                    influencerId={props.influencerDetails.id}
                    key={service.advertisingProductId}
                    serviceKey={service.key}
                    setSelectedInfs={null}
                    name={service.name}
                    state="view"
                    currencyKey={service.currencyKey}
                    price={service.basePrice}
                    basePrice={service.basePrice}
                    checked={false}
                  />
                ))
              );

              tempItems.push({
                key: index,
                label: (
                  <InfluencerDetailsPlatformItem
                    platformImg={socialItem.logoUrl}
                    platformName={socialItem.name}
                    platfromLink={socialItem.socialPlatformUrl}
                    followersCount={socialItem.followersCount}
                    isVerified={socialItem.isVerified}
                    platformKey={socialItem.key}
                    isActive={socialItem.isActive}
                  />
                ),
                children: (
                  <>
                    <div className={styles.services_container}>
                      {socialItem.services.map(
                        (service: any, index: number) => (
                          <Service
                            isDetailsBox={true}
                            data={service}
                            platformKey={socialItem.key}
                            influencerId={props.influencerDetails.id}
                            key={service.advertisingProductId}
                            serviceKey={service.key}
                            setSelectedInfs={null}
                            name={service.name}
                            state="view"
                            currencyKey={service.currencyKey}
                            price={service.basePrice}
                            basePrice={service.basePrice}
                            checked={false}
                          />
                        )
                      )}
                    </div>
                    {(socialItem.audienceSegmentation &&
                      !Object.values(socialItem.audienceSegmentation).every(
                        (a) =>
                          a === null || (Array.isArray(a) && a.length === 0)
                      ) &&
                      socialItem.audienceSegmentation?.countries?.length > 0 &&
                      (socialItem.audienceSegmentation?.countries[0]
                        ?.percentage > 0 ||
                        socialItem.audienceSegmentation?.countries[1]
                          ?.percentage > 0)) ||
                      (socialItem.audienceSegmentation?.genders?.length > 0 &&
                        (socialItem.audienceSegmentation?.genders[0]?.percentage >
                          0 ||
                          socialItem.audienceSegmentation?.genders[1]
                            ?.percentage > 0)) ||
                      (socialItem.audienceSegmentation?.ageRanges &&
                        socialItem.audienceSegmentation?.ageRanges.percentage >
                        0) ? (
                      <div
                        className={styles.segments_wrapper + " " + "_lr-hide"}
                        data-private
                      >
                        <h6 className={styles.segments_title}>
                          {t("top_segments")}
                        </h6>
                        <div className={styles.segments_container}>
                          {socialItem.audienceSegmentation?.countries?.length >
                            0 &&
                            (socialItem.audienceSegmentation?.countries[0]
                              ?.percentage > 0 ||
                              socialItem.audienceSegmentation?.countries[1]
                                ?.percentage > 0) && (
                              <div className={styles.segment_item}>
                                <span className={styles.segment_title}>
                                  {t("countries")}
                                </span>
                                <div className={styles.segment_content}>
                                  {socialItem.audienceSegmentation?.countries.map(
                                    (c: any) =>
                                      c.percentage > 0 ? (
                                        <div
                                          key={c.key}
                                          className={
                                            styles.segment_content_item
                                          }
                                        >
                                          {/* <Flag
                                            height="24"
                                            width="24"
                                            className={
                                              styles.segment_content_item_flag
                                            }
                                            code={c.key.toLowerCase()}
                                          /> */}
                                          <span
                                            className={`${styles.segment_content_item_flag
                                              } fi fis fi-${c.isoTwoCode.toLowerCase()}`}
                                          ></span>
                                          <span>
                                            {Math.round(c.percentage)}%
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}
                                </div>
                              </div>
                            )}
                          {socialItem.audienceSegmentation?.genders?.length >
                            0 &&
                            (socialItem.audienceSegmentation?.genders[0]
                              ?.percentage > 0 ||
                              socialItem.audienceSegmentation?.genders[1]
                                ?.percentage > 0) && (
                              <div className={styles.segment_item}>
                                <span className={styles.segment_title}>
                                  {t("genders")}
                                </span>
                                <div className={styles.segment_content}>
                                  {socialItem.audienceSegmentation.genders.map(
                                    (g: any) =>
                                      g.percentage > 0 ? (
                                        <div
                                          key={g.key}
                                          className={
                                            styles.segment_content_item
                                          }
                                        >
                                          {g.key === "Male" ? (
                                            <IoIosMale
                                              className={styles.maleIcon}
                                            />
                                          ) : (
                                            <IoIosFemale
                                              className={styles.femaleIcon}
                                            />
                                          )}
                                          <span>
                                            {Math.round(g.percentage)}%
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}
                                </div>
                              </div>
                            )}
                          {socialItem.audienceSegmentation?.ageRanges &&
                            socialItem.audienceSegmentation?.ageRanges
                              .percentage > 0 ? (
                            <div className={styles.segment_item}>
                              <span className={styles.segment_title}>
                                {t("agegroups")}
                              </span>
                              <div className={styles.segment_content}>
                                <div className={styles.segment_content_item}>
                                  <HiOutlineUsers
                                    className={styles.age_groups_icon}
                                  />
                                  {socialItem.audienceSegmentation?.ageRanges
                                    .from &&
                                    socialItem.audienceSegmentation?.ageRanges
                                      .to ? (
                                    <label>
                                      {
                                        socialItem.audienceSegmentation
                                          ?.ageRanges.from
                                      }{" "}
                                      -{" "}
                                      {
                                        socialItem.audienceSegmentation
                                          ?.ageRanges.to
                                      }
                                    </label>
                                  ) : (
                                    <label
                                      style={
                                        language == "ar"
                                          ? { direction: "ltr" }
                                          : { direction: "rtl" }
                                      }
                                    >
                                      {
                                        socialItem.audienceSegmentation
                                          ?.ageRanges.from
                                      }
                                    </label>
                                  )}
                                  <span className={styles.moreSpace}>
                                    {Math.round(
                                      socialItem.audienceSegmentation?.ageRanges
                                        .percentage
                                    )}
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {socialItem.audienceSegmentation?.otherSegments
                            ?.length > 0 && (
                              <div
                                className={
                                  styles.segment_item + " " + styles.otherSegments
                                }
                              >
                                <span className={styles.segment_title}>
                                  {t("other_segements")}
                                </span>
                                <div className={styles.segment_content}>
                                  {socialItem.audienceSegmentation?.otherSegments?.map(
                                    (s: any) => (
                                      <div key={s} className={styles.segment_tag}>
                                        {s}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ),
              });
            }
          }
        }
      )
    );
    setSocialItems(tempItems);
    return tempItems;
  };
  const influencerInfo = () => {
    let arr = [
      {
        label: <p>{t("basic_info")}</p>,
        children: <InfluencerBasicInfo details={props.influencerDetails} />,
      },
    ];
    if (
      props.influencerDetails?.metaData?.commitmentLevel ||
      props.influencerDetails?.metaData?.allowInsightsSharing ||
      props.influencerDetails?.metaData?.hasMawthok
    ) {
      arr.push({
        label: <p>{t("badges")}</p>,
        children: <InfluencerBadges details={props.influencerDetails} />,
      });
    }
    if (props.influencerDetails?.relationships) {
      arr.push({
        label: <p>{t("relationships")}</p>,
        children: <InfluencerRelations details={props.influencerDetails} />,
      });
    }
    if (props.influencerDetails?.exclusiveBrands) {
      arr.push({
        label: <p>{t("exclusive_brands")}</p>,
        children: (
          <InfluencerExclusiveBrands details={props.influencerDetails} />
        ),
      });
    }
    if (
      props.influencerDetails?.influencerIndustries &&
      props.influencerDetails?.influencerIndustries.length
    ) {
      arr.push({
        label: <p>{t("suitable_industries")}</p>,
        children: <InfluencerIndustries details={props.influencerDetails} />,
      });
    }
    if (
      props.influencerDetails?.influencerTags &&
      props.influencerDetails?.influencerTags.length
    ) {
      arr.push({
        label: <p>{t("tags")}</p>,
        children: <InfluencerTags details={props.influencerDetails} />,
      });
    }
    if (props.influencerDetails?.influencerBrandSafetyFlagKeys) {
      arr.push({
        label: <p>{t("safety_flags")}</p>,
        children: (
          <InfluencerBrandSafteyFlags details={props.influencerDetails} />
        ),
      });
    }

    return arr;
  };
  const handleSocialPlatformTab = (res: any) => {
    let tempTabsItems: TabsProps["items"] = [];
    tempTabsItems[1] = {
      key: "2",
      label: t("platforms_services"),
      children: (
        <div className={styles.influencer_platforms}>
          {/* <h6 className="title">{t("platforms_services")}</h6> */}
          <p
            className={styles.influencer_price_range + " " + "_lr-hide"}
            data-private
          >
            <HiOutlineBanknotes />
            {props.influencerDetails?.influencerSocialPlatforms?.maxBasePrice >
              0 ? (
              <span>
                {t("service_price_range")}
                {props.influencerDetails?.influencerSocialPlatforms
                  ?.minBasePrice &&
                  formatNumber(
                    props.influencerDetails?.influencerSocialPlatforms
                      .minBasePrice
                  )}{" "}
                ~
                {props.influencerDetails?.influencerSocialPlatforms
                  ?.maxBasePrice &&
                  formatNumber(
                    props.influencerDetails?.influencerSocialPlatforms
                      .maxBasePrice
                  )}
              </span>
            ) : (
              <span className={styles.noPrices}>
                {t("no_available_prices")}
              </span>
            )}
          </p>
          <div className={styles.services_wrapper}>
            <Collapse
              defaultActiveKey={Array.from({ length: res.length + 1 }, (_, i) =>
                i.toString()
              )}
              items={res}
              className="inf_details_accordion"
            />
          </div>
        </div>
      ),
    };
    tempTabsItems[0] = {
      key: "1",
      label: t("info"),
      children: (
        <Collapse
          defaultActiveKey={Array.from({ length: 10 }, (_, i) => i.toString())}
          items={influencerInfo()}
          className="inf_details_accordion inf_info"
        />
      ),
    };
    setTabsItems(tempTabsItems);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (props.influencerDetails) {
        await handleSocialPlatformsData().then((res: any) => {
          handleSocialPlatformTab(res);
        });
      }
    };
    fetchData();
  }, [props.influencerDetails]);
  const handleImgError = (e: any) => {
    e.target.src = influencerPlaceholder;
  };
  const handleDrawerScroll = (e: any) => {
    const { scrollTop } = e.target;
    setIsScrolling(scrollTop > 0);
  };
  const onClose = () => {
    props.onClose();
  };

  const { TextArea } = Input;

  const [comment, setComment] = useState("");
  const [selectedReportKey, setSelectedReportKey] = useState("");

  const setReportKeys: (
    key: string,
    e: RadioChangeEvent | ChangeEvent<HTMLTextAreaElement>
  ) => void = (
    key: string,
    e: RadioChangeEvent | ChangeEvent<HTMLTextAreaElement>
  ) => {
      if (key == "selectedKey") {
        setSelectedReportKey(e.target.value);
      } else if (key == "otherComment") {
        setOtherComment(e.target.value);
      } else {
        setComment(e.target.value);
      }
    };

  const [modalOpen, setModalOpen] = useState(false);
  const [otherComment, setOtherComment] = useState("");

  const [successOpened, setSuccessOpened] = useState(false);

  const [reportLoading, setReportLoading] = useState(false);

  const isReportValid = () => {
    if (reportLoading) return true;
    if (!selectedReportKey) {
      return true;
    } else if (
      selectedReportKey == "MissingData" ||
      selectedReportKey == "InAccurateData"
    ) {
      return false;
    } else if (selectedReportKey == "Other") {
      return !otherComment.trim().length;
    }
  };

  const submitHandler = async () => {
    setReportLoading(true);
    if (selectedReportKey) {
      console.log("selectedReportKey is undefined or null", selectedReportKey);
    }

    const influencerReportData = {
      basicInfo: {
        issueTypeKey: selectedReportKey,
        comment: selectedReportKey === "Other" ? otherComment : comment,
      },
    };
    const res = await reportInf(
      props.influencerDetails?.id,
      influencerReportData
    );
    if (!res.error) {
      setReportLoading(false);
      setModalOpen(false);
      setSuccessOpened(true);
      setSelectedReportKey("");
      setOtherComment("");
      setComment("")
    } else {
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("error_label"),
        description: t("something_went_wrong"),
        icon: <HiOutlineXCircle className="danger-text" />,
        className: "no-wrap",
      });
      setReportLoading(false);

    }
  };

  return (
    <>
      <div className="drawer_wrapper">
        <Drawer
          title={null}
          placement={dir() === "ltr" ? "right" : "left"}
          closable={true}
          onClose={onClose}
          closeIcon={<HiOutlineXMark className="close-drawer" />}
          open={props.open}
          getContainer={props.container}
          className={`${isScrolling ? "scrolling-drawer" : ""}`}
          contentWrapperStyle={{ width: "36.75rem" }}
        >
          <div
            className="scrolling-drawer-content"
            onScroll={handleDrawerScroll}
          >
            <div
              className={`${styles.influencer_details} ${showScroll ? "showScroll" : ""
                }`}
              onScroll={handleScroll}
            >
              {props.loading ? (
                <CampaignDetailsSkeleton />
              ) : (
                <>
                  <div
                    className={styles.influencer_basicInfo + " " + "_lr-hide"}
                    data-private
                  >
                    <div className={styles.influencer_img_container}>
                      <img
                        src={
                          props.influencerDetails?.avatarUrl ||
                          influencerPlaceholder
                        }
                        alt={props.influencerDetails?.name}
                        onError={handleImgError}
                      />
                      {/* <div className={styles.influencer_quality}>
            <HiStar />
            <span>{props.influencerDetails.influencerQualityKey.name}</span>
          </div> */}
                    </div>
                    <div className={styles.influencer_info}>
                      <div className={styles.nameCont}>
                        <h4 className={styles.influencer_name}>
                          <span>{props.influencerDetails?.name}</span>
                          {/* {props.influencerDetails?.isFeatured && <HiShieldCheck />} */}
                        </h4>
                        <Tooltip
                          title={t("report_problem")}
                          overlayClassName="platform_tooltip"
                        >
                          <button
                            className={styles.reportBtn}
                            onClick={() => setModalOpen(true)}
                          >
                            <TbMessageReport />
                          </button>
                        </Tooltip>
                      </div>

                      <p className={styles.influencer_id}>
                        INF - {props.influencerDetails?.id}
                      </p>
                      {props.influencerDetails?.influencerNicheKeys && (
                        <p className={styles.influencer_niche}>
                          {props.influencerDetails?.influencerNicheKeys
                            .map((niche: any) => {
                              return niche.name;
                            })
                            .join(", ")}{" "}
                        </p>
                      )}

                      {/* {props.influencerDetails?.influencerBrandSafetyFlagKeys && (
                      <div className={styles.influencer_status_contaier}>
                        {props.influencerDetails?.influencerBrandSafetyFlagKeys?.map(
                          (brandSaftey: any, index: number) => (
                            <span
                              key={brandSaftey.key}
                              className={styles.influencer_status_item}
                            >
                              {brandSaftey.name}
                            </span>
                          )
                        )}
                      </div>
                    )} */}
                    </div>
                  </div>
                  <Tabs
                    defaultActiveKey="1"
                    items={tabsItems}
                    className="inf_tabs"
                  />
                </>
              )}
            </div>
          </div>
        </Drawer>
      </div>
      {modalOpen ? (
        <Modal
          centered
          wrapClassName="report-modal"
          title={t("report_problem")}
          open={modalOpen}
          className={styles.reportModal + " "}
          onCancel={() => {
            setModalOpen(false);
            setComment("");
            setSelectedReportKey("");
          }}
          footer={() => (
            <div className="btn-group align-end">
              <button
                onClick={() => setModalOpen(false)}
                className="lavad-btn lavad-btn-md lavad-btn-ghost"
              >
                {t("cancel")}
              </button>

              <button
                onClick={submitHandler}
                disabled={isReportValid()}
                className="lavad-btn lavad-btn-md lavad-btn-primary"
              >
                {reportLoading ? (
                  <span className="icon-loader spin"></span>
                ) : (
                  t("send")
                )}
              </button>
            </div>
          )}
        >
          <div className={styles.modalBody}>
            <Radio.Group
              value={selectedReportKey}
              className={styles.radios + ' ' + 'reportRadios'}
              onChange={(e) => setReportKeys("selectedKey", e)}
            >
              {lookups[language].zohoInfluencerProfileIssueTypes?.map((a: any) => (
                <Radio value={a.key}>{a.name}</Radio>
              ))}
              {selectedReportKey == "Other" && (
                <TextArea
                  value={otherComment}
                  onChange={(e) => setReportKeys("otherComment", e)}
                  showCount
                  maxLength={1000}
                  rows={4}
                  className={`lavad-input lavad-input-lg lavad-textarea  ${styles.commentResize} ${styles.otherTxt} ${styles.removeResize} reportTxtArea`}
                  placeholder={t("tell_us_about_issue")}
                />
              )}
            </Radio.Group>

            {(selectedReportKey != "Other" ||
              !selectedReportKey) && (
                <div className={styles.commentSection}>
                  <p
                    className={`${!selectedReportKey ? styles.disabledTxt : ""}`}
                  >
                    {t("comment")} <span>({t("optional")})</span>
                  </p>
                  <TextArea
                    disabled={!selectedReportKey}
                    value={comment}
                    onChange={(e) => setReportKeys("comment", e)}
                    showCount
                    maxLength={1000}
                    rows={4}
                    className={`lavad-input lavad-input-lg lavad-textarea ${styles.commentResize
                      } ${styles.removeResize} ${!selectedReportKey ? styles.disabledTxt : ""
                      }`}
                    placeholder={t("report_placeholder")}
                  />
                </div>
              )}
          </div>
        </Modal>
      ) : (
        ""
      )}
      <Modal
        centered
        wrapClassName="report-modal no-footer-border"
        open={successOpened}
        onCancel={() => setSuccessOpened(false)}
        footer={""}
      >
        <div className={styles.succModalBody}>
          <p>{t("report_send_succ")}</p>
          <img src={MailImg} />
        </div>
      </Modal>
    </>
  );
};

export default InfluencerDetails;
