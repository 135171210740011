import { Col, ConfigProvider, Drawer, Dropdown, Form, Input, MenuProps, Modal, notification, Row, Skeleton, Table, Tabs, TabsProps, Tooltip } from 'antd'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next';
import { HiArrowRight, HiCalendar, HiOutlineArrowPathRoundedSquare, HiOutlineCalendar, HiOutlineCheckCircle, HiOutlineChevronLeft, HiOutlineChevronRight, HiOutlineClock, HiOutlineMagnifyingGlass, HiOutlinePencil, HiOutlinePencilSquare, HiOutlineRocketLaunch, HiOutlineSquare2Stack, HiOutlineStopCircle, HiOutlineTrash, HiOutlineTrophy, HiOutlineXCircle, HiOutlineXMark, HiSquare2Stack, HiUserCircle } from 'react-icons/hi2';
import { useContext, useEffect, useState } from 'react';
import CreateClient from '../../../Components/CreateClient';
import { v4 as uuidv4 } from 'uuid';
import { ClientBrandItem, ClientCampaignItem, editClient, getApplicationUsers, getClient, getClientBrandsListing, getClientCampaignListing } from '../../../Context/Actions/clients';
import i18n from '../../../Utils/i18n/i18n';
import { AuthStateContext } from '../../../Context/MainContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetClientInterface } from '../../../Context/Actions/clients';
import { HiArrowLeft, HiOutlineEllipsisVertical, HiOutlineInformationCircle } from "react-icons/hi2";
import { HiOutlineCash, HiOutlineDownload } from "react-icons/hi";

import { Typography } from 'antd';
import { PiClockClockwiseFill } from 'react-icons/pi';
import dayjs from 'dayjs';
import { getFileExtension, isValidURL, moneyFormatter } from '../../../Utils/helpers';
import { ColumnGroupType, ColumnsType } from 'antd/lib/table';
import { AnyObject } from 'antd/lib/_util/type';

import BrandsEmptyState from '../../../assets/imgs/client_brands_empty.svg'
import CampaignsEmptyState from '../../../assets/imgs/client_campaigns_empty.svg'

import nodata from "../../../assets/imgs/filters-no-data.svg";
import ActivityLogs from '../../../Components/Activitylogs';




const { Paragraph, Text } = Typography;


const ClientDetails = () => {
    const { id } = useParams()
    const [form] = Form.useForm();

    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups, brands },
        dispatch,
    } = useContext(AuthStateContext);
    const [openEdit, setOpenEdit] = useState(false);

    const [fileList, setFileList] = useState<any>([])
    const [selectedBrands, setSelectedBrands] = useState<any>([{ key: '', type: '', uid: uuidv4() }])

    const [editLoading, setEditLoading] = useState(false);

    const [createErrors, setCreateErrors] = useState({ vat: "", cr: "", legalEn: '', legalAr: '' });

    const [appUsers, setAppUsers] = useState<any>([])

    const [selectedFilters, setSelectedFilters] = useState({ search: "" })

    const [brandSortDirections, setBrandSortDirections] = useState<any>({ name: '', direction: '' })

    const [brandPagination, setBrandPagination] = useState({
        page: 1,
        pageSize: 10,
        total: 0,
    });


    const [campaignSortDirections, setCampaignSortDirections] = useState<any>({ name: '', direction: '' })

    const [campaignPagination, setCampaignPagination] = useState({
        page: 1,
        pageSize: 10,
        total: 0,
    });

    const [brandsListing, setBrandsLising] = useState<any>([{ load: 'load' }, { load: 'load' }, { load: 'load' },])
    const [campaignsListing, setCampaignsLising] = useState<any>([{ load: 'load' }, { load: 'load' }, { load: 'load' },])

    const [brandsLoading, setBrandsLoading] = useState(true);
    const [campaignsLoading, setCampaignsLoading] = useState(true);



    const [details, setDetails] = useState<{
        id: string | number;
        basicInfo: {
            agencyFees: number;
            name: string;
            countryKey: string;
            vatNumber: string;
            commercialRegistration: string;
            legalName: {
                en: string;
                ar: string;
            };
            ownerId: string;
            createdBy: string;
            creationDate: number;
        };

    }>({
        id: '',
        basicInfo: {
            agencyFees: 0,
            name: '',
            countryKey: "",
            vatNumber: "",
            commercialRegistration: "",
            legalName: {
                en: "",
                ar: "",
            },
            ownerId: "",
            createdBy: "",
            creationDate: 0,
        }

    });

    const [formValues, setFormValues] = useState({ name: "", legalEn: "", legalAr: "", vat: "", cr: "", fees: "", owner: "", country: '', brand: '', attachment: "" })

    const [modalOpen, setModalOpen] = useState(false);

    const [client, setClient] = useState<any>(null)

    const [detailsLoading, setDetailsLoading] = useState(false)

    const [openDelete, setOpenDelete] = useState(false)

    const navigate = useNavigate();

    const [showLogs, setShowLogs] = useState(false)



    useEffect(() => {
        const getUsers = async () => {
            const users = await getApplicationUsers()
            setAppUsers(users.data)
        }
        getUsers()

    }, [i18n.language, lookups])

    useEffect(() => {
        getClientDetails()

    }, [i18n.language, lookups, id])

    useEffect(() => {
        getBrandsListing()
    }, [i18n.language, lookups, id, selectedFilters, brandSortDirections])

    useEffect(() => {
        getCampaignsListing()
    }, [i18n.language, lookups, id, selectedFilters, campaignSortDirections])




    const getBrandsListing = async () => {
        setBrandsLoading(true)
        setBrandsLising([{ name: '' }, { name: '' }, { name: '' }, , { name: '' }, { name: '' },])
        const brands: { totalRecords: number, data: ClientBrandItem[] } = await getClientBrandsListing(selectedFilters, brandSortDirections, brandPagination.page, brandPagination.pageSize, id)
        const mappedData = brands.data.map(d => {
            const obj = {
                name: { en: d.basicInfo.name.en, ar: d.basicInfo.name.ar }, logo: d.basicInfo.logoUrl, url: d.basicInfo.websiteUrl, allCampaigns: d.campaignsSummary.totalCount, totalSelling: d.campaignsSummary.totalSellingPrice, acceptedCampaigns: { a: d.campaignsSummary?.totalAcceptedCount, b: `${((d.campaignsSummary?.totalAcceptedCount / d.campaignsSummary?.totalCount) * 100).toFixed(0)}%` }, type: lookups && lookups[i18n.language].brandTypes.find((a: any) => a.key == d.metaData.typeKey)?.name, association: lookups && lookups[i18n.language].clientBrandRelationships.find((a: any) => a.key == d.clientInfo.relationshipKey)?.name
            }
            return obj

        })
        setBrandsLising(mappedData)
        setBrandPagination(prev => ({ ...prev, total: brands.totalRecords }))
        setBrandsLoading(false)
    }

    const getCampaignsListing = async () => {
        setCampaignsLoading(true)
        setCampaignsLising([{ name: '' }, { name: '' }, { name: '' }, , { name: '' }, { name: '' },])

        const campaigns: { totalRecords: number, data: ClientCampaignItem[] } = await getClientCampaignListing(selectedFilters, campaignSortDirections, campaignPagination.page, campaignPagination.pageSize, id)
        const mappedData = campaigns.data.map(d => {
            const obj = {
                creationDate: d.auditInfo.created.date, name: d.basicInfo.name, id: d.basicInfo.id, brand: { name: d.brandInfo.name, logo: d.brandInfo.logoUrl }, requestedInfs: d.basicInfo.requestedInfluencersCount, assignedInfs: d.summary.assignedInfluencersCount, cost: d.summary?.cost, status: d.basicInfo.status
            }
            return obj

        })
        setCampaignsLising(mappedData)
        setCampaignPagination(prev => ({ ...prev, total: campaigns.totalRecords }))
        setCampaignsLoading(false)
    }

    const getClientDetails = async () => {
        setDetailsLoading(true)
        const client: GetClientInterface = await getClient(id as string)
        console.log(client, "client details")
        setClient(client)
        setDetails({
            id: client.basicInfo.id,
            basicInfo: { agencyFees: client.basicInfo.agencyFees, name: client.basicInfo.name, countryKey: client.basicInfo.countryKey, vatNumber: client.basicInfo.vatNumber, commercialRegistration: client.basicInfo.commercialRegistration, legalName: { en: client.basicInfo.legalName.en, ar: client.basicInfo.legalName.ar }, ownerId: client.basicInfo.owner?.id, createdBy: client.auditInfo.created?.by?.name, creationDate: client.auditInfo.created.date }
        })
        setSelectedBrands(client?.assignedbrands?.length ? client.assignedbrands?.map((a) => ({ key: a.brand.id, type: a.clientRelationshipKey, uid: uuidv4() })) : [{ key: '', type: '', uid: uuidv4() }])
        setFileList(client.basicInfo?.attachments?.length ? client.basicInfo.attachments?.map((a: any) => ({ url: a.url, uid: uuidv4(), name: a.fileName, percent: 100, status: 'done' })) : [])
        setDetailsLoading(false)

    }



    const onSubmitHandler = async () => {
        // setCreateErrors({ vat: "vat", cr: "cr" })
        // submit handler
        setEditLoading(true)
        const values = form.getFieldsValue()
        const client = await editClient({ basicInfo: { agencyFees: (typeof values.agency_fees == 'number' || (typeof values.agency_fees == 'string' && values.agency_fees)) ? Number(values.agency_fees) : null, name: values.name, countryKey: values.country, vatNumber: values.country == "SAU" ? values.vat ? values.vat : null : null, attachments: fileList.map((file: any) => ({ url: file.url, fileName: file.name })), commercialRegistration: values.country == "SAU" ? values.cr ? values.cr : null : null, legalName: { en: values.legalEn, ar: values.legalAr }, ownerId: values.account_owner ? values.account_owner : null }, brands: selectedBrands.filter((b: any) => b.key && b.type).map((a: any) => ({ id: a.key, clientRelationshipKey: a.type })) }, id as string)
        console.log(client, "cient")
        if (!client.error) {
            cancel()
            notification.open({
                placement: dir() === "rtl" ? "topLeft" : "topRight",
                message: t("success_label"),
                description: t("client_edited"),
                icon: <HiOutlineCheckCircle className="success-text" />,
                className: "no-wrap",
            });
            getClientDetails()
            getBrandsListing()
        } else {
            setCreateErrors({ cr: client.errors.find((e: any) => e.field == "basicInfo.commercialRegistration") ? "cr_err" : "", vat: client.errors.find((e: any) => e.field == "basicInfo.vatNumber") ? "vat_err" : "", legalAr: client.errors.find((e: any) => e.field == "basicInfo.legalName.ar") ? "legalAr_err" : "", legalEn: client.errors.find((e: any) => e.field == "basicInfo.legalName.en") ? "legalEn_err" : "" })
        }
        setEditLoading(false)


    }

    const cancel = () => {
        setOpenEdit(false)
        setCreateErrors({ vat: "", cr: "", legalAr: "", legalEn: "" })
        setSelectedBrands(client?.assignedbrands?.length ? client.assignedbrands?.map((a: any) => ({ key: a.brand.id, type: a.clientRelationshipKey, uid: uuidv4() })) : [{ key: '', type: '', uid: uuidv4() }])
        setFileList(client.basicInfo?.attachments?.length ? client.basicInfo.attachments?.map((a: any) => ({ url: a.url, uid: uuidv4(), name: a.fileName, percent: 100, status: 'done' })) : [])

    }

    const openEditDrawer = async () => {
        setOpenEdit(true)
    }


    const checkIfHasData = () => {
        // add if fields change
        const hasValue = Object.values(formValues).some((a: any) => !!a)
        if (hasValue) {
            console.log()
            setModalOpen(true)
        } else {
            cancel()
        }
        // if(form.getFieldsValue)
    }
    useEffect(() => {

        const hasValue = Object.values(formValues).some((a: any) => !!a)
        window.onbeforeunload = function (e) {
            if (hasValue && openEdit) {
                return "Please click 'Stay on this Page' if you did this unintentionally";
            }
        };
    }, [openEdit, formValues])

    // <button onClick={openEditDrawer}>Edit Client</button>

    const items: MenuProps['items'] = [
        {
            label: <div className={styles.editBtn}><HiOutlinePencil /> {t("edit_client")}</div>,
            key: '0',
            onClick: openEditDrawer
        },
        // {
        //     label: <div className={styles.deleteBtn}><HiOutlineTrash /> {t("delete")}</div>,
        //     key: '1',
        //     onClick: () => setOpenDelete(true)
        // },

    ];
    let brandColumns: ColumnsType<AnyObject> | undefined = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'brandName',
            sorter: true,
            render: (a: any, _) => {
                return <div className={styles.brandName}>
                    {brandsLoading ? <><Skeleton.Button active style={{ width: '64px', height: '64px' }} /><Skeleton className='brand-name-skeleton' active paragraph={{ rows: 1 }} /></> : <>
                        <img src={_.logo} alt={a.en} />
                        <div>
                            <p>{a.en} - {a.ar}</p>
                            {isValidURL(_.url) ? <a target='_blank' href={_.url}>{_.url}</a> : ''
                            }                        </div>
                    </>}
                </div>
            }
        },

        {
            title: t('all_campaigns'),
            dataIndex: 'allCampaigns',
            key: 'campaignsCount',
            sorter: true,
            render: (a: any) => brandsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? a : <span className='alignText'>-</span>

        },
        {
            title: t('accepted_campaigns'),
            dataIndex: 'acceptedCampaigns',
            key: 'acceptedCampaignsCount',
            render: (obj: any) => brandsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : obj.a && obj.b ? <div className={styles.acceptedCampaign}><span>{obj.a}</span><span>{obj.b}</span></div> : <span className='alignText'>-</span>,
            sorter: true,
        },
        {
            title: <p className={styles.totalSelling}><span>{t('total_selling')}</span> <Tooltip title={t('total_cost_accepted_campaigns')}><HiOutlineInformationCircle /></Tooltip></p>,
            dataIndex: 'totalSelling',
            key: 'totalSellingPrice',
            sorter: true,
            render: (a: any) => brandsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <p className={styles.sellPrice}>{moneyFormatter(a)} <span>{i18n.language == "en" ? "SAR" : "ر.س"}</span></p> : <span className='alignText'>-</span>
        },
        {
            title: t('association'),
            dataIndex: 'association',
            key: 'clientBrandRelationship',
            sorter: true,
            render: (a: any) => brandsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a
        },
        {
            title: t('type'),
            dataIndex: 'type',
            key: 'brandType',
            sorter: true,
            render: (a: any) => brandsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <span style={{ textTransform: 'capitalize' }}>{a}</span> : <span className='alignText'>-</span>
        },

    ];

    const returnStatusIcon = (status: any) => {
        if (status.toLowerCase() == "new") {
            return <HiOutlineRocketLaunch width={18} height={18} />;
        } else if (status.toLowerCase() == "planning") {
            return <HiOutlinePencilSquare width={18} height={18} />;
        } else if (status.toLowerCase() == "accepted") {
            return <HiOutlineCheckCircle width={18} height={18} />;
        } else if (status.toLowerCase() == "waiting") {
            return <HiOutlineClock width={18} height={18} />;
        } else if (status.toLowerCase() == "running") {
            return <HiOutlineArrowPathRoundedSquare width={18} height={18} />;
        } else if (status.toLowerCase() == "cancelled") {
            return <HiOutlineXCircle width={18} height={18} />;
        } else if (status.toLowerCase() == "paused") {
            return <HiOutlineStopCircle width={18} height={18} />;
        } else if (status.toLowerCase() == "completed") {
            return <HiOutlineTrophy width={18} height={18} />;
        }
    };

    const returnStatusClass = (status: string) => {
        let labelClass = "";
        if (status === "Cancelled") {
            labelClass = "label-danger";
        } else if (status === "Accepted" || status === "Completed") {
            labelClass = "label-success";
        } else if (status === "Planning") {
            labelClass = "label-warning";
        } else if (status === "Waiting") {
            labelClass = "label-warning-700";
        } else if (status === "New") {
            labelClass = "label-info";
        } else if (status === "Running") {
            labelClass = "label-info-700";
        } else if (status === "Paused") {
            labelClass = "label-neutral";
        } else {
            labelClass = "";
        }
        return labelClass
    }




    let campaignColumns: ColumnsType<AnyObject> | undefined = [
        {
            title: t('creationDate'),
            dataIndex: 'creationDate',
            key: 'creationDate',
            render: (a: any, _) => {
                return campaignsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : dayjs(a).locale(i18n.language).format("MMM D, YYYY")
            },
            className: 'fs_12'
        },

        {
            title: t('campaignName'),
            dataIndex: 'name',
            key: 'campaignName',
            render: (a: any, _: any) => campaignsLoading ? <Skeleton className='campaign-details-skeleton' active paragraph={{ rows: 1 }} /> : a ? <div className={styles.campaignName}><p><Link to={`/campaign/${_.id}`} className='changeOnHover'>{a}</Link></p> <span>#{_.id}</span></div> : <span className='alignText'>-</span>

        },
        {
            title: t('brand_details'),
            dataIndex: 'brand',
            key: 'brand',
            sorter: true,
            render: (obj: any) => campaignsLoading ? <Skeleton className='campaign-brand-skeleton' active avatar paragraph={{ rows: 0 }} /> : obj ? <div className={styles.brand}><img src={obj.logo} alt='brand' /> <p>{obj.name}</p></div> : <span className='alignText' > -</span>,
        },
        {
            title: t('requested_infs'),
            dataIndex: 'requestedInfs',
            key: 'RequestedInfluencersCount',
            sorter: true,
            render: (a: any) => campaignsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? a : <span className='alignText'>-</span>
        },
        {
            title: t('assigned_infs'),
            dataIndex: 'assignedInfs',
            key: 'assignedInfluencersCount',
            sorter: true,
            render: (a: any) => campaignsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <span className={styles.assignedInfs}>{a}</span> : <span className='alignText'>-</span>
        },
        {
            title: <p className={styles.totalSelling}><span>{t('cost')}</span> <Tooltip title={t('total_cost_selling_price')}><HiOutlineInformationCircle /></Tooltip></p>,
            dataIndex: 'cost',
            key: 'cost',
            sorter: true,
            render: (a: any) => campaignsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <p className={styles.sellPrice}>{moneyFormatter(a)} <span>{i18n.language == "en" ? "SAR" : "ر.س"}</span></p> : <span className='alignText'>-</span>
        },
        {
            title: t('status'),
            dataIndex: 'status',
            key: 'campaignStatus',
            sorter: true,
            render: (a: any) => campaignsLoading ? <Skeleton.Button active style={{ width: '74px', height: '17px' }} /> : a ? <div className={`${returnStatusClass(a)} ${styles.status}`}>{returnStatusIcon(a)}<span className='fs_12'>{lookups && lookups[i18n.language]?.campaignStatuses?.find(
                (s: any) => s.key.toLowerCase() === a.toLowerCase()
            )?.name}</span></div> : <span className='alignText'>-</span>
        },

    ];

    const renderBrands = () => {
        if (!brandsListing.length && !selectedFilters.search && !brandsLoading) {
            return <div className={styles.empty}>
                <img src={BrandsEmptyState} alt='' />
                <p>{t('client_empty_brands')}</p>
                <p>{t('client_assign_brands')}</p>
                <div onClick={openEditDrawer} className="lavad-btn lavad-btn-lg lavad-btn-outline btn-icon"><HiOutlinePencil /> {t("edit_client")}</div>

            </div>
        } else if (!brandsListing.length && selectedFilters.search) {
            return <div className={styles.emptyStateWithFilters}>
                <img src={nodata} alt="no data" />
                <div className={styles.empty_state_text}>
                    <h3>{t("no_results_found")}</h3>
                    <p className="title">{t("no_results_subtitle")}</p>
                </div>
            </div>
        }
        return <div className={styles.tableContainer}><ConfigProvider theme={{
            components: {
                Table: {
                    borderColor: '#EEE',
                    rowHoverBg: '#F5F6FF',
                    headerColor: '#1F1F1F',
                    headerSplitColor: "transparent",


                },
            },
        }}>{brandColumns && <Table className='brandsTable' pagination={{
            defaultPageSize: brandPagination?.pageSize, total: brandPagination?.total, current: brandPagination?.page, onChange(page, pageSize) {
                setBrandPagination((prev: any) => ({ ...prev, page, pageSize }))
            },
        }} scroll={{ x: 1216, scrollToFirstRowOnChange: true }} showSorterTooltip={false} onChange={(p, f, s: any) => {
            setBrandSortDirections((prev: any) => ({ name: [s.columnKey], direction: s.order ? s.order : "" }));
        }} columns={brandColumns} dataSource={brandsListing} />}</ConfigProvider></div>
    }

    const renderCampaigns = () => {
        if (!campaignsListing.length && !selectedFilters.search && !campaignsLoading) {
            return <div className={styles.empty}>
                <img src={CampaignsEmptyState} alt='' />
                <p>{t('client_empty_campaigns')}</p>
                <p>{t('client_assign_campaigns')}</p>
                <div onClick={() => navigate('/')} className="lavad-btn lavad-btn-lg lavad-btn-outline btn-icon">{t("view_campaigns")} {i18n.language == "en" ? <HiOutlineChevronRight /> : <HiOutlineChevronLeft />}</div>

            </div>
        }
        else if (!campaignsListing.length && selectedFilters.search) {
            return <div className={styles.emptyStateWithFilters}>
                <img src={nodata} alt="no data" />
                <div className={styles.empty_state_text}>
                    <h3>{t("no_results_found")}</h3>
                    <p className="title">{t("no_results_subtitle")}</p>
                </div>
            </div>
        }
        return <div className={styles.tableContainer}><ConfigProvider theme={{
            components: {
                Table: {
                    borderColor: '#EEE',
                    rowHoverBg: '#F5F6FF',
                    headerColor: '#1F1F1F',
                    headerSplitColor: "transparent",


                },
            },
        }}>{brandColumns && <Table onRow={(record, rowIndex) => {
            return {
                onClick: event => {
                    navigate(`/campaign/${record.id}`)
                },
            };
        }} className='campaignsTable' pagination={{
            defaultPageSize: campaignPagination?.pageSize, total: campaignPagination?.total, current: campaignPagination?.page, onChange(page, pageSize) {
                setCampaignPagination((prev: any) => ({ ...prev, page, pageSize }))
            },
        }} scroll={{ x: 1216, scrollToFirstRowOnChange: true }} showSorterTooltip={false} onChange={(p, f, s: any) => {
            setCampaignSortDirections((prev: any) => ({ name: [s.columnKey], direction: s.order ? s.order : "" }));
        }} columns={campaignColumns} dataSource={campaignsListing} />}</ConfigProvider></div>
    }
    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: <div className={styles.tabTitle}><p>{t('campaigns')}</p> <span>{!campaignsLoading ? campaignPagination.total : 0}</span></div>,
            children: renderCampaigns()
        },
        {
            key: '2',
            label: <div className={styles.tabTitle}><p>{t('brands')}</p> <span>{!brandsLoading ? brandPagination.total : 0}</span></div>,
            children: renderBrands(),
        },


    ];


    const downloadData = async (fileURL: any, fileName: any) => {
        try {
            // Fetch data from an API or other source
            const response = await fetch(fileURL);
            const blob = await response.blob(); // Convert response to Blob

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);
            const filename = 'file'; // Desired file name

            // Create a temporary <a> element
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;

            // Append the <a> element to the body
            document.body.appendChild(a);
            a.click(); // Simulate a click to start the download

            // Remove the <a> element from the body
            document.body.removeChild(a);
            URL.revokeObjectURL(url); // Release the Blob URL

        } catch (error) {
            console.error('Error downloading data:', error);
        }
    }

    const renderUploadedItem = (file: any) => {
        let extension = getFileExtension(file.name);
        extension = (extension == "jpeg" || extension == "jpg" || extension == "png" || !extension) ? "img" : (extension == "doc" || extension == "docx") ? 'doc' : (extension == "ppt" || extension == "pptx") ? "ppt" : extension;
        return <div className={styles.uploadedImg}><div><img src={`/imgs/${extension.toLowerCase()}.svg`} alt='' />  <div className={styles.txt}>
            <p>{file.name.split(".")[0]}</p>
            {getFileExtension(file.name) ? <span>.{getFileExtension(file.name)}</span> : ""}
        </div></div><HiOutlineDownload onClick={() => downloadData(file.url, file.name)} /></div>
    }

    return (
        <>
            <Row className={styles.row}>
                <Col span={6} className={styles.left}>
                    <Link to={"/clients"} className={styles.navigate}>
                        {detailsLoading ? <Skeleton active paragraph={{ rows: 0 }} style={{ height: '17px' }} /> : <>{i18n.language == "en" ? <HiArrowLeft /> : <HiArrowRight />}
                            <span>{t('back_to_clients')}</span></>}
                    </Link>
                    <div className={styles.basicInfo}>
                        <div className={styles.idContainer}>
                            {detailsLoading ? <Skeleton.Button active style={{ maxWidth: '197px', minWidth: '197px', height: "16px" }} /> : <p>#{details?.id}</p>}
                            {detailsLoading ? <Skeleton.Button active style={{ width: '16px', minWidth: '16px', height: "16px" }} /> : <Dropdown placement={i18n.language == "en" ? 'bottomRight' : "bottomLeft"} className='client-menu' menu={{ items }} trigger={['click']}>
                                <a className={styles.menu} onClick={(e) => e.preventDefault()}>
                                    <HiOutlineEllipsisVertical />
                                </a>
                            </Dropdown>}
                        </div>
                        {detailsLoading ? <Skeleton.Button active style={{ maxWidth: '252px', minWidth: '252px', height: "16px" }} /> : ''}

                        {detailsLoading ? <Skeleton.Button active style={{ maxWidth: '291px', minWidth: '291px', height: "16px" }} /> : <p className={styles.cliName}>{details?.basicInfo?.name}</p>}
                        <div className={styles.bottomSec}>
                            {detailsLoading ? <Skeleton className='details-avatar-skeleton' active avatar paragraph={{ rows: 0 }} /> : <p><HiUserCircle /><span>{details?.basicInfo?.createdBy}</span></p>}
                            {detailsLoading ? <Skeleton className='details-avatar-skeleton' active avatar paragraph={{ rows: 0 }} /> : <p><HiOutlineCalendar /><span>{dayjs(details?.basicInfo?.creationDate).locale(i18n.language).format("DD MMMM, YYYY")}</span></p>
                            }                        </div>
                    </div>
                    <div className={styles.withBorders}>
                        <div className={styles.legal}>
                            {detailsLoading ? <Skeleton.Button style={{ maxWidth: '178px', minWidth: '178px', height: "24px" }} /> : <>
                                <p className={styles.tit}>{t('legal_name')}</p>
                                <p className={styles.txt}>{details?.basicInfo?.legalName.en} - {details?.basicInfo?.legalName.ar}</p></>}
                        </div>
                        <div className={styles.country}>
                            {detailsLoading ? <Skeleton.Button active style={{ maxWidth: '423px', minWidth: '423px', height: "24px" }} /> : <> <div className={styles.top}><span>{t('country')}</span> <div><p>{lookups && lookups[i18n.language].countries.find((a: any) => a.key == details?.basicInfo?.countryKey)?.name}</p> <img src={`/imgs/${lookups && lookups[i18n.language].countries.find((a: any) => a.key == details?.basicInfo?.countryKey)?.isoTwoCode.toLowerCase()}.svg`} /></div></div>
                                {details?.basicInfo?.countryKey == "SAU" ? <div className={styles.vatCont}>
                                    {<div><p><strong>{details?.basicInfo?.vatNumber ? t('vat_details') : t('vat__details')} </strong>{details?.basicInfo?.vatNumber ? `#${details?.basicInfo?.vatNumber}` : ''}</p> {details?.basicInfo?.vatNumber ? <Paragraph className={styles.copy} copyable={{ text: details?.basicInfo?.vatNumber, icon: <HiOutlineSquare2Stack /> }}></Paragraph> : ''}</div>}
                                    {<div><p><strong>{details?.basicInfo?.commercialRegistration ? t('cr_details') : t('cr__details')} </strong>{details?.basicInfo?.commercialRegistration ? `#${details?.basicInfo?.commercialRegistration}` : ''}</p> {details?.basicInfo?.commercialRegistration ? <Paragraph className={styles.copy} copyable={{ text: details?.basicInfo?.commercialRegistration, icon: <HiOutlineSquare2Stack /> }}></Paragraph> : ''}</div>}

                                </div> : ""}</>}
                            {detailsLoading ? <Skeleton.Button active style={{ maxWidth: '423px', minWidth: '423px', height: "24px", marginTop: '10px' }} /> : ''}
                        </div>
                        {<>{detailsLoading ? <Skeleton.Button active style={{ maxWidth: '423px', minWidth: '423px', height: "24px" }} /> : typeof details?.basicInfo?.agencyFees == 'number' ? <div className={styles.fees}>
                            <p><HiOutlineCash /> {t('agency_fees')}</p>
                            <div>{details?.basicInfo?.agencyFees}%</div>
                        </div> : ""}</>}
                        {details?.basicInfo?.ownerId && !detailsLoading ? <div className={styles.owner}>
                            {detailsLoading ? <Skeleton active className='details-owner-skeleton' avatar paragraph={{ rows: 0 }} /> : <>
                                <p className={styles.tit}>{t('account_owner')}</p>
                                {details?.basicInfo?.ownerId && <div className={styles.user}>
                                    <div className={styles.userImg}>{`${appUsers.find((a: any) => a.id == details?.basicInfo?.ownerId)?.name.trim().split(" ").length > 1 ? appUsers.find((a: any) => a.id == details?.basicInfo?.ownerId)?.name.trim().split(" ")[0][0] + appUsers.find((a: any) => a.id == details?.basicInfo?.ownerId)?.name.trim().split(" ")[1][0] : appUsers.find((a: any) => a.id == details?.basicInfo?.ownerId)?.name.trim().split(" ")[0][0]}`}</div>
                                    {appUsers.find((a: any) => a.id == details?.basicInfo?.ownerId)?.name}
                                </div>}</>}
                        </div> : ''}
                        {detailsLoading ? <Skeleton className='details-attachments-skeleton' style={{ maxWidth: '423px', minWidth: '423px', height: "24px" }} paragraph={{ rows: 2 }} /> : !!client?.basicInfo?.attachments?.length && <div className={styles.owner}>
                            <p className={`${styles.tit} ${styles.attachmentTitle}`}>{t('attachments')} <span onClick={() => fileList.forEach((file: any) => downloadData(file.url, file.name))}>{t('download_all')}</span></p>
                            <div className={styles.attachments}>
                                {client?.basicInfo?.attachments?.map((a: any) => ({ url: a.url, uid: uuidv4(), name: a.fileName, percent: 100, status: 'done' })).map((file: any) => renderUploadedItem(file))}
                            </div>
                        </div>}
                    </div>

                </Col>
                <Col span={18} className={styles.right}>

                    <Tabs className='clientTabs' defaultActiveKey="1" items={tabs} renderTabBar={(props, Tabbar) => <div className={styles.header}><Tabbar {...props} /> <div className={styles.cont}>
                        <Input value={selectedFilters.search} onChange={(e) => { setCampaignPagination(prev => ({ ...prev, page: 1 })); setBrandPagination(prev => ({ ...prev, page: 1 })); setSelectedFilters(prev => ({ ...prev, search: e.target.value })) }} placeholder={t('search_by_brand')} prefix={<HiOutlineMagnifyingGlass color='#8E8E8E' />} className={`lavad-input lavad-input-lg ${styles.searchInput}`} />
                        <div className={styles.iconContainer} onClick={() => setShowLogs(true)}>
                            <PiClockClockwiseFill />
                        </div>
                    </div>
                    </div>} />




                </Col>
            </Row>
            {openEdit && < Drawer
                placement={dir() === "ltr" ? "right" : "left"}
                onClose={() => { }} footer={< div className={styles.footer} >
                    <button onClick={checkIfHasData} className="lavad-btn lavad-btn-lg lavad-btn-outline" >{t('cancel')}</button>
                    <button disabled={editLoading || fileList.find((a: any) => a.status == "uploading")} onClick={() => form.submit()} className="lavad-btn lavad-btn-lg lavad-btn-primary" >{editLoading ?
                        <span className="icon-loader spin"></span> : t('save_changes')}</button>

                </div >} rootClassName='lavad-drawer-wrapper' className='lavad-drawer' open={openEdit} title={t('edit_client')} >
                <div className={styles.contentWrapper}>
                    <CreateClient setFormValues={setFormValues} isEdit={true} details={details} setErrors={setCreateErrors} appUsers={appUsers} errors={createErrors} loading={editLoading} fileList={fileList} setFileList={setFileList} selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} onSubmitHandler={onSubmitHandler} form={form} />
                </div>
            </Drawer >}
            <Modal mask={false} open={modalOpen} classNames={{ wrapper: 'incZ' }} centered className='report-modal incZ' title={t('exit_without_saving')} onCancel={() => setModalOpen(false)} footer={
                <div className='btn-group align-items-end'>
                    <button onClick={() => setModalOpen(false)} className='lavad-btn lavad-btn-md lavad-btn-outline'>
                        {t('keep_editing')}
                    </button>
                    <button onClick={() => { cancel(); setModalOpen(false) }} className='lavad-btn lavad-btn-md lavad-btn-danger-primary'>
                        {t('yes_leave')}
                    </button>
                </div>

            }>
                <p className={styles.modal_text}>{t('sure_cancel')}</p>
            </Modal>
            <Modal open={openDelete} classNames={{ wrapper: 'incZ' }} centered className='report-modal incZ' title={t('delete_client')} onCancel={() => setOpenDelete(false)} footer={
                <div className='btn-group align-items-end'>
                    <button onClick={() => setOpenDelete(false)} className='lavad-btn lavad-btn-md lavad-btn-outline'>
                        {t('cancel')}
                    </button>
                    <button onClick={() => { }} className='lavad-btn lavad-btn-md lavad-btn-danger-primary'>
                        {t('delete')}
                    </button>
                </div>

            }>
                <div className={styles.deleteModal}>
                    <p className={styles.modal_text}>{t('sure_delete')}</p>
                    <div>
                        <p>{t('type_name_of_client')}</p>
                        <Input className='lavad-input lavad-input-lg' />
                    </div>
                </div>
            </Modal>
            {
                showLogs && <Drawer
                    title={null}
                    placement={dir() === "ltr" ? "right" : "left"}
                    closable={true}
                    closeIcon={<HiOutlineXMark className="close-drawer" />}
                    open={showLogs}
                    onClose={() => setShowLogs(false)}
                    contentWrapperStyle={{ width: "427px" }}
                    className="activity-drawer"

                >
                    <ActivityLogs entityKey={'Client'} entityId={id} />


                </Drawer>
            }
        </>
    )
}

export default ClientDetails