import { signInWithEmailAndPassword, signOut } from "@firebase/auth";

import { auth } from "../../Utils/firebase";
import {
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  signInWithEmailLink,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import api from "../../Utils/axiosInterceptor";
export const requestLogin = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((res) => {
      console.log("response", res);
    })
    .catch((error) => {
      console.log(error, "error");
      return error;
    });
};

export const forgotPassword = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

export const signUp = async (email: string, url: string) => {
  return await signInWithEmailLink(auth, email, url).catch((error) => {
    console.error(" failed:", error);
  });
};
export const resetPassword = async (oobCode: string, newPassword: string) => {
  return confirmPasswordReset(auth, oobCode, newPassword);
};
// export const getUsers = async ()=>{
//   api.post('https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyAKXWdRf0_Zt-Bmd6-9Ijn4apWodg2Ca9s',{
//     idToken:JSON.parse(localStorage.getItem('tokens')!).accessToken
//   })
// }
export const forceLogout = () => {
  localStorage.removeItem("lookups");
  localStorage.removeItem("currentUser");
  localStorage.removeItem("tokens");
  window.location.reload();
};
export const logout = async () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      localStorage.removeItem("lookups");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("tokens");
    })
    .catch((error) => {
      // An error happened.
      forceLogout();
      console.log(error);
    });
};
export const checkUserEmail = async (email: string) => {
  const res = await api.get(
    `${process.env.REACT_APP_API_URL}api/application-user/check-email?email=${email}`
  );

  return res.data;
};
