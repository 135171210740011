import { Checkbox, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import styles from "./index.module.scss"
import { useTranslation } from 'react-i18next';
const SelectWithAll = ({ options, prefixImg, onChange, selected }: any) => {
    const { Option } = Select;
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const [selectedItems, setSelectedItems] = useState<any>(selected || []);
    const [prevSelectedItems, setPrevSelectedItems] = useState([])
    const checkRef = useRef<any>(null)
    const handleSelectAll = (e: any) => {
        if (e.target.checked) {
            setSelectedItems(options.map((option: any) => option.value));
        } else {
            setSelectedItems([]);
        }
    };
    const handleSelectItems = (e: any) => {
        console.log("slected", selected)
        setPrevSelectedItems(selected)
        if (e.includes('selectAll')) {
            if (!checkRef?.current?.input.checked) {
                setSelectedItems(options.map((option: any) => option.value));
            } else {
                setSelectedItems([]);
            }
        } else {
            console.log(e, "eeee")
            setSelectedItems(e)
        }
    }
    useEffect(() => {
        console.log("selectedItems", selectedItems)
        onChange(prevSelectedItems, selectedItems)
    }, [selectedItems])

    // useEffect(() => {
    //     setSelectedItems(selected)
    // }, [])

    return (
        <div className={styles.selectCont}>
            <img src={prefixImg} />
            <Select
                onChange={handleSelectItems}
                popupClassName={styles.popupClassName + ' ' + 'servicespopupClassName'}
                allowClear
                defaultActiveFirstOption={false}
                virtual={false}
                maxTagCount={1}
                showSearch={false}
                className={styles.marginTop + " " + 'lavad-md-drop' + ' ' + 'selectWithAll'}
                mode="multiple"
                style={{ width: "100%" }}
                onInputKeyDown={(event) => {
                    if (event.keyCode === 8 || event.key === "Backspace") {
                        return event.stopPropagation();
                    }
                }}

                value={selected}

                size="large"
                placeholder={<span className={styles.placeholder}>{t("select_services")}</span>}

            >
                <Option value="selectAll" className={styles.removeSelect + ' ' + 'removeSelect'}>
                    <div className={styles.selectAll}>
                        <Checkbox
                            ref={checkRef}
                            onChange={e => handleSelectAll(e)}
                            className="inf-checkbox"
                            checked={selected.length == options.length}

                        />{t('select_all')}
                    </div>
                </Option>
                {options.map((option: any) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default SelectWithAll