import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  HiCheckCircle,
  HiOutlineCheckCircle,
  HiOutlineChevronLeft,
  HiOutlineUser,
  HiOutlineUserGroup,
  HiXMark,
} from "react-icons/hi2";

import {
  Modal,
  Button,
  Steps,
  Form,
  Radio,
  Checkbox,
  Select,
  Input,
  Col,
  Row,
  message,
  UploadFile,
  Upload,
  notification,
  Progress,
  Tag,
  InputNumber,
  Drawer,
} from "antd";
import { FaRegDotCircle } from "react-icons/fa";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import {
  HiOutlineArrowTrendingUp,
  HiOutlineLightBulb,
  HiOutlineMagnifyingGlass,
  HiOutlineMegaphone,
  HiOutlineTrash,
  HiOutlineXCircle,
  HiOutlineXMark,
} from "react-icons/hi2";
import {
  AuthStateContext,
  useAuthDispatch,
  useAuthState,
} from "../../Context/MainContext";
import i18n from "../../Utils/i18n/i18n";

import SearchClients from "../SearchClients";
import BrandSearch from "../SearchBrands";

import { FiPaperclip, FiPercent } from "react-icons/fi";
import { getFileExtension, moneyFormatter } from "../../Utils/helpers";
import uploadFileToBlob from "../../Utils/upload_azure_storage";
import {
  ClientData,
  createClient,
  getAllClientsListing,
  getApplicationUsers,
  getClientBrandRelationship,
} from "../../Context/Actions/clients";
import { Campaign, createCampaign, editCampaign } from "../../actions/campaign";
import { CampaignDetailsInterface } from "../../Context/Actions/campaign";
import { useParams } from "react-router-dom";
import CreateClient from "../CreateClient";
import AddBrand from "../AddBrand";
import { use } from "i18next";
import { createBrand } from "../../Context/Actions/campaign";
import { getBrands } from "../../Context/Actions/lookups";

import CreateCampaginLogo from "../../assets/imgs/create-campaign-logo.svg"
import CollapsedLogo from "../../assets/imgs/collapsed-logo.svg"


const { Step } = Steps;
const { TextArea } = Input;

const NewCreateCampaign = ({
  modalOpen,
  setModalOpen,
  onSuccess,
  isEdit,
  campaignDetails,
  getDetails,
}: {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  isEdit?: boolean;
  campaignDetails?: CampaignDetailsInterface | null;
  getDetails?: () => void;
}) => {
  const allowedExtensions = ["svg", "png", "jpg", "jpeg", "gif"];

  const sectionRefs = useRef<(HTMLDivElement | any)[]>([]);
  const socialSearch = useRef<any>(null);
  const [openClientDrawer, setOpenClientDrawer] = useState(false);
  const [openBrandDrawer, setOpenBrandDrawer] = useState(false);
  const [current, setCurrent] = useState(0);
  const [fileList, setFileList] = useState<
    { url: string; name: string; uid: string }[]
  >([]);
  const [users, setUsers] = useState<{ id: string; name: string }[]>([]);
  const [showUsers, setShowUsers] = useState(false);
  const [socialPlatformsSearchResults, setSocialPlatformsSearchResults] =
    useState(null);
  const [socialPlatforms, setSocialPlatforms] = useState([]);
  const [platformsEmpty, setPlatformsEmpty] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [leavePageModalOpen, setLeavePageModalOpen] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [budget, setBudget] = useState<any>(null);
  const [team, setTeam] = useState([]);
  const [brandIntialValue, setBrandIntialValue] = useState("");
  const [clientIntialValue, setClientIntialValue] = useState("");
  const [wrongFileExtension, setWrongFileExtension] = useState(false);

  const {
    t,
    i18n: { changeLanguage, dir },
  } = useTranslation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Dragger } = Upload;
  const {
    state: { lookups, brands, lang, user, agency },
    dispatch,
  } = useContext(AuthStateContext);
  const { id } = useParams();
  console.log("Campaign ID:", id);
  const niches =
    lookups &&
    lookups[i18n.language]?.niches?.map((i: any) => ({
      label: (
        <>
          {i.name}{" "}
          {/* <span className="select_label_span">({t(i.key + "_info")})</span> */}
        </>
      ),
      value: i.key,
    }));
  // console.log("niches", niches);
  const options =
    lookups &&
    lookups[i18n.language]?.influencerTypes?.map((i: any) => ({
      label: (
        <>
          {i.name}{" "}
          <span className="select_label_span">({t(i.key + "_info")})</span>
        </>
      ),
      value: i.key,
    }));

  useEffect(() => {
    if (campaignDetails && isEdit) {
      let services =
        campaignDetails.influencerAndServices.socialPlatforms.flatMap(
          (platform: { servicesKeys: string[] }) => platform.servicesKeys
        );

      // Prepare the initial file list for attachments
      const initialFileList =
        campaignDetails.briefAndAttachments.attachments?.map((file) => ({
          uid: file.id,
          name: file.fileName,
          url: file.url,
        })) || [];
      setFileList(initialFileList);
      // console.log("try try" , selectedBrand);

      // Set form fields values
      form.setFieldsValue({
        name: campaignDetails.campaignSummary.name,
        objective: campaignDetails.campaignSummary.campaignObjectiveKey,
        brand: `${campaignDetails.campaignSummary.brand.name.ar} - ${campaignDetails.campaignSummary.brand.name.en}`,
        client: campaignDetails.campaignSummary.client?.name,
        influencer_type:
          campaignDetails.influencerAndServices.influencerTypeKeys,
        influncers:
          campaignDetails.influencerAndServices.requestedInfluencers || null,
        platforms_services: services,
        niche: campaignDetails.influencerAndServices.campaignNicheKeys,
        budget: campaignDetails.budgetAndTeam.budget || null,
        Campaign_lead: campaignDetails.budgetAndTeam.campaignLeadId,
        team_members: campaignDetails.budgetAndTeam.campaignAssignedMembers.map(
          (member: { id: string }) => member.id
        ),
        brief: campaignDetails.briefAndAttachments.brief,
        influncers_dos: campaignDetails.briefAndAttachments.influencerDos,
        influncers_donts: campaignDetails.briefAndAttachments.influencerDonts,
        attachments: initialFileList,
      });
      setSelectedBrand({ value: campaignDetails.campaignSummary.brand?.id });
      setSelectedClient({ id: campaignDetails.campaignSummary.client?.id });
      setBudget(campaignDetails.budgetAndTeam.budget || null);
    }
  }, [campaignDetails]);

  useEffect(() => {
    window.onbeforeunload = function (e) {
      if ((isDirty || selectedBrand || selectedClient) && modalOpen) {
        return "Please click 'Stay on this Page' if you did this unintentionally";
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, selectedBrand, selectedClient, modalOpen]);
  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getApplicationUsers();
      setUsers(users.data);
      console.log(users);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getApplicationUsers();
      setUsers(users.data);
      console.log(users);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      console.log("entries", entries);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.findIndex(
            (ref) => ref === entry.target
          );
          if (index !== -1) {
            setCurrent(index);
          }
        }
      });
    }, options);

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  useEffect(() => {
    setSocialPlatforms(
      lookups &&
      lookups[i18n.language]?.socialPlatforms?.map((s: any) => ({
        label: s.name,
        options: s.socialPlatformServices.map((p: any) => ({
          label: `${s.name}: ${p.name}`,
          value: p.key,
        })),
      }))
    );
    setSocialPlatformsSearchResults(
      lookups &&
      lookups[i18n.language]?.socialPlatforms?.map((s: any) => ({
        label: s.name,
        options: s.socialPlatformServices.map((p: any) => ({
          label: `${s.name}: ${p.name}`,
          value: p.key,
        })),
      }))
    );
  }, [lookups, i18n]);
  const cancel = () => {
    form.resetFields();
    setError("");
    setLoading(false);
    setModalOpen(false);
  };

  const handleCancel = () => {
    if (isDirty || selectedBrand || selectedClient) {
      setLeavePageModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };

  const handleFormChange = () => {
    setIsDirty(true);
  };

  const handleBrandSelect = (brand: any) => {
    console.log("Selected brand:", brand);

    setSelectedBrand(brand);
    form.setFieldsValue({
      brand: brand.label,
    });

    setOpenBrandDrawer(false);
  };

  const handleClientSelect = (client: any) => {
    console.log("Selected brand:", client.label);
    console.log(client, "clieeent");
    setSelectedClient(client);
    form.setFieldsValue({
      client: client.label,
    });
    setOpenClientDrawer(false);
  };
  const onFinishHandler = async (data: any) => {
    setLoading(true);

    console.log("daaaaaaa", data);
    const campaign: Campaign = {
      campaignSummary: {
        name: data.name.trim(),
        campaignObjectiveKey: data.objective,
        brandId: selectedBrand?.value,
        clientId: selectedClient?.id || null,
        ...(selectedBrand?.value && selectedClient?.id ? { clientRelationshipKey: data.associationType } : {})
      },
      influencerAndServices: {
        campaignNicheKeys: data.niche,
        requestedInfluencers: parseInt(data.influncers),
        influencerTypeKeys: data.influencer_type,

        socialPlatformServices: data.platforms_services,
      },
      budgetAndTeam: {
        budget: parseInt(budget),
        campaignLeadId: data.Campaign_lead,
        campaignAssignedMembers: data.team_members,
      },

      briefAndAttachments: {
        brief: data.brief.trim(),
        influencerDos: data.influncers_dos?.trim(),
        influencerDonts: data.influncers_donts?.trim(),
        attachments: fileList?.map((file) => ({
          url: file.url,
          fileName: file.name,
        })),
      },
    };

    // console.log("files", fileList)
    console.log("Campaign", campaign);

    if (isEdit) {
      let c = await editCampaign(campaign, id as string);
      if (c.error) {
        setLoading(false);
        setError("something_went_wrong");

        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      } else {
        setLoading(false);
        setModalOpen(false);
        setIsDirty(false);
        form.resetFields();
        openNotification();

        onSuccess();

        if (getDetails) {
          await getDetails();
        }
      }
    } else {
      let c = await createCampaign(campaign);
      if (c.error) {
        setLoading(false);
        setError("something_went_wrong");
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
      } else {
        setLoading(false);
        setModalOpen(false);
        form.resetFields();
        setIsDirty(false);
        openNotification();
        onSuccess();
      }
    }
  };

  const onFinishFailedHandler = ({ errorFields }: any) => {
    console.log("Error Fields:", errorFields);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      const fieldName = firstErrorField.name[0];
      const fieldElement = document.querySelector(`[name="${fieldName}"]`);
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const renderUploadedItem = (
    originNode: any,
    file: any,
    fileList: any,
    actions: any
  ) => {
    let extension = getFileExtension(file.name);
    extension =
      extension == "jpeg" ||
        extension == "jpg" ||
        extension == "png" ||
        extension == "gif" ||
        extension == "svg" ||
        !extension
        ? "img"
        : extension == "doc" || extension == "docx"
          ? "doc"
          : extension == "ppt" || extension == "pptx"
            ? "ppt"
            : extension;
    if (file.percent < 100 && file.status == "uploading") {
      return (
        <div className={styles.uploadedImg}>
          <div>
            <img
              src={require(`../../../public/imgs/${extension.toLowerCase()}.svg`)}
              alt=""
            />{" "}
            <div className={`${styles.nameWithProgress} nameWithProgress`}>
              <div className={styles.txt}>
                <p>{file.name.split(".")[0]}</p>
                <span>
                  .{file.name.split(".")[file.name.split(".").length - 1]}
                </span>
              </div>
              <Progress
                strokeColor={"#6C73FF"}
                trailColor={"#EEEEEE"}
                percent={file.percent}
              />
            </div>
          </div>
          <HiOutlineXMark
            onClick={() =>
              setFileList((prev: any) =>
                prev.filter((f: any) => f.uid != file.uid)
              )
            }
          />
        </div>
      );
    }

    return (
      <div className={styles.uploadedImg}>
        <div>
          <img src={require(`../../../public/imgs/${extension.toLowerCase()}.svg`)} alt="" />{" "}
          <div className={styles.txt}>
            <p>{file.name.split(".")[0]}</p>
            {getFileExtension(file.name) ? (
              <span>.{getFileExtension(file.name)}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <HiOutlineTrash
          onClick={() => {
            setFileList((prev: any) =>
              prev.filter((f: any) => f.uid != file.uid)
            );
          }}
        />
      </div>
    );
  };
  console.log("files", fileList);

  const getInitials = (name: string) => {
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  };
  const isLastSection = current === sectionRefs.current.length - 1;

  const openNotification = () => {
    notification.open({
      placement: dir() === "rtl" ? "topLeft" : "topRight",
      message: t("success_label"),
      description: campaignDetails
        ? t("success_edit_campaign")
        : t("success_create_campaign"),
      icon: <HiOutlineCheckCircle className="success-text" />,
      className: "no-wrap",
    });
  };

  const handleClientInputClick = (e: any) => {
    // setClientIntialValue(e.target.value);
    setOpenClientDrawer(true);
    setOpenBrandDrawer(false);
    setOpenCreateBrand(false);
  };

  const handleBrandInputClick = (e: any) => {
    // setBrandIntialValue(e.target.value);
    setOpenBrandDrawer(true);
    setOpenClientDrawer(false);
    setOpenCreate(false);
  };

  const handleInputUserClick = () => {
    setShowUsers(!showUsers);
  };

  useEffect(() => {
    const zoomLevel = window.devicePixelRatio;
    console.log(zoomLevel, "zoom level");
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: zoomLevel <= 1 ? 0.8 : 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      console.log("entries", entries);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.findIndex(
            (ref) => ref === entry.target
          );
          if (index !== -1) {
            setCurrent(index);
          }
        }
      });
    }, options);

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const scrollToSection = (index: number) => {
    const section = sectionRefs.current[index];
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleStepChange = (index: number) => {
    // setCurrent(index);
    scrollToSection(index);
  };
  const handleContinue = () => {
    if (current < sectionRefs.current.length - 1) {
      handleStepChange(current + 1);
    }
  };

  const handleBack = () => {
    if (current > 0) {
      handleStepChange(current - 1);
    }
  };

  const handleUpload = async (file: any) => {
    setWrongFileExtension(false);
    if (
      !allowedExtensions.includes(
        file.name.split(".")[file.name.split(".").length - 1].toLowerCase()
      )
    ) {
      setWrongFileExtension(true);
      notification.destroy();
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("error_label"),
        description: `${t("format_not_supported")} "${file.name
          .split(".")
        [file.name.split(".").length - 1].toLowerCase()}"`,
        icon: <HiOutlineXCircle className="danger-text" />,
        className: "no-wrap",
      });
      return;
    }
    try {
      file.percent = 0;
      file.status = "uploading";
      setFileList((prevList: any) => [...prevList, file]);

      const uploadedImgUrl = await uploadFileToBlob("campaign-attachments", file, (percent) => {
        setFileList((prevList: any) =>
          prevList.map((item: any) => {
            if (item.uid === file.uid) {
              item.percent = percent;
            }
            console.log("image", item);
            return item;
          })
        );
      });

      const handleNext = () => {
        if (current < sectionRefs.current.length - 1) {
          setCurrent((prev) => prev + 1);
        }
      };
      setFileList((prevList: any) =>
        prevList.map((item: any) => {
          // console.log("upload" , item.uploadedImgUrl)
          if (item.uid === file.uid) {
            item.status = "done";
            item.percent = 99;
            item.url = uploadedImgUrl;
          }

          return item;
        })
      );
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const onSocialPlatformsChange = (e: any) => {
    const v = e.target.value;
    const filtered: any = socialPlatforms.map((b: any) => {
      const options = b.options.filter((k: any) => {
        if (k.label.toLowerCase().includes(v.toLowerCase())) {
          return k;
        }
      });
      if (options) {
        return { label: b.label, options };
      }
    });
    if (filtered.every((f: any) => !f.options.length)) {
      setPlatformsEmpty(true);
    } else {
      setPlatformsEmpty(false);
    }
    setSocialPlatformsSearchResults(filtered);
  };

  function handleFinish(): void {
    form.submit();
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    console.log("foooooooorm");
  };

  // create client

  const [clientFormValues, setClientFormValues] = useState({
    name: "",
    legalEn: "",
    legalAr: "",
    vat: "",
    cr: "",
  });

  const [clientForm] = Form.useForm();
  const [brandForm] = Form.useForm();

  const [openCreate, setOpenCreate] = useState(false);

  const [createErrors, setCreateErrors] = useState({
    vat: "",
    cr: "",
    legalAr: "",
    legalEn: "",
  });

  const [createClientLoading, setCreateClientLoading] = useState(false);

  const [clientsLoading, setClientsLoading] = useState(false);

  const [clients, setClients] = useState<any>([]);

  const [openCreateBrand, setOpenCreateBrand] = useState(false);

  const [createBrandLoading, setCreateBrandLoading] = useState(false);

  const [brandErrors, setBrandErrors] = useState({
    nameEn: "",
    nameAr: "",
    website: "",
    logo: "",
  });

  const [collapsedView, setCollapsedView] = useState(false);

  const [brandLogoLoading, setBrandLogoLoading] = useState(false);

  const [loadingRelationship, setLoadingRelationship] = useState(false)
  const [associationType, setAssociationType] = useState(null)

  const cancelCreate = () => {
    setOpenCreate(false);
    setCreateErrors({ vat: "", cr: "", legalAr: "", legalEn: "" });
    clientForm.resetFields();
  };

  const checkIfHasData = () => {
    // add if fields change
    // const hasValue = Object.values(clientFormValues).some((a: any) => !!a)
    // if (hasValue) {
    //   setModalOpen(true)
    // } else {
    cancelCreate();
    // }
    // if(form.getFieldsValue)
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    const getRelationship = async () => {
      setLoadingRelationship(true)
      const relationship = await getClientBrandRelationship(selectedClient.id, selectedBrand.value)
      if (!relationship.key) {
        setAssociationType(null)
        form.setFieldValue('associationType', null)
        form.validateFields(['associationType'])
      } else {
        setAssociationType(relationship.key)
        form.setFieldValue('associationType', relationship.key)
      }
      form.validateFields(['associationType'])
      setLoadingRelationship(false)

    }
    if (selectedBrand?.value && selectedClient?.id) {
      console.log("here")
      getRelationship()
    }
    form.validateFields(['associationType'])
  }, [selectedBrand, selectedClient])

  const fetchClients = async () => {
    setClientsLoading(true);
    try {
      const response = await getAllClientsListing();
      if ("error" in response) {
        notification.open({
          placement: dir() === "rtl" ? "topLeft" : "topRight",
          message: t("error_label"),
          description: t("something_went_wrong"),
          icon: <HiOutlineXCircle className="danger-text" />,
          className: "no-wrap",
        });
        console.error("Failed to fetch clients");
      } else {
        const clientsData = response.data as ClientData[];
        const results = clientsData.map((client) => {
          const { basicInfo } = client;
          return {
            id: basicInfo?.id,
            name: basicInfo?.name,
            legalNameAr: basicInfo?.legalName?.ar || "",
            // legalNameEn: basicInfo.legalName?.en || '',
            label: `${basicInfo.legalName?.en} - ${basicInfo.legalName?.ar}`,
          };
        });
        setClients(results);
        return results;
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setClientsLoading(false);
    }
  };

  const onSubmitClientHandler = async () => {
    // setCreateErrors({ vat: "vat", cr: "cr" })
    // submit handler
    setCreateClientLoading(true);
    const values = clientForm.getFieldsValue();
    const client = await createClient({
      basicInfo: {
        agencyFees: null,
        name: values.name,
        countryKey: values.country,
        vatNumber:
          values.country == "SAU" ? (values.vat ? values.vat : null) : null,
        attachments: [],
        commercialRegistration:
          values.country == "SAU" ? (values.cr ? values.cr : null) : null,
        legalName: { en: values.legalEn, ar: values.legalAr },
        ownerId: null,
      },
      brands: [],
    });
    if (!client.error) {
      cancelCreate();
      setOpenClientDrawer(false);
      notification.open({
        placement: dir() === "rtl" ? "topLeft" : "topRight",
        message: t("success_label"),
        description: t("client_created"),
        icon: <HiOutlineCheckCircle className="success-text" />,
        className: "no-wrap",
      });

      // setTableData()
      const newClients = await fetchClients();
      setClients(newClients);
      const createdClient = newClients?.find((c: any) => c.id == client.id);
      setSelectedClient(createdClient);
      form.setFieldsValue({
        client: createdClient?.label,
      });
    } else {
      setCreateErrors({
        cr: client.errors.find(
          (e: any) => e.field == "basicInfo.commercialRegistration"
        )
          ? "cr_err"
          : "",
        vat: client.errors.find((e: any) => e.field == "basicInfo.vatNumber")
          ? "vat_err"
          : "",
        legalAr: client.errors.find(
          (e: any) => e.field == "basicInfo.legalName.ar"
        )
          ? "legalAr_err"
          : "",
        legalEn: client.errors.find(
          (e: any) => e.field == "basicInfo.legalName.en"
        )
          ? "legalEn_err"
          : "",
      });
    }
    setCreateClientLoading(false);
  };

  useEffect(() => {
    if (openClientDrawer || openBrandDrawer || openCreate || openCreateBrand) {
      setCollapsedView(true);
    } else {
      setCollapsedView(false);
    }
  }, [openClientDrawer, openBrandDrawer, openCreate, openCreateBrand]);

  const handleBrandSubmit = () => {
    setBrandErrors({ nameAr: "", nameEn: "", website: "", logo: "" });

    brandForm.submit();
  };

  const submitBrand = (values: any) => {
    console.log(values, "value");
    const create = async () => {
      setCreateBrandLoading(true);
      const brand = await createBrand({
        basicInfo: {
          name: { ar: values.arabicName, en: values.englishName },
          logoUrl: values.image,
          websiteUrl: values.website,
        },
        clientInfo:
          values.selectedClient.id && values.selectedClient.type
            ? {
              id: values.selectedClient.id,
              relationshipKey: values.selectedClient.type,
            }
            : null,
        metaData: {
          typeKey: values?.brandType ? values?.brandType : null,
          classificationKey: values?.classification
            ? values?.classification
            : null,
          industryKeys: values?.industry ? values?.industry : [],
        },
        socialPlatforms: values.selectedPlatforms
          ? values.selectedPlatforms
            .filter((a: any) => a.key && a.url)
            .map((a: any) => ({ key: a.key, url: a.url }))
          : [],
      });
      if (!brand.error) {
        console.log(brand, "brand");
        const res = await getBrands();
        dispatch({ type: "SET_BRANDS", payload: res.data });
        const newBrand = res.data?.find((b: any) => b.id == brand.id);
        console.log("newBrand", newBrand);

        setSelectedBrand({
          value: newBrand.id,
          label: `${newBrand.name.find((a: any) => a.languageKey == "en").value
            } - ${newBrand.name.find((a: any) => a.languageKey == "ar").value}`,
        });
        form.setFieldsValue({
          brand: `${newBrand.name.find((a: any) => a.languageKey == "en").value
            } - ${newBrand.name.find((a: any) => a.languageKey == "ar").value}`,
        });
        setOpenCreateBrand(false);
        setOpenBrandDrawer(false);
        brandForm.resetFields();
      } else {
        const errors = brand.errors;
        errors.forEach((e: any) => {
          if (e.field == "basicInfo.websiteUrl" && e.key == "Duplicated") {
            setBrandErrors((prev) => ({
              ...prev,
              website: "website_duplicated",
            }));
          } else if (e.field == "basicInfo.name.ar" && e.key == "Duplicated") {
            setBrandErrors((prev) => ({
              ...prev,
              nameAr: "nameAr_duplicated",
            }));
          } else if (e.field == "basicInfo.name.en" && e.key == "Duplicated") {
            setBrandErrors((prev) => ({
              ...prev,
              nameEn: "nameEn_duplicated",
            }));
          }
        });
        console.log(brand, "errors");
      }
      setCreateBrandLoading(false);
    };
    if (values.selectedClient.id || values.selectedClient.type) {
      if (values.selectedClient.id && values.selectedClient.type) {
        create();
      } else {
        return;
      }
    } else {
      create();
    }
  };

  console.log("selectedBrand?.label && !selectedClient?.id", selectedBrand, selectedClient)

  return (
    <>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        footer={null}
        className="create-modal"
        closable={true}
      >
        <Row>
          <Col
            className={`${styles.sidepanel} ${dir() === "rtl" ? styles.rtl : ""
              } ${collapsedView ? "collapsedSidePanel" : ""}`}
            span={!collapsedView ? 4 : 1}
          >
            <div className={styles.img_container + ' ' + 'sidePanelLogo'}>
              {collapsedView ? <img src={CollapsedLogo} alt="" /> : <img src={CreateCampaginLogo} alt="" />}
            </div>
            <Steps
              direction="vertical"
              current={current}
              onChange={handleStepChange}
              className={`steps createCampaignSteps`}
            >
              <Step
                title={t("campaign_summary")}
                description={t("details")}
                icon={
                  current > 0 ? (
                    <HiCheckCircle />
                  ) : (
                    <div className="stepIcon">
                      <div></div>
                    </div>
                  )
                }
              />
              <Step
                title={t("influncers_services")}
                description={t("influncers_details")}
                icon={
                  current > 1 ? (
                    <HiCheckCircle />
                  ) : (
                    <div className="stepIcon">
                      <div></div>
                    </div>
                  )
                }
              />
              <Step
                title={t("budget_team")}
                description={t("budget_team_details")}
                icon={
                  current > 2 ? (
                    <HiCheckCircle />
                  ) : (
                    <div className="stepIcon">
                      <div></div>
                    </div>
                  )
                }
                className="step"
              />
              <Step
                title={t("brief_attachments")}
                description={t("brief_attachments_details")}
                icon={
                  <div className="stepIcon">
                    <div></div>
                  </div>
                }
              />
            </Steps>
          </Col>
          <Col span={!collapsedView ? 20 : 22}>
            <Row>
              <Col>
                <header className={styles.headerContainer}>
                  <div className={styles.container}>
                    <h1 data-private className={styles.title}>
                      {isEdit ? t("edit_campaign") : t("create_campaign")}
                    </h1>
                  </div>
                </header>
              </Col>
              <Col span={24} className={styles.formContainer}>
                <Col span={10}>
                  <Form
                    key={form.getFieldsValue()}
                    scrollToFirstError={true}
                    form={form}
                    onFinish={onFinishHandler}
                    onFinishFailed={onFinishFailedHandler}
                    layout="vertical"
                    onValuesChange={handleFormChange}
                    className={"fixHeights"}
                  >
                    <div
                      ref={(el) => (sectionRefs.current[0] = el)}
                      className={` ${current >= 0
                        ? styles.activeSection
                        : styles.inactiveSection
                        }`}
                    >
                      <div className={styles.headingContainer}>
                        <h3 className={styles.heading}>
                          {t("campaign_details")}
                        </h3>
                      </div>
                      <div className={styles.second}>
                        <Form.Item
                          label={
                            <label data-private className={styles.label}>
                              {t("create_campaign_name")}
                            </label>
                          }
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: t("campaign_name_required"),
                              validator: (_, value) => {
                                if (!value.trim().length) {
                                  return Promise.reject(
                                    t("campaign_name_required")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                          className="removeStars"
                        >
                          <Input
                            className="lavad-input lavad-input-lg"
                            placeholder={t("campaign_name_placeholder")}
                            name="name"
                          />
                        </Form.Item>
                      </div>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("create_campaign_objective")}
                          </label>
                        }
                        name="objective"
                        rules={[
                          {
                            required: true,
                            message: t("campaign_objective_required"),
                          },
                        ]}
                        className="removeStars"
                      >
                        <Radio.Group
                          size="large"
                          className="campaign-objectives"
                          name="name"
                        >
                          {lookups &&
                            lookups[i18n.language]?.campaignObjectives?.map(
                              (obj: any) => {
                                const i =
                                  obj.key == "Consideration" ? (
                                    <HiOutlineLightBulb
                                      width={24}
                                      height={24}
                                    />
                                  ) : obj.key == "Awareness" ? (
                                    <HiOutlineMegaphone
                                      width={24}
                                      height={24}
                                    />
                                  ) : (
                                    <HiOutlineArrowTrendingUp
                                      width={24}
                                      height={24}
                                    />
                                  );
                                return (
                                  <Radio.Button key={obj.key} value={obj.key}>
                                    <div>
                                      {i}
                                      <p className="caption">{obj.name}</p>
                                    </div>
                                  </Radio.Button>
                                );
                              }
                            )}
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("client")}{" "}
                            <span style={{ fontWeight: "400" }}>
                              ({t("optional")})
                            </span>
                          </label>
                        }
                        name="client"
                      >
                        <Input
                          // style={{ overflowY:'hidden'}}
                          onChange={(e) => {
                            if (!e.target.value) setSelectedClient(null);
                          }}
                          readOnly
                          allowClear
                          className="lavad-input lavad-input-lg showClear pointer"
                          placeholder={t("choose_client")}
                          onClick={handleClientInputClick}
                          suffix={
                            <HiOutlineMagnifyingGlass
                              color="#8E8E8E"
                              className="searchIconSvg"
                            />
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("brand")}
                          </label>
                        }
                        name="brand"
                        rules={[
                          { required: true, message: t("brand_required") },
                        ]}
                        className="removeStars"
                      >
                        <Input
                          onChange={(e) => {
                            if (!e.target.value) setSelectedBrand(null);
                          }}
                          readOnly
                          allowClear
                          className="lavad-input lavad-input-lg showClear pointer"
                          placeholder={t("search_brand")}
                          onClick={handleBrandInputClick}
                          suffix={
                            <HiOutlineMagnifyingGlass
                              color="#8E8E8E"
                              className="searchIconSvg"
                            />
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          { required: selectedBrand?.value && selectedClient?.id, message: t("association_type_required") },
                        ]}
                        label={
                          <label data-private className={styles.label}>
                            {t("association_type")}
                          </label>
                        }
                        name="associationType"

                        className={`removeStars ${(!selectedBrand?.value || !selectedClient?.id || associationType || loadingRelationship) ? 'disabledField' : ''}`}
                      >
                        <Select
                          placeholder={t("association_type")}
                          loading={loadingRelationship}

                          className={`brands-select`}
                          virtual={false}
                          allowClear
                          options={lookups && lookups[i18n.language].clientBrandRelationships?.map((b: any) => ({ value: b.key, label: b.name }))}


                        ></Select>
                      </Form.Item>
                    </div>
                    <div
                      ref={(el) => (sectionRefs.current[1] = el)}
                      className={` ${current >= 1
                        ? styles.activeSection
                        : styles.inactiveSection
                        }`}
                    >
                      <div className={styles.headingContainer}>
                        <h3 className={styles.heading}>
                          {t("influncer_services")}
                        </h3>
                      </div>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("influencer_type")}
                          </label>
                        }
                        name="influencer_type"
                        rules={[
                          {
                            required: true,
                            message: t("influencer_type_required"),
                          },
                        ]}
                        className="removeStars"
                      >
                        <Checkbox.Group
                          options={options}
                          className={styles.checkboxes}
                          onChange={(checkedValues) => {
                            console.log(
                              "Selected influencer_type values:",
                              checkedValues
                            );
                          }}
                        ></Checkbox.Group>
                      </Form.Item>

                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("num_influncer")}
                          </label>
                        }
                        name="influncers"
                        rules={[
                          {
                            required: true,
                            message: t("num-influncers-required"),
                          },
                          {
                            validator: (_, value) => {
                              if (
                                value &&
                                (!/^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/.test(
                                  value
                                ) ||
                                  value <= 0)
                              ) {
                                return Promise.reject(t("min_0"));
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                        className="removeStars"
                      >
                        <Input
                          className="lavad-input lavad-input-lg"
                          size="large"
                          placeholder={t("many_influncers")}
                          name="name"
                          style={{
                            fontSize: "16px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("platforms_services")}
                          </label>
                        }
                        name="platforms_services"
                        rules={[
                          {
                            required: true,
                            message: t("platforms_services_required"),
                          },
                        ]}
                        className="removeStars"
                      >
                        {socialPlatformsSearchResults && (
                          <Select
                            ref={socialSearch}
                            defaultActiveFirstOption={false}
                            popupClassName="socialPlatform-dropdown"
                            virtual={false}
                            className="brands-select"
                            dropdownMatchSelectWidth={true}
                            placement="bottomLeft"
                            dropdownRender={(menu) => (
                              <>
                                {!platformsEmpty ? (
                                  menu
                                ) : (
                                  <div
                                    role="checkbox"
                                    id="platforms_list"
                                    className="ant-select-item-empty"
                                  >
                                    <div className="css-dev-only-do-not-override-amq5gd ant-empty ant-empty-normal ant-empty-small">
                                      <div className="ant-empty-image">
                                        <svg
                                          width="64"
                                          height="41"
                                          viewBox="0 0 64 41"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g
                                            transform="translate(0 1)"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <ellipse
                                              fill="#f5f5f5"
                                              cx="32"
                                              cy="33"
                                              rx="32"
                                              ry="7"
                                            ></ellipse>
                                            <g
                                              fill-rule="nonzero"
                                              stroke="#d9d9d9"
                                            >
                                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                              <path
                                                d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                fill="#fafafa"
                                              ></path>
                                            </g>
                                          </g>
                                        </svg>
                                      </div>
                                      <div className="ant-empty-description">
                                        {t("not_found")}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            showSearch={false}
                            mode="multiple"
                            maxTagCount={'responsive'}

                            allowClear
                            options={socialPlatformsSearchResults!}
                            size="large"
                            placeholder={t("platforms_services_placeholder")}
                            onInputKeyDown={(event) => {
                              if (
                                event.keyCode === 8 ||
                                event.key === "Backspace"
                              ) {
                                return event.stopPropagation();
                              }
                            }}
                          ></Select>
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("niche")}{" "}
                            <span style={{ fontWeight: "400" }}>
                              ({t("max.5")})
                            </span>
                          </label>
                        }
                        name="niche"
                        rules={[
                          { required: true, message: t("niches_required") },
                        ]}
                        className="removeStars"
                      >
                        <Select
                          //  popupClassName="socialPlatform-dropdown"
                          placeholder={t("select_niches")}
                          showSearch={false}
                          mode="multiple"
                          maxTagCount={'responsive'}
                          maxCount={5}
                          onChange={(selectedValues) => {
                            console.log(
                              "Selected niche values:",
                              selectedValues
                            );
                          }}
                          allowClear
                          defaultActiveFirstOption={false}
                          className="brands-select"
                          virtual={false}
                          onInputKeyDown={(event) => {
                            if (
                              event.keyCode === 5 ||
                              event.key === "Backspace"
                            ) {
                              return event.stopPropagation();
                            }
                          }}
                        >
                          {niches &&
                            niches.map((niche: any) => (
                              <Option key={niche.value} value={niche.value}>
                                {niche.label}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div
                      ref={(el) => (sectionRefs.current[2] = el)}
                      className={` ${current >= 2
                        ? styles.activeSection
                        : styles.inactiveSection
                        }`}
                    >
                      <div className={styles.headingContainer}>
                        <h3 className={styles.heading}>{t("budget_team")}</h3>
                      </div>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("campaign_budget")}
                          </label>
                        }
                        name="budget"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (!budget) {
                                return Promise.reject(t("budget_required"));
                              } else if (
                                budget !== null &&
                                isNaN(Number(budget))
                              ) {
                                return Promise.reject(
                                  t("budget_must_be_number")
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                        className="removeStars"
                      >
                        <p style={{ display: "none" }}>{showNumber}</p>
                        <Input
                          value={
                            budget === null || isNaN(Number(budget))
                              ? ""
                              : !showNumber && budget
                                ? Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 2,
                                }).format(+budget)
                                : budget
                          }
                          onChange={(e) => {
                            form.validateFields(["budget"]);
                            setBudget(e.target.value);
                          }}
                          onFocus={() => {
                            setShowNumber(true);
                          }}
                          onBlur={() => {
                            form.validateFields(["budget"]);
                            setShowNumber(false);
                          }}
                          addonBefore={
                            <span className={styles.customaddon}>
                              {t("sar")}
                            </span>
                          }
                          className="lavad-input-addon lavad-input-addon-lg"
                          placeholder={t("add_budget")}
                        />
                      </Form.Item>
                      <Form.Item
                        initialValue={user.id}
                        label={
                          <label data-private className={styles.label}>
                            {t("campaign_lead")}
                          </label>
                        }
                        name="Campaign_lead"
                        rules={[
                          {
                            required: true,
                            message: t("campaign_lead_required"),
                          },
                        ]}
                        className="removeStars"
                      >
                        <Select
                          filterOption={(input: any, option: any) => {
                            return option?.label
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          showSearch
                          defaultValue={user.id}
                          placeholder={t("select_account_owner")}
                          onClick={handleInputUserClick}
                          suffixIcon={
                            <HiOutlineMagnifyingGlass
                              color="#8E8E8E"
                              className="searchIconSvg"
                            />
                          }
                          className="brands-select"
                          virtual={false}
                          allowClear
                          options={users.map((user: any) => ({
                            value: user.id,
                            label: user.name,
                          }))}
                          optionRender={(option: any) => {
                            return (
                              <div className={styles.user}>
                                <div className={styles.userImg}>
                                  {getInitials(option.data.label)}
                                </div>
                                {option.data.label}
                              </div>
                            );
                          }}
                          labelRender={(props: any) => {
                            const { label, value } = props;
                            return (
                              <div className={styles.user}>
                                <div className={styles.userImg}>{`${label?.trim().split(" ").length > 1
                                  ? label?.trim().split(" ")[0][0] +
                                  label?.trim().split(" ")[1][0]
                                  : label?.trim().split(" ")[0][0]
                                  }`}</div>
                                {label}
                              </div>
                            );
                          }}
                        ></Select>
                      </Form.Item>

                      <Form.Item
                        label={
                          <label data-private className={` ${styles.label}`}>
                            {t("team_members")}
                          </label>
                        }
                        name="team_members"
                        rules={[
                          {
                            required: true,
                            message: t("team_members_required"),
                          },
                        ]}
                        className="removeStars"
                      >
                        <Select
                          filterOption={(input: any, option: any) => {
                            return option?.label
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          placeholder={t("add_team")}
                          onClick={handleInputUserClick}
                          suffixIcon={
                            team.length ? (
                              <HiOutlineUserGroup
                                color="#8E8E8E"
                                className="searchIconSvg"
                              />
                            ) : (
                              <HiOutlineMagnifyingGlass
                                color="#8E8E8E"
                                className="searchIconSvg"
                              />
                            )
                          }
                          className="brands-select incSelectLabelPadding brands-select-12"
                          virtual={false}
                          mode="multiple"
                          allowClear
                          maxTagCount={'responsive'}
                          options={users.map((user: any) => ({ value: user.id, label: user.name }))}
                          onChange={(value) => {
                            console.log("Selected Team Members:", value);
                            setTeam(value);
                          }}
                          optionRender={(option: any) => {
                            return (
                              <div className={styles.user}>
                                <div className={styles.userImg}>
                                  {getInitials(option.data.label)}
                                </div>
                                {option.data.label}
                              </div>
                            );
                          }}
                          labelRender={(props: any) => {
                            const { label, value } = props;
                            return (
                              <div
                                className={
                                  styles.user + " " + styles.optWithPadding
                                }
                              >
                                <div className={styles.userImg}>{`${label?.trim().split(" ").length > 1
                                  ? label?.trim().split(" ")[0][0] +
                                  label?.trim().split(" ")[1][0]
                                  : label?.trim().split(" ")[0][0]
                                  }`}</div>
                                {label}
                              </div>
                            );
                          }}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div
                      ref={(el) => (sectionRefs.current[3] = el)}
                      className={` ${current === 3
                        ? styles.activeSection
                        : styles.inactiveSection
                        }`}
                    >
                      <div className={styles.headingContainer}>
                        <h3 className={styles.heading}>
                          {t("brief_attachments")}
                        </h3>
                      </div>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("brief")}
                          </label>
                        }
                        name="brief"
                        rules={[
                          { required: true, message: t("brief_required") },
                        ]}
                        className="removeStars"
                      >
                        <TextArea
                          showCount
                          maxLength={1000}
                          rows={4}
                          className={`lavad-input lavad-input-lg lavad-textarea ${styles.removeResize}`}
                          placeholder={t("brief_placeholder")}
                        />
                      </Form.Item>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("dos'")}{" "}
                            <span style={{ fontWeight: "400" }}>
                              ({t("optional")})
                            </span>
                          </label>
                        }
                        name="influncers_dos"
                      >
                        <TextArea
                          showCount
                          maxLength={1000}
                          rows={4}
                          className={`lavad-input lavad-input-lg lavad-textarea ${styles.removeResize}`}
                          placeholder={t("influncers_do")}
                        />
                      </Form.Item>
                      <Form.Item
                        label={
                          <label data-private className={styles.label}>
                            {t("donts'")}{" "}
                            <span style={{ fontWeight: "400" }}>
                              ({t("optional")})
                            </span>
                          </label>
                        }
                        name="influncers_donts"
                      >
                        <TextArea
                          showCount
                          maxLength={1000}
                          rows={4}
                          className={`lavad-input lavad-input-lg lavad-textarea ${styles.removeResize}`}
                          placeholder={t("influncers_shouldn't")}
                        />
                      </Form.Item>
                      <div>
                        <Form.Item
                          label={
                            <label data-private className={styles.label}>
                              {t("attachments")}{" "}
                              <span style={{ fontWeight: "400" }}>
                                ({t("optional")})
                              </span>
                            </label>
                          }
                          className={styles.label}
                          name="attachments"
                        >
                          <Dragger
                            multiple
                            fileList={fileList}
                            beforeUpload={handleUpload}
                            itemRender={renderUploadedItem}
                            className={"uploader"}
                          >
                            <FiPaperclip />
                            <div>
                              <p className={styles.info}>
                                {t("drag_and_drop")}
                              </p>
                              <p className={styles.subInfo}>
                                {t("attachments_format")}
                              </p>
                            </div>
                          </Dragger>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                  {openClientDrawer && (
                    <SearchClients
                      intialValue={clientIntialValue}
                      openDrawer={openClientDrawer}
                      setOpenDrawer={setOpenClientDrawer}
                      onSelectClient={handleClientSelect}
                      loading={clientsLoading}
                      clients={clients}
                      onAddNew={() => {
                        setOpenCreate(true);
                      }}
                    />
                  )}
                  {openBrandDrawer && (
                    <BrandSearch
                      intialValue={brandIntialValue}
                      openDrawer={openBrandDrawer}
                      setOpenDrawer={setOpenBrandDrawer}
                      onSelectBrand={handleBrandSelect}
                      onAddNew={() => {
                        setOpenCreateBrand(true);
                      }}
                    />
                  )}
                  {openCreate && (
                    <Drawer
                      mask={false}
                      placement={dir() === "ltr" ? "right" : "left"}
                      onClose={() => { }}
                      footer={
                        <div className={styles.clientFooter}>
                          <button
                            onClick={checkIfHasData}
                            className="lavad-btn lavad-btn-lg lavad-btn-outline"
                          >
                            {t("cancel")}
                          </button>
                          <button
                            disabled={createClientLoading}
                            onClick={() => clientForm.submit()}
                            className="lavad-btn lavad-btn-lg lavad-btn-primary"
                          >
                            {createClientLoading ? (
                              <span className="icon-loader spin"></span>
                            ) : (
                              t("save_add_client")
                            )}
                          </button>
                        </div>
                      }
                      rootClassName="lavad-drawer-wrapper lavad-drawer-same-modal-level"
                      className="lavad-drawer alignTitleStart"
                      open={openCreate}
                      title={
                        <div className={styles.header}>
                          <HiOutlineChevronLeft
                            onClick={() => {
                              setOpenCreate(false);
                            }}
                          />{" "}
                          {t("add_new_client")}
                        </div>
                      }
                    >
                      <div className={styles.clientContentWrapper}>
                        <CreateClient
                          fromCampaign={true}
                          setFormValues={setClientFormValues}
                          openStatus={openCreate}
                          setErrors={setCreateErrors}
                          appUsers={users}
                          errors={createErrors}
                          loading={createClientLoading}
                          onSubmitHandler={onSubmitClientHandler}
                          form={clientForm}
                        />
                      </div>
                    </Drawer>
                  )}
                  {openCreateBrand && (
                    <Drawer
                      mask={false}
                      placement={dir() === "ltr" ? "right" : "left"}
                      onClose={() => { }}
                      footer={
                        <div className={styles.clientFooter}>
                          <button
                            onClick={() => {
                              setOpenCreateBrand(false);
                              brandForm.resetFields();
                            }}
                            className="lavad-btn lavad-btn-lg lavad-btn-outline"
                          >
                            {t("cancel")}
                          </button>
                          <button
                            disabled={createBrandLoading || brandLogoLoading}
                            onClick={handleBrandSubmit}
                            className="lavad-btn lavad-btn-lg lavad-btn-primary"
                          >
                            {createBrandLoading ? (
                              <span className="icon-loader spin"></span>
                            ) : (
                              t("save_add_brand")
                            )}
                          </button>
                        </div>
                      }
                      rootClassName="lavad-drawer-wrapper lavad-drawer-same-modal-level"
                      className="lavad-drawer alignTitleStart"
                      open={openCreateBrand}
                      title={
                        <div className={styles.header}>
                          <HiOutlineChevronLeft
                            onClick={() => {
                              setOpenCreateBrand(false);
                            }}
                          />
                          {t("add_new_brand")}
                        </div>
                      }
                    >
                      <div className={styles.clientContentWrapper}>
                        <AddBrand
                          setBrandLogoLoading={setBrandLogoLoading}
                          form={brandForm}
                          onSubmitHandler={submitBrand}
                          clients={clients}
                          setErrors={setBrandErrors}
                          errors={brandErrors}
                        />
                      </div>
                    </Drawer>
                  )}
                </Col>
              </Col>

              {!collapsedView && (
                <Col span={24} className={styles.footerColContainer}>
                  <Col span={10}>
                    <footer className={styles.footerContainer}>
                      <div className={styles.footerContent}>
                        <div className={styles.buttonGrid}>
                          <Button
                            className={` lavad-btn-lg lavad-btn-outline ${current === 0 ? styles.hidden : ""
                              }`}
                            onClick={handleBack}
                          >
                            {t("back")}
                          </Button>

                          {isLastSection ? (
                            <Button
                              onClick={handleFinish}
                              className={`lavad-btn lavad-btn-lg lavad-btn-primary ${loading ? "loading" : ""
                                }`}
                              disabled={
                                loadingRelationship ||
                                loading ||
                                !!fileList?.find(
                                  (a: any) => a.status == "uploading"
                                )
                              }
                            >
                              {loading ? (
                                <span className="icon-loader spin"></span>
                              ) : (
                                t("finish")
                              )}
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                handleContinue();
                              }}
                              className="lavad-btn-lg lavad-btn-primary"
                            >
                              {t("continue")}
                            </Button>
                          )}
                        </div>
                      </div>
                    </footer>
                  </Col>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Modal>
      <Modal
        closeIcon={<HiXMark height={24} width={24} />}
        centered
        onCancel={() => setLeavePageModalOpen(false)}
        className="alert_modal"
        title={t("discard_edit")}
        open={leavePageModalOpen}
        footer={
          <div className={styles.alertModalFooter}>
            <Button
              onClick={() => setLeavePageModalOpen(false)}
              className="lavad-btn lavad-btn-md lavad-btn-outline"
              key="back"
            >
              {t("keep_edit")}
            </Button>
            <Button
              className="lavad-btn lavad-btn-md lavad-btn-danger-primary"
              key="submit"
              type="primary"
              onClick={() => {
                setLeavePageModalOpen(false);
                setModalOpen(false);
              }}
            >
              {t("leave")}
            </Button>
          </div>
        }
      >
        <p className="body">{t("edit_data")}</p>
      </Modal>
    </>
  );
};

export default NewCreateCampaign;
