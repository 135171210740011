import { HiOutlineCheckCircle, HiOutlinePencil, HiOutlineXCircle } from 'react-icons/hi2'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { AuthStateContext } from '../../Context/MainContext';
import { Button, Input, notification, Spin, Upload } from 'antd';
import uploadFileToBlob from '../../Utils/upload_azure_storage';
import uploaderImg from '../../assets/imgs/agency-uploader-default.svg'
import { getImageDimensions, isValidURL } from '../../Utils/helpers';
import getPalette from 'tailwindcss-palette-generator';
import { editAgencyData } from '../../Context/Actions/settings';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';

const GeneralSettings = () => {
    const {
        t,
        i18n: { changeLanguage, dir },
    } = useTranslation();
    const {
        state: { lookups, agency },
        dispatch,

    } = useContext(AuthStateContext);
    const [showEdit, setShowEdit] = useState(false)
    const [image, setImage] = useState("")
    const [loadingImage, setLoadingImage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        logo: "",
        name: "",
        link: "",
        color: ""
    })
    const [submitted, setSubmitted] = useState(false)
    const removeImg = (e: any) => {
        setData(prev => ({ ...prev, logo: '' }));
        e.preventDefault()
        e.stopPropagation()

    }
    useEffect(() => {
        if (agency) {
            setData({
                logo: agency.logoUrl,
                name: agency.name ? agency.name : '_',
                link: agency.websiteUrl ? agency.websiteUrl : '',
                color: agency.primaryColor ? agency.primaryColor : "#5f5bf9"
            })
        }
    }, [agency])
    const checkIfFieldsAreValid = () => {
        if (data.link.trim().length) {
            return data.name.trim().length && isValidURL(data.link) && /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(data.color);
        } else {
            return data.name.trim().length && /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(data.color);
        }
    }

    const validateExtension = (file: any) => {
        if (!['png'].includes(file.name.split(".")[file.name.split(".").length - 1].toLowerCase())) {
            notification.destroy()
            notification.open({
                placement: dir() === "rtl" ? "topLeft" : "topRight",
                message: t("error_label"),
                description: `${t("format_not_supported")} "${file.name.split(".")[file.name.split(".").length - 1].toLowerCase()}"`,
                icon: <HiOutlineXCircle className="danger-text" />,
                className: "no-wrap",
            });
            return;
        }
    }

    const handleUpload = async (file: any) => {
        if (showEdit) {
            // const dimensions = await getImageDimensions(file)
            // console.log(dimensions)
            // if (dimensions.width < 300 || dimensions.height < 80) {
            //     notification.destroy()
            //     notification.open({
            //         placement: dir() === "rtl" ? "topLeft" : "topRight",
            //         message: t("error_label"),
            //         description: `${t("dimensions_not_supported")}`,
            //         icon: <HiOutlineXCircle className="danger-text" />,
            //         className: "no-wrap",
            //     });
            //     return;
            // }
            if (!['png'].includes(file.name.split(".")[file.name.split(".").length - 1].toLowerCase())) {
                notification.destroy()
                notification.open({
                    placement: dir() === "rtl" ? "topLeft" : "topRight",
                    message: t("error_label"),
                    description: `${t("format_not_supported")} "${file.name.split(".")[file.name.split(".").length - 1].toLowerCase()}"`,
                    icon: <HiOutlineXCircle className="danger-text" />,
                    className: "no-wrap",
                });
                return;
            }
            try {
                setLoadingImage(true)
                const uploadedImgUrl = await uploadFileToBlob("agency-logos", file);
                setLoadingImage(false)
                setData(prev => ({ ...prev, logo: uploadedImgUrl }));

            } catch (error) {
                console.error('Upload failed:', error);
                setLoadingImage(false)

            }
        }
    };
    const cancel = () => {
        // set state with old data
        setSubmitted(false)
        setShowEdit(false)
        setData({
            logo: agency.logoUrl,
            name: agency.name,
            link: agency.websiteUrl ? agency.websiteUrl : '_',
            color: agency.primaryColor ? agency.primaryColor : "#5f5bf9"
        })
    }

    const changeTheme = (color: string) => {
        const palette = getPalette({ color, name: 'primary' })
        localStorage.setItem("primaryColor", color)
        document.documentElement.style.setProperty('--primary-700', palette.primary["700"])
        document.documentElement.style.setProperty('--primary-600', palette.primary["600"])
        document.documentElement.style.setProperty('--primary-500', palette.primary["500"])
        document.documentElement.style.setProperty('--primary-400', palette.primary["400"])
        document.documentElement.style.setProperty('--primary-300', palette.primary["300"])
        document.documentElement.style.setProperty('--primary-200', palette.primary["200"])
        document.documentElement.style.setProperty('--primary-100', palette.primary["100"])
        document.documentElement.style.setProperty('--primary-50', palette.primary["50"])
    }

    const submit = async () => {
        setSubmitted(true)
        if (checkIfFieldsAreValid()) {
            setLoading(true)

            const newAgency = await editAgencyData(agency.id, {
                basicInfo: {
                    logoUrl: data.logo || null,
                    primaryColor: data.color,
                    name: data.name,
                    websiteUrl: (data.link && (!data.link.includes("http://") && !data.link.includes("https://"))) ? `https://${data.link}` : data.link
                }
            })
            console.log(newAgency, "newAgency")
            if (!newAgency?.error) {
                dispatch({
                    type: "AGENCY_DATA", payload: {
                        "id": agency.id,
                        "logoUrl": data.logo || null,
                        "primaryColor": `${data.color}`,
                        "name": data.name,
                        "websiteUrl": (data.link && (!data.link.includes("http://") && !data.link.includes("https://"))) ? `https://${data.link}` : data.link
                    }
                })
                // changeTheme(`${data.color}`)
                setShowEdit(false)
                localStorage.setItem("agency", JSON.stringify({
                    "id": agency.id,
                    "logoUrl": data.logo,
                    "primaryColor": `${data.color}`,
                    "name": data.name,
                    "websiteUrl": (data.link && (!data.link.includes("http://") && !data.link.includes("https://"))) ? `https://${data.link}` : data.link
                }))
                notification.open({
                    placement: dir() === "rtl" ? "topLeft" : "topRight",
                    message: t("success_label"),
                    description: t("preferences_updated"),
                    icon: <HiOutlineCheckCircle className="success-text" />,
                    className: "no-wrap",
                });
            }
            setLoading(false)

        }

    }
    const cropperProps = {
        aspect: 300 / 80,
        modalClassName: 'cropper-modal',
        modalTitle: t("cropper_title"),
        modalOk: t('save'),
        modalCancel: t('cancel'),
        fillColor:"transparent",
        beforeCrop: (file: any) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const allowedExtensions = ['png'];
            if (!allowedExtensions.includes(fileExtension)) {
                notification.destroy();
                notification.open({
                    placement: dir() === "rtl" ? "topLeft" : "topRight",
                    message: t("error_label"),
                    description: `${t("format_not_supported")} "${fileExtension}"`,
                    icon: <HiOutlineXCircle className="danger-text" />,
                    className: "no-wrap",
                });
                return false;
            } else {
                return true;
            }
        }

    }
    return (
        <div className={styles.pref}>
            <div className={styles.prefContainer}>
                <div className={styles.header}>
                    <div className={styles.tit}>
                        <p>{t('agency_preferences')}</p>
                        <span>{t('manage_edit_agency')}</span>
                    </div>
                    {!showEdit && <button
                        className={`lavad-btn-icon lavad-btn-outline btn-icon ${styles.editBtn}`}
                        onClick={() => setShowEdit(true)}
                    >
                        <HiOutlinePencil width={20} height={20} />{" "}
                    </button>}
                </div>
                <div className={styles.seperator}></div>
                {agency ? <div className={styles.info}>
                    <div className={`${styles.uploader} agency-upload ${!showEdit ? styles.disableUploader : ''} `}>
                        <ImgCrop {...cropperProps}>
                            <Upload
                                beforeUpload={handleUpload}

                            >
                                <div
                                >
                                    {(!data.logo || loadingImage) ? <button className={styles.placeholderImg} type="button" disabled={loadingImage}>
                                        {!data.logo && !loadingImage ? <img src={uploaderImg} /> : <span className="icon-loader spin"></span>}
                                    </button> : <button className={styles.uploadedImage} type="button" disabled={loadingImage}>
                                        <div className={styles.logoContainer}>
                                            <img src={data.logo} alt='brand-logo' />
                                        </div>
                                    </button>
                                    }
                                </div>
                            </Upload>
                        </ImgCrop>
                        <div className={styles.info}>
                            <div className={styles.logo_info}>
                                <label>{t("agency_logo")}</label>
                                <p>{t("format_png")}</p>
                            </div>
                            <ImgCrop {...cropperProps}>
                                <Upload
                                    beforeUpload={handleUpload}

                                >{!data.logo ? showEdit ? <button className={styles.defaultUpload} disabled={loadingImage} type='button'>{t("change")}</button> : '' : !data.logo && loadingImage ? <Spin /> : showEdit ? <div className={styles.changeButtons}><button type='button' disabled={loadingImage} className={styles.change}>{t('change')}</button><button disabled={loadingImage} type='button' onClick={removeImg} className={styles.remove}>{t('remove')}</button></div> : ""}
                                </Upload>
                            </ImgCrop>
                        </div>
                    </div>
                    <div className={styles.name}>
                        <label>
                            {t('name')} <span className='required'>*</span>
                        </label>
                        {showEdit ? <Input
                            placeholder={t('agency_name_placeholder')}
                            style={{ maxWidth: "326px" }}
                            onChange={(e) => setData((prev: any) => ({ ...prev, name: e.target.value }))}
                            className={`lavad-input lavad-input-lg  ${submitted && data.name.trim().length == 0 ? 'ant-input-status-error' : ''}`}
                            value={data.name}
                        /> : <p>{data.name}</p>}
                    </div>
                    {!!(data.name.trim().length == 0) && submitted && showEdit ? <p className='error'>{t('valid_name')}</p> : ''}
                    <div className={styles.website}>
                        <label>
                            {t('website_link')}
                        </label>
                        {showEdit ? <Input
                            style={{ width: "326px" }}
                            addonBefore={"http://"}
                            value={!data.link.trim() ? "" : data.link}
                            className={`lavad-input-addon lavad-input-addon-lg ltr-whole-input ${submitted && data.link.trim() && !isValidURL(data.link) ? 'ant-input-status-error' : ''}`}
                            placeholder={t("website_placeholder")}
                            onChange={(e) => { setData((prev: any) => ({ ...prev, link: e.target.value })); }}

                        /> : !data.link.trim() ? "_" : <a target='_blank' href={data.link}>{data.link}</a>}
                    </div>
                    {data.link.trim() && !isValidURL(data.link) && submitted && showEdit ? <p className='error'>{t('website_pattern')}</p> : ''}
                    <div className={styles.themeContainer}>
                        <div className={styles.themeHeader}>
                            <p>{t("custom_theme")}</p>
                            <span>{t("adjust_theme")}</span>
                        </div>
                        <div className={styles.seperator}></div>
                        <div className={styles.colors}>
                            <p>{t("primary_colors")} <span className='required'>*</span></p>
                            {showEdit ?
                                <div className={styles.pickerContainer}>
                                    <div className={`${styles.picker} ${styles.editablePicker} ${!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(data.color) && submitted ? 'ant-input-status-error' : ''}`}>
                                        <div className={styles.pickerCont}>
                                            <div style={{ background: `${data.color}` }} className={styles.pickedColor}></div>
                                        </div>
                                        <div className={`${styles.colorInput}`}>
                                            <input placeholder={t('color_placeholder')}
                                                maxLength={7} onChange={(e) => { setData((prev: any) => ({ ...prev, color: e.target.value })); }} value={`${data.color}`} />
                                        </div>

                                    </div>
                                    {!!!data.color && <svg onClick={() => setData((prev: any) => ({ ...prev, color: agency.primaryColor || "#5f5bf9" }))} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g id="refresh-ccw-05">
                                            <path id="Icon" d="M8.54661 19.7675C10.9457 20.8319 13.8032 20.7741 16.2502 19.3613C20.3157 17.0141 21.7086 11.8156 19.3614 7.75008L19.1114 7.31706M4.63851 16.2502C2.2913 12.1847 3.68424 6.98619 7.74972 4.63898C10.1967 3.22621 13.0542 3.16841 15.4533 4.23277M2.49341 16.3338L5.22546 17.0659L5.95751 14.3338M18.0426 9.6659L18.7747 6.93385L21.5067 7.6659" stroke="#4F3DEE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                    </svg>}
                                </div>
                                :
                                <div className={styles.picker}>
                                    <div style={{ background: `${data.color}` }} className={styles.pickedColor}></div>
                                    <p className={styles.color}>{data.color}</p>
                                </div>



                            }
                        </div>
                        {!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(data.color) && submitted && showEdit ? <p className='error'>{t('valid_color')}</p> : ''}
                    </div>
                </div> : <div className={styles.loadingScreen}>
                    <div className={`shimmer ${styles.loadingLogo}`}>

                    </div>
                    <div className={`shimmer ${styles.loadingSec}`}>

                    </div>
                    <div className={`shimmer ${styles.loadingSec}`}>

                    </div>
                    <div className={`shimmer ${styles.loadingSec}`}>

                    </div>
                </div>}
            </div>
            {showEdit ? <div className={styles.prefFooter}>
                <Button
                    disabled={loading || loadingImage}
                    className={`lavad-btn lavad-btn-lg lavad-btn-primary ${loading || loadingImage ? "loading" : ""
                        }`}
                    onClick={submit}
                >
                    {loading || loadingImage ? (
                        <span className="icon-loader spin"></span>
                    ) : (
                        t("save_changes")
                    )}
                </Button>
                <Button
                    className="lavad-btn lavad-btn-lg lavad-btn-ghost"
                    onClick={cancel}
                >
                    {t("cancel")}
                </Button>
            </div> : ""}
        </div>
    )
}

export default GeneralSettings